import { DatePipe } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ParadeService } from "src/app/shared/services/parade.service";

@Component({
  selector: "parade-create-modal",
  templateUrl: "parade-create-modal.html",
  styleUrls: ["parade-create-modal.scss"],
  providers: [DatePipe],
})
export class ParadeCreateModalDialogComponent {
  paradeData: any = [];
  loading = false;

  paradeForm = new FormGroup({
    name: new FormControl("", [Validators.required]),
  });

  constructor(
    private readonly spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<ParadeCreateModalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly toastrService: ToastrService,
    private readonly paradeService: ParadeService
  ) {}

  ngOnInit() {
    this.paradeData = this.data.paradeData;
  }

  createParade() {
    const namesArr: any = [];
    const filesArr: any = [];
    this.paradeData.forEach((element: any) => {
      namesArr.push(element.label);
      filesArr.push(element.file);
    });
    const formData = new FormData();
    formData.append("name", this.paradeForm.value.name);
    formData.append("names", JSON.stringify(namesArr));
    filesArr.forEach((e: any) => {
      formData.append("files", e);
    });
    this.spinner.show();
    this.loading = true;
    this.paradeService.createParade(formData).subscribe((res: any) => {
      this.spinner.hide();
      this.loading = false;
      this.toastrService.success("Parade created successfully...");
      this.dialogRef.close();
      this.closeModal(res.id);
    });
  }

  closeModal(id: any): void {
    this.dialogRef.close(id);
  }
}
