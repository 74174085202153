import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ChallengeWorkbenchService {
  private readonly dataSubject = new BehaviorSubject<string>(""); // Initial value can be empty or default
  data$: Observable<string> = this.dataSubject.asObservable();

  private readonly dataSubjectResponse = new BehaviorSubject<string>("");
  dataResponse$: Observable<string> = this.dataSubjectResponse.asObservable();

  private readonly dataSubjectComment = new BehaviorSubject<string>("");
  dataComment$: Observable<string> = this.dataSubjectComment.asObservable();

  constructor(private readonly http: HttpClient) {}

  setData(newData: string) {
    this.dataSubject.next(newData);
  }

  setResponseData(newData: string) {
    this.dataSubjectResponse.next(newData);
  }

  setCommentData(newData: string) {
    this.dataSubjectComment.next(newData);
  }

  getOkrList() {
    return this.http.get(`okr/objectives-title/`);
  }

  getAllServiceProviderList(queryName: any) {
    return this.http.get(
      `challenge_workbench/service_provider/?starts_with=${queryName}`
    );
  }
  getAllVendorList(queryName: any) {
    return this.http.get(`vendors/?starts_with=${queryName}`);
  }
  getServiceProviderById(id: any) {
    return this.http.get(`challenge_workbench/service_provider/${id}/`);
  }

  createChallengeStatement(payload: any) {
    return this.http.post(`challenge_workbench/challenge_statement/`, payload);
  }
  updateChallengeStatement(payload: any, id: any) {
    return this.http.patch(
      `challenge_workbench/challenge_statement/${id}/`,
      payload
    );
  }
  getChallengeStatement() {
    return this.http.get(`challenge_workbench/challenge_statement/`);
  }

  getFilteredChallengeStatement(queryParams: any) {
    return this.http.get(
      `challenge_workbench/challenge_statement/?${queryParams}`
    );
  }

  createServiceProvider(payload: any) {
    return this.http.post(`challenge_workbench/service_provider/`, payload);
  }
  updateServiceProvider(payload: any, id: any) {
    return this.http.patch(
      `challenge_workbench/service_provider/${id}/`,
      payload
    );
  }

  getChallengeStatementById(id: any) {
    return this.http.get(`challenge_workbench/challenge_statement/${id}/`);
  }
  downloadAttachmentFile(id: any) {
    return this.http.get(
      `challenge_workbench/challenge_statement_files/challenge_documents/${id}/`,
      { responseType: "blob" }
    );
  }
  deleteChallengeStatementFile(id: any) {
    return this.http.delete(
      `challenge_workbench/challenge_statement_files/${id}/`
    );
  }

  getChallengeStatementResponse(id: any) {
    return this.http.get(
      `challenge_workbench/response/?challengestatement_id=${id}`
    );
  }
  getChallengeStatementResponseById(id: any) {
    return this.http.get(`challenge_workbench/response/${id}/`);
  }

  getIdeaPitchResponseById(id: any) {
    return this.http.get(`ideapitch/idea_pitch/${id}/`);
  }

  downloadResponseAttachmentFile(id: any) {
    return this.http.get(
      `challenge_workbench/responsefiles/response_documents/${id}/`,
      { responseType: "blob" }
    );
  }
  downloadIdeaPitchFile(id: any) {
    return this.http.get(
      `ideapitch/ideapitch_files/ideapitch_documents/${id}/`,
      { responseType: "blob" }
    );
  }
  postServiceProviderResponse(payload: any) {
    return this.http.post("challenge_workbench/response/", payload);
  }

  updateServiceProviderResponse(payload: any, resId: any) {
    return this.http.patch(`challenge_workbench/response/${resId}/`, payload);
  }
  deleteResponseAttachmentFile(id: any) {
    return this.http.delete(`challenge_workbench/responsefiles/${id}/`);
  }
  getResponseComments(responseId: any) {
    return this.http.get(
      `challenge_workbench/comment/?response_id=${responseId}`
    );
  }
  postComments(formData: any) {
    return this.http.post(`challenge_workbench/comment/`, formData);
  }
  downloadCommentFile(id: any) {
    return this.http.get(
      `challenge_workbench/comment/comment_document/${id}/`,
      { responseType: "blob" }
    );
  }
  moveToAccelerator(data: any) {
    return this.http.post(`accelerators/accelerators/`, data);
  }

  getDeliveryAccelerator() {
    return this.http.get(`accelerators/accelerators/`);
  }

  getFilteredDeliveryAccelerator(qParam: string) {
    return this.http.get(`accelerators/accelerators/?${qParam}`);
  }

  getDeliveryAcceleratorById(id: any) {
    return this.http.get(`accelerators/details/${id}/`);
  }
  rejectAcceleratorById(payload: any, id: any) {
    return this.http.patch(`accelerators/accelerators/${id}/`, payload);
  }
  rejectAcceleratorPatchById(payload: any, id: any) {
    return this.http.patch(`accelerators/details/${id}/`, payload);
  }
  postCommercials(payload: any) {
    return this.http.post(`accelerators/details/`, payload);
  }
  patchCommercials(payload: any, id: any) {
    return this.http.patch(`accelerators/details/${id}/`, payload);
  }

  getRoiValue() {
    return this.http.get(`base_value/?type=roi`);
  }

  getInvestmentInfo(vendorId: any) {
    return this.http.get(`finance/investments/blended_cost_by_investment/${vendorId}/`);
  }

  deleteChallenge(id: any) {
    return this.http.delete(`challenge_workbench/challenge_statement/${id}/`);
  }
}
