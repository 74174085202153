import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { setupTextFilters } from "src/app/shared/helpers/filter-utils";

@Component({
  selector: "app-input",
  templateUrl: "./input.component.html",
  styleUrls: ["./input.component.scss"],
})
export class InputComponent implements OnInit {
  @Input() placeholderText: string;
  @Input() controlName: string;
  @Input() width: string;
  @Output() inputValue = new EventEmitter();
  value: any;
  inputText = new FormControl("");

  constructor() {}

  ngOnInit(): void {
    const filterControls = [this.inputText];
    setupTextFilters(filterControls, () => this.onChange());
  }

  onChange() {
    this.inputValue.emit(this.inputText.value);
  }

  clearField() {
    this.inputText.setValue("");
    this.inputValue.emit(this.inputText.value);
  }
}
