<div *ngIf="filterCfg?.inputs" class="filter-holder">
  <form>
    <div class="d-flex">
      <div style="width: 50px; margin-right: 5px">
        <div class="filter-text">
          <span class="hld">
            <img
              *ngIf="!isFilterhasValue"
              src="./assets/images/filter-cp.png"
              alt="filter"
            />
            <img
              *ngIf="isFilterhasValue"
              class="cursor-pointer"
              title="Clear All"
              (click)="clearAllFilter()"
              src="./assets/images/clear-filter.png"
              alt="clear"
            />
          </span>
        </div>
      </div>
      <div class="flex-grow-1 vscroll">
        <div *ngFor="let item of filterCfg?.inputs">
          <div class="form-item" *ngIf="item.type === 'searchable-multiselect'">
            <app-searchable-multiselect
              [controlName]="item.field"
              [placeholderText]="item.placeholder"
              [options]="item.options"
              [isDisabled]="item.disabled"
              (multiSelectValues)="
                handleSearchableMultiSelectValue($event, item.field)
              "
            ></app-searchable-multiselect>
          </div>
          <div class="form-item" *ngIf="item.type === 'searchable-select'">
            <app-searchable-select
              [controlName]="item.field"
              [placeholderText]="item.placeholder"
              [options]="item.options"
              [isDisabled]="item.disabled"
              (selectValue)="handleSearchableSelectValue($event, item.field)"
            ></app-searchable-select>
          </div>
          <div class="form-item" *ngIf="item.type === 'input'">
            <app-input
              [controlName]="item.field"
              [placeholderText]="item.placeholder"
              (inputValue)="handleInputOrDateValue($event, item.field)"
              [width]="item.width"
            ></app-input>
          </div>
          <div class="form-item" *ngIf="item.type === 'datepicker'">
            <app-datepicker
              [controlName]="item.field"
              [placeholderText]="item.placeholder"
              (selectDate)="handleInputOrDateValue($event, item.field)"
            ></app-datepicker>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
