import {
  AfterViewInit,
  ElementRef,
  Inject,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
  Component,
  OnInit,
} from "@angular/core";
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import html2canvas from "html2canvas";
import { MessageService } from "./shared/services/message.service";
import { ToastrService } from "ngx-toastr";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ParadeCreateModalDialogComponent } from "./pages/parade/parade-create-modal/parade-create-modal";
import { ParadeExistingModalDialogComponent } from "./pages/parade/parade-existing-modal/parade-existing-modal";
import { LoggingService } from "./logging.service";
import { AuthService } from "./shared/services/auth.service";

@Component({
  selector: "add-file",
  templateUrl: "./add-file-modal.html",
})
export class AddFileModalComponent implements AfterViewInit {
  filelabel: any;
  fileForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<AddFileModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.initializeFileForm();
  }

  initializeFileForm() {
    this.fileForm = new FormGroup({
      label: new FormControl("", [Validators.required]),
    });
  }

  ngAfterViewInit() {
    this.initializeFileForm();
  }

  saveTemplate() {
    this.dialogRef.close(this.fileForm.value.label);
  }

  cancel() {
    this.dialogRef.close(false);
  }
}

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  @ViewChild("screenshotContainer", { static: false })
  screenshotContainer: ElementRef;
  private selectionBox: HTMLElement;
  currentCaptureStatus = false;
  paradeImgArray: any = [];
  iframeEmbedded = false;
  isVendor = false;
  leftX: number;
  topY: number;
  title = "kairhos-front-end";
  isLoggedIn = false;
  windowScrolled = false;
  constructor(
    private readonly router: Router,
    private readonly spinner: NgxSpinnerService,
    private readonly renderer: Renderer2,
    private readonly messageService: MessageService,
    private readonly toastrService: ToastrService,
    private readonly matDialog: MatDialog,
    private readonly activatedRoute: ActivatedRoute,
    private readonly loggingService: LoggingService,
    private readonly authService: AuthService
  ) {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      if (params?.embedded === "true") {
        this.messageService.setIframeData(true);
        this.iframeEmbedded = true;
      }
    });

    this.router.events.subscribe((e: any) => {
      this.navigationInterceptor(e);
    });
  }

  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: any): void {
    if (event instanceof NavigationStart) {
      //  this.spinner.show()
    }
    if (event instanceof NavigationEnd) {
      this.spinner.hide();
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.spinner.hide();
    }
    if (event instanceof NavigationError) {
      this.spinner.hide();
    }
  }
  ngOnInit(): void {
    this.authService.isVendorLogin.subscribe((res: any) => {
      this.isVendor = res;
    });
    this.authService.loginStatus.subscribe((res: any) => {
      this.isLoggedIn = res || localStorage.getItem("token");
    });
    this.messageService.getIframeData().subscribe((res: any) => {
      this.iframeEmbedded = typeof res !== "object";
    });

    window.addEventListener("scroll", () => {
      this.windowScrolled = window.scrollY !== 0;
    });

    this.messageService.getCaptureStatus().subscribe((res: any) => {
      if (typeof res === "object") {
        this.currentCaptureStatus = false;
      } else {
        this.currentCaptureStatus = res;
      }
    });
  }

  scrollToTop(): void {
    window.scrollTo(0, 0);
  }

  capture() {
    this.toastrService.info("Parade mode started");
    this.currentCaptureStatus = !this.currentCaptureStatus;
    this.messageService.setCaptureStatus(this.currentCaptureStatus);
  }

  removeCapture() {
    this.currentCaptureStatus = !this.currentCaptureStatus;
    this.messageService.setCaptureStatus(this.currentCaptureStatus);
  }

  startSelection(event: MouseEvent) {
    if (this.currentCaptureStatus) {
      this.selectionBox = this.renderer.createElement("div");
      this.renderer.setStyle(this.selectionBox, "position", "absolute");
      this.renderer.setStyle(this.selectionBox, "border", "1px solid #007bff");
      this.renderer.setStyle(this.selectionBox, "z-index", "99999999999");
      this.renderer.setStyle(this.selectionBox, "left", event.clientX + "px");
      this.renderer.setStyle(this.selectionBox, "top", event.clientY + "px");
      this.renderer.appendChild(
        this.screenshotContainer.nativeElement,
        this.selectionBox
      );

      this.leftX = event.clientX;
      this.topY = event.clientY;

      this.renderer.listen(document, "mousemove", this.adjustSelectionBox);
      this.renderer.listen(document, "mouseup", this.finishSelection);
    }
  }

  adjustSelectionBox = (event: MouseEvent) => {
    const width = Math.abs(
      event.clientX - this.selectionBox.getBoundingClientRect().left
    );
    const height = Math.abs(
      event.clientY - this.selectionBox.getBoundingClientRect().top
    );

    this.renderer.setStyle(this.selectionBox, "left", this.leftX + "px");
    this.renderer.setStyle(this.selectionBox, "top", this.topY + "px");
    this.renderer.setStyle(this.selectionBox, "width", width + "px");
    this.renderer.setStyle(this.selectionBox, "height", height + "px");
  };

  finishSelection = () => {
    this.selectionBox.style.border = "none";
    this.renderer.removeChild(
      this.screenshotContainer.nativeElement,
      this.selectionBox
    );

    const html2canvasInstance: any = html2canvas;
    const max = 50;
    const extra = 55;
    if (
      this.selectionBox.offsetWidth > max &&
      this.selectionBox.offsetHeight > max
    ) {
      let yVal: any = null;
      if (this.selectionBox.getBoundingClientRect().top - extra < 0) {
        yVal = 0;
      } else {
        yVal = this.selectionBox.getBoundingClientRect().top - extra;
      }
      html2canvasInstance(this.screenshotContainer.nativeElement, {
        x: this.selectionBox.getBoundingClientRect().left,
        y: yVal,
        width: this.selectionBox.offsetWidth,
        height: this.selectionBox.offsetHeight,
      }).then((canvas: HTMLCanvasElement) => {
        const screenshotUrl = canvas.toDataURL();
        this.urltoFile(
          screenshotUrl,
          `sample${Math.random() * 10000}.png`,
          "image/png"
        )
          .then((e: any) => {
            this.addFileLabel(screenshotUrl, e);
          })
          .catch((e) => {
            this.loggingService.error("Error capturing screenshot:", e);
          });
      });
    }
  };

  urltoFile(url: any, filename: any, mimeType: any) {
    if (url.startsWith("data:")) {
      const arr = url.split(",");
      const mime = arr[0].match(/^data:([^;]+);/)[1];
      const bstr = atob(arr[arr.length - 1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n) {
        n -= 1;
        u8arr[n] = bstr.charCodeAt(n);
      }
      const file = new File([u8arr], filename, { type: mime || mimeType });
      return Promise.resolve(file);
    }
    return fetch(url)
      .then((res) => res.arrayBuffer())
      .then((buf) => new File([buf], filename, { type: mimeType }));
  }

  addFileLabel(base64: any, file: any) {
    this.matDialog
      .open(AddFileModalComponent, {
        hasBackdrop: true,
        disableClose: true,
        width: "500px",
        maxHeight: "400px",
      })
      .afterClosed()
      .subscribe((val) => {
        if (val) {
          this.paradeImgArray.push({ base64, file, label: val });
          this.messageService.setParadeImage(this.paradeImgArray);
        }
      });
  }

  deleteItem(i: any) {
    this.paradeImgArray.splice(i, 1);
    this.messageService.setParadeImage(this.paradeImgArray);
  }

  clear() {
    this.paradeImgArray = [];
    this.messageService.setParadeImage(this.paradeImgArray);
  }

  navigateTo(url: any) {
    this.router.navigateByUrl(url);
  }

  openDetail() {
    this.paradeImgArray = [];
    this.currentCaptureStatus = !this.currentCaptureStatus;
    this.messageService.setCaptureStatus(this.currentCaptureStatus);
    this.router.navigateByUrl("parade/detail");
  }

  createParade() {
    this.matDialog
      .open(ParadeCreateModalDialogComponent, {
        width: "500px",
        maxHeight: "400px",
        data: { paradeData: this.paradeImgArray },
      })
      .afterClosed()
      .subscribe((id) => {
        if (id) {
          this.paradeImgArray = [];
          this.currentCaptureStatus = !this.currentCaptureStatus;
          this.messageService.setCaptureStatus(this.currentCaptureStatus);
          this.router.navigateByUrl("parade/detail/" + btoa(id + ""));
        }
      });
  }

  mapParade() {
    this.matDialog
      .open(ParadeExistingModalDialogComponent, {
        width: "500px",
        maxHeight: "400px",
        data: { paradeData: this.paradeImgArray },
      })
      .afterClosed()
      .subscribe((id) => {
        if (id) {
          this.paradeImgArray = [];
          this.currentCaptureStatus = !this.currentCaptureStatus;
          this.messageService.setCaptureStatus(this.currentCaptureStatus);
          this.router.navigateByUrl("parade/detail/" + btoa(id + ""));
        }
      });
  }
}
