import { BehaviorSubject, Observable } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class MessageService {
  public attritionTrendSubject: BehaviorSubject<any> = new BehaviorSubject({});
  public kRRTrendData: BehaviorSubject<any> = new BehaviorSubject({});
  public resourceTrendData: BehaviorSubject<any> = new BehaviorSubject({});
  public spendTrendData: BehaviorSubject<any> = new BehaviorSubject({});
  public staffingPhyramidData: BehaviorSubject<any> = new BehaviorSubject({});
  public applicationSelected: BehaviorSubject<any> = new BehaviorSubject({});
  public changeRequestData: BehaviorSubject<any> = new BehaviorSubject({});
  public subscribeOKRData: BehaviorSubject<any> = new BehaviorSubject({});
  public subscribeTagsData: BehaviorSubject<any> = new BehaviorSubject({});
  public subscribeOnwerData: BehaviorSubject<any> = new BehaviorSubject({});
  public subscribeWorkgroupData: BehaviorSubject<any> = new BehaviorSubject({});
  public subscribeCaptureData: BehaviorSubject<any> = new BehaviorSubject({});
  public subscribeParadeImageData: BehaviorSubject<any> = new BehaviorSubject(
    {}
  );
  public iframeData: BehaviorSubject<any> = new BehaviorSubject({});
  public newsData: BehaviorSubject<any> = new BehaviorSubject({});
  public updatedDeliveryObligation: BehaviorSubject<any> = new BehaviorSubject(
    {}
  );

  setAttritionTrendData(attritionTrendData: any) {
    this.attritionTrendSubject.next(attritionTrendData);
  }

  getAttritionTrendData(): Observable<any> {
    return this.attritionTrendSubject.asObservable();
  }

  setKRRTrendData(kRRTrendData: any) {
    this.kRRTrendData.next(kRRTrendData);
  }

  getKRRTrendData(): Observable<any> {
    return this.kRRTrendData.asObservable();
  }

  setResourceTrendData(resourceTrendData: any) {
    this.resourceTrendData.next(resourceTrendData);
  }

  getResourceTrendData(): Observable<any> {
    return this.resourceTrendData.asObservable();
  }

  setSpendTrendData(spendTrendData: any) {
    this.spendTrendData.next(spendTrendData);
  }

  getSpendTrendData(): Observable<any> {
    return this.spendTrendData.asObservable();
  }

  setStaffingPhyramidData(staffingPhyramidData: any) {
    this.staffingPhyramidData.next(staffingPhyramidData);
  }

  getStaffingPhyramidData(): Observable<any> {
    return this.staffingPhyramidData.asObservable();
  }

  setApplicationSelected(applicationSelected: any) {
    this.applicationSelected.next(applicationSelected);
  }

  getApplicationSelected(): Observable<any> {
    return this.applicationSelected.asObservable();
  }

  setChangeRequestData(changeRequestData: any) {
    this.changeRequestData.next(changeRequestData);
  }

  getChangeRequestData(): Observable<any> {
    return this.changeRequestData.asObservable();
  }

  setSubscribeOKRData(subscribeOKRData: any) {
    this.subscribeOKRData.next(subscribeOKRData);
  }

  getSubscribeOKRData(): Observable<any> {
    return this.subscribeOKRData.asObservable();
  }

  setTags(subscribeTagsData: any) {
    this.subscribeTagsData.next(subscribeTagsData);
  }

  getTags(): Observable<any> {
    return this.subscribeTagsData.asObservable();
  }

  setOwner(subscribeOnwerData: any) {
    this.subscribeOnwerData.next(subscribeOnwerData);
  }

  getOwner(): Observable<any> {
    return this.subscribeOnwerData.asObservable();
  }

  setWorkgroup(subscribeWorkgroupData: any) {
    this.subscribeWorkgroupData.next(subscribeWorkgroupData);
  }

  getWorkgroup(): Observable<any> {
    return this.subscribeWorkgroupData.asObservable();
  }

  setCaptureStatus(subscribeCaptureData: any) {
    this.subscribeCaptureData.next(subscribeCaptureData);
  }

  getCaptureStatus(): Observable<any> {
    return this.subscribeCaptureData.asObservable();
  }

  setParadeImage(subscribeParadeImageData: any) {
    this.subscribeParadeImageData.next(subscribeParadeImageData);
  }

  getParadeImage(): Observable<any> {
    return this.subscribeParadeImageData.asObservable();
  }

  setIframeData(iframeData: any) {
    this.iframeData.next(iframeData);
  }

  getIframeData(): Observable<any> {
    return this.iframeData.asObservable();
  }

  setGnews(newsData: any) {
    this.newsData.next(newsData);
  }

  getGnews(): Observable<any> {
    return this.newsData.asObservable();
  }

  setUpdatedDeliveryObligation(updatedDeliveryObligation: any) {
    this.updatedDeliveryObligation.next(updatedDeliveryObligation);
  }

  getUpdatedDeliveryObligation(): Observable<any> {
    return this.updatedDeliveryObligation.asObservable();
  }
}
