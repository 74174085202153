import { Component, OnInit, ViewEncapsulation, Inject } from "@angular/core";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import textConfiguration from "src/assets/static-text-configuration.json";
@Component({
  selector: "delete-popup",
  templateUrl: "./delete-popup.component.html",
  styleUrls: ["./delete-popup.component.scss"],
  encapsulation: ViewEncapsulation.Emulated,
})
export class DeletePopupComponent implements OnInit {
  buttonsText: any = (textConfiguration as any).buttons;
  modalPopUpText: any = (textConfiguration as any).modal_popup;

  deletionWarning = "";

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DeletePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public popupData: any
  ) {
    dialogRef.disableClose = true;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = true;
  }

  ngOnInit(): void {
    if (this.popupData) {
      this.deletionWarning = `Are you sure you want to delete <strong>${this.popupData}</strong> ? You can't restore it once deleted.`;
    }
  }

  closeDialog() {
    this.dialogRef.close(null);
  }

  delete() {
    this.dialogRef.close(true);
  }
}
