import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "getRandomColor",
})
export class GetRandomColorPipe implements PipeTransform {
  transform(index: any = "", type: any = ""): any {
    let ColorRandom;
    if (type === "chart") {
      const colorsChart: any[] = [
        "#87aafc",
        "#0bc2e2",
        "#f8ae43",
        "#8495a6",
        "#9594f1",
        "#ed5c49",
        "#f9a914",
        "#87AAFC",
      ];
      const Textcolors: any[] = [
        "#eef3ff",
        "#ebfbfd",
        "#fdf4e7",
        "#eaeff3",
        "#e9e9ff",
        "#ffffff",
        "#f9a9149e",
        "#87AAFC9e",
      ];
      let color = index % colorsChart.length;
      ColorRandom = {
        bgColor: colorsChart[color],
        textColor: Textcolors[color],
      };
    } else if (type === "bubble_benefits") {
      const colors: any[] = [
        "#07b797",
        "#1a85d6",
        "#ff6f00",
        "#232459",
        "#d40f78",
        "#b22727",
        "#5126a2",
        "#00bfc6",
        "#e10303",
      ];
      ColorRandom = index % colors.length;
      ColorRandom = colors[ColorRandom];
    } else if (type === "bubble") {
      const colors: any[] = ["#07b797", "#5126a2", "#ed0000", "#ed0086"];
      ColorRandom = index % colors.length;
      ColorRandom = colors[ColorRandom];
    } else if (type === "random") {
      const colors: any[] = [
        "#ed5c49",
        "#f9a914",
        "#0f355a",
        "#48db7e",
        "#479cdd",
        "#ff9a07",
        "#6ce197",
        "#38c4ab",
        "#df559e",
        "#f07c6c",
      ];
      const random = Math.floor(Math.random() * colors.length);
      ColorRandom = colors[random];
    } else if (type === "idea") {
      const colors: any[] = [
        "#dbfff9",
        "#E5F2FF",
        "#FFEAD9",
        "#F1F1F1",
        "#E7E7FF",
        "#FFEAEA",
        "#EDE4FF",
        "#DCFBFC",
        "#FFE1E1",
      ];
      ColorRandom = index % colors.length;
      ColorRandom = colors[ColorRandom];
    } else if (type === "table_colors") {
      const colors: any[] = ["#E7FFFB", "#F8D5FF", "#EBEBFF", "#E6F4FF"];
      ColorRandom = index % colors.length;
      ColorRandom = colors[ColorRandom];
    } else {
      const colors: any[] = [
        "#ed5c49",
        "#f9a914",
        "#2a79c9",
        "#48db7e",
        "#479cdd",
        "#ff9a07",
        "#6ce197",
        "#38c4ab",
        "#df559e",
        "#f07c6c",
      ];
      ColorRandom = index % colors.length;
      ColorRandom = colors[ColorRandom];
    }
    return ColorRandom;
  }
}
