<app-loading *ngIf="!dataLoaded"></app-loading>
<div *ngIf="dataLoaded">
  <mat-tab-group
    *ngIf="!matrixOverView"
    mat-align-tabs="start"
    (selectedTabChange)="changeTab($event)"
  >
    <mat-tab label="Balanced Scorecard">
      <div class="mt-2">
        <div style="overflow: hidden">
          <div class="row">
            <div class="col-md-12 col-lg-12 col-xl-6 mb-4">
              <div
                class="column-bg-1 d-flex align-items-center justify-content-between"
              >
                <div class="d-flex align-items-center justify-content-center">
                  <i class="fas fa-user-friends user-icon"> </i>
                  <p class="title">{{ quadrants?.customer }}</p>
                  <span
                    (click)="showPulses('customer')"
                    *ngIf="pulseCounts?.Customer?.positive_pulses > 0"
                    class="positive-pulse mb-3 ml-1"
                  >
                  </span>
                  <span
                    (click)="showPulses('customer')"
                    *ngIf="pulseCounts?.Customer?.negative_pulses > 0"
                    class="negative-pulse mb-3 ml-1"
                  >
                  </span>
                </div>
                <div class="pr-2">
                  <mat-icon (click)="quadrantInfo(140, info, 'Customer')"
                    >info</mat-icon
                  >
                </div>
              </div>
              <div *ngIf="!noDataCustomer">
                <!--Customer Card start-->
                <div class="table-responsive" style="height: 310px">
                  <table
                    id="table-sparkline"
                    class="table"
                    style="font-size: 0.5rem; border-top: medium none"
                  >
                    <ng-container
                      *ngTemplateOutlet="matricTableBody"
                    ></ng-container>
                    <tbody
                      id="tbody-sparkline"
                      class="example-list"
                      cdkDropListGroup
                      cdkDropList
                      [cdkDropListData]="customerData"
                      (cdkDropListDropped)="drop($event, 140)"
                    >
                      <tr
                        title="click to drag and drop"
                        *ngFor="let metric of customerData; index as i"
                        cdkDrag
                        class="example-box"
                        (click)="enableDragClick(i, 140)"
                        [cdkDragDisabled]="
                          dragCustomerEnableFlag[i]
                            ? dragCustomerEnableFlag[i]
                            : true
                        "
                      >
                        <td
                          (click)="
                            clickOverView(140, metric, customerData, 'Customer')
                          "
                          class="text-primary cursor-pointer"
                        >
                          <span
                            class="fw-bold text-underline"
                            matTooltip="{{ metric?.desc }}"
                            matTooltipClass="example-tooltip"
                            >{{ metric?.name }}&nbsp;
                            <span *ngIf="metric?.unit_name"
                              >({{ metric?.unit_name }})</span
                            ></span
                          >
                        </td>
                        <td>
                          <div
                            *ngIf="
                              metric?.trend?.options?.xAxis?.categories?.length
                            "
                            [chart]="metric?.trend"
                          ></div>
                          <div
                            *ngIf="
                              !metric?.trend?.options?.xAxis?.categories?.length
                            "
                            class="no-data"
                          >
                            No data to display
                          </div>
                        </td>
                        <td>
                          <span class="fw-bold">{{
                            (metric?.actual_value ? metric?.actual_value : 0)
                              | number : "1.2-2"
                          }}</span>
                        </td>
                        <td
                          class="text-center"
                          *ngIf="metric.change != 'false'"
                        >
                          <div
                            class="fw-bold"
                            [ngClass]="
                              metric.is_update == false
                                ? 'spanGreen'
                                : 'spanRed'
                            "
                          >
                            {{
                              (metric?.change ? metric?.change : 0)
                                | number : "1.2-2"
                            }}
                          </div>
                          <span class="px-2 d-block"
                            >{{ metric?.variance_per }}%</span
                          >
                        </td>
                        <td *ngIf="metric.change == 'false'"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!--Customer Card end-->
              </div>
              <div class="row" *ngIf="noDataCustomer">
                <div class="col-md-5 mx-auto mt-5">
                  <img
                    src="./assets/images/no-record.png"
                    class="mt-3"
                    height="100%"
                    width="100%"
                    alt="no records"
                  />
                </div>
                <div class="col-md-8 mx-auto">
                  <p class="text-center text-muted mt-2 font-weight-bold mb-1">
                    {{ commonText.no_data_found }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-12 col-lg-12 col-xl-6 mb-4">
              <div
                class="column-2-bg d-flex align-items-center justify-content-between"
              >
                <div class="d-flex align-items-center justify-content-center">
                  <i class="far fa-sack-dollar user-icon"> </i>
                  <p class="title">{{ quadrants?.finance }}</p>
                  <span
                    (click)="showPulses('finance')"
                    *ngIf="pulseCounts?.Finance?.positive_pulses > 0"
                    class="positive-pulse mb-3 ml-1"
                  >
                  </span>
                  <span
                    (click)="showPulses('finance')"
                    *ngIf="pulseCounts?.Finance?.negative_pulses > 0"
                    class="negative-pulse mb-3 ml-1"
                  >
                  </span>
                </div>
                <div class="pr-2">
                  <mat-icon (click)="quadrantInfo(138, info, 'Finance')"
                    >info</mat-icon
                  >
                </div>
              </div>
              <div *ngIf="!noDataFinance">
                <!--finance Card start-->
                <div class="table-responsive" style="height: 310px">
                  <table
                    id="table-sparkline"
                    class="table"
                    style="font-size: 0.5rem; border-top: medium none"
                  >
                    <ng-container
                      *ngTemplateOutlet="matricTableBody"
                    ></ng-container>
                    <tbody
                      id="tbody-sparkline"
                      class="example-list"
                      cdkDropListGroup
                      cdkDropList
                      [cdkDropListData]="financeData"
                      (cdkDropListDropped)="drop($event, 138)"
                    >
                      <tr
                        title="click to drag and drop"
                        *ngFor="let metric of financeData; index as i"
                        cdkDrag
                        class="example-box"
                        (click)="enableDragClick(i, 138)"
                        [cdkDragDisabled]="
                          dragFinanceEnableFlag[i]
                            ? dragFinanceEnableFlag[i]
                            : true
                        "
                      >
                        <td
                          (click)="
                            clickOverView(138, metric, financeData, 'Finance')
                          "
                          class="text-primary cursor-pointer"
                        >
                          <span
                            class="fw-bold text-underline"
                            matTooltip="{{ metric?.desc }}"
                            matTooltipClass="example-tooltip"
                            >{{ metric?.name }}&nbsp;
                            <span *ngIf="metric?.unit_name"
                              >({{ metric?.unit_name }})</span
                            ></span
                          >
                        </td>
                        <td>
                          <div
                            *ngIf="
                              metric?.trend?.options?.xAxis?.categories?.length
                            "
                            [chart]="metric?.trend"
                          ></div>
                          <div
                            *ngIf="
                              !metric?.trend?.options?.xAxis?.categories?.length
                            "
                            class="no-data"
                          >
                            No data to display
                          </div>
                        </td>
                        <td>
                          <span class="fw-bold">{{
                            (metric?.actual_value ? metric?.actual_value : 0)
                              | number : "1.2-2"
                          }}</span>
                        </td>
                        <td class="text-center">
                          <div
                            class="fw-bold"
                            [ngClass]="
                              metric.is_update == false
                                ? 'spanGreen'
                                : 'spanRed'
                            "
                          >
                            {{
                              (metric?.change ? metric?.change : 0)
                                | number : "1.2-2"
                            }}
                          </div>
                          <span class="px-2 d-block"
                            >{{ metric?.variance_per }}%</span
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!--finance Card end-->
              </div>
              <div class="row" *ngIf="noDataFinance">
                <div class="col-md-5 mx-auto mt-5">
                  <img
                    src="./assets/images/no-record.png"
                    class="mt-3"
                    height="100%"
                    width="100%"
                    alt="no records"
                  />
                </div>
                <div class="col-md-8 mx-auto">
                  <p class="text-center text-muted mt-2 font-weight-bold mb-1">
                    {{ commonText.no_data_found }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-12 col-lg-12 col-xl-6 mb-4">
              <div
                class="column-bg-3 d-flex align-items-center justify-content-between"
              >
                <div class="d-flex align-items-center justify-content-center">
                  <i class="fas fa-users user-icon"> </i>
                  <p class="title">{{ quadrants?.engineering }}</p>
                  <span
                    (click)="showPulses('delivery')"
                    *ngIf="pulseCounts?.Delivery?.positive_pulses > 0"
                    class="positive-pulse mb-3 ml-1"
                  >
                  </span>
                  <span
                    (click)="showPulses('delivery')"
                    *ngIf="pulseCounts?.Delivery?.negative_pulses > 0"
                    class="negative-pulse mb-3 ml-1"
                  >
                  </span>
                </div>
                <div class="pr-2">
                  <mat-icon (click)="quadrantInfo(139, info, 'Engineering')"
                    >info</mat-icon
                  >
                </div>
              </div>
              <div *ngIf="!noDataDelivery">
                <!--engg Card start-->
                <div class="table-responsive" style="height: 310px">
                  <table
                    id="table-sparkline"
                    class="table"
                    style="font-size: 0.5rem; border-top: medium none"
                  >
                    <ng-container
                      *ngTemplateOutlet="matricTableBody"
                    ></ng-container>
                    <tbody
                      id="tbody-sparkline"
                      class="example-list"
                      cdkDropListGroup
                      cdkDropList
                      [cdkDropListData]="engineerData"
                      (cdkDropListDropped)="drop($event, 139)"
                    >
                      <tr
                        title="click to drag and drop"
                        *ngFor="let metric of engineerData; index as i"
                        cdkDrag
                        class="example-box"
                        (click)="enableDragClick(i, 139)"
                        [cdkDragDisabled]="
                          dragEnggEnableFlag[i] ? dragEnggEnableFlag[i] : true
                        "
                      >
                        <td
                          (click)="
                            clickOverView(
                              139,
                              metric,
                              engineerData,
                              'Engineering'
                            )
                          "
                          class="text-primary cursor-pointer"
                        >
                          <span
                            class="fw-bold text-underline"
                            matTooltip="{{ metric?.desc }}"
                            matTooltipClass="example-tooltip"
                            >{{ metric?.name }}&nbsp;
                            <span *ngIf="metric?.unit_name"
                              >({{ metric?.unit_name }})</span
                            ></span
                          >
                        </td>
                        <td>
                          <div
                            *ngIf="
                              metric?.trend?.options?.xAxis?.categories?.length
                            "
                            [chart]="metric?.trend"
                          ></div>
                          <div
                            *ngIf="
                              !metric?.trend?.options?.xAxis?.categories?.length
                            "
                            class="no-data"
                          >
                            No data to display
                          </div>
                        </td>
                        <td>
                          <span class="fw-bold">{{
                            (metric?.actual_value ? metric?.actual_value : 0)
                              | number : "1.2-2"
                          }}</span>
                        </td>
                        <td class="text-center">
                          <div
                            class="fw-bold"
                            [ngClass]="
                              metric.is_update == false
                                ? 'spanGreen'
                                : 'spanRed'
                            "
                          >
                            {{
                              (metric?.change ? metric?.change : 0)
                                | number : "1.2-2"
                            }}
                          </div>
                          <span class="px-2 d-block"
                            >{{ metric.variance_per }}%</span
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!--engg Card end-->
              </div>
              <div class="row" *ngIf="noDataDelivery">
                <div class="col-md-5 mx-auto mt-5">
                  <img
                    src="./assets/images/no-record.png"
                    class="mt-3"
                    height="100%"
                    width="100%"
                    alt="no records"
                  />
                </div>
                <div class="col-md-8 mx-auto">
                  <p class="text-center text-muted mt-2 font-weight-bold mb-1">
                    {{ commonText.no_data_found }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-12 col-lg-12 col-xl-6 mb-4">
              <div
                class="column-bg-4 d-flex align-items-center justify-content-between"
              >
                <div class="d-flex align-items-center justify-content-center">
                  <i class="fad fa-analytics user-icon"> </i>
                  <p class="title">{{ quadrants?.people }}</p>
                  <span
                    (click)="showPulses('people')"
                    *ngIf="pulseCounts?.People?.positive_pulses > 0"
                    class="positive-pulse mb-3 ml-1"
                  >
                  </span>
                  <span
                    (click)="showPulses('people')"
                    *ngIf="pulseCounts?.People?.negative_pulses > 0"
                    class="negative-pulse mb-3 ml-1"
                  >
                  </span>
                </div>
                <div class="pr-2">
                  <mat-icon (click)="quadrantInfo(137, info, 'People')"
                    >info</mat-icon
                  >
                </div>
              </div>
              <div *ngIf="!noDataPeople">
                <!--people Card start-->
                <div class="table-responsive" style="height: 310px">
                  <table
                    id="table-sparkline"
                    class="table"
                    style="font-size: 0.5rem; border-top: medium none"
                  >
                    <ng-container
                      *ngTemplateOutlet="matricTableBody"
                    ></ng-container>
                    <tbody
                      id="tbody-sparkline"
                      class="example-list"
                      cdkDropListGroup
                      cdkDropList
                      [cdkDropListData]="peopleData"
                      (cdkDropListDropped)="drop($event, 137)"
                    >
                      <tr
                        title="click to drag and drop"
                        *ngFor="let metric of peopleData; index as i"
                        cdkDrag
                        class="example-box"
                        (click)="enableDragClick(i, 137)"
                        [cdkDragDisabled]="
                          dragPeopleEnableFlag[i]
                            ? dragPeopleEnableFlag[i]
                            : true
                        "
                      >
                        <td
                          (click)="
                            clickOverView(137, metric, peopleData, 'People')
                          "
                          class="text-primary cursor-pointer"
                        >
                          <span
                            class="fw-bold text-underline"
                            matTooltip="{{ metric.desc }}"
                            matTooltipClass="example-tooltip"
                            >{{ metric.name }}&nbsp;
                            <span *ngIf="metric?.unit_name"
                              >({{ metric?.unit_name }})</span
                            ></span
                          >
                        </td>
                        <td
                          [attr.colspan]="
                            metric.metric_name == 'req_aging' ? '2' : ''
                          "
                        >
                          <div
                            *ngIf="
                              metric?.trend?.options?.xAxis?.categories?.length
                            "
                            [chart]="metric?.trend"
                          ></div>
                          <div
                            *ngIf="
                              !metric?.trend?.options?.xAxis?.categories?.length
                            "
                            class="no-data"
                          >
                            No data to display
                          </div>
                        </td>
                        <td *ngIf="metric.actual_value != 'false'">
                          <span class="fw-bold">{{
                            (metric?.actual_value ? metric?.actual_value : 0)
                              | number : "1.2-2"
                          }}</span>
                        </td>
                        <td
                          class="text-center"
                          *ngIf="metric.change != 'false'"
                        >
                          <div
                            class="fw-bold"
                            [ngClass]="
                              metric.is_update == false
                                ? 'spanGreen'
                                : 'spanRed'
                            "
                          >
                            {{
                              (metric?.change ? metric?.change : 0)
                                | number : "1.2-2"
                            }}
                          </div>
                          <span class="px-2 d-block"
                            >{{ metric.variance_per }}%</span
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!--people Card end-->
              </div>
              <div class="row" *ngIf="noDataPeople">
                <div class="col-md-5 mx-auto mt-5">
                  <img
                    src="./assets/images/no-record.png"
                    class="mt-3"
                    height="100%"
                    width="100%"
                    alt="no records"
                  />
                </div>
                <div class="col-md-8 mx-auto">
                  <p class="text-center text-muted mt-2 font-weight-bold mb-1">
                    {{ commonText.no_data_found }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Additional Information">
      <div class="mt-2">
        <div class="accordion" id="accordionExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="assessmentHeading">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#assessmentCollapse"
                aria-expanded="true"
                aria-controls="assessmentCollapse"
                style="font-size: 13px"
              >
                {{ "Assessments (" + assessmentsList.length + ")" }}
              </button>
            </h2>
            <div
              id="assessmentCollapse"
              class="accordion-collapse collapse show"
              aria-labelledby="assessmentHeading"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body p-2">
                <div>
                  <mat-form-field class="w-50" appearance="outline">
                    <mat-label>Assessment</mat-label>
                    <mat-select
                      [(ngModel)]="currentAssessment"
                      (ngModelChange)="getAssessmentAggregateUserResponse()"
                    >
                      <ng-container *ngFor="let item of assessmentsList">
                        <mat-option [value]="item.id">
                          {{ item.name }}
                        </mat-option>
                      </ng-container>
                    </mat-select>
                  </mat-form-field>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="custom-height">
                        <div
                          class="custom-table table-responsive"
                          style="max-height: calc(100vh - 220px)"
                        >
                          <table class="table table-bordered">
                            <thead style="position: sticky; z-index: 1">
                              <tr>
                                <th
                                  *ngFor="let field of tableColumns"
                                  scope="col"
                                  class="text-center"
                                >
                                  {{ field }}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <ng-container
                                *ngFor="let group of assessmentAggregateData"
                              >
                                <tr
                                  *ngFor="
                                    let question of group.questions;
                                    let i = index
                                  "
                                >
                                  <td
                                    *ngIf="i === 0"
                                    [attr.rowspan]="group.questions.length"
                                  >
                                    {{ group.group_name }}
                                  </td>
                                  <td>{{ question.question }}</td>
                                  <td class="text-center">
                                    <highcharts-chart
                                      style="display: flex"
                                      [Highcharts]="Highcharts"
                                      [options]="question.chart"
                                      [callbackFunction]="chartCallback"
                                      [oneToOne]="true"
                                      style="
                                        display: block;
                                        width: 300px;
                                        height: 180px;
                                      "
                                    ></highcharts-chart>
                                  </td>
                                </tr>
                              </ng-container>
                            </tbody>
                            <tr *ngIf="!assessmentAggregateData?.length">
                              <td
                                colspan="10"
                                class="noRecords text-center mt-2"
                              >
                                <span class="mt-2"
                                  >{{ commonText.no_records_found }}
                                </span>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="auditHeading">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#auditCollapse"
                aria-expanded="true"
                aria-controls="auditCollapse"
                style="font-size: 13px"
              >
                {{ "Audits (" + auditsList.length + ")" }}
              </button>
            </h2>
            <div
              id="auditCollapse"
              class="accordion-collapse collapse"
              aria-labelledby="auditHeading"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body p-2">
                <div class="mt-2">
                  <mat-form-field class="w-50" appearance="outline">
                    <mat-label>Audit</mat-label>
                    <mat-select
                      [(ngModel)]="currentAudit"
                      (ngModelChange)="getAuditInstanceInfo()"
                    >
                      <mat-option value="">All</mat-option>
                      <ng-container *ngFor="let item of auditsList">
                        <mat-option [value]="item.id">
                          {{ item.name }}
                        </mat-option>
                      </ng-container>
                    </mat-select>
                  </mat-form-field>
                  <div
                    class="custom-table table-responsive"
                    style="max-height: calc(100vh - 220px)"
                  >
                    <table class="table table-bordered">
                      <thead style="position: sticky; z-index: 1">
                        <tr>
                          <th
                            *ngFor="let field of auditTableColumns"
                            scope="col"
                            class="text-center"
                          >
                            {{ field }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          *ngFor="
                            let response of auditInstanceInfo;
                            let i = index
                          "
                        >
                          <td>
                            {{ response.template_title }}
                          </td>
                          <td>
                            {{
                              (response.auditor.first_name || "") +
                                " " +
                                (response.auditor.last_name || "")
                            }}
                          </td>
                          <td>
                            {{ response.observation_count }}
                          </td>
                          <td class="text-center">
                            <highcharts-chart
                              style="display: flex"
                              [Highcharts]="Highcharts"
                              [options]="response.chart"
                              [callbackFunction]="chartCallback"
                              [oneToOne]="true"
                              style="
                                display: block;
                                width: 300px;
                                height: 180px;
                              "
                            ></highcharts-chart>
                          </td>
                        </tr>
                      </tbody>
                      <tr *ngIf="!auditInstanceInfo?.length">
                        <td colspan="10" class="noRecords text-center mt-2">
                          <span class="mt-2"
                            >{{ commonText.no_records_found }}
                          </span>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="govHeading">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#govCollapse"
                aria-expanded="true"
                aria-controls="govCollapse"
                style="font-size: 13px"
              >
                {{ "Governance Lighthouse (" + assessmentsList.length + ")" }}
              </button>
            </h2>
            <div
              id="govCollapse"
              class="accordion-collapse collapse"
              aria-labelledby="govHeading"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body p-1">
                <div class="mt-2">
                  <mat-form-field class="w-50" appearance="outline">
                    <mat-label>Governance Assessment</mat-label>
                    <mat-select
                      [(ngModel)]="currentGovAssessment"
                      (ngModelChange)="getGovAggregateUserResponse()"
                    >
                      <ng-container *ngFor="let item of govAssessmentsList">
                        <mat-option [value]="item.id">
                          {{ item.name }}
                        </mat-option>
                      </ng-container>
                    </mat-select>
                  </mat-form-field>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="custom-height">
                        <div
                          class="custom-table table-responsive"
                          style="max-height: calc(100vh - 220px)"
                        >
                          <table class="table table-bordered">
                            <thead style="position: sticky; z-index: 1">
                              <tr>
                                <th
                                  *ngFor="let field of tableColumns"
                                  scope="col"
                                  class="text-center"
                                >
                                  {{ field }}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <ng-container
                                *ngFor="let group of govAggregateData"
                              >
                                <tr
                                  *ngFor="
                                    let question of group.questions;
                                    let i = index
                                  "
                                >
                                  <td
                                    *ngIf="i === 0"
                                    [attr.rowspan]="group.questions.length"
                                  >
                                    {{ group.group_name }}
                                  </td>
                                  <td>{{ question.question }}</td>
                                  <td class="text-center">
                                    <highcharts-chart
                                      style="display: flex"
                                      [Highcharts]="Highcharts"
                                      [options]="question.chart"
                                      [callbackFunction]="chartCallback"
                                      [oneToOne]="true"
                                      style="
                                        display: block;
                                        width: 300px;
                                        height: 180px;
                                      "
                                    ></highcharts-chart>
                                  </td>
                                </tr>
                              </ng-container>
                            </tbody>
                            <tr *ngIf="!govAggregateData?.length">
                              <td
                                colspan="10"
                                class="noRecords text-center mt-2"
                              >
                                <span class="mt-2"
                                  >{{ commonText.no_records_found }}
                                </span>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
  <div *ngIf="matrixOverView" class="scorecard d-block mt-2">
    <div class="p-0 d-flex mb-4 align-items-center">
      <h5 class="m-0">
        {{ cardMatric?.name }}
        <span *ngIf="cardMatric?.unit_name">({{ cardMatric?.unit_name }})</span>
      </h5>
      <button
        mat-flat-button
        mat-button
        color="primary"
        class="bg-primary text-white ml-auto"
        (click)="closeMatrixOverview()"
      >
        <span *ngIf="pageType != 'projectLevel'">Balanced Scorecard</span>
        <span *ngIf="pageType == 'projectLevel'">Performance Dashboard</span>
      </button>
    </div>
    <div class="d-flex justify-content-between">
      <div>
        <span>{{ cardMatric?.desc }}</span>
      </div>
      <div>
        <div class="p-0 mb-1 d-flex">
          <div
            class="p-4 pt-1 pb-0 text-center"
            style="background-color: rgb(237, 231, 225)"
            *ngIf="cardMatric?.actual_value != 'false'"
          >
            <span style="font-size: 12px">Current Value</span>
            <div class="mt-2">
              <span class="fw-bold">{{
                (cardMatric?.actual_value ? cardMatric?.actual_value : 0)
                  | number : "1.2-2"
              }}</span>
            </div>
          </div>
          <div class="pl-4 pr-4"></div>
          <div
            class="p-4 pt-1 pb-0 text-center"
            style="background-color: rgb(237, 231, 225)"
            *ngIf="cardMatric?.change != 'false'"
          >
            <span style="font-size: 12px">% Change</span><br />
            <div
              class="fw-bold"
              [ngClass]="
                cardMatric?.is_update == false ? 'spanGreen' : 'spanRed'
              "
            >
              {{
                (cardMatric?.change ? cardMatric?.change : 0) | number : "1.2-2"
              }}
            </div>
            <span class="px-2 d-block">{{ cardMatric?.variance_per }}%</span>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-2 mb-2 trendchart">
      <div [chart]="cardMatric.overviewTrend"></div>
    </div>
    <mat-tab-group
      mat-align-tabs="start"
      class="head"
      mat-stretch-tabs
      [selectedIndex]="0"
    >
      <mat-tab>
        <ng-template mat-tab-label>
          <div class="tab-label">
            <img
              src="./assets/images/balancescore/metric_indicators/insights.png"
              width="24px"
              height="24px"
              class="mr-2"
              alt="insights"
              class="tab-icon"
            />
            Insights
          </div>
        </ng-template>
        <div *ngIf="cardMatric?.powerBiReport?.length > 0">
          <powerbi-report
            [embedConfig]="reportConfig"
            [cssClassName]="reportClass"
          ></powerbi-report>
        </div>
        <div
          *ngIf="cardMatric?.powerBiReport?.length == 0"
          class="text-center p-4"
        >
          <span>No reports found</span>
        </div>
      </mat-tab>
      <mat-tab label="Linked Metrics">
        <ng-template mat-tab-label>
          <div class="tab-label">
            <img
              src="./assets/images/balancescore/metric_indicators/linkedmetrics.png"
              width="24px"
              height="24px"
              class="mr-2"
              alt="linkedMatrics"
              class="tab-icon"
            />
            Linked Metrics
          </div>
        </ng-template>
        <div>
          <br />
          <div class="row" style="width: 98%; margin-left: 10px">
            <div class="col-md-12 mb-4">
              <div
                class="column-bg-1 d-flex align-items-center justify-content-between"
              >
                <div
                  class="d-flex align-items-center justify-content-center ml-2 w-100"
                >
                  <img
                    src="./assets/images/balancescore/metric_indicators/linkedmetrics.png"
                    width="24px"
                    height="24px"
                    class="mr-2"
                    alt="linkedMatrics"
                  />
                  <span class="title">Linked Metrics</span>
                  <button
                    class="small-icon-button ml-auto mb-3"
                    (click)="openWhatIfModel()"
                    style="box-shadow: 0px 0px 15px gray"
                    matTooltip="what if"
                    mat-icon-button
                  >
                    <img
                      class="mb-1"
                      src="assets/Icons/what (5).svg"
                      alt="what if"
                      height="30px"
                      width="30px"
                    />
                  </button>
                </div>
              </div>
              <!--Customer Card start-->
              <div class="table-responsive">
                <table
                  id="table-sparkline"
                  class="table"
                  style="
                    font-size: 0.5rem;
                    border-top: medium none;
                    min-height: 60px;
                  "
                >
                  <thead>
                    <tr>
                      <th class="tblBg">Metrics</th>
                      <th class="tblBg">
                        {{ balanceScorecardText?.dashboard?.trend }}
                      </th>
                      <th class="tblBg">
                        Current Value
                        <span *ngIf="cardMatric?.unit_name"></span>
                      </th>
                      <th class="tblBg">Change</th>
                    </tr>
                  </thead>
                  <tbody id="tbody-sparkline" class="example-list">
                    <tr
                      *ngFor="let item of linkedMatrics; let i = index"
                      class="example-box"
                    >
                      <td>
                        <span
                          class="fw-bold text-center"
                          atTooltipClass="example-tooltip"
                          >{{ item?.display_name }} ({{
                            item?.unit_name
                          }})</span
                        >
                      </td>
                      <td class="text-center">
                        <div [chart]="item?.trend"></div>
                      </td>
                      <td class="text-center">
                        <span class="fw-bold">{{
                          formatNumber(
                            item?.actual_value[item?.actual_value.length - 1]
                              ?.actual_value
                              ? item?.actual_value[
                                  item?.actual_value.length - 1
                                ]?.actual_value
                              : 0,
                            2
                          ) | number : "1.2-2"
                        }}</span>
                      </td>
                      <td class="text-center">
                        <div
                          class="fw-bold"
                          [ngClass]="
                            item.change > 0
                              ? 'spanGreen'
                              : item.change < 0
                              ? 'spanRed'
                              : ''
                          "
                        >
                          {{ item?.change | number }}
                        </div>
                        <span class="px-2 d-block"
                          >{{ item.percent_change }}%</span
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--Customer Card end-->
            </div>
            <div
              class="row"
              *ngIf="linkedMatrics?.length == 0"
              style="position: absolute; bottom: 30px"
            >
              <div class="col-md- 4 mx-auto">
                <p class="text-center mt-2 mb-1">
                  No Linked Metrics Configured
                </p>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Performance BenchMark">
        <ng-template mat-tab-label>
          <div class="tab-label">
            <img
              src="./assets/images/balancescore/metric_indicators/perforamanceBench.png"
              width="24px"
              height="24px"
              class="mr-2"
              alt="performanceBenchMark"
              class="tab-icon"
            />
            Performance Benchmark
          </div>
        </ng-template>

        <div class="row mt-4" style="width: 98%; margin-left: 10px">
          <div class="col-md-12 mb-4">
            <div
              class="column-2-bg d-flex align-items-center justify-content-between"
            >
              <div
                class="d-flex align-items-center justify-content-center ml-2"
              >
                <img
                  src="./assets/images/balancescore/metric_indicators/perforamanceBench.png"
                  width="24px"
                  height="24px"
                  class="mr-2"
                  alt="performanceBenchMark"
                />
                <span class="title">Performance Benchmark</span>
              </div>
            </div>
            <!--Customer Card start-->
            <div class="table-responsive">
              <table
                id="table-sparkline"
                class="table"
                style="
                  font-size: 0.5rem;
                  border-top: medium none;
                  min-height: 60px;
                "
              >
                <thead>
                  <tr>
                    <th class="tblBg">{{ displayLabels.portfolio }}</th>
                    <th class="tblBg">
                      {{ balanceScorecardText?.dashboard?.trend }}
                    </th>
                    <th class="tblBg">
                      Current Value
                      <span *ngIf="cardMatric?.unit_name"></span>
                    </th>
                    <th class="tblBg">Change</th>
                    <th class="tblBg">Variance</th>
                    <th class="tblBg">% of Variance</th>
                  </tr>
                </thead>
                <tbody id="tbody-sparkline" class="example-list">
                  <tr
                    *ngFor="let item of performanceBenchMark; let i = index"
                    class="example-box"
                  >
                    <td>
                      <span class="fw-bold" matTooltipClass="example-tooltip">{{
                        item?.name
                      }}</span>
                    </td>
                    <td>
                      <div [chart]="item?.trend"></div>
                    </td>
                    <td class="text-center">
                      <span class="fw-bold">{{
                        formatNumber(
                          item?.actual_value[item?.actual_value.length - 1]
                            ?.actual_value
                            ? item?.actual_value[item?.actual_value.length - 1]
                                ?.actual_value
                            : 0,
                          2
                        ) | number : "1.2-2"
                      }}</span>
                    </td>
                    <td class="text-center">
                      <div
                        class="fw-bold text-center"
                        [ngClass]="
                          item.change > 0
                            ? 'spanGreen'
                            : item.change < 0
                            ? 'spanRed'
                            : ''
                        "
                      >
                        {{ item?.change | number }}
                      </div>
                      <span class="px-2 d-block"
                        >{{ item.percent_change }}%</span
                      >
                    </td>
                    <td class="text-center">
                      <div class="fw-bold">
                        <span
                          class="spanvalue"
                          [ngStyle]="
                            item?.status
                              ? { 'background-color': item?.status }
                              : ''
                          "
                        >
                          {{
                            getDifference(
                              item?.actual_value[item?.actual_value?.length - 1]
                                ?.actual_value,
                              cardMatric?.actual_value
                            ) | number : "1.2-2"
                          }}
                        </span>
                      </div>
                    </td>
                    <td class="fw-bold text-center">
                      {{ getContribution(item, cardMatric?.actual_value) }}%
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!--Customer Card end-->
          </div>
          <div
            class="row"
            *ngIf="performanceBenchMark?.length == 0"
            d
            style="position: absolute; bottom: 30px"
          >
            <div class="col-md- 4mx-auto">
              <p class="text-center mt-2 mb-1">
                {{ commonText.no_data_found }}
              </p>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<ng-template #matricTableBody>
  <thead>
    <tr>
      <th class="tblBg">
        {{ balanceScorecardText?.dashboard?.metrics }}
      </th>
      <th class="tblBg">
        {{ balanceScorecardText?.dashboard?.trend }}
      </th>
      <th class="tblBg">Current Value</th>
      <th class="tblBg">
        {{ balanceScorecardText?.dashboard?.change }}
      </th>
    </tr>
  </thead>
</ng-template>

<ng-template #info>
  <div class="modal-dialog custom-width m-0">
    <div class="modal-content">
      <app-modal-header-common [title]="infoQuadrantName">
      </app-modal-header-common>

      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <div class="custom-height infoQuadrant">
              <div
                class="custom-table table-responsive"
                style="max-height: calc(100vh - 400px)"
              >
                <table class="table table-bordered">
                  <thead style="position: sticky; z-index: 1">
                    <tr>
                      <th class="text-center">Metrics</th>
                      <th class="text-center">Description</th>
                      <th class="text-center">Unit Name</th>
                      <th class="text-center">MOS Type Name</th>
                      <th class="text-center">Target</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of quadrantInfoDetails; let i = index">
                      <td>{{ item?.display_name }}</td>
                      <td>{{ item?.description }}</td>
                      <td>{{ item?.unit_name }}</td>
                      <td>{{ item?.mos_type_name }}</td>
                      <td>{{ item?.off_track_value | number }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer border-0">
        <div class="row">
          <div class="col-md-6">
            <button
              (click)="dialogClose()"
              [disabled]="disableFields"
              mat-button
              mat-dialog-close
              color="primary"
              class="mx-1"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
