import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-tree-listing",
  templateUrl: "./tree-listing.component.html",
  styleUrls: ["./tree-listing.component.scss"],
})
export class TreeListingComponent implements OnInit {
  @Input() items: any = [];
  @Input() tree: any;
  @Input() context: any;
  @Input() rightpane = false;
  @Input() filterArray: any = null;

  loading = false;
  user: any;

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem("permission") ?? "{}");
    this.expandAccordions();
  }

  async expandAccordions() {
    for (const portfolio of this.items) {
      if (portfolio.children && this.portfolioExpansionRequired(portfolio)) {
        await this.expandItemAndChildren(
          portfolio,
          this.subportfolioExpansionRequired.bind(this)
        );
      }
    }
  }

  private async expandItemAndChildren(
    item: any,
    childExpansionCheck: Function
  ) {
    await this.expandItem(item);

    for (const child of item.children) {
      if (childExpansionCheck(child)) {
        await this.expandItemAndChildren(
          child,
          this.getNextExpansionCheck(child)
        );
      }
    }
  }

  private getNextExpansionCheck(item: any): Function {
    if (item.children[0]?.children) {
      return this.programExpansionRequired.bind(this);
    }
    if (item.children[0]?.children?.[0]?.children) {
      return this.projectExpansionRequired.bind(this);
    }
    return () => false;
  }

  async expandItem(item: any) {
    item.expanded = true;
    await this.toggleAccordion(item, true);
  }

  projectExpansionRequired(project: any): boolean {
    return (
      this.filterArray.project?.includes(project.id) ||
      this.filterArray.opportunity?.includes(project.id) ||
      this.filterArray.vendor?.length > 0 ||
      this.filterArray.status?.length > 0 ||
      this.filterArray.tags?.length > 0
    );
  }

  private checkProjectInProgram(program: any, projectId: number): boolean {
    return program.children?.some((project: any) => project.id === projectId);
  }

  private checkProjectInSubportfolio(
    subportfolio: any,
    projectId: number
  ): boolean {
    return subportfolio.children?.some((program: any) =>
      this.checkProjectInProgram(program, projectId)
    );
  }

  private checkProjectExpansion(portfolio: any): boolean {
    return this.filterArray.project?.some((projectId: number) =>
      portfolio.children?.some((subportfolio: any) =>
        this.checkProjectInSubportfolio(subportfolio, projectId)
      )
    );
  }

  private checkOpportunityInProgram(
    program: any,
    opportunityId: number
  ): boolean {
    return program.children?.some(
      (opportunity: any) => opportunity.id === opportunityId
    );
  }

  private checkOpportunityInSubportfolio(
    subportfolio: any,
    opportunityId: number
  ): boolean {
    return subportfolio.children?.some((program: any) =>
      this.checkOpportunityInProgram(program, opportunityId)
    );
  }

  private checkOpportunityExpansion(portfolio: any): boolean {
    return this.filterArray.opportunity?.some((opportunityId: number) =>
      portfolio.children?.some((subportfolio: any) =>
        this.checkOpportunityInSubportfolio(subportfolio, opportunityId)
      )
    );
  }

  portfolioExpansionRequired(portfolio: any) {
    return (
      this.filterArray.subportfolio?.some((subId: number) =>
        portfolio.children?.some(
          (subportfolio: any) => subportfolio.id === subId
        )
      ) ||
      this.filterArray.program?.some((programId: number) =>
        portfolio.children?.some((subportfolio: any) =>
          subportfolio.children?.some(
            (program: any) => program.id === programId
          )
        )
      ) ||
      this.checkProjectExpansion(portfolio) ||
      this.checkOpportunityExpansion(portfolio) ||
      this.filterArray.vendor?.length ||
      this.filterArray.status?.length ||
      this.filterArray.tags?.length
    );
  }

  subportfolioExpansionRequired(subportfolio: any) {
    return (
      this.filterArray.subportfolio?.includes(subportfolio.id) ||
      this.filterArray.program?.some((programId: number) =>
        subportfolio.children.some((program: any) => program.id === programId)
      ) ||
      this.filterArray.project?.some((projectId: number) =>
        subportfolio.children.some((program: any) =>
          program.children.some((project: any) => project.id === projectId)
        )
      ) ||
      this.filterArray.opportunity?.some((opportunityId: number) =>
        subportfolio.children.some((program: any) =>
          program.children.some(
            (opportunity: any) => opportunity.id === opportunityId
          )
        )
      ) ||
      this.filterArray.vendor?.length ||
      this.filterArray.status?.length ||
      this.filterArray.tags?.length
    );
  }

  programExpansionRequired(program: any) {
    return (
      this.filterArray.project?.some((projectId: number) =>
        program.children.some((project: any) => project.id === projectId)
      ) ||
      this.filterArray.opportunity?.some((opportunityId: number) =>
        program.children.some(
          (opportunity: any) => opportunity.id === opportunityId
        )
      ) ||
      this.filterArray.vendor?.length ||
      this.filterArray.status?.length ||
      this.filterArray.tags?.length
    );
  }

  async toggleAccordion(item: any, initialExpand = false) {
    if (!initialExpand) {
      item.expanded = !item.expanded;
    }

    if (item.expanded || initialExpand) {
      this.context.componentParent.collapse(item);

      this.loading = true;
      await this.context.componentParent.getLevel(item);
      this.loading = false;
    }
  }

  getColor(item: any) {
    if (item.level === "portfolio" || item.level === "Level 1") {
      return "#0E2954";
    } else if (item.level === "sub_portfolio") {
      return "#1F6E8C";
    } else if (item.level === "program") {
      return "#2E8A99";
    } else if (item.level === "last") {
      return "rgb(224 224 224)";
    } else {
      return "";
    }
  }

  openUrl(item: any, event?: any) {
    event.preventDefault();
    this.context.componentParent.navigateTo(item);
  }

  deleteItem(item: any) {
    this.context.componentParent.deleteItem(item);
  }

  archiveItem(item: any) {
    this.context.componentParent.archiveItem(item);
  }

  archiveList(item: any) {
    this.context.componentParent.archiveList(item);
  }
}
