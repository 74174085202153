import { FormControl } from "@angular/forms";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { Constants } from "../constant/constant";
import { LoggingService } from "src/app/logging.service";

export function setupTextFilters(
  filterControls: FormControl[],
  searchFunction: () => void
) {
  filterControls.forEach((filterControl) => {
    filterControl.valueChanges
      .pipe(debounceTime(Constants.DEBOUNCE_TIME), distinctUntilChanged())
      .subscribe(() => searchFunction());
  });
}

export function hasValue(val: any, loggingService: LoggingService) {
  if (Array.isArray(val)) {
    if (val.length) {
      return true;
    } else {
      return false;
    }
  } else if (val) {
    return true;
  } else {
    loggingService.warn("Unexpected value");
  }
  return false;
}
