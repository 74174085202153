import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class TagsService {
  constructor(private readonly http: HttpClient) {}

  getTags() {
    return this.http.get(`tag/`);
  }

  deleteTag(id: number) {
    return this.http.delete(`tag/${id}/`);
  }

  createTag(data: any) {
    return this.http.post(`tag/`, data);
  }

  magTag(data: any) {
    return this.http.post(`maptag/`, data);
  }

  removeTag(data: any) {
    return this.http.delete(
      `removetag/?entity_type=${data.entity_type}&entity_id=${data.entity_id}&tag_id=${data.id}`
    );
  }
}
