import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TransferState } from "@angular/platform-browser";

@Injectable({
  providedIn: "root",
})
export class OkrConvergenceService {
  constructor(
    private readonly http: HttpClient,
    private readonly transferState: TransferState
  ) {}

  projectList() {
    return this.http.get("delivery/project-list?flag=mapped_list");
  }

  getKeyResultsRecords(query?: any) {
    return this.http.get(`dynamic-form/?form_name=${query}` + `&flag=master`);
  }

  getFrequencyValues() {
    return this.http.get(`dynamic-form/kr_measure_of_success_frequency`);
  }
  getUnitValues() {
    return this.http.get(`dynamic-form/kr_measure_of_success_unit`);
  }

  getGoalMaster(queryparam: any) {
    return this.http.get(`okr/hierarchy/?${queryparam}`);
  }

  getMosMaster() {
    return this.http.get(`metrics/metric-value/okr_metrics`);
  }

  getGoalOpp(queryParam: any) {
    return this.http.get(`okr/opportunity-goals/?${queryParam}`);
  }

  getGoalProject(queryParam: any) {
    return this.http.get(`delivery/get-risk-goal-project/?${queryParam}`);
  }

  getGoalApplication(queryParam: any) {
    return this.http.get(`master/application/list-unique/?${queryParam}`);
  }

  updateOkr(payload: any) {
    return this.http.post(`okr/`, payload);
  }

  createMosValue(payload: any, id: any = "") {
    return this.http.post(`okr/measure_of_success/`, payload);
  }

  deleteMosValue(id: any) {
    return this.http.delete(`okr/measure_of_success/${id}`);
  }
  getUser() {
    return this.http.get(`users/user-list/`);
  }
  getBaseValue(type: any) {
    return this.http.get(`base_value/?type=${type}`);
  }
}
