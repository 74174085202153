import { DatePipe } from "@angular/common";
import {
  Component,
  Inject,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatStepper } from "@angular/material/stepper";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import textConfiguration from "src/assets/static-text-configuration.json";
import { CommonService } from "../../services/common.service";
import { FilterService } from "../../services/filter.service";
import { FormValidatorService } from "../../services/form-validator/form-validator.service";
import { ProgramService } from "../../services/program.service";
import { ProjectService } from "../../services/project.service";
import { TagsService } from "../../services/tag.service";
import { LoggingService } from "src/app/logging.service";
import {
  addOpportunity,
  createForm,
  getDisplayLables,
  getOwnerList,
  getProjectFundingType,
  getProjectType,
  handleOpportunityName,
  handleOpportunityOwners,
  handleOpportunityPortfolio,
  handleOpportunitySubPortfolio,
  handleOpportunityType,
  handleProgramName,
  portfolioFilter,
  programFilter,
  subPortfolioFilter,
  typeFilter,
} from "./utils/utils";

@Component({
  selector: "app-modal",
  templateUrl: "./modal.component.html",
  styleUrls: ["./modal.component.scss"],
  providers: [DatePipe],
  encapsulation: ViewEncapsulation.Emulated,
})
export class ModalComponent implements OnInit {
  modalPopupText: any = (textConfiguration as any).modal_popup;
  buttonsText: any = (textConfiguration as any).buttons;
  commonText: any = (textConfiguration as any).common;
  type: any;
  typeName: any;
  data: any;
  opportunityForm: any;
  dealForm: any;
  projectForm: FormGroup;
  projectMapForm = new FormGroup({});
  options2: any;
  options3: any;
  filteredOptions2: any;
  filteredOptions3: any;
  options1: any;
  filteredOptions1: any;
  subPortOptions: any;
  ownersOptions: any;
  subPortfilteredOptions: any;
  ownersfilteredOptions: any[] = [];
  show = false;
  submitted = false;
  //// project screen model data
  OpportunityFieldList: any;
  OpportunityName: any;
  filterOptionType: Observable<any[]>;
  filterOptionProgram: Observable<any[]>;
  filterOptionPortfolio: Observable<any[]>;
  filterOptionSubPortfolio: Observable<any[]>;
  option: any;
  maxChars = 100;
  subPortFolioChild: any[] = [];
  ownerDetails: any = {};
  archiveList: any;
  userSearchText: any = "";
  contractSearchText: any = "";
  filterTeamList: any;
  filterContractList: any;
  surveyComments: any;
  tempTags: any = [];
  dealTags: any = [];
  currentDomain: string = window.location.origin;
  iframeUrl: any = null;
  iframeInstanceUrl: any = null;
  displayLabels: any;
  iframeLoaded = false;
  iframeLoadingError = false;
  errorText = "Unexpected value";
  subportfolioChildText = "Sub-Portfolio Child ";
  @ViewChild(MatStepper) stepper: MatStepper;

  constructor(
    private readonly dialogRef: MatDialogRef<any>,
    private readonly fb: FormBuilder,
    private readonly toastrService: ToastrService,
    private readonly router: Router,
    private readonly ps: ProgramService,
    private readonly spinner: NgxSpinnerService,
    private readonly _formValidator: FormValidatorService,
    private readonly tagService: TagsService,
    private readonly projectService: ProjectService,
    private readonly datepipe: DatePipe,
    private readonly sanitizer: DomSanitizer,
    private readonly filterService: FilterService,
    private readonly commonService: CommonService,
    private readonly loggingService: LoggingService,
    @Inject(MAT_DIALOG_DATA) public modalData: any
  ) {}

  ngOnInit(): void {
    getDisplayLables.bind(this)();
    if (this.type === "Addopperuntiy") {
      this.initializeOpportunityForm();
    } else if (this.type === "AddProject") {
      this.initializeProjectForm();
    } else {
      this.initializePopups();
    }
  }

  private initializeOpportunityForm(): void {
    createForm.bind(this)();
    this.data = {
      name: true,
      opportunity: false,
      portfolio: false,
      subPortfolio: false,
      program: false,
      owners: false,
    };
    if (typeof this.OpportunityName === "object") {
      this.ownerDetails = this.OpportunityName?.owner;
      this.ownerDetails.mail = this.ownerDetails.email;
      this.opportunityForm.patchValue({
        name: this.OpportunityName.title,
        portfolio: this.OpportunityName?.portfolio?.parent_portfolio,
        subPortfolio: this.OpportunityName?.portfolio,
        program: this.OpportunityName?.program,
        owners: `${this.OpportunityName?.owner.first_name} ${this.OpportunityName?.owner.last_name}`,
        details_id: this.OpportunityName?.details_id,
      });
    }
    this.getOpportunity();
  }

  private initializeProjectForm(): void {
    this.createProjectForm();
    this.data = {
      project_name: true,
      project_id: true,
      project_value: true,
      begin_date: true,
      expected_end_date: true,
      project_type_id: true,
      project_funding_type_id: true,
      program_id: true,
      program: true,
      subportfolio_id: true,
      owner: true,
    };
    this.getDealFields("project");
    getProjectType.bind(this)();
    getProjectFundingType.bind(this)();
    getOwnerList.bind(this)();
  }

  private initializePopups(): void {
    if (this.type?.name === "user_owner") {
      this.filterTeamList = this.type.team_list;
    }
    if (this.type?.name === "map_sow_to_project") {
      this.filterContractList = this.type.contract_List;
    }
    if (this.type?.name === "surveyComments") {
      this.surveyComments = this.type?.comments;
    }

    if (this.type?.name === "projectpop") {
      this.iframeUrl = `${this.currentDomain}/view/${btoa(
        this.modalData?.opportunity
      )}/overview?embedded=true`;
      this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        this.iframeUrl
      );
    }

    if (this.type?.name === "opportunitypop") {
      this.iframeUrl = `${this.currentDomain}/rfx/${encodeURIComponent(
        btoa(this.modalData?.opportunity)
      )}/opportunity/preview/?embedded=true`;
      this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        this.iframeUrl
      );
    }

    if (this.type?.name === "assessmentpop") {
      this.iframeUrl = `${
        this.currentDomain
      }/strategicRelevance/assessment-central/assessment-result/${encodeURIComponent(
        btoa(this.modalData?.assessment)
      )}?embedded=true`;
      this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        this.iframeUrl
      );
    }

    if (this.type?.name === "instancepop") {
      this.iframeInstanceUrl = `${this.currentDomain}/incidentracker/instance?id=${this.modalData?.instance}&embedded=true`;
      this.iframeInstanceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        this.iframeInstanceUrl
      );
    }
  }

  get check() {
    return this.projectMapForm.controls;
  }

  showFn(data: any) {
    if (data?.opportunityname) {
      return data.opportunityname;
    } else {
      return "";
    }
  }

  createDealForm() {
    this.dealForm = this.fb.group({
      name: ["", [Validators.required]],
      deal_type: [""],
      portfolio: [
        "",
        [
          Validators.required,
          this._formValidator.autocompleteObjectValidator(),
        ],
      ],
      subPortfolio: [
        "",
        [
          Validators.required,
          this._formValidator.autocompleteObjectValidator(),
        ],
      ],
      program: [""],
      program_id: ["", [Validators.required]],
      owners: [
        "",
        [
          Validators.required,
          this._formValidator.autocompleteObjectValidator(),
        ],
      ],
    });
    this.dealForm.get("owners").disable();
  }

  createProjectForm() {
    this.projectForm = this.fb.group({
      project_name: ["", [Validators.required]],
      project_id: ["", [Validators.required]],
      project_value: ["", [Validators.required]],
      begin_date: ["", [Validators.required]],
      expected_end_date: ["", [Validators.required]],
      project_type_id: ["", [Validators.required]],
      project_funding_type_id: ["", [Validators.required]],
      program_id: ["", [Validators.required]],
      portfolio_id: ["", [Validators.required]],
      subportfolio_id: ["", [Validators.required]],
      owner_id: ["", [Validators.required]],
    });
  }

  initAutoComplete(): any {
    if (this.data.opportunity) {
      const opportunityControl = this.opportunityForm.get("opportunity");
      if (opportunityControl) {
        this.filterOptionType = opportunityControl.valueChanges.pipe(
          startWith(""),
          map((value: any) => {
            if (typeof value === "string") {
              return value;
            } else {
              return value?.name;
            }
          }),
          map((name: any) => {
            if (name) {
              return typeFilter.bind(this)(name);
            } else {
              return this.filteredOptions2.slice();
            }
          })
        );
      } else {
        this.loggingService.warn(this.errorText);
      }
    }
    if (this.data.portfolio) {
      const portfolioControl = this.opportunityForm.get("portfolio");

      if (portfolioControl) {
        this.filterOptionPortfolio = portfolioControl.valueChanges.pipe(
          startWith(""),
          map((value: any) => {
            if (typeof value === "string") {
              return value;
            } else {
              return value?.name;
            }
          }),
          map((name: any) => {
            if (name) {
              return portfolioFilter.bind(this)(name);
            } else {
              return this.filteredOptions1.slice();
            }
          })
        );
      } else {
        this.loggingService.warn(this.errorText);
      }
    }
    if (this.data.program) {
      const programControl = this.opportunityForm.get("program");

      if (programControl) {
        this.filterOptionProgram = programControl.valueChanges.pipe(
          startWith(""),
          map((value: any) => {
            if (typeof value === "string") {
              return value;
            } else {
              return value?.name;
            }
          }),
          map((name: any) => {
            if (name) {
              return programFilter.bind(this)(name);
            } else {
              return this.filteredOptions3.slice();
            }
          })
        );
      } else {
        this.loggingService.warn(this.errorText);
      }
    }
  }

  displayFn(data: any) {
    if (data?.name) {
      return data.name;
    } else {
      return "";
    }
  }

  async getOpportunity() {
    const asyncOperations: Promise<void>[] = [];
    this.OpportunityFieldList?.forEach((i: any) => {
      switch (i.field_name) {
        case "opportunity_name":
          handleOpportunityName.bind(this)(i);
          break;
        case "opportunity_type":
          handleOpportunityType.bind(this)(i);
          break;
        case "program_name":
          handleProgramName.bind(this)(i);
          break;
        case "opportunity_portfolio":
          handleOpportunityPortfolio.bind(this)(i, asyncOperations);
          break;
        case "opportunity_sub_portfolio":
          handleOpportunitySubPortfolio.bind(this)(i);
          break;
        case "opportunity_owners":
          handleOpportunityOwners.bind(this)(i);
          break;
        default:
          break;
      }
    });
    await Promise.all(asyncOperations);
    this.initAutoComplete();
  }

  onSelectionChangePortfolio(event: any) {
    const portFolioId = event.source.value.id;
    this.subPortfilteredOptions = [];
    if (!portFolioId) {
      return;
    }
    this.opportunityForm.patchValue({
      subPortfolio: "",
      owners: "",
      program: "",
    });
    this.subPortFolioChild = [];
    Object.keys(this.opportunityForm.controls).forEach((control: any) => {
      if (control.includes("sub_portfolio_child")) {
        this.opportunityForm.removeControl(control);
      }
    });
    this.filterService.getSubPortfolios(portFolioId).subscribe((resp: any) => {
      this.subPortOptions = resp.records;
      this.subPortfilteredOptions = this.subPortOptions;
      const subportfolioControl = this.opportunityForm.get("subPortfolio");
      if (subportfolioControl) {
        this.filterOptionSubPortfolio = subportfolioControl.valueChanges.pipe(
          startWith(""),
          map((value: any) => {
            if (typeof value === "string") {
              return value;
            } else {
              return value?.name;
            }
          }),
          map((name: any) => {
            if (name) {
              return subPortfolioFilter.bind(this)(name);
            } else {
              return this.subPortfilteredOptions.slice();
            }
          })
        );
      } else {
        this.loggingService.warn(this.errorText);
      }
    });
  }

  onSelectionChangeSubportPortfolio(e: any) {
    if (e.isUserInput) {
      const subportFolioId = e.source.value.id;
      this.spinner.show();
      if (!subportFolioId) {
        return;
      }
      this.opportunityForm.patchValue({
        program: "",
      });
      const value = e.source.value;
      this.filterService.getProgram(e.source.value.id).subscribe((res: any) => {
        const programControl = this.opportunityForm.get("program");
        if (programControl) {
          this.filterOptionProgram = programControl.valueChanges.pipe(
            startWith(""),
            map((val: any) => {
              if (typeof val === "string") {
                return val;
              } else {
                return val?.name;
              }
            }),
            map((name: any) => {
              if (name) {
                return programFilter.bind(this)(name);
              } else {
                return res.records.slice();
              }
            })
          );
        }
        this.spinner.hide();
      });
      this.subPortFolioChild = [];
      if (JSON.stringify(value).includes("sub_portfolio_child")) {
        Object.keys(value).forEach((key: any) => {
          if (
            key.includes("sub_portfolio_child") &&
            value[key] &&
            value[key]?.length > 0
          ) {
            this.opportunityForm.addControl(
              key,
              new FormControl("", [
                Validators.required,
                this._formValidator.autocompleteObjectValidator(),
              ])
            );
            const level = key?.split("_")?.pop();
            this.subPortFolioChild.push({
              key,
              name: this.subportfolioChildText + level,
              arrList: value[key],
            });
          } else {
            this.opportunityForm.patchValue({
              owners:
                value.owner_details?.[0]?.full_name ||
                value.owner_details?.[0]?.username,
            });
            this.ownerDetails = value.owner_details?.[0];
            this.ownerDetails["mail"] = this.ownerDetails["email"];
          }
        });
      } else {
        this.opportunityForm.patchValue({
          owners: value.owner?.full_name || value.owner?.username,
        });
        this.ownerDetails = value.owner;
        this.ownerDetails["mail"] = this.ownerDetails["email"];
      }
    }
  }

  add() {
    this.submitted = true;
    if (this.opportunityForm.invalid) {
      return;
    } else {
      if (this.data.opportunity) {
        const a = this.filteredOptions2.some(
          (i: any) => i.id === this.opportunityForm.value.opportunity.id
        );
        if (!a) {
          this.toastrService.error(
            `Please select anyone value from ${this.data.disOpportunity}`
          );
          return;
        }
      }

      if (this.data.portfolio) {
        const b = this.filteredOptions1.some(
          (i: any) => i.id === this.opportunityForm.value.portfolio.id
        );
        if (!b) {
          this.toastrService.error(
            `Please select anyone value from ${this.data.disPortfolio}`
          );
          return;
        }
      }
      addOpportunity.bind(this)();
    }
  }

  cancel() {
    this.dialogRef.close();
  }
  done() {
    if (this.type === "ArchiveList") {
      this.dialogRef.close(this.archiveList);
    } else if (this.type?.name === "user_owner") {
      this.dialogRef.close(this.type.team_list);
    } else if (this.type?.name === "map_sow_to_project") {
      this.dialogRef.close(this.type.contract_List);
    } else {
      this.dialogRef.close(true);
    }
  }

  searchUser(item: any, search: any) {
    if (search !== "") {
      return item?.name?.toLowerCase().includes(search?.toLowerCase());
    } else {
      return true;
    }
  }

  searchContract(item: any, search: any) {
    if (search !== "") {
      return item?.contract_name?.toLowerCase().includes(search?.toLowerCase());
    } else {
      return true;
    }
  }

  ngAfterViewInit() {
    try {
      if (this.stepper) {
        this.stepper._getIndicatorType = () => "number";
      }
    } catch (error) {
      this.loggingService.log("error", error);
    }
    if (this.type?.id) {
      const el: any = document.getElementById(this.type?.id);
      el.scrollIntoView();
    }
    if (
      this.type?.name === "projectpop" ||
      this.type?.name === "instancepop" ||
      this.type?.name === "assessmentpop"
    ) {
      setTimeout(() => {
        if (!this.iframeLoaded) {
          this.iframeLoadingError = true;
        }
      }, 10000);
      const iframeElement = document.getElementById(
        "emberIfram"
      ) as HTMLIFrameElement;
      if (iframeElement) {
        iframeElement.onload = () => {
          this.iframeLoaded = true;
          this.iframeLoadingError = false;
        };
        iframeElement.onerror = () => {
          this.iframeLoadingError = true;
        };
      }
    }
  }

  getChildTags(data: any) {
    this.tempTags = data;
  }

  projectTags: any = [];
  getChildProjectTags(data: any) {
    this.projectTags = data;
  }

  addProject() {
    this.projectForm.markAllAsTouched();
    if (this.projectTags.length <= 0) {
      this.toastrService.error(`Please select tags`);
      return;
    }
    if (this.projectForm.invalid) {
      return;
    } else {
      this.projectForm.value.begin_date = this.datepipe.transform(
        this.projectForm.value.begin_date,
        "yyyy-MM-dd"
      );
      this.projectForm.value.expected_end_date = this.datepipe.transform(
        this.projectForm.value.expected_end_date,
        "yyyy-MM-dd"
      );
      this.spinner.show();
      const payload = {
        project_name: this.projectForm.value.project_name,
        project_id: this.projectForm.value.project_id,
        project_value: this.projectForm.value.project_value,
        begin_date: this.projectForm.value.begin_date,
        expected_end_date: this.projectForm.value.expected_end_date,
        project_type_id: this.projectForm.value.project_type_id,
        project_funding_type_id: this.projectForm.value.project_funding_type_id,
        subportfolio_id: this.projectForm.value.subportfolio_id.id,
        program_id: this.projectForm.value.program_id.id,
        owner_id: this.projectForm.value.owner_id,
        work_type_id: 50,
      };
      this.projectService.addNewProject(payload).subscribe(async (res: any) => {
        this.spinner.hide();
        if (res) {
          await this.projectTags.forEach(async (element: any) => {
            const pst = {
              entity_id: res.id,
              entity_type: "project",
              tag_id: element.id,
            };
            this.tagService.magTag(pst).subscribe(() => {});
          });

          const newitem = {
            project_id: res.id,
            program_name:
              this.projectForm.value.program_id.name ||
              this.projectForm.value.program_id.display_name,
            program_id: this.projectForm.value.program_id.id,
            subportfolio_name:
              this.projectForm.value.subportfolio_id.name ||
              this.projectForm.value.subportfolio_id.display_name,
            subportfolio_id: this.projectForm.value.subportfolio_id.id,
            portfolio_name:
              this.projectForm.value.portfolio_id.name ||
              this.projectForm.value.portfolio_id.display_name,
            portfolio_id: this.projectForm.value.portfolio_id.id,
            manager: this.ownerName,
          };
          localStorage.setItem("program", JSON.stringify(newitem));
          this.router.navigate([`view/${btoa(res?.id)}/overview`]);
          this.dialogRef.close();
          this.toastrService.success("Project Created successfully.");
        }
      });
    }
  }

  async getDealFields(form: string) {
    const asyncOperations: Promise<void>[] = [];
    this.OpportunityFieldList?.forEach((i: any) => {
      switch (i.field_name) {
        case "opportunity_name":
          handleOpportunityName.bind(this)(i);
          break;
        case "opportunity_type":
          handleOpportunityType.bind(this)(i);
          break;
        case "program_name":
          handleProgramName.bind(this)(i);
          break;
        case "opportunity_portfolio":
          handleOpportunityPortfolio.bind(this)(i, asyncOperations);
          break;
        case "opportunity_sub_portfolio":
          handleOpportunitySubPortfolio.bind(this)(i);
          break;
        case "opportunity_owners":
          handleOpportunityOwners.bind(this)(i);
          break;
        default:
          break;
      }
    });
    await Promise.all(asyncOperations);
    if (form === "deal") {
      this.initAutoCompleteDeal();
    } else {
      this.initAutoCompleteProject();
    }
  }

  initAutoCompleteDeal(): any {
    const nameControl = this.dealForm.get("name");
    const portfolioControl = this.dealForm.get("portfolio");
    const programControl = this.dealForm.get("program");
    if (this.data.opportunity && nameControl) {
      this.filterOptionType = nameControl.valueChanges.pipe(
        startWith(""),
        map((value: any) => {
          if (typeof value === "string") {
            return value;
          } else {
            return value?.name;
          }
        }),
        map((name: any) => {
          if (name) {
            return typeFilter.bind(this)(name);
          } else {
            return this.filteredOptions2.slice();
          }
        })
      );
    }
    if (this.data.portfolio && portfolioControl) {
      this.filterOptionPortfolio = portfolioControl.valueChanges.pipe(
        startWith(""),
        map((value: any) => {
          if (typeof value === "string") {
            return value;
          } else {
            return value?.name;
          }
        }),
        map((name: any) => {
          if (name) {
            return portfolioFilter.bind(this)(name);
          } else {
            return this.filteredOptions1.slice();
          }
        })
      );
    }
    if (this.data.program && programControl) {
      this.filterOptionProgram = programControl.valueChanges.pipe(
        startWith(""),
        map((value: any) => {
          if (typeof value === "string") {
            return value;
          } else {
            return value?.name;
          }
        }),
        map((name: any) => {
          if (name) {
            return programFilter.bind(this)(name);
          } else {
            return this.filteredOptions3.slice();
          }
        })
      );
    }
  }

  initAutoCompleteProject(): any {
    const portfolioControl = this.projectForm.get("portfolio_id");
    const programControl = this.projectForm.get("program_id");
    if (this.data.portfolio && portfolioControl) {
      this.filterOptionPortfolio = portfolioControl.valueChanges.pipe(
        startWith(""),
        map((value: any) => {
          if (typeof value === "string") {
            return value;
          } else {
            return value?.name;
          }
        }),
        map((name: any) => {
          if (name) {
            return portfolioFilter.bind(this)(name);
          } else {
            return this.filteredOptions1.slice();
          }
        })
      );
    }
    if (this.data.program && programControl) {
      this.filterOptionProgram = programControl.valueChanges.pipe(
        startWith(""),
        map((value: any) => {
          if (typeof value === "string") {
            return value;
          } else {
            return value?.name;
          }
        }),
        map((name: any) => {
          if (name) {
            return programFilter.bind(this)(name);
          } else {
            return this.filteredOptions3.slice();
          }
        })
      );
    }
  }

  onSelectionChangePortfolioProject(event: any) {
    this.spinner.show();
    const portFolioId = event.source.value.id;
    this.subPortfilteredOptions = [];
    if (!portFolioId) {
      return;
    }
    this.projectForm.patchValue({
      subportfolio_id: "",
      program_id: "",
    });
    this.filterService
      .getSubportfolioList(portFolioId)
      .subscribe((resp: any) => {
        this.subPortOptions = resp.records;
        this.subPortfilteredOptions = this.subPortOptions;
        const subportfolioControl = this.projectForm.get("subportfolio_id");
        if (subportfolioControl) {
          this.filterOptionSubPortfolio = subportfolioControl.valueChanges.pipe(
            startWith(""),
            map((value: any) => {
              if (typeof value === "string") {
                return value;
              } else {
                return value?.name;
              }
            }),
            map((name: any) => {
              if (name) {
                return subPortfolioFilter.bind(this)(name);
              } else {
                return this.subPortfilteredOptions.slice();
              }
            })
          );
        }
      });
    this.spinner.hide();
  }

  ownerName: any = "";

  onSelectionChangeSubportPortfolioProject(e: any) {
    if (e.isUserInput) {
      this.spinner.show();
      const subportFolioId = e.source.value.id;
      if (!subportFolioId) {
        return;
      }
      this.projectForm.patchValue({
        program_id: "",
      });
      const value = e.source.value;
      this.filterService
        .getProgram(e.source.value.id)
        .subscribe(async (res: any) => {
          const programControl = this.projectForm.get("program_id");
          if (programControl) {
            this.filterOptionProgram = programControl.valueChanges.pipe(
              startWith(""),
              map((val: any) => {
                if (typeof val === "string") {
                  return val;
                } else {
                  return val?.name;
                }
              }),
              map((name: any) => {
                if (name) {
                  return programFilter.bind(this)(name);
                } else {
                  return res.records.slice();
                }
              })
            );
          }
          this.spinner.hide();
        });
      this.ownerName = `${value.owner.first_name} ${value.owner.last_name}`;
    }
  }
  selectedSowResources: any = [];

  getResourceListFromContract(index: any, event: any) {
    if (event) {
      this.type.contract_List[index].resourseList = [];
    }
  }

  selectedResource(resource: any, i: any, event: any) {
    if (event) {
      this.selectedSowResources.push(resource);
      this.type.contract_List.forEach((res: any, index: any) => {
        if (index !== i && res?.resourseList) {
          this.type.contract_List[index].resourseList =
            res?.resourseList?.filter((item: any) => item.id !== resource.id);
        }
      });
    } else {
      this.selectedSowResources = this.selectedSowResources?.filter(
        (item: any) => item.id !== resource.id
      );
    }
  }
  projectType: any = [];
  projectFundingType: any = [];
  ownerList: any = [];

  close() {
    this.dialogRef.close();
  }

  get f() {
    return this.opportunityForm.controls;
  }
  get fd() {
    return this.dealForm.controls;
  }

  getConsumed(blended: any) {
    let amount = 0;
    blended.forEach((element: any) => {
      amount = amount + Number(element.cost);
    });
    return amount;
  }
}
