import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  constructor(private readonly http: HttpClient) {}

  getDisplayNames() {
    return this.http.get("display-names/");
  }

  getLabels() {
    const labels: any = localStorage.getItem("displayLabels");
    return JSON.parse(labels);
  }
}
