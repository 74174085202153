import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from "@angular/core";
import { Router } from "@angular/router";
import moment from "moment";
import { ProgramService } from "src/app/shared/services/program.service";
import { MatDialog } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import { SurveyService } from "src/app/shared/services/survey.service";
import { PublishSurveyComponent } from "src/app/pages/survey/publish-survey/publish-survey.component";
import textConfiguration from "src/assets/static-text-configuration.json";
import { CommonService } from "../../services/common.service";
@Component({
  selector: "app-table-card",
  templateUrl: "./table-card.component.html",
  styleUrls: ["./table-card.component.scss"],
  encapsulation: ViewEncapsulation.Emulated,
})
export class TableCardComponent implements OnInit {
  staticText: any = (textConfiguration as any).common;
  @Input() proposalLists: any[] = [];
  @Input() screenName: any;
  @Input() statusList: any;
  @Output() refereshParentData = new EventEmitter<string>();
  page = 1;
  totalCount: any;
  selectedId: any;
  initiatedDate: any;
  toeStatusValue: any;
  statusId: any;
  cardData: any;
  toeStatus: any[] = [];
  statusFilter: any;
  ColorRandom: any;
  trackerStatusValue: any;
  trackerStatus: any;
  displayLabels: any;

  constructor(
    private readonly router: Router,
    private readonly ps: ProgramService,
    private readonly spinner: NgxSpinnerService,
    public dialog: MatDialog,
    private readonly toastrService: ToastrService,
    private readonly surveyService: SurveyService,
    private readonly commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.getDisplayLables();
  }

  getDisplayLables() {
    this.displayLabels = this.commonService.getLabels();
  }

  viewProject(id: any) {
    this.router.navigate([`view/${btoa(id)}/overview`]);
  }

  getToestatus() {
    this.proposalLists = this.proposalLists?.map((i: any) => {
      if (i.last_updated_on) {
        i.last_updated_on = moment(i.last_updated_on).format(
          "DD-MM-yyyy  HH:mm"
        );
      } else {
        i.last_updated_on = "";
      }
      i.updatedStatus = i?.status;
    });
  }
  getName(name: any) {
    try {
      return name
        .split(" ")
        .slice(0, 2)
        .map((n: any) => n[0])
        .join("");
    } catch (e) {
      return name;
    }
  }

  viewProposal(id: any, status: string) {
    if (status === "Published") {
      this.router.navigate([`getproposal/${btoa(id)}/proposal-evaluation`]);
    } else if (status === "Evaluating") {
      this.router.navigate([`getproposal/${btoa(id)}/summary`]);
    } else {
      this.router.navigate([`getproposal/${btoa(id)}/proposal-evaluation`]);
    }
  }

  viewToe(id: any, view: any) {
    this.dialog.open(view);
    const obj = this.proposalLists.filter(function (data) {
      return data.id === id;
    });
    this.cardData = obj[0];
    return obj;
  }

  openOpportunity(id: any, viewOpportunity: any) {
    this.dialog.open(viewOpportunity, { panelClass: "mw-100" });
    const obj = this.proposalLists.filter(function (data) {
      return data.id === id;
    });
    this.cardData = obj[0];
    return obj;
  }

  openProposal(id: any, viewProposalPopup: any) {
    this.dialog.open(viewProposalPopup);
    const obj = this.proposalLists.filter(function (data) {
      return data.id === id;
    });
    this.cardData = obj[0];
    return obj;
  }

  projectViewPopup(id: any, data: any) {
    this.dialog.open(data);
    const obj = this.proposalLists.filter(function (item) {
      return item.id === id;
    });
    this.cardData = obj[0];
    return obj;
  }

  editToe(id: any, status: string) {
    if (status === "Evaluating") {
      this.router.navigate([`getproposal/${btoa(id)}/summary`]);
    } else {
      this.router.navigate([`toe/${btoa(id)}/award-deal`]);
    }
  }
  screenInfo(id: any) {
    this.dialog.closeAll();
    this.router.navigate([`view/${btoa(id)}/overview`]);
  }
  navigateTo(id: any) {
    this.dialog.closeAll();
    this.router.navigate([`getproposal/${btoa(id)}/proposal-evaluation`]);
  }

  editOpportunity(id: any) {
    this.router.navigate([`rfx${btoa(id)}/opportunity/setup`]);
  }

  viewPreview(id: any) {
    this.router.navigate([`rfx/${btoa(id)}/opportunity/preview`]);
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  selectToeStatus(e: any, i: any) {
    this.selectedId = i.id;
    this.toeStatusValue = e.value;
    this.updateToeStatus();
  }
  selectedTrackerStatus(e: any, i: any) {
    this.selectedId = i.id;
    this.trackerStatusValue = e.value.slug;
    this.updateTrackerStatus();
  }

  statusChange(e: any) {
    this.statusId = e.value.id;
  }

  updateToeStatus() {
    const payload = {
      opportunity: this.selectedId,
      status: this.toeStatusValue,
    };
    this.ps.updateToeStatus(payload).subscribe(
      (res: any) => {
        if (res) {
          this.spinner.hide();
          this.toastrService.success("Saved succesfully");
          this.refereshParentData.emit();
          this.dialog.closeAll();
        }
      },
      (error: any) => {
        throw error;
      }
    );
  }

  updateTrackerStatus() {
    const payload = {
      action_tracker_id: this.selectedId,
      status: this.trackerStatusValue,
    };
    this.ps.updateStatus(payload).subscribe(
      (res: any) => {
        if (res) {
          this.spinner.hide();
          this.refereshParentData.emit();
          this.toastrService.success("Saved succesfully");
          this.dialog.closeAll();
        }
      },
      (error: any) => {
        throw error;
      }
    );
  }

  editProgram(id: any) {
    this.router.navigate([`rfx/${btoa(id)}/opportunity/setup`]);
  }

  viewProgram(id: any, status: string) {
    if (status === "Published") {
      this.router.navigate([`getproposal/${btoa(id)}/proposal-evaluation`]);
    } else if (status === "Evaluating") {
      this.router.navigate([`getproposal/${btoa(id)}/summary`]);
    } else if (status === "SoW Signed") {
      this.router.navigate([`toe/${btoa(id)}/award-deal`]);
    } else if (status === "Draft") {
      this.ps.getSingleOpportunityById(id).subscribe(() => {
        this.router.navigate([`rfx/${btoa(id)}/opportunity/setup`]);
      });
    } else {
      this.router.navigate([`rfx/${btoa(id)}/opportunity/preview`]);
    }
  }

  ideaDetails(id: any) {
    this.router.navigate(["innovation/detail-ideas/" + btoa(id)]);
  }

  getRandomcolors(index?: any) {
    const colors: any[] = [
      "#ed5c49",
      "#f9a914",
      "#0f355a",
      "#48db7e",
      "#479cdd",
      "#ff9a07",
      "#6ce197",
      "#38c4ab",
      "#df559e",
      "#f07c6c",
    ];
    this.ColorRandom = Math.floor(Math.random() * colors.length);
    this.ColorRandom = colors[index];
    return this.ColorRandom;
  }
  getRandomBg(index?: any) {
    const colors: any[] = [
      "#479cdd",
      "#ff9a07",
      "#6ce197",
      "#38c4ab",
      "#df559e",
      "#f07c6c",
      "#ed5c49",
      "#f9a914",
      "#0f355a",
      "#48db7e",
    ];
    this.ColorRandom = Math.floor(Math.random() * colors.length);
    this.ColorRandom = colors[index];
    return this.ColorRandom;
  }
  viewSurveyDetails(form: any) {
    this.surveyService.setActiveTab("questions");
    this.router.navigate([`BVT/survey-details/${btoa(form?.id)}`]);
  }
  openSurveyModal(id: any, evt: Event) {
    this.surveyService.setFormId(id);
    const dialogRef = this.dialog.open(PublishSurveyComponent, {
      panelClass: "survey-container",
    });
    evt.stopPropagation();
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.refereshParentData.emit();
      }
    });
  }
  navigateToProject() {
    this.dialog.closeAll();
    this.router.navigate([`view/${btoa(this.cardData?.id)}/overview`]);
  }
}
