<mat-form-field
  appearance="outline"
  class="w-100 filter-dropdown"
  style="font-size: 12px"
>
  <input
    class="lh-20"
    matInput
    [matAutocomplete]="auto"
    type="text"
    [value]="displayNameFn(selectedValue) || searchText"
    (input)="searchText = $event.target.value"
    [disabled]="isDisabled"
    placeholder="{{ placeholderText }}"
  />
  <button
    *ngIf="selectedValue || searchText"
    mat-icon-button
    matSuffix
    aria-label="Clear"
    (click)="clearField()"
  >
    <mat-icon>close</mat-icon>
  </button>
  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="displayNameFn"
    (optionSelected)="optionSelected($event)"
  >
    <mat-option
      *ngFor="let option of filteredList(options)"
      [value]="option"
      style="font-size: 12px"
    >
      {{ option.display_name }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
