<label *ngIf="!editMode" (click)="editMode = true; oldData = inputString" class="m-0">
    {{inputString}}
    <span *ngIf="!inputString" class="placeholder-txt">{{placeholder}}</span>
</label>
<div style="position: relative;">
    <input *ngIf="editMode" [(ngModel)]="inputString" (keydown.esc)="cancelEdit()" (keydown.enter)="editMode = false" #inputField style="border: 0; background: #edf0f2; padding-left: 10px;  width: 100%;"/>
    <div *ngIf="editMode" style="position: absolute; right: 0; background: #cac7c7; font-size: 10px; z-index: 1;">
        <button mat-icon-button class="small-icon-button" (click)="updateData()">
            <mat-icon>done</mat-icon>
        </button>
        <button mat-icon-button class="small-icon-button" (click)="cancelEdit()">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
</div>