import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { TransferState, makeStateKey } from "@angular/platform-browser";
import { of, Observable, Subject } from "rxjs";
import { tap } from "rxjs/operators";
import { LoggingService } from "src/app/logging.service";
const metricsListDatas = [
  { name: "Java", displayName: "Java" },
  { name: "Angular", displayName: "Angular" },
  { name: "Dot Net", displayName: "Dot Net" },
];

@Injectable({
  providedIn: "root",
})
export class ProjectService {
  editId: any;
  private readonly currentStatus: Subject<any> = new Subject();
  deliveryProjectRoute = "delivery/project/";
  subportfolioParam = "&subportfolio=";
  portfolioParam = "&portfolio=";
  warningText = "Unexpected value";
  constructor(
    private readonly http: HttpClient,
    private readonly router: Router,
    private readonly transferState: TransferState,
    private readonly loggingService: LoggingService
  ) {}
  resolve(route: any): Observable<any> {
    let elem: any = localStorage.getItem("program");
    elem = JSON.parse(elem);
    let id = route.params["id"];
    if (!id) {
      id = route?.parent.params["id"];
    }
    try {
      id = atob(id);
    } catch (e) {
      this.router.navigate(["404"]);
    }
    const detailKey = makeStateKey(
      `projects/${id}/?program_id=${elem.program_id}&subportfolio_id=${elem.subportfolio_id}&portfolio_id=${elem.portfolio_id}`
    );
    if (route.params["id"]) {
      return this.http
        .get(
          `projects/${id}/?program_id=${elem.program_id}&subportfolio_id=${elem.subportfolio_id}&portfolio_id=${elem.portfolio_id}`
        )
        .pipe(
          tap((res: any) => {
            localStorage.setItem("selectedProject", JSON.stringify(res));
            this.transferState.set(detailKey, res);
          })
        );
    } else {
      return of(this.transferState.get(detailKey, null));
    }
  }
  resolve1(route: any) {
    const id = route.params["id"];
    const detailKey = makeStateKey(`projects/${id}/`);
    return this.transferState.get(detailKey, null);
  }
  public getStatusValue(): Observable<any> {
    return this.currentStatus.asObservable();
  }
  public setStatusValue(value: any): void {
    this.currentStatus.next(value);
  }
  getListProjects(limit?: any, offset = 0) {
    return this.http.get(
      `opportunities/?offset=${offset}&end=${limit + offset}`
    );
  }

  deliveryProjectList() {
    return this.http.get("delivery/project-list");
  }

  projectmapping(data: any) {
    return this.http.post("delivery/map-project/", data);
  }

  getOpportunityData(query?: any) {
    return this.http.get(`dynamic-form/?form_name=${query}`);
  }

  getProjectKrData(projectId?: any) {
    return this.http.get(`okr/project-goals/?hierarchy_project=${projectId}`);
  }

  getRiskOwners() {
    return this.http.get(`users/microsoft-users/`);
  }

  deleteProject(id: any) {
    return this.http.delete("projects/" + id);
  }

  getprojectdetailsOverview(id: any, force = false) {
    let elem: any = localStorage.getItem("program");
    elem = JSON.parse(elem);
    const detailKey = makeStateKey(
      `projects/${id}/?&program_id=${elem.program_id}&subportfolio_id=${elem.subportfolio_id}&portfolio_id=${elem.portfolio_id}`
    );
    const cachedResponse = this.transferState.get(detailKey, null);
    if (!cachedResponse || force) {
      return this.http
        .get(
          `projects/${id}/?program_id=${elem.program_id}&subportfolio_id=${elem.subportfolio_id}&portfolio_id=${elem.portfolio_id}`
        )
        .pipe(tap((res: any) => this.transferState.set(detailKey, res)));
    }
    return of(cachedResponse);
  }

  getToEData(id: any) {
    return this.http.get(`toe?project_id=${id}`);
  }

  getMappedProjectToEs(id: number) {
    return this.http.get(`toe?project_id=${id}&only_mapped=true`);
  }

  updateProjectVendorDetails(payload: any) {
    return this.http.post(`projects/contracts/`, payload);
  }

  uploadVendorContract(payload: any, id: any) {
    return this.http.post(
      `projects/contracts/${id}/sow-contract-upload/`,
      payload
    );
  }

  getResourceRightData(id: any) {
    return this.http.get("workforce/resources?id=" + id);
  }
  bindEditId(data: any) {
    this.editId = data;
  }
  getProjectEditId() {
    return this.editId;
  }

  getselectedMetric(projectid: any, metricid: any) {
    let params = "";
    if (metricid) {
      params = `&balance_score_card_id=${metricid}`;
    }
    return this.http.get(`projects/${projectid}/metrics/?${params}`);
  }

  getMetricsListDatas() {
    return metricsListDatas;
  }

  updateMetrics(data: any, projectId: any, metricCatId: any) {
    return this.http.post(
      `projects/${projectId}/edit-metrics/${metricCatId}/`,
      data
    );
  }

  getscoreCardQuadrant(
    projectId: any,
    portfolioId: any,
    subportfolioId: any,
    programId: any,
    vendorId: any
  ) {
    let qprm = "";
    if (projectId) {
      qprm += "&project_id=" + projectId;
    }
    if (vendorId) {
      qprm += "&vendorId=" + vendorId;
    } else if (programId) {
      qprm += "&program_id=" + programId;
    } else if (subportfolioId) {
      qprm += "&portfolio_id=" + subportfolioId;
    } else if (portfolioId) {
      qprm += "&portfolio_id=" + portfolioId;
    } else {
      this.loggingService.warn(this.warningText);
    }
    return this.http.get(`metrics/metric-value/?${qprm}`);
  }

  saveSortedQuadrant(data: any) {
    return this.http.post(`metrics/sorting/`, data);
  }

  scoreCardSetupData(projectId: any, categoryId: any) {
    return this.http.get(
      `projects/${projectId}/metrics/?metric_category_id=${categoryId}`
    );
  }

  /* -------------------- Project Release Management -------------------- */

  getGoalsList(id: any) {
    return this.http.get(`projects/${id}/releases`);
  }
  getGoalsForProject(id: any) {
    return this.http.get(`okr/project-goals/?hierarchy_project=${id}`);
  }

  saveReleaseForProject(payload: any, id: any) {
    return this.http.post(`projects/${id}/update-releases/`, payload);
  }

  getTrainingProjectGoals(id: any) {
    return this.http.get(
      `workforce/project-trainings/with-goals/?project_id=${id}`
    );
  }

  saveProjectGoalTrainings(payload: any, id: any) {
    return this.http.post(
      `workforce/project-trainings/?project_id=${id}`,
      payload
    );
  }

  getPortfolioList() {
    const dataKey = makeStateKey("get-all-portfolio");
    const cachedResponse = this.transferState.get(dataKey, null);
    if (!cachedResponse) {
      return this.http
        .get(`dynamic-form/get-all-portfolio/`)
        .pipe(tap((res: any) => this.transferState.set(dataKey, res)));
    }
    return of(cachedResponse);
  }

  getAllPortfolioList(queryParams: any = "") {
    return this.http
      .get(`org/portfolios/project-portfolios/` + queryParams)
      .toPromise();
  }

  getSubPortfolioList(payload: any, queryParams: any = "") {
    return this.http
      .get(
        `org/portfolios/project-subportfolios/?portfolio_id=${payload.id}` +
          queryParams
      )
      .toPromise();
  }

  getPortfolioPrograms(payload: any, queryParams: any = "") {
    return this.http
      .get(
        `org/programs/subportfolios/?sub_portfolio_id=${payload.id}` +
          queryParams
      )
      .toPromise();
  }

  getOpportunityList(payload: any, queryParams: any = "") {
    return this.http
      .get(
        `projects/programs/?program_id=${payload.id}&sub_portfolio_id=${payload?.parent?.id}` +
          queryParams
      )
      .toPromise();
  }

  selectProjectGoals(payload: any) {
    return this.http.post(`okr/project-goals/`, payload);
  }

  getProjectRiskData(projectID: any) {
    return this.http.get(`risk/project-risks/hierarchy/${projectID}`);
  }

  selectProjectRisk(payload: any) {
    return this.http.post(`risk/project-risks/select-risk/`, payload);
  }

  getFilteredList(payload: any) {
    return this.http.post("projects/hierarchy/", payload).toPromise();
  }

  getGoalMetrics(projectID: any, goalID: any) {
    return this.http.get(
      `okrs/project-goal-metrics/?project_id=${projectID}&goal_id=${goalID}`
    );
  }

  setGoalMetrics(payload: any) {
    return this.http.post(`okrs/project-goal-metrics/`, payload);
  }

  setRiskResponse(queryParam: any, payload: any) {
    return this.http.put(
      `risk/project-risks/update-risk-response/` + queryParam,
      payload
    );
  }

  getRiskProjectMapped(projectID: any, riskId: any) {
    return this.http.get(
      `risk/project-risks/risk/?project=${projectID}&risk=${riskId}`
    );
  }

  deleteProjectById(item: any) {
    return this.http.delete(`projects/${item}/`).toPromise();
  }

  archiveProjecById(item: any) {
    return this.http.put(`projects/${item}/archive/`, []).toPromise();
  }

  getArchiveList(portfolio: any, subportfolio: any, program: any) {
    return this.http
      .get(
        `projects/archived?portfolio=${portfolio}&sub_portfolio=${subportfolio}&program=${program}`
      )
      .toPromise();
  }

  unarchiveProj(payload: any) {
    return this.http.put(`projects/unarchive/`, payload).toPromise();
  }

  getAllSOWContractList() {
    return this.http.get(`master/contracts`);
  }

  getLinkedMatricsData(
    metricId: any,
    projectId: any,
    portfolioId: any,
    subportfolioId: any,
    programId: any
  ) {
    let qprm = "";
    if (projectId) {
      qprm = `independent_metric=${metricId}&project_id=${projectId}`;
    } else if (portfolioId) {
      qprm = `independent_metric=${metricId}&portfolio_id=${portfolioId}`;
    } else if (subportfolioId) {
      qprm = `independent_metric=${metricId}&portfolio_id=${subportfolioId}`;
    } else if (programId) {
      qprm = `independent_metric=${metricId}&program_id=${programId}`;
    } else {
      qprm = `independent_metric=${metricId}`;
    }
    return this.http.get(`metrics/linked-matrix/?${qprm}`);
  }

  getPerformanceBenchMarkData(
    metricId: any,
    projectId: any,
    portfolioId: any,
    subportfolioId: any,
    programId: any
  ) {
    let qprm = `metric_id=${metricId}`;
    if (projectId) {
      qprm += `&project_id=${projectId}`;
    }
    if (portfolioId.length) {
      qprm += `&portfolio_id=${portfolioId}`;
    }
    if (subportfolioId.length) {
      qprm += `&subportfolio_id=${subportfolioId}`;
    }
    if (programId.length) {
      qprm += `&program_id=${programId}`;
    }
    return this.http.get(`metrics/benchmark/?${qprm}`);
  }

  quadrantIfo(quadId: any) {
    return this.http.get(`metrics/instance-metric/?phase_id=${quadId}`);
  }

  getProjectType() {
    return this.http.get(`base_value/?type=project_type`);
  }

  getOwners() {
    return this.http.get(`users/user-list/`);
  }

  getProjectFundingType() {
    return this.http.get(`base_value/?type=project_funding_type`);
  }

  addNewProject(payload: any) {
    return this.http.post(`projects/`, payload);
  }

  getProjectToeData(projectId: any) {
    return this.http.get(`terms/category/?project_id=${projectId}`);
  }

  getProjectTerms(projectId: any) {
    return this.http.get(`terms/project-terms/?project_id=${projectId}`);
  }

  selectProjectToe(payload: any) {
    return this.http.post(`terms/project-terms/`, payload);
  }
  getBaseValue(type: any) {
    return this.http.get(`base_value/?type=${type}`);
  }

  getUser() {
    return this.http.get(`users/user-list/`);
  }

  getVendorList() {
    return this.http.get(`vendors/names/`);
  }

  getPulseCounts() {
    return this.http.get("nps/pulses/counts/");
  }

  getCardPulse(card: any) {
    return this.http.get(`nps/pulses/?performance_impact=${card}`);
  }

  getAudits(
    projectId: any,
    portfolioId: any,
    subportfolioId: any,
    programId: any,
    vendorId: any
  ) {
    let qprm = "";
    if (projectId) {
      qprm += "&project=" + projectId;
    }
    if (vendorId) {
      qprm += "&vendor=" + vendorId;
    } else if (programId) {
      qprm = "&program=" + programId;
    } else if (subportfolioId) {
      qprm = this.subportfolioParam + subportfolioId;
    } else if (portfolioId) {
      qprm = this.portfolioParam + portfolioId;
    } else {
      this.loggingService.warn(this.warningText);
    }
    return this.http.get(`grc/audit-instances/owned-entity/?${qprm}`);
  }

  getAssessments(
    projectId: any,
    portfolioId: any,
    subportfolioId: any,
    programId: any,
    vendorId: any
  ) {
    let qprm = "";
    if (projectId) {
      qprm = "project=" + projectId;
    }
    if (vendorId) {
      qprm += "&vendor=" + vendorId;
    } else if (programId) {
      qprm = "&program=" + programId;
    } else if (subportfolioId) {
      qprm = this.subportfolioParam + subportfolioId;
    } else if (portfolioId) {
      qprm = this.portfolioParam + portfolioId;
    } else {
      this.loggingService.warn(this.warningText);
    }
    return this.http.get(
      `assessment/assignement_instance/owned-entity/?${qprm}`
    );
  }

  getGovAssessments(
    projectId: any,
    portfolioId: any,
    subportfolioId: any,
    programId: any,
    vendorId: any
  ) {
    let qprm = "";
    if (projectId) {
      qprm = "&project=" + projectId;
    }
    if (vendorId) {
      qprm += "&vendor=" + vendorId;
    } else if (programId) {
      qprm = "&program=" + programId;
    } else if (subportfolioId) {
      qprm = this.subportfolioParam + subportfolioId;
    } else if (portfolioId) {
      qprm = this.portfolioParam + portfolioId;
    } else {
      this.loggingService.warn(this.warningText);
    }
    return this.http.get(
      `gov-lighthouse/governance_lighthouse_instance/owned-entity/?${qprm}`
    );
  }
}
