import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { MessageService } from "../../services/message.service";
import { TurnkeyTrackerService } from "../../services/turnkey-tracker.service";
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";

@Component({
  selector: "app-owner",
  templateUrl: "./owner.component.html",
  styleUrls: ["./owner.component.scss"],
})
export class OwnerComponent {
  private _programId: Object;
  @Input() inputString: any;
  @Input() inputType: any;
  @Output() refereshParentData = new EventEmitter<string>();
  @Input() editable = true;
  @Input() ownerdata: any;
  @Input() context: any = "";
  @Input() allowOneInput = false;
  @Input() filterOwner: any = false;
  @Input() get programId(): any {
    return this._programId;
  }

  set programId(value: any) {
    if (this.filterOwner && value) {
      this._programId = value?.id;
      this.getFilterOwner();
    }
  }

  oldData: any;
  owners: any;
  selectedOwners: any = [];

  myControl = new FormControl();
  filteredOptions: Observable<string[]>;

  editMode = false;

  constructor(
    private readonly cookieService: CookieService,
    private readonly messageService: MessageService,
    private readonly turnkeyTrackerService: TurnkeyTrackerService
  ) {}

  ngOnInit() {
    if (!this.filterOwner) {
      this.getOwner();
      if (!this.cookieService.get("owner")) {
        const myDate: Date = new Date();
        myDate.setSeconds(myDate.getSeconds() + 10);
        this.cookieService.set("owner", "true", { expires: myDate });
        this.getAllOwner();
      }
    }
  }

  setOwnerData(owners: any) {
    this.selectedOwners = [];
    this.owners = owners;
    if (this.ownerdata) {
      this.ownerdata.forEach((element: any) => {
        this.owners.forEach((elm: any) => {
          if (elm.id === element.user) {
            this.selectedOwners.push(elm);
          }
        });
      });
    }
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value))
    );
  }

  getFilterOwner() {
    this.turnkeyTrackerService
      .getFilterOwner({ program_id: this._programId })
      .subscribe((res: any) => {
        this.setOwnerData(res);
      });
  }

  async getOwner() {
    this.messageService.getOwner().subscribe((res: any) => {
      if (res.length > 0) {
        this.setOwnerData(res);
      }
    });
  }

  private _filter(value: any): any {
    if (typeof value === "string") {
      const filterValue = value.toLowerCase();
      const removeSelected = this.owners.filter(
        (el: any) => !this.selectedOwners.includes(el)
      );
      return removeSelected.filter((owner: any) =>
        owner?.email?.toLowerCase().includes(filterValue)
      );
    }
    return null;
  }

  getAllOwner() {
    this.turnkeyTrackerService.getOwner().subscribe((res: any) => {
      this.messageService.setOwner(res);
    });
  }

  addOwner(e: any) {
    if (this.allowOneInput) {
      if (this.selectedOwners.length === 0) {
        this.selectedOwners.push(e);
        this.myControl.setValue("");
        this.sendToParent();
      } else {
        this.myControl.setValue("");
        this.sendToParent();
        return;
      }
    } else {
      this.selectedOwners.push(e);
      this.myControl.setValue("");
      this.sendToParent();
    }
  }

  getIconNameShort(obj: any) {
    let firstName = "";
    let lastName = "";
    if (obj.first_name) {
      firstName = obj.first_name.substring(0, 1);
    }
    if (obj.last_name) {
      lastName = obj.last_name.substring(0, 1);
    }
    return (firstName + lastName).toUpperCase();
  }

  removeUser(i: any) {
    this.selectedOwners.splice(i, 1);
    this.sendToParent();
  }

  sendToParent() {
    const sel: any = [];
    this.selectedOwners.forEach((element: any) => {
      sel.push(element.id);
    });

    this.refereshParentData.emit(sel);
  }

  cancelEdit() {
    this.inputString = this.oldData;
    this.editMode = false;
  }
}
