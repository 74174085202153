import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class RiskConvergenceService {
  constructor(private readonly http: HttpClient) {}


  projectList() {
    return this.http.get("delivery/project-list?flag=mapped_list");
  }

  getVendorList() {
    return this.http.get(
      "dynamic-form/?form_name=opportunity_empanelled_vendors"
    );
  }

  getvendordetails() {
    return this.http.get("action-tracker/get-vendor-details");
  }

  getRiskMaster(filterString = "") {
    return this.http.get(`risk/risks/?${filterString}`);
  }


  getRiskProject(queryParam: any) {
    return this.http.get(`delivery/get-risk-goal-project/?${queryParam}`);
  }

  getRiskApplication(queryParam: any) {
    return this.http.get(`master/application/list-unique/?${queryParam}`);
  }

  updateRisk(payload: any) {
    return this.http.post(`risk/risks/`, payload);
  }

  getRiskTracker(offSet?: any, end?: any) {
    const params = `offset=${offSet}&index=${end}`;
    return this.http.get(`risk/project-risks/?${params}`);
  }

  getFilteredRiskTracker(offSet?: any, end?: any, queryParams?: any) {
    const params = `offset=${offSet}&index=${end}${queryParams}`;
    return this.http.get(`risk/project-risks/?${params}`);
  }

  downloadTrackerList(queryParam: any) {
    return this.http.get(`risk/project-risks/?response_type=csv${queryParam}`, {
      responseType: "blob",
    });
  }

  getRiskPulse() {
    return this.http.get("nps/pulses/?performance_impact=Risk");
  }
}
