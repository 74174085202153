export class Calculator {
  data: any;
  values: any;

  constructor(data: any) {
    this.data = data;
    this.values = {};
  }

  calculate() {
    // Populate the initial values from default
    for (const [key, value] of Object.entries(this.data.default)) {
      this.values[key] = value;
    }
  }

  private calculateMetric(formula: string): number | null {
    try {
      return new Function("values", `with (values) { return ${formula}; }`)(
        this.values
      );
    } catch (e) {
      return null;
    }
  }

  changeMetric(metricName: string, metricValue: number) {
    this.values[metricName] = metricValue;
    const dependents = this.data.derived_metrics[metricName].dependents;
    for (const dependent of dependents) {
      const calculatedValue = this.calculateMetric(
        this.data.derived_metrics[dependent].formula
      );
      if (calculatedValue !== null) {
        this.changeMetric(dependent, calculatedValue);
      }
    }
  }
}
