<div *ngIf="resDetails" style="overflow-x: scroll; padding: 0px 15px">
  <h6>Response Description</h6>
  <p style="font-size: 14px; margin-top: 15px; text-align: justify">
    {{ resDetails?.description }}
  </p>
  <mat-accordion>
    <mat-expansion-panel
      [expanded]="true"
      (opened)="panelOpenState = true"
      (closed)="panelOpenState = false"
      style="border-radius: 20px"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          Attachments ({{
            resDetails?.response_files?.length ||
              resDetails?.idea_pitch_files?.length
          }})
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div style="padding: 20px; overflow-x: scroll">
        <div
          class="d-flex flex-row flex-nowrap overflow-auto"
          *ngIf="resDetails?.response_files?.length > 0"
          style="padding: 10px 0"
        >
          <div
            class="card card-block mx-2"
            style="
              width: 150px;
              min-width: 150px;
              height: 150px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 15px;
              padding: 0 8px;
            "
            *ngFor="let x of resDetails?.response_files; let i = index"
            (click)="downloadFile(x)"
          >
            <mat-icon style="position: absolute; top: 0; right: 4px"
              >cloud_download</mat-icon
            >
            <mat-icon
              mat-list-icon
              class="material-icons my-auto fs-1 text-danger"
              style="width: 30px"
            >
              description</mat-icon
            >
            <p class="m-0">{{ x?.file_name }}</p>
          </div>
        </div>
        <div *ngIf="resDetails?.response_files?.length == 0">
          No files attached.
        </div>
        <div
          class="d-flex flex-row flex-nowrap overflow-auto"
          *ngIf="resDetails?.idea_pitch_files?.length > 0"
          style="padding: 10px 0"
        >
          <div
            class="card card-block mx-2"
            style="
              width: 150px;
              min-width: 150px;
              height: 150px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 15px;
              padding: 0 8px;
              overflow: hidden;
            "
            *ngFor="let x of resDetails?.idea_pitch_files; let i = index"
            (click)="downloadIdeaPitchFile(x)"
          >
            <mat-icon style="position: absolute; top: 0; right: 4px"
              >cloud_download</mat-icon
            >
            <mat-icon
              mat-list-icon
              class="material-icons my-auto fs-1 text-danger"
              style="width: 30px"
            >
              description</mat-icon
            >
            <p class="m-0" style="overflow: hidden">{{ x?.file_name }}</p>
          </div>
        </div>
        <div *ngIf="resDetails?.idea_pitch_files?.length == 0">
          No files attached.
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  <br />
  <mat-accordion>
    <mat-expansion-panel
      [expanded]="true"
      (opened)="panelOpenState1 = true"
      (closed)="panelOpenState1 = false"
      style="border-radius: 20px"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          Links ({{ resDetails?.links?.length }})
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div style="padding: 20px; overflow-x: scroll">
        <div
          class="d-flex flex-row flex-nowrap overflow-auto"
          *ngIf="resDetails?.links?.length > 0"
          style="padding: 10px 0"
        >
          <div
            class="card card-block mx-2"
            style="
              min-width: 150px;
              height: 150px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 15px;
            "
            *ngFor="let x of resDetails?.links; let i = index"
            (click)="openLink(x)"
          >
            <mat-icon>link</mat-icon>
            <p class="m-0">Links {{ i + 1 }}</p>
          </div>
        </div>
        <div *ngIf="resDetails?.links?.length == 0">No Links Attached.</div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  <br />
</div>
