export const environment = {
  production: false,
  keplerServerURL: "https://demo.stage-keplerbe.kairhos.com",
  surveyServerURL: "https://survey.dev.kairhos.com/",
  elasticSearchURL: "https://esearch.dev.kairhos.com/",
  ipdbURL: "https://ipdb.dev.kairhos.com/admin/",
  elastic_auth: "admin:Mwx9rVbNkxcWDn7",
  ideapitchURL: "https://ideapitch.dev.kepler.kairhos.com/",
  tenant_info: {
    // alpha: { CLIENT_ID: 'd5948759-0c28-4d4a-850c-44251b99b300', TENANT_ID: 'fbfcf739-7deb-4829-b8b1-797a7b1727a1' },
    // beta: { CLIENT_ID: 'd5948759-0c28-4d4a-850c-44251b99b300', TENANT_ID: 'fbfcf739-7deb-4829-b8b1-797a7b1727a1' },
    kepler: {
      CLIENT_ID: "d5948759-0c28-4d4a-850c-44251b99b300",
      TENANT_ID: "fbfcf739-7deb-4829-b8b1-797a7b1727a1",
    },
    beta: {
      CLIENT_ID: "6f4118cd-98da-43ca-9a38-325f60732054",
      TENANT_ID: "806ff6b2-e0d0-4dbe-9de0-f7c1df905ba4",
    },
    alpha: {
      CLIENT_ID: "6f4118cd-98da-43ca-9a38-325f60732054",
      TENANT_ID: "806ff6b2-e0d0-4dbe-9de0-f7c1df905ba4",
    },
    titan: {
      CLIENT_ID: "6f4118cd-98da-43ca-9a38-325f60732054",
      TENANT_ID: "806ff6b2-e0d0-4dbe-9de0-f7c1df905ba4",
    },
    gama: {
      CLIENT_ID: "6f4118cd-98da-43ca-9a38-325f60732054",
      TENANT_ID: "806ff6b2-e0d0-4dbe-9de0-f7c1df905ba4",
    },
    demo: {
      CLIENT_ID: "6f4118cd-98da-43ca-9a38-325f60732054",
      TENANT_ID: "806ff6b2-e0d0-4dbe-9de0-f7c1df905ba4",
    },
    www: {
      CLIENT_ID: "6f4118cd-98da-43ca-9a38-325f60732054",
      TENANT_ID: "806ff6b2-e0d0-4dbe-9de0-f7c1df905ba4",
    },
  },
};

//ATBBydaVyye385tR7MpKzxqyEHMz5D9487E5
