import { Component, ViewChild, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Constants } from "../../constant/constant";
import { LoggingService } from "src/app/logging.service";

const TREE_DATA: any = [
  {
    name: "CoSInE",
    children: [
      { name: "Opportunity Workbench", url: "/program" },
      { name: "Deal Structure", url: "/deal-structure" },
      {
        name: "Challenge Workbench",
        url: "/challenge-workbench/challenge-statements",
      },
      { name: "Idea Pitch", url: "/ideapitch" },
    ],
  },
  {
    name: "Kaleidoscope",
    children: [
      { name: "Balanced Scorecard", url: "/balancedscorecard/dashboard" },
      { name: "Delivery Risk", url: "/riskconvergence" },
      { name: "People Insights", url: "/workforce/dashboard" },
      { name: "Hubble", url: "/performanceInsights/hubble" },
    ],
  },
  {
    name: "Northstar",
    children: [
      { name: "OKR Convergence", url: "/okrconvergence" },
      {
        name: "Assessment Central",
        url: "strategicRelevance/assessment-central",
      },
      { name: "Governance Lighthouse", url: "strategicRelevance/governancect" },
      { name: "Maven", url: "strategicRelevance/maven" },
    ],
  },
  {
    name: "Confluence",
    children: [
      { name: "Delivery Accelerator", url: "/delivery-accelerator/dashboard" },
      { name: "Plan Central", url: "/confluence/teamplantracker" },
      { name: "Parade", url: "/parade" },
      { name: "Community of Practice", url: "external" },
    ],
  },
  {
    name: "Prism",
    children: [
      { name: "Idea Pitch Login", url: "/prospect-provider/login" },
      { name: "Project Central", url: "/delivery/projects" },
      { name: "IPDB", url: "prism/ipdb" },
      { name: "Masters", url: "prism/masters" },
      { name: "Action Central", url: "/actiontracker" },
      { name: "GRC Workbench", url: "/incidentracker" },
      { name: "Airo", url: "https://airo.kairhos.com" },
    ],
  },
];

const VENDOR_MENU_DATA: any = [
  {
    name: "CoSInE",
    children: [
      { name: "Opportunity Workbench", url: "/program" },
      {
        name: "Challenge Workbench",
        url: "/challenge-workbench/challenge-statements",
      },
    ],
  },
  {
    name: "Confluence",
    children: [{ name: "Community of Practice", url: "external" }],
  },
  {
    name: "Prism",
    children: [
      { name: "Project Central", url: "/delivery/projects" },
      { name: "GRC Workbench", url: "/incidentracker" },
    ],
  },
];

@Component({
  selector: "app-side-menu",
  templateUrl: "./side-menu.component.html",
  styleUrls: ["./side-menu.component.scss"],
  encapsulation: ViewEncapsulation.Emulated,
})
export class SideMenuComponent {
  mainmenu: any;
  @ViewChild("stepper") stepper: any;
  user: any;

  constructor(
    private readonly route: Router,
    private readonly activeroute: ActivatedRoute,
    private readonly loggingService: LoggingService
  ) {
    this.user = JSON.parse(localStorage.getItem("permission") ?? "{}");
    if (!this.user?.is_vendor) {
      this.mainmenu = TREE_DATA;
    } else {
      this.mainmenu = VENDOR_MENU_DATA;
    }
  }
  ngOnInit(): void {
    this.activeroute.params.subscribe(() => {
      this.stepSeleted();
    });
  }

  stepSeleted() {
    setTimeout(() => {
      for (const i in this.mainmenu) {
        if (this.mainmenu.hasOwnProperty(i)) {
          const children = this.mainmenu[i]?.children || [];
          children.forEach((element: any) => {
            if (this.isRouteMatch("survey-details", element.url, "hubble", i)) {
              return;
            }
            if (
              this.isRouteMatch(
                "challenge-workbench",
                element.url,
                "challenge-workbench",
                i
              )
            ) {
              return;
            }
            if (
              this.isRouteMatch(
                "delivery-accelerator",
                element.url,
                "delivery-accelerator",
                i
              )
            ) {
              return;
            }
            if (this.isRouteMatchForSowMaster(element.url, i)) {
              return;
            }
            if (
              this.isRouteMatch(
                "confluence",
                element.url,
                "confluence/teamplantracker",
                i,
                ["confluence/teamplantemplates", "confluence/plan"]
              )
            ) {
              return;
            }
            if (this.isRouteMatchForVendorMaster(element.url, i)) {
              return;
            }
            if (this.route?.url?.includes(element.url)) {
              this.stepper.selectedIndex = i;
            }
          });
        }
      }
    }, 100);
  }

  isRouteMatch(
    routeKey: string,
    elementUrl: string,
    matchUrl: string,
    index: any,
    additionalUrls: string[] = []
  ) {
    if (
      this.route?.url?.includes(routeKey) &&
      (elementUrl.includes(matchUrl) ||
        additionalUrls.some((url) => elementUrl.includes(url)))
    ) {
      this.stepper.selectedIndex = index;
      return true;
    }
    return false;
  }

  isRouteMatchForSowMaster(elementUrl: string, index: any) {
    const routes: any = [
      "sowmaster",
      "people-competency-matrics",
      "technology-business-management-taxonomy",
      "toemasterlist",
      "metrics-master",
      "survey-central",
      "application-master",
    ];
    return this.isRouteMatch(
      routes.find((route: any) => this.route?.url?.includes(route)),
      elementUrl,
      "prism/ipdb",
      index
    );
  }

  isRouteMatchForVendorMaster(elementUrl: string, index: any) {
    const routes: any = ["vendor-master", "rate/rate-master"];
    return this.isRouteMatch(
      routes.find((route: any) => this.route?.url?.includes(route)),
      elementUrl,
      "prism/masters",
      index
    );
  }

  hasChild = (_: number, node: any) =>
    !!node.children && node.children.length > 0;

  navigateUrl(url: any) {
    if (url && url.indexOf("external") > -1) {
      this.navigateToSP();
    } else if (url) {
      this.route.navigateByUrl(url);
    } else {
      this.loggingService.warn("Unexpected value");
    }
  }

  openAiro(url: any) {
    window.open(url);
  }

  navigateToSP() {
    window.open(Constants.SHAREPOINT_URL, "_blank");
  }
}
