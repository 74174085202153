<app-modal-header-common [title]="'Map to Existing Parade'">
</app-modal-header-common>
<div class="plan-form">
  <app-loading *ngIf="!dataLoaded"></app-loading>
  <form *ngIf="dataLoaded" [formGroup]="paradeForm">
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>Parade</mat-label>
      <mat-select formControlName="parade_id">
        <mat-option value="{{data.id}}" *ngFor="let data of paradeInfo"
          >{{data.name}}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <div style="display: flex; justify-content: end" class="mt-3">
      <button
        mat-raised-button
        color="primary"
        class="templ-btn"
        (click)="mapParade()"
      >
        Map
      </button>
    </div>
  </form>
</div>
