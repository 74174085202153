<section class="deletePopup">
  <div class="modal-dialog m-0">
    <div class="modal-content">
      <app-modal-header-common [title]="modalPopUpText?.confirmation_title">
      </app-modal-header-common>

      <div class="modal-body">
        <p class="fs-18 py-3 text-center primary m-0 fw-bold">
          {{ modalPopUpText?.del_msg }}
        </p>
        <p
          [innerHTML]="
            deletionWarning ? deletionWarning : modalPopUpText?.cant_retrieve
          "
        ></p>
      </div>

      <div class="modal-footer border-0">
        <div class="row">
          <div class="col-md-6">
            <button mat-flat-button color="secondary" (click)="closeDialog()">
              {{ buttonsText.cancel_btn }}
            </button>
          </div>
          <div class="col-md-6">
            <button mat-raised-button color="primary" (click)="delete()">
              {{ buttonsText?.delete_btn }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
