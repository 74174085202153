import { DatePipe } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ParadeService } from "src/app/shared/services/parade.service";

@Component({
  selector: "parade-existing-modal",
  templateUrl: "parade-existing-modal.html",
  styleUrls: ["parade-existing-modal.scss"],
  providers: [DatePipe],
})
export class ParadeExistingModalDialogComponent {
  paradeData: any = [];
  paradeForm = new FormGroup({
    name: new FormControl("", [Validators.required]),
    parade_id: new FormControl("", []),
  });
  paradeInfo: any = [];
  dataLoaded = false;

  constructor(
    private readonly spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<ParadeExistingModalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly toastrService: ToastrService,
    private readonly paradeService: ParadeService
  ) {}

  ngOnInit() {
    this.getAllParade();
    this.paradeData = this.data.paradeData;
  }

  getAllParade() {
    this.paradeService.getNamesList().subscribe((res: any) => {
      this.paradeInfo = res;
      this.dataLoaded = true;
    });
  }

  mapParade() {
    const namesArr: any = [];
    const filesArr: any = [];
    this.paradeData.forEach((element: any) => {
      namesArr.push(element.label);
      filesArr.push(element.file);
    });

    const formData = new FormData();
    formData.append("names", JSON.stringify(namesArr));
    filesArr.forEach((e: any) => {
      formData.append("files", e);
    });
    this.spinner.show();
    this.paradeService
      .updateParade(this.paradeForm.value.parade_id, formData)
      .subscribe((res: any) => {
        this.spinner.hide();
        this.toastrService.success("Parade mapped successfully...");
        this.dialogRef.close();
        this.closeModal(res.id);
      });
  }

  closeModal(id: any): void {
    this.dialogRef.close(id);
  }
}
