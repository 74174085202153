<div class="d-flex">
  <div class="d-flex img-container">
    <img class="img-align" src="./assets/images/bg3.png" alt="background" />
    <div class="centered fw-bold fs-14">
      {{ opportunityDetails?.opportunity_name }}
    </div>
  </div>
</div>

<div>
  <div class="d-flex justify-content-center align-items-center mt-3">
    <circle-progress
      [percent]="percent"
      [radius]="50"
      [innerStrokeWidth]="0"
      [innerStrokeColor]="'#E1E1E8'"
      [outerStrokeColor]="colorCode"
      [animation]="true"
      [animationDuration]="300"
    ></circle-progress>
  </div>
  <p class="d-flex justify-content-center fw-bold">
    {{ oppCurrentStatus?.action_complete_label }}
  </p>
  <hr />

  <div id="setBox" class="mat-left-content">
    <mat-stepper class="mat-nav-list" #stepper orientation="vertical">
      <ng-template matStepperIcon="edit">
        <mat-icon>circle</mat-icon>
      </ng-template>

      <mat-step
        class="px-1"
        label="{{ firstSepperLable }}"
        *ngIf="!user?.is_vendor"
      >
        <ng-template matStepLabel>{{ firstSepperLable }}</ng-template>
        <ng-template matStepContent>
          <ul
            class="nav-list mb-0 nodots"
            style="margin-left: -16px; margin-top: -10px"
          >
            <li></li>
            <li>
              <b>Opportunity Definition</b>
              <ul class="nodots">
                <li
                  routerLinkActive="active"
                  class="px-1"
                  (click)="navigateTO('setup')"
                  [class.active]="router?.url?.includes('opportunity/setup')"
                >
                  Vitals{{ route.path | json }}
                </li>
                <li
                  (click)="navigateTO('scope')"
                  routerLinkActive="active"
                  [class.active]="router?.url?.includes('opportunity/scope')"
                  class="px-1"
                >
                  Scope
                </li>
                <li
                  routerLinkActive="active"
                  class="px-1"
                  (click)="navigateTO('terms')"
                  [class.active]="router?.url?.includes('opportunity/terms')"
                >
                  {{ toeText.toe_shortform }}
                </li>
              </ul>
            </li>
            <li>
              <b>Opportunity Distribution</b>
              <ul class="nodots">
                <li
                  routerLinkActive="active"
                  class="px-1"
                  (click)="navigateTO('distribution')"
                  [class.active]="
                    router?.url?.includes('opportunity/distribution')
                  "
                >
                  {{ opportunityText?.distribution }}
                </li>
                <li
                  routerLinkActive="active"
                  class="px-1"
                  (click)="navigateTO('evaluation')"
                  [class.active]="
                    router?.url?.includes('opportunity/evaluation')
                  "
                >
                  {{ rfxText?.eval_matrix }}
                </li>
              </ul>
            </li>
            <li
              routerLinkActive="active"
              class="px-1"
              (click)="navigateTO('more-info')"
              [class.active]="router?.url?.includes('opportunity/more-info')"
            >
              {{ rfxText?.more_info }}
            </li>
            <li
              routerLinkActive="active"
              class="px-1"
              (click)="navigateTO('preview')"
              [class.active]="router?.url?.includes('opportunity/preview')"
            >
              {{ rfxText?.preview_send }}
            </li>
          </ul>
        </ng-template>
      </mat-step>
      <mat-step
        label="{{ proposalText?.evaluate }}"
        *ngIf="
          oppCurrentStatus?.slug != 'opportunity_draft' &&
          oppCurrentStatus?.slug != 'opportunity_created' &&
          oppCurrentStatus?.slug != 'opportunity_modified'
        "
      >
        <ng-template matStepLabel
          >{{
            !user?.is_vendor
              ? proposalText?.evaluate
              : proposalText?.evaluate_vendor
          }}
        </ng-template>
        <ng-template matStepContent>
          <ul class="nav-list nodots">
            <li
              class="nav-list-columns px-1"
              routerLinkActive="active"
              routerLink="/getproposal/{{ convertedID }}/proposal-evaluation"
              [class.active]="router?.url?.includes('/proposal-evaluation')"
            >
              {{
                !user?.is_vendor
                  ? proposalText?.evaluation
                  : proposalText?.evaluation_vendor
              }}
            </li>
            <li
              class="nav-list-columns px-1"
              routerLinkActive="active"
              *ngIf="!user?.is_vendor"
              routerLink="/getproposal/{{ convertedID }}/summary"
              [class.active]="router?.url?.includes('/summary')"
            >
              {{ proposalText?.proposal_evaluation.summary }}
            </li>
            <li
              class="nav-list-columns px-1"
              routerLinkActive="active"
              *ngIf="
                !user?.is_vendor &&
                user?.permissions?.indexOf('opportunity.add_opportunity') > -1
              "
              routerLink="/toe/{{ convertedID }}/award-deal"
              [class.active]="router?.url?.includes('/award-deal')"
            >
              {{ proposalText?.proposal_evaluation.award_deal }}
            </li>
          </ul>
        </ng-template>
      </mat-step>
    </mat-stepper>
  </div>
</div>
