import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { MessageService } from "../../services/message.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
  encapsulation: ViewEncapsulation.Emulated,
})
export class FooterComponent implements OnInit {
  iframeEmbedded = false;

  constructor(private readonly messageService: MessageService) {}

  ngOnInit(): void {
    this.messageService.getIframeData().subscribe((res: any) => {
      this.iframeEmbedded = typeof res != "object";
    });
  }
}
