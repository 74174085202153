import { Component } from "@angular/core";
import { ScorecardService } from "src/app/shared/services/scorecard.service";
import textConfiguration from "src/assets/static-text-configuration.json";

@Component({
  selector: "app-individual-scorecard",
  templateUrl: "./individual-scorecard.component.html",
  styleUrls: ["./individual-scorecard.component.scss"],
})
export class IndividualScorecardComponent {
  cards: any;
  staticText: any = (textConfiguration as any).balance_scorecard;
  constructor(private readonly scorecardService: ScorecardService) {
    this.scorecardService.cardDetails.subscribe((cards) => {
      this.cards = cards;
    });
  }

  clickOnMetric(metric: any, cardDetails: any, cardName: any) {
    const data: any = {
      metric,
      cardDetails,
      cardName,
    };
    this.scorecardService.changeMessage(data);
  }
  ngOnDestroy(): void {
    this.scorecardService.changeMessage("nodata");
  }
}
