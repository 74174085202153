<div class="modal-dialog what-if-modal m-0">
  <div class="modal-content">
    <app-modal-header-common [title]="'What-if Analysis'">
    </app-modal-header-common>

    <div class="modal-body">
      <mat-form-field class="w-50" appearance="outline">
        <mat-label>Metric</mat-label>
        <mat-select
          [(ngModel)]="currentMetricId"
          (ngModelChange)="getWhatIfInfo()"
        >
          <ng-container *ngFor="let item of metricsList">
            <mat-option [value]="item.id">
              {{ item.display_name }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
      <div *ngIf="whatIfData">
        <div class="row mb-2">
          <div class="col-sm-3">
            <h6>Primary Metric</h6>
            <div class="metric-info-holder">
              <p class="metric-name">
                {{ whatIfData?.metric?.display_name }} ({{
                  whatIfData?.metric?.unit_name
                }})
              </p>
              <p class="metric-value-txt">Current Value:</p>
              <p class="metric-value">
                {{
                  whatIfData?.default[whatIfData?.metric?.name] +
                    " " +
                    whatIfData?.metric?.unit_name
                }}
              </p>
            </div>
          </div>
          <div class="col-sm-6" style="display: flex; align-items: center">
            <div class="wrapper">
              <button class="increment-btn" (click)="increment()">+</button>
              <input
                class="input-range"
                type="range"
                min="{{whatIfData?.metric?.min_value}}"
                max="{{whatIfData?.metric?.max_value}}"
                [(ngModel)]="sliderValue"
                (input)="onInputChange()"
              />
              <button class="decrement-btn" (click)="decrement()">−</button>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="metric-info-holder" style="text-align: right">
              <p
                class="mb-1"
                style="line-height: 1.2; margin-top: 4rem; font-size: 10px"
              >
                New value <br />
                <span style="font-size: 22px; font-weight: 700"
                  >{{ sliderValue > 0 ? sliderValue : 0 }} {{whatIfData?.metric?.unit_name}}</span
                >
              </p>
            </div>
          </div>
        </div>

        <hr />

        <h6>Linked Metrics</h6>

        <div
          *ngIf="whatIfItems"
          style="
            display: flex;
            height: calc(100vh - 60vh);
            overflow-y: scroll;
            overflow-x: hidden;
          "
        >
          <div style="flex: auto">
            <div
              class="row mb-2"
              *ngFor="let item of whatIfItems; let i = index"
            >
              <div class="col-sm-5">
                <div class="lmetric-card">
                  <div class="lmetric-name-holder">
                    <h6>
                      {{ item.display_name }}
                      <span *ngIf="item?.unit_name"
                        >({{ item?.unit_name }})</span
                      >
                    </h6>
                  </div>
                  <div class="lmetric-value-holder">
                    <h4>{{ defaultWhatIfItems[item.name] }}</h4>
                    <p>Current Value</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-7">
                <p class="item-change-value">
                  {{
                    (updatedValues && updatedValues["new_" + item.name]
                      ? updatedValues["new_" + item.name]
                      : defaultWhatIfItems[item.name]
                    ) | number : "1.2-2"
                  }}
                  <span style="display: block; font-size: 9px; font-weight: 100"
                    >Change value
                  </span>
                </p>
                <input
                  type="range"
                  min="-{{ item.min_value  || defaultWhatIfItems[item.name] * 100 }}"
                  max="{{ item.max_value  || defaultWhatIfItems[item.name] * 100}}"
                  [value]="
                    updatedValues && updatedValues['new_' + item.name]
                      ? updatedValues['new_' + item.name]
                      : defaultWhatIfItems[item.name]
                  "
                  class="range blue"
                  [ngStyle]="{'pointer-events': whatIfData?.model_parameter ? 'all' : 'none'}"
                  (input)="onLinkedMetricInput($event, item.name)"
                  (mouseup)="predictMetrics()"
                  [disabled]="!whatIfData?.model_parameter?.includes(item.name)"
                  />
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <h6 class="text-center mt-4 opacity-50" *ngIf="!whatIfData">
        No records found
      </h6>
    </div>
    <div class="modal-footer border-0">
      <div class="row">
        <div class="col-md-6">
          <button
            mat-button
            mat-dialog-close
            (click)="dialogClose()"
            class="bg-primary text-white"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
