<mat-form-field
  class="example-full-width"
  style="width: 100%"
  appearance="outline"
>
  <mat-chip-list #chipList1 aria-label="multi selection">
    <mat-chip
      *ngFor="let item of selectedValues"
      [selectable]="selectable"
      (removed)="remove(item)"
    >
      {{ item.display_name ? item.display_name : item.name }}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
    <input
      class="lh-20"
      placeholder="{{ placeholderText }}"
      [(ngModel)]="searchText"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList1"
      [disabled]="isDisabled"
      name="searchText"
    />
  </mat-chip-list>
  <mat-autocomplete
    #auto="matAutocomplete"
    (optionSelected)="onOptionSelection($event)"
  >
    <mat-option
      *ngFor="let option of filteredList(options)"
      [value]="option"
      style="font-size: 0.8rem; height: 30px"
    >
      <ng-container *ngIf="controlName === 'tag'; else defaultContent">
        <b>{{ option.name }}</b> - {{ option.tag_category }}
      </ng-container>
      <ng-template #defaultContent>
        {{ option.display_name ?? option.name }}
      </ng-template>
    </mat-option>
  </mat-autocomplete>
  <button
    mat-button
    mat-icon-button
    matSuffix
    class="close-btn"
    (click)="clearField()"
    *ngIf="selectedValues.length > 0"
  >
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>
