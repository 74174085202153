import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-searchable-select",
  templateUrl: "./searchable-select.component.html",
  styleUrls: ["./searchable-select.component.scss"],
})
export class SearchableSelectComponent {
  @Input() placeholderText: any;
  @Input() isDisabled: any;
  @Input() options: any;
  @Input() controlName: any;
  @Output() selectValue = new EventEmitter();
  selectedValue: any = null;
  searchText = "";

  constructor() {}

  filteredList(filterObj: any) {
    if (filterObj) {
      let filteredArray = filterObj;
      if (typeof this.searchText === "string") {
        filteredArray = filterObj.filter((f: any) => {
          let opt = f.name;
          if (f.display_name) {
            opt = f.display_name;
          }
          if (opt) {
            return (
              opt.toLowerCase().indexOf(this.searchText.toLowerCase()) === 0
            );
          } else {
            return false;
          }
        });
      }
      return filteredArray;
    } else {
      return [];
    }
  }
  optionSelected(event: any) {
    this.selectedValue = event.option?.value;
    this.selectValue.emit(this.selectedValue);
  }

  clearField() {
    this.selectedValue = null;
    this.searchText = "";
    this.selectValue.emit(this.selectedValue);
  }

  displayNameFn(data: any) {
    if (data?.display_name) {
      return data.display_name;
    } else {
      return "";
    }
  }

  manipulateValue(val: any) {
    this.selectedValue = val;
    this.selectValue.emit(this.selectedValue);
  }
}
