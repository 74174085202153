import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class FilterService {
  public filterSubject = new BehaviorSubject<{}>({});
  public filterValues = new BehaviorSubject<{}>({});
  public filterCfgs = new BehaviorSubject<{}>({});
  public filterFlag = new BehaviorSubject<{}>({});

  constructor(private readonly http: HttpClient) {}

  setValue(value: string) {
    this.filterSubject.next(value);
  }

  setFilterValue(value: any) {
    this.filterValues.next(value);
  }

  setFilterCfgs(cfg: any) {
    this.filterCfgs.next(cfg);
  }

  getValue() {
    return this.filterSubject.asObservable();
  }

  setFilterField(field: any) {
    this.filterFlag.next({ field });
  }

  getFilterField() {
    return this.filterFlag.asObservable();
  }

  getPortfolios() {
    return this.http.get(`org/portfolios/opportunity-portfolio/`);
  }

  getPortfolioList() {
    return this.http.get(`org/portfolios/project-portfolios/`);
  }

  getSubportfolioList(portfolioId: any) {
    return this.http.get(
      `org/portfolios/project-subportfolios?portfolio_id=${portfolioId}`
    );
  }

  getFilterList(queryParam: any) {
    return this.http.get(`opportunities/hierarchy/?${queryParam}`);
  }

  getSubPortfolios(portfolioId: any) {
    if (portfolioId) {
      return this.http.get(
        `org/portfolios/subportfolios/?parent=${portfolioId}`
      );
    }
    return this.http.get(`org/portfolios/complete-subportfolios/`);
  }

  getProgram(subPortfolioId: any) {
    if (subPortfolioId) {
      return this.http.get(
        `org/programs/subportfolios/?sub_portfolio_id=${subPortfolioId}`
      );
    }
    return this.http.get(`org/programs/`);
  }

  getOpportunity(subPortfolioId: any, program: any) {
    if (subPortfolioId && program) {
      return this.http.get(
        `opportunities/with-portfolio-program/?portfolio=${subPortfolioId}&program=${program}`
      );
    }
    return this.http.get(`opportunities/names/`);
  }

  getOppStatus(pagetype: any) {
    let queryParam = "";
    if (pagetype === "deal") {
      queryParam += "page=deal";
    }
    return this.http.get(`opportunities/status?${queryParam}`);
  }

  getProject(subPortfolioId: any, program: any) {
    if (subPortfolioId && program) {
      return this.http.get(
        `projects/programs/?program_id=${program}&sub_portfolio_id=${subPortfolioId}`
      );
    }
    return this.http.get(`projects/`);
  }

  getVendor() {
    return this.http.get(`vendors/names/`);
  }

  getTag() {
    return this.http.get(`tag`);
  }

  getApplicationFilters() {
    return this.http.get(`master/application/filters/`);
  }

  getWorkforceFilters() {
    return this.http.get(`workforce/filters/`);
  }

  getRiskDomain() {
    return this.http.get(`risk/domains/`);
  }

  getRisks() {
    return this.http.get(`risk/risks/names/`);
  }

  getRiskSubDomain() {
    return this.http.get(`risk/sub-domains/`);
  }
  getObjectives() {
    return this.http.get(`okr/objectives/`);
  }
  getKeyResult() {
    return this.http.get(`okr/keyresults/`);
  }
  getGoals() {
    return this.http.get(`okr/goals`);
  }
  getOwners() {
    return this.http.get(`users/user-list/`);
  }
  getMOS() {
    return this.http.get(`metrics/metric-value/okr_metrics`);
  }
  getTypes() {
    return this.http.get(`base_value/?type=grc_audit_type`);
  }
  getNames() {
    return this.http.get(`grc/audit-templates/titles/`);
  }
  getJobTitle() {
    return this.http.get(`rate/job-titles/`);
  }
  getJobFamily() {
    return this.http.get(`rate/job-families/`);
  }

  getIdeaPitchNames() {
    return this.http.get(`ideapitch/idea_pitch/titles/`);
  }
}
