<div
  class="d-flex align-items-center input-box gap-2"
  [ngStyle]="{ width: width === 'w-16' ? '16rem' : '' }"
>
  <input
    type="text"
    placeholder="{{ placeholderText }}"
    class="form-control text-input"
    [formControl]="inputText"
  />
  <button
    *ngIf="inputText.value"
    mat-icon-button
    matSuffix
    aria-label="Clear"
    (click)="clearField()"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
