<app-modal-header-common
  title="{{ isEditMode ? 'Edit Pulse' : 'Create Pulse' }}"
>
</app-modal-header-common>
<div class="plan-form">
  <app-loading *ngIf="!dataLoaded"></app-loading>
  <div *ngIf="dataLoaded">
    <div *ngIf="showFile" class="row mb-3">
      <div class="col-sm-3 label">File upload</div>
      <div class="col-sm-9 value">
        <input
          type="file"
          (change)="this.onFileChange($event)"
          #fileInput
          hidden
        />
        <div class="d-flex align-items-center gap-2">
          <mat-icon *ngIf="showUpload" (click)="fileInput.click()"
            >cloud_upload</mat-icon
          >
          <mat-icon *ngIf="showDownload" (click)="downloadFile()"
            >cloud_download</mat-icon
          >
          <span [matTooltip]="fileName" class="file-name" *ngIf="fileName">{{
            fileName
          }}</span>
          <mat-icon *ngIf="fileName" color="remove" (click)="removeFile()"
            >clear</mat-icon
          >
        </div>
      </div>
    </div>
    <form [formGroup]="templateForm">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Name</mat-label>
        <input matInput formControlName="title" />
      </mat-form-field>
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Description</mat-label>
        <textarea matInput formControlName="description" rows="3"></textarea>
      </mat-form-field>
      <div class="row">
        <div class="col-sm-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ displayLabels.portfolio }}</mat-label>
            <input
              matInput
              [matAutocomplete]="auto"
              type="text"
              formControlName="portfolio_id"
            />
            <mat-icon matSuffix class="muted">search</mat-icon>
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
              <mat-option
                *ngFor="let option of filterOptionPortfolio | async"
                [value]="option"
                (onSelectionChange)="onSelectionChangePortfolio($event)"
              >
                {{ option.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="col-sm-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ displayLabels.sub_portfolio }}</mat-label>
            <input
              matInput
              [matAutocomplete]="auto1"
              type="text"
              formControlName="sub_portfolio_id"
            />
            <mat-icon matSuffix class="muted">search</mat-icon>
            <mat-autocomplete
              #auto1="matAutocomplete"
              [displayWith]="displayFn"
            >
              <mat-option
                *ngFor="let option of filterOptionSubPortfolio | async"
                [value]="option"
                (onSelectionChange)="onSelectionChangeSubportfolio($event)"
              >
                {{ option.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ displayLabels.program }}</mat-label>
            <input
              matInput
              [matAutocomplete]="auto2"
              type="text"
              formControlName="program_id"
            />
            <mat-icon matSuffix class="muted">search</mat-icon>
            <mat-autocomplete
              #auto2="matAutocomplete"
              [displayWith]="displayFn"
            >
              <mat-option
                *ngFor="let option of filterOptionProgram | async"
                [value]="option"
                (onSelectionChange)="onSelectionChangeProgram($event)"
              >
                {{ option.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="col-sm-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Project</mat-label>
            <input
              matInput
              [matAutocomplete]="auto3"
              type="text"
              formControlName="project_id"
            />
            <mat-icon matSuffix class="muted">search</mat-icon>
            <mat-autocomplete
              #auto3="matAutocomplete"
              [displayWith]="displayFnProject"
            >
              <mat-option
                *ngFor="let option of filterOptionProject | async"
                [value]="option"
              >
                {{ option.project_name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Vendor</mat-label>
            <input
              matInput
              [matAutocomplete]="auto4"
              type="text"
              formControlName="vendor_id"
            />
            <mat-icon matSuffix class="muted">search</mat-icon>
            <mat-autocomplete
              #auto4="matAutocomplete"
              [displayWith]="displayFn"
            >
              <mat-option
                *ngFor="let option of filterOptionVendor | async"
                [value]="option"
              >
                {{ option.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="col-sm-6">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Performance Impact</mat-label>
            <mat-select formControlName="performance_impact_id" multiple>
              <ng-container *ngFor="let opt of performanceImpactList">
                <mat-option [value]="opt.id">{{ opt.display_name }}</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Start Date</mat-label>
            <input
              matInput
              [matDatepicker]="picker1"
              formControlName="start_date"
            />
            <mat-datepicker-toggle matSuffix [for]="picker1">
            </mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-sm-6">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>End Date</mat-label>
            <input
              matInput
              [matDatepicker]="picker2"
              formControlName="end_date"
            />
            <mat-datepicker-toggle matSuffix [for]="picker2">
            </mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <mat-slide-toggle class="mb-3" formControlName="is_positive"
          >Is Positive</mat-slide-toggle
        >
      </div>
      <button
        *ngIf="!isEditMode"
        (click)="askAiro()"
        mat-raised-button
        color="primary"
        class="templ-btn"
        [disabled]="isAskAiroDisabled()"
      >
        Ask Airo
      </button>

      <div class="mt-3 d-flex justify-content-end gap-2">
        <button
          (click)="closeDialog()"
          mat-button
          mat-dialog-close
          color="primary"
          class="mx-1"
        >
          Close
        </button>
        <button
          mat-raised-button
          color="primary"
          class="templ-btn"
          (click)="createPulse()"
          [disabled]="templateForm.invalid"
        >
          {{ isEditMode ? "Update" : "Create" }}
        </button>
      </div>
    </form>
  </div>
</div>
