<div class="d-flex" style="width: 100%">
  <div class="d-flex img-container">
    <img class="img-align" src="./assets/images/bg3.png" alt="background" />
    <div class="centered fw-bold fs-14">Main Menu</div>
  </div>
</div>

<div>
  <div id="setBox">
    <mat-stepper class="mat-nav-list" #stepper orientation="vertical">
      <mat-step
        class="px-1"
        label="{{ menu.name }}"
        *ngFor="let menu of mainmenu"
      >
        <ng-template matStepLabel>{{ menu.name }}</ng-template>
        <ng-template matStepContent>
          <ul class="nav-list mb-0 nodots">
            <ng-container *ngFor="let item of menu.children">
              <!-- {{route?.url}} -->
              <ng-container
                *ngIf="route?.url?.includes('survey-details'); else elseif2"
              >
                <li
                  (click)="navigateUrl(item.url)"
                  routerLinkActive="active"
                  class="px-1"
                  [class.active]="
                    item?.url?.includes('performanceInsights/hubble')
                  "
                >
                  {{ item.name }}
                </li>
              </ng-container>
              <ng-template #elseif2>
                <ng-container
                  *ngIf="
                    route?.url?.includes('challenge-workbench') ||
                      route?.url?.includes('prospect-provider-workbench');
                    else elseif3
                  "
                >
                  <li
                    (click)="navigateUrl(item.url)"
                    routerLinkActive="active"
                    class="px-1"
                    [class.active]="
                      item?.url?.includes(
                        'challenge-workbench/challenge-statements'
                      ) ||
                      item?.url?.includes(
                        'prospect-provider-workbench/challenge-statements'
                      )
                    "
                  >
                    {{ item.name }}
                  </li>
                </ng-container>
              </ng-template>
              <ng-template #elseif3>
                <ng-container
                  *ngIf="
                    route?.url?.includes('delivery-accelerator');
                    else elseif4
                  "
                >
                  <li
                    (click)="navigateUrl(item.url)"
                    routerLinkActive="active"
                    class="px-1"
                    [class.active]="
                      item?.url?.includes('delivery-accelerator/dashboard')
                    "
                  >
                    {{ item.name }}
                  </li>
                </ng-container>
              </ng-template>
              <ng-template #elseif4>
                <ng-container
                  *ngIf="route?.url?.includes('sowmaster'); else elseif5"
                >
                  <li
                    (click)="navigateUrl(item.url)"
                    routerLinkActive="active"
                    class="px-1"
                    [class.active]="item?.url?.includes('prism/masters')"
                  >
                    {{ item.name }}
                  </li>
                </ng-container>
              </ng-template>
              <ng-template #elseif5>
                <ng-container
                  *ngIf="route?.url?.includes('toemasterlist'); else elseif6"
                >
                  <li
                    (click)="navigateUrl(item.url)"
                    routerLinkActive="active"
                    class="px-1"
                    [class.active]="item?.url?.includes('prism/ipdb')"
                  >
                    {{ item.name }}
                  </li>
                </ng-container>
              </ng-template>
              <ng-template #elseif6>
                <ng-container
                  *ngIf="route?.url?.includes('confluence'); else elseif7"
                >
                  <li
                    (click)="navigateUrl(item.url)"
                    routerLinkActive="active"
                    class="px-1"
                    [class.active]="
                      item?.url?.includes('confluence/teamplantracker') ||
                      item?.url?.includes('confluence/plan') ||
                      item?.url?.includes('confluence/teamplantemplates')
                    "
                  >
                    {{ item.name }}
                  </li>
                </ng-container>
              </ng-template>
              <ng-template #elseif7>
                <ng-container
                  *ngIf="
                    route?.url?.includes('vendor-master') ||
                      route?.url?.includes('rate/rate-master');
                    else elseif8
                  "
                >
                  <li
                    (click)="navigateUrl(item.url)"
                    routerLinkActive="active"
                    class="px-1"
                    [class.active]="item?.url?.includes('prism/masters')"
                  >
                    {{ item.name }}
                  </li>
                </ng-container>
              </ng-template>
              <ng-template #elseif8>
                <ng-container
                  *ngIf="
                    route?.url?.includes('application-master');
                    else elseif9
                  "
                >
                  <li
                    (click)="navigateUrl(item.url)"
                    routerLinkActive="active"
                    class="px-1"
                    [class.active]="item?.url?.includes('prism/masters')"
                  >
                    {{ item.name }}
                  </li>
                </ng-container>
              </ng-template>
              <ng-template #elseif9>
                <ng-container
                  *ngIf="
                    route?.url?.includes('people-competency-matrics');
                    else elseif10
                  "
                >
                  <li
                    (click)="navigateUrl(item.url)"
                    routerLinkActive="active"
                    class="px-1"
                    [class.active]="item?.url?.includes('prism/ipdb')"
                  >
                    {{ item.name }}
                  </li>
                </ng-container>
              </ng-template>
              <ng-template #elseif10>
                <ng-container
                  *ngIf="
                    route?.url?.includes(
                      'technology-business-management-taxonomy'
                    );
                    else elseif11
                  "
                >
                  <li
                    (click)="navigateUrl(item.url)"
                    routerLinkActive="active"
                    class="px-1"
                    [class.active]="item?.url?.includes('prism/ipdb')"
                  >
                    {{ item.name }}
                  </li>
                </ng-container>
              </ng-template>
              <ng-template #elseif11>
                <ng-container
                  *ngIf="
                    route?.url?.includes('metrics-master') ||
                      route?.url?.includes('survey-central');
                    else elseif12
                  "
                >
                  <li
                    (click)="navigateUrl(item.url)"
                    routerLinkActive="active"
                    class="px-1"
                    [class.active]="item?.url?.includes('prism/ipdb')"
                  >
                    {{ item.name }}
                  </li>
                </ng-container>
              </ng-template>
              <ng-template #elseif12>
                <ng-container *ngIf="item?.name === 'Airo'; else elseif1">
                  <li
                    (click)="openAiro(item.url)"
                    routerLinkActive="active"
                    class="px-1"
                  >
                    {{ item.name }}
                  </li>
                </ng-container>
              </ng-template>
              <ng-template #elseif1>
                <li
                  (click)="navigateUrl(item.url)"
                  routerLinkActive="active"
                  class="px-1"
                  [class.active]="route?.url?.includes(item.url)"
                >
                  {{ item.name }}
                </li>
              </ng-template>
            </ng-container>
          </ul>
        </ng-template>
      </mat-step>
    </mat-stepper>
  </div>
</div>
