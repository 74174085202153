import { NgModule } from "@angular/core";
import { DateAgoPipe } from "./date-ago.pipe";
import { GetRandomColorPipe } from "./get-random-color.pipe";
import { GetFilesizePipe } from "./get-filesize.pipe";
import { ConvertDataStringPipe } from "./convert-data-string.pipe";
import { FilterPortfoliosPipe } from "./filter-portfolios.pipe";
import { ReplaceCharacterPipe } from "./replace-character.pipe";

@NgModule({
  imports: [],
  declarations: [
    DateAgoPipe,
    GetRandomColorPipe,
    GetFilesizePipe,
    ConvertDataStringPipe,
    FilterPortfoliosPipe,
    ReplaceCharacterPipe,
  ],
  exports: [
    DateAgoPipe,
    GetRandomColorPipe,
    GetFilesizePipe,
    ConvertDataStringPipe,
    FilterPortfoliosPipe,
    ReplaceCharacterPipe,
  ],
})
export class PipeModule {
  static forRoot() {
    return {
      ngModule: PipeModule,
      providers: [],
    };
  }
}
