<div class="mb-4" *ngIf="screenName != 'Innovation' && screenName != 'Survey'">
  <div class="row row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5">
    <div class="col mt-4" *ngFor="let items of proposalLists; index as i">
      <div class="card card-wrapper" *ngIf="screenName == 'Proposal'">
        <div
          class="top-border"
          [ngClass]="{
            'red-border':
              items.status?.label == 'Draft' ||
              items.status?.label == 'Cancelled',
            'hold-border':
              items.status?.label === 'Create' ||
              items.status?.label === 'Modified',
            empty: items?.status?.label == '',
            'initiated-border': items.status?.label == 'Published',
            'partially-closed-border': items.status?.label == 'Evaluating',
            'green-border': items.status?.label == 'SoW Signed'
          }"
        ></div>
        <div class="row header-wrapper">
          <div class="col">
            <h5 (click)="viewProposal(items.id, items.status?.label)">
              {{ items.opportunity_number }}
            </h5>
          </div>
          <div class="col">
            <img
              src="./assets/Icons/table-card/view.svg"
              (click)="viewProposal(items.id, items.status?.label)"
            />
            <img
              class="edit-icon"
              src="./assets/Icons/table-card/edit.svg"
              (click)="viewProposal(items.id, items.status?.label)"
            />
          </div>
        </div>
        <div class="col-12 sub-heading">
          {{items.portfolio_details?.[1]?.name || 'N/A'}}
        </div>
        <div class="inside-card">
          <div class="col-12 status-text">
            <p
              class="status-wrap"
              [ngClass]="{
                red:
                  items.status?.label == 'Draft' ||
                  items.status?.label == 'Cancelled',
                empty: items.status?.label == '',
                initiated: items.status?.label == 'Published',
                'partially-closed': items.status?.label == 'Evaluating',
                hold:
                  items.status?.label === 'Create' ||
                  items.status?.label === 'Modified',
                green: items.status?.label == 'SoW Signed'
              }"
            >
              {{ items.status }}
            </p>
          </div>
          <div class="last-wrapper">
            <div class="col-12">
              <img src="./assets/Icons/table-card/portfolio.svg" />
              <span>Channels</span>
            </div>
            <div class="col-12">
              <img src="./assets/Icons/table-card/reservations.svg" />
              <span>Reservations</span>
            </div>
            <div class="col-12" *ngIf="items.start_date">
              <img
                class="mr-2 date-icons"
                src="./assets/Icons/table-card/start_date.svg"
              />
              <span>{{ items.start_date | date : "dd-MM-yyyy  HH:mm" }}</span>
            </div>
          </div>
        </div>
      </div>

      <div
        class="card card-wrapper project-wrapper"
        *ngIf="screenName == 'projects'"
      >
        <div
          class="top-border"
          [ngClass]="{
            empty: items?.status_details?.label == '',
            'initiated-border': items.status_details?.label == 'Initiated',
            'partially-closed-border':
              items.status_details?.label == 'Delivery',
            'green-border': items.status_details?.label == 'Delivered',
            'hold-border': items.status_details?.label == 'Suspended',
            'red-border': items.status_details?.label == 'Terminated'
          }"
        ></div>
        <div class="row header-wrapper">
          <div class="col">
            <h5 (click)="viewProject(items.id)">
              {{ items.project_id }} sdfsfsdfsdf
            </h5>
          </div>
          <div class="col pr-2">
            <img
              src="./assets/Icons/table-card/view.svg"
              (click)="projectViewPopup(items.id, projectsview)"
            />
            <!--  <img class="edit-icon" src="./assets/Icons/table-card/edit.svg" (click)="viewProject(items.id)"> -->
          </div>
        </div>
        <div class="col-12 sub-heading">{{ items?.project_name }}</div>
        <div class="inside-card">
          <div class="col-12 status-text p-0">
            <p
              class="status-wrap"
              [ngClass]="{
                initiated: items.status_details?.label == 'Initiated',
                'partially-closed': items.status_details?.label == 'Delivery',
                hold: items.status_details?.label == 'Suspended',
                red: items.status_details?.label == 'Terminated',
                green: items.status_details?.label == 'Delivered'
              }"
            >
              {{ items?.status_details?.label }}
            </p>
          </div>
          <div class="last-wrapper">
            <div class="col-12">
              <div class="d-flex mt-4">
                <div>
                  <img
                    src="./assets/images/delivery-projects.svg"
                    class="mr-3 project-name"
                  />
                </div>
                <div>
                  <span
                    matTooltip="{{ displayLabels.portfolio }} Name"
                    matTooltipClass="example-tooltip"
                    >{{ items?.portfolio || "N/A" }}</span
                  >
                </div>
              </div>
            </div>
            <div class="col-12 pb-3 d-flex mt-2">
              <div
                class="d-flex flex-column"
                *ngIf="
                  items?.begin_date || items?.expected_end_date;
                  else noData
                "
              >
                <div class="d-flex">
                  <img
                    class="mr-2 date-icons"
                    src="./assets/Icons/table-card/start_date.svg"
                  />
                  <div
                    *ngIf="items?.begin_date"
                    matTooltip="Project Begin Date"
                  >
                    <span class="field-text px-2">{{
                      items?.begin_date | date : "dd-MM-yyyy"
                    }}</span>
                  </div>
                </div>
                <div class="d-flex">
                  <img
                    class="mr-2 date-icons"
                    src="./assets/Icons/table-card/end_date.svg"
                  />
                  <div
                    *ngIf="items?.expected_end_date"
                    matTooltip="Project End Date"
                  >
                    <span class="field-text px-2">{{
                      items?.expected_end_date | date : "dd-MM-yyyy"
                    }}</span>
                  </div>
                </div>
              </div>
              <ng-template #noData>
                <div class="d-flex">
                  <img
                    class="mr-2"
                    src="./assets/Icons/table-card/calendar.svg"
                  />
                  <span class="px-2">N/A</span>
                </div></ng-template
              >
            </div>
          </div>
        </div>
      </div>

      <div class="card card-wrapper" *ngIf="screenName == 'toe'">
        <div
          class="top-border"
          [ngClass]="{
            'red-border':
              items.status?.label == 'Draft' ||
              items.status?.label == 'Cancelled',
            'hold-border':
              items.status?.label === 'Create' ||
              items.status?.label === 'Modified',
            empty: items?.status?.label == '',
            'initiated-border': items.status?.label == 'Published',
            'partially-closed-border': items.status?.label == 'Evaluating',
            'green-border': items.status?.label == 'SoW Signed'
          }"
        ></div>
        <div class="row header-wrapper">
          <div class="col">
            <h5 (click)="editToe(items.id, items.status?.label)">
              {{ items.opportunity_number }}
            </h5>
          </div>
          <div class="col" hidden>
            <img
              src="./assets/Icons/table-card/view.svg"
              (click)="viewToe(items.id, view)"
            />
          </div>
        </div>

        <div class="col-12 sub-heading" matTooltip="Opportunity name">
          {{ items.opportunity_name }}
        </div>
        <div class="inside-card">
          <div class="col-12 status-text p-0">
            <!--  <mat-select class="dropdown-toe-status" (selectionChange)="selectToeStatus($event , items)"
              placeholder="Select" [(ngModel)]="items.status.slug"
              [ngClass]="{'approved': items.status.slug == 'toe_open' , 'progress': items.status.slug == 'toe_inprogress' ,
                     'rejected': items.status.slug == 'toe_contract_signed'}" class="dropdown-toe-status ">
              <mat-option *ngFor='let i of toeStatus' [value]="i.slug">
                <span >{{i.action_complete_label | titlecase}}</span>
              </mat-option>
            </mat-select>
 -->

            <!-- <mat-select (selectionChange)="selectToeStatus($event , items)" placeholder="Select"
              [(ngModel)]="items.status.slug" [ngClass]="{'approved': items.status.slug == 'toe_open' , 'progress': items.status.slug == 'toe_inprogress' ,
                     'rejected': items.status.slug == 'toe_contract_signed'}" class="dropdown-toe-status ">
              <mat-option *ngFor='let i of toeStatus' [value]="i.slug">
                <span>{{i.action_complete_label | titlecase}}</span>
              </mat-option>
            </mat-select> -->
            <p
              class="status-wrap"
              [ngClass]="{
                red:
                  items.status?.label == 'Draft' ||
                  items.status?.label == 'Cancelled',
                empty: items.status?.label == '',
                initiated: items.status?.label == 'Published',
                'partially-closed': items.status?.label == 'Evaluating',
                hold:
                  items.status?.label === 'Create' ||
                  items.status?.label === 'Modified',
                green: items.status?.label == 'SoW Signed'
              }"
            >
              {{ items.status?.action_complete_label }}
            </p>
          </div>
          <div class="last-wrapper">
            <div class="col-12">
              <div class="d-flex mt-4">
                <div>
                  <img
                    class="mr-3"
                    src="./assets/Icons/table-card/portfolio.svg"
                  />
                </div>
                <div>
                  <span
                    matTooltip="{{ displayLabels.portfolio }}"
                    matTooltipClass="example-tooltip"
                    >{{items?.portfolio_details?.[0]?.display_name||'N/A'}}</span
                  >
                </div>
              </div>
            </div>

            <div class="col-12 mb-1">
              <div class="d-flex">
                <div>
                  <img
                    class="mr-3"
                    src="./assets/Icons/table-card/reservations.svg"
                  />
                </div>
                <div>
                  <span
                    matTooltip="{{ displayLabels.sub_portfolio }}"
                    matTooltipClass="example-tooltip"
                    >{{items?.portfolio_details?.[1]?.display_name || 'N/A'}}</span
                  >
                </div>
              </div>
            </div>
            <div class="col-12 pb-3 d-flex mt-2">
              <div
                class="d-flex flex-column"
                *ngIf="
                  items?.initiation_date || items?.last_updated_on;
                  else noData
                "
              >
                <div class="d-flex">
                  <img
                    class="mr-2 date-icons"
                    src="./assets/Icons/table-card/start_date.svg"
                  />
                  <div
                    *ngIf="items?.initiation_date"
                    matTooltip="Initiation Date"
                  >
                    <span class="field-text px-2">{{
                      items?.initiation_date | date : "dd-MM-yyyy"
                    }}</span>
                  </div>
                </div>
                <div class="d-flex">
                  <img
                    class="mr-2 date-icons"
                    src="./assets/Icons/table-card/calendar.svg"
                  />
                  <div
                    *ngIf="items?.last_updated_on"
                    matTooltip="Last updated On"
                  >
                    <span class="field-text px-2">{{
                      items?.last_updated_on | date : "dd-MM-yyyy"
                    }}</span>
                  </div>
                </div>
              </div>
              <ng-template #noData>
                <div class="d-flex" matTooltip="Initiation Date">
                  <img
                    class="mr-2 date-icons"
                    src="./assets/Icons/table-card/start_date.svg"
                  />
                  <span class="px-2">N/A</span>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>

      <div class="card card-wrapper" *ngIf="screenName == 'proposal-screen'">
        <div
          class="top-border"
          [ngClass]="{ 'initiated-border': items.status == 'Published' }"
        ></div>
        <div class="row header-wrapper">
          <div class="col">
            <h5 (click)="viewProposal(items.id, items.status?.label)">
              {{ items.opportunity_number }}
            </h5>
          </div>
          <div class="col">
            <img
              src="./assets/Icons/table-card/view.svg"
              (click)="openProposal(items.id, viewProposalPopup)"
            />
          </div>
        </div>
        <div class="col-12 sub-heading">{{ items?.opportunity_name }}</div>
        <div class="opportunity-inside-card">
          <div class="col-12 status-text p-0">
            <p
              class="status-wrap"
              [ngClass]="{ initiated: items.status == 'Published' }"
            >
              {{ items.status }}
            </p>
          </div>
          <div class="last-wrapper">
            <div class="col-12">
              <div class="d-flex mt-4">
                <div>
                  <img
                    class="mr-3"
                    src="./assets/Icons/table-card/portfolio.svg"
                  />
                </div>
                <div>
                  <span
                    matTooltip="{{ displayLabels.portfolio }}"
                    matTooltipClass="example-tooltip"
                    >{{items?.portfolio_details?.[0]?.display_name}}</span
                  >
                </div>
              </div>
            </div>
            <div class="col-12 mb-1">
              <div class="d-flex">
                <div>
                  <img
                    class="mr-3"
                    src="./assets/Icons/table-card/reservations.svg"
                  />
                </div>
                <div>
                  <span
                    matTooltip="{{ displayLabels.sub_portfolio }}"
                    matTooltipClass="example-tooltip"
                    >{{items?.portfolio_details?.[1]?.display_name || 'N/A'}}</span
                  >
                </div>
              </div>
            </div>
            <div class="col-md-12 mb-1" *ngIf="items.received_date">
              <img
                class="date-icons"
                src="./assets/Icons/table-card/end_date.svg"
              />
              <span
                class="pl-3"
                matTooltip="Received On"
                matTooltipClass="example-tooltip"
                >{{ items.received_date }}</span
              >
            </div>
          </div>
        </div>
      </div>

      <div class="card card-wrapper h-100" *ngIf="screenName == 'opportunity'">
        <div
          class="top-border"
          [ngClass]="{
            'red-border':
              items.status?.label == 'Draft' ||
              items.status?.label == 'Cancelled',
            'hold-border':
              items.status?.label === 'Create' ||
              items.status?.label === 'Modified',
            empty: items?.status?.label == '',
            'initiated-border': items.status?.label == 'Published',
            'partially-closed-border': items.status?.label == 'Evaluating',
            'green-border': items.status?.label == 'SoW Signed'
          }"
        ></div>
        <div class="row header-wrapper">
          <div class="col">
            <h5 (click)="viewProgram(items.id, items?.status?.label)">
              {{ items?.opportunity_number }}
            </h5>
          </div>
          <div class="col" hidden>
            <img
              src="./assets/Icons/table-card/view.svg"
              (click)="openOpportunity(items.id, viewOpportunity)"
            />
          </div>
        </div>
        <div class="col-12 sub-heading">{{ items.opportunity_name }}</div>
        <div class="opportunity-inside-card cursor-pointer">
          <div class="col-12 status-text p-0">
            <p
              class="status-wrap"
              [ngClass]="{
                red:
                  items.status?.label == 'Draft' ||
                  items.status?.label == 'Cancelled',
                empty: items.status?.label == '',
                initiated: items.status?.label == 'Published',
                'partially-closed': items.status?.label == 'Evaluating',
                hold:
                  items.status?.label === 'Create' ||
                  items.status?.label === 'Modified',
                green: items.status?.label == 'SoW Signed'
              }"
            >
              {{ items.status?.action_complete_label }}
            </p>
          </div>
          <div class="last-wrapper">
            <div class="col-12">
              <div class="d-flex mt-4">
                <div>
                  <img
                    class="mr-3"
                    src="./assets/Icons/table-card/portfolio.svg"
                  />
                </div>
                <div>
                  <span
                    matTooltip="{{ displayLabels.portfolio }}"
                    matTooltipClass="example-tooltip"
                    >{{items?.portfolio_details?.[0]?.display_name}}</span
                  >
                </div>
              </div>
            </div>
            <div class="col-12 mb-1">
              <div class="d-flex">
                <div>
                  <img
                    class="mr-3"
                    src="./assets/Icons/table-card/reservations.svg"
                  />
                </div>
                <div>
                  <span
                    matTooltip="{{ displayLabels.sub_portfolio }}"
                    matTooltipClass="example-tooltip"
                    >{{items?.portfolio_details?.[1]?.display_name || 'N/A'}}</span
                  >
                </div>
              </div>
            </div>
            <div class="col-md-12 mb-1 pl-3 pr-1" *ngIf="items.created_on">
              <img
                class="date-icons"
                src="./assets/Icons/table-card/start_date.svg"
              />
              <span
                class="pl-3"
                matTooltip="Created On"
                matTooltipClass="example-tooltip"
                >{{ items.created_on | date : "dd-MM-yyyy" }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="proposalLists?.length == 0">
    <div class="col-md-12 mx-auto">
      <img
        src="./assets/images/no-record.png"
        class="no-records"
        height="100%"
        width="100%"
      />
    </div>
    <div class="col-md-8 mx-auto">
      <p class="text-center fw-bold text-muted">
        {{ staticText.no_records_found }}
      </p>
    </div>
  </div>
</div>

<div class="mb-4 px-2" *ngIf="screenName == 'Innovation'">
  <div class="row row-cols-sm-2 row-cols-md-4 row-cols-lg-4 row-cols-xl-7">
    <div class="col mt-4 p-0" *ngFor="let items of proposalLists; index as i">
      <div class="card card-wrapper mx-2" (click)="ideaDetails(items?.id)">
        <div
          class="top-border"
          [ngClass]="{
            'red-border': items.status?.label == 'Rejected',
            'initiated-border': items.status?.label == 'Approved',
            empty: items?.status == '',
            'green-border': items.status?.label === 'Open',
            'partially-closed-border': items.status?.label == 'Under Review'
          }"
        ></div>
        <div class="row header-wrapper cursor-pointer">
          <div class="d-flex align-items-center col">
            <div class="mr-1" *ngIf="items?.vendor_details == false">
              <span
                class="name-1 cursor-pointer"
                [ngStyle]="{ backgroundColor: i | getRandomColor }"
              >
                {{ getName(items?.user_details) }}
              </span>
            </div>
            <div class="mr-1" *ngIf="items?.vendor_details != false">
              <span
                class="name-1 cursor-pointer"
                [ngStyle]="{ backgroundColor: i | getRandomColor : 'chart' }"
              >
                {{
                  getName(
                    items?.vendor_details.primary_contact_details.username
                  )
                }}
              </span>
            </div>
            <div>
              <div *ngIf="items?.vendor_details == false">
                <p
                  class="text-dark fw-bold text-truncate card-title cursor-pointer"
                  matTooltip="{{ items?.user_details }}"
                  matTooltipClass="example-tooltip"
                >
                  Delta {{ items?.user_details }}
                </p>
              </div>
              <div *ngIf="items?.vendor_details != false">
                <p
                  class="text-dark fw-bold text-truncate card-title cursor-pointer"
                  matTooltip="{{
                    items?.vendor_details?.primary_contact_details?.username
                  }}"
                  matTooltipClass="example-tooltip"
                >
                  {{ items?.vendor_details?.primary_contact_details?.username }}
                </p>
              </div>

              <small class="text-muted">{{
                items.created_on | date : "d MMM y"
              }}</small>
            </div>
          </div>
        </div>
        <div class="col-12 sub-heading"></div>
        <div class="innovation-inside-card">
          <div class="last-wrapper">
            <div class="col-md-12 px-0">
              <div class="d-flex">
                <p
                  class="text fw-bold idea-name"
                  matTooltip="{{ items?.name }}"
                  matTooltipClass="example-tooltip"
                >
                  {{ items.name }}
                </p>
              </div>
              <div class="d-flex">
                <small class="benefits-details">
                  {{ items?.benefits_details?.display_name }}
                </small>
              </div>
            </div>
          </div>
        </div>
        <div
          class="d-flex justify-content-between align-items-center mb-3 px-3"
        >
          <div>
            <div class="d-flex align-items-center">
              <img
                src="./assets/images/people.svg"
                *ngIf="items?.initiative_type_details.display_name == 'People'"
              />
              <img
                src="./assets/images/technology.svg"
                *ngIf="
                  items?.initiative_type_details.display_name == 'Technology'
                "
              />
              <img
                src="./assets/images/Business_Excellence.svg"
                *ngIf="
                  items?.initiative_type_details.display_name ==
                  'Business Excellence'
                "
              />
              <img
                src="./assets/images/Process.svg"
                *ngIf="items?.initiative_type_details.display_name == 'Process'"
              />
              <p
                class="p-0 text-nowrap mb-0"
                [ngClass]="{
                  'people-initiative':
                    items?.initiative_type_details.display_name == 'People',
                  'technology-initiative ':
                    items?.initiative_type_details.display_name == 'Technology',
                  'process-initiative':
                    items?.initiative_type_details.display_name == 'Process',
                  'business-initiative':
                    items?.initiative_type_details.display_name ==
                    'Business Excellence'
                }"
              >
                {{ items?.initiative_type_details.display_name }}
              </p>
            </div>
          </div>
          <div class="status-text p-0">
            <p
              class="innovation-status"
              [ngClass]="{
                red: items.status?.label == 'Rejected',
                empty: items.status?.label == '',
                initiated: items.status?.label == 'Approved',
                green: items.status?.label === 'Open',
                'partially-closed': items.status?.label == 'Under Review'
              }"
            >
              {{ items.status?.label }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="proposalLists?.length == 0">
    <div class="col-md-12 mx-auto">
      <img
        src="./assets/images/no-record.png"
        class="no-records"
        height="100%"
        width="100%"
      />
    </div>
    <div class="col-md-8 mx-auto">
      <p class="text-center fw-bold text-muted">
        {{ staticText.no_records_found }}
      </p>
    </div>
  </div>
</div>

<div class="mb-4 px-2" *ngIf="screenName == 'Survey'">
  <div class="row row-cols-sm-2 row-cols-md-4 row-cols-lg-4 row-cols-xl-7">
    <div class="col mt-4 p-0" *ngFor="let items of proposalLists; index as i">
      <div class="card card-wrapper mx-2" (click)="viewSurveyDetails(items)">
        <div
          class="top-border"
          [ngClass]="{
            'red-border':
              items?.status?.label == 'Rejected' ||
              items?.status?.label == null,
            'initiated-border': items?.status?.label == 'Approved',
            empty: items?.status == '',
            'green-border': items?.status?.label === 'Open',
            'partially-closed-border': items?.status?.label == 'Under Review'
          }"
        ></div>
        <div class="row header-wrapper cursor-pointer">
          <div class="d-flex col">
            <div class="align-items-center">
              <div>
                <p
                  class="text-dark fw-bold text-truncate card-title cursor-pointer"
                >
                  {{ items?.name }}
                </p>
              </div>
              <!-- <small class="text-muted">{{items.date_modified | date: 'd MMM y'}}</small> -->
            </div>
            <!-- <ng-container *ngIf="!items.survey_published">
              <div class="col" *ngxPermissionsOnly="['logger.add_publish']">
                <img src="./assets/Icons/table-card/view.svg" (click)="openSurveyModal(items.formid,$event)">
              </div>
            </ng-container> -->
          </div>
        </div>
        <div
          class="d-flex justify-content-between align-items-center mb-3 mt-3 flex-row"
        >
          <div class="survey-inside-card">
            <div class="last-wrapper">
              <div class="col-12 d-flex mb-1" *ngIf="items.start_date">
                <img src="./assets/Icons/table-card/calendar.svg" />
                <span class="pl-2">{{
                  items.start_date | date : "dd MMM yyyy"
                }}</span>
              </div>
              <div class="col-12 d-flex" *ngIf="items.end_date">
                <img src="./assets/Icons/table-card/calendar.svg" />
                <span class="pl-2">{{
                  items.end_date | date : "dd MMM yyyy"
                }}</span>
              </div>
            </div>
          </div>
          <div class="status-text p-0 mr-3 mt-4 w-40">
            <p
              class="innovation-status"
              [ngClass]="{
                red:
                  items.status?.label == 'Rejected' ||
                  items.status?.label == null,
                empty: items.status?.label == '',
                initiated: items.status?.label == 'Approved',
                green: items.status?.label === 'Open',
                'partially-closed': items.status?.label == 'Under Review'
              }"
            >
              {{ items.status?.label ?? "open" | titlecase }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="proposalLists?.length == 0">
    <div class="col-md-12 mx-auto">
      <img
        src="./assets/images/no-record.png"
        class="no-records"
        height="100%"
        width="100%"
      />
    </div>
    <div class="col-md-8 mx-auto">
      <p class="text-center fw-bold text-muted">
        {{ staticText.no_records_found }}
      </p>
    </div>
  </div>
</div>
<ng-template #projectsview>
  <div class="modal-dialog view-screen custom-width m-0">
    <div class="modal-content">
      <div class="modal-body">
        <div class="modal-popup">
          <div
            class="d-flex justify-content-between align-items-center header-wrapper px-2 pr-0"
          >
            <div class="">
              <h5 class="font-weight-bold">{{ cardData?.project_id }}</h5>
            </div>
            <div>
              <div class="col-12 status-text p-0">
                <p
                  class="status-wrap px-2"
                  [ngClass]="{
                    empty: cardData.status_details?.label == '',
                    initiated: cardData.status_details?.label == 'Initiated',
                    'partially-closed':
                      cardData.status_details?.label == 'Delivery',
                    hold: cardData.status_details?.label == 'Suspended',
                    red: cardData.status_details?.label == 'Terminated',
                    green: cardData.status_details?.label == 'Delivered'
                  }"
                >
                  {{ cardData.status_details?.action_complete_label }}
                </p>
              </div>
            </div>
          </div>
          <div
            class="col-xl-auto section-portfolio pb-2 mt-3"
            matTooltip="Project Name"
          >
            <img
              src="./assets/Icons/table-card/portfolio.svg"
              class="image-portfolio"
            />
            <span class="content">{{ cardData?.project_name }}</span>
          </div>
          <div
            class="col-xl-auto section-portfolio pb-2"
            matTooltip="{{ displayLabels.portfolio }}"
          >
            <img
              src="./assets/Icons/table-card/portfolio.svg"
              class="image-portfolio"
            />
            <span class="content">{{ cardData?.portfolio || "N/A" }}</span>
          </div>

          <div class="col-12">
            <div class="d-flex">
              <div>
                <img
                  src="./assets/images/delivery-projects.svg"
                  class="mr-3 project-name"
                />
              </div>
              <div class="d-flex w-100">
                <div matTooltip="OU/GDP" class="col-6 pl-0">
                  {{ cardData?.vendor_details?.name || "N/A" }}
                </div>
                <div class="col-6">
                  <span
                    matTooltip="Project Value"
                    matTooltipClass="example-tooltip"
                    >{{ cardData?.project_value || "N/A" }}</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 pb-3 d-flex mt-2">
            <div
              class="d-flex"
              *ngIf="
                cardData?.begin_date || cardData?.expected_end_date;
                else noData
              "
            >
              <img
                class="mr-2 date-icons"
                src="./assets/Icons/table-card/start_date.svg"
              />
              <div *ngIf="cardData?.begin_date" matTooltip="Project Begin Date">
                <span class="field-text px-2">{{
                  cardData?.begin_date | date : "dd-MM-yyyy"
                }}</span>
              </div>
              &nbsp;-&nbsp;
              <div
                *ngIf="cardData?.expected_end_date"
                matTooltip="Project End Date"
              >
                <img
                  class="mr-2 date-icons"
                  src="./assets/Icons/table-card/end_date.svg"
                />
                <span class="field-text px-2">{{
                  cardData?.expected_end_date | date : "dd-MM-yyyy"
                }}</span>
              </div>
            </div>
            <ng-template #noData><span class="px-2">N/A</span></ng-template>
          </div>
          <hr class="m-0" />
        </div>
      </div>
      <div class="modal-footer border-0">
        <div class="row">
          <div class="col-md-6">
            <button
              type="button"
              class="btn font-weight-bold text-primary"
              (click)="closeDialog()"
            >
              Close
            </button>
          </div>
          <div class="col-md-6 pl-0">
            <button
              type="button"
              class="btn btn-primary"
              (click)="screenInfo(cardData?.id)"
            >
              Overview
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #viewProposalPopup>
  <div class="modal-dialog view-screen custom-width m-0">
    <div class="modal-content">
      <div class="modal-body">
        <div class="modal-popup">
          <div
            class="d-flex justify-content-between align-items-center header-wrapper pr-0"
          >
            <div class="px-2">
              <h5 class="font-weight-bold">
                {{ cardData?.opportunity_number }}
              </h5>
            </div>
            <div class="d-flex align-items-center justify-content-center">
              <div class="">
                <div class="col-12 status-text p-0">
                  <p
                    class="status-wrap px-2"
                    [ngClass]="{ initiated: cardData.status == 'Published' }"
                  >
                    {{ cardData.status }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 pb-3 sub-heading">
            {{ cardData?.opportunity_name }}
          </div>
          <div class="col-xl-auto section-portfolio pb-2">
            <img
              src="./assets/Icons/table-card/portfolio.svg"
              class="image-portfolio"
            />
            <span
              class="content"
              >{{cardData?.portfolio_details?.[0]?.display_name}}</span
            >
          </div>
          <div class="col-12 pb-2">
            <img
              src="./assets/Icons/table-card/reservations.svg"
              class="image-sub-portfolio"
            />
            <span
              class="content"
              >{{cardData?.portfolio_details?.[1]?.display_name || 'N/A'}}</span
            >
          </div>

          <div class="col-12 pb-3">
            <div class="d-flex">
              <!-- <span class="content">{{cardData?.last_updated_on | date: 'd MMM y, h:mm' }}</span> -->
              <div
                *ngIf="cardData?.start_date"
                matTooltip="Opportunity Start Date"
              >
                <img src="./assets/Icons/table-card/start_date.svg" />
                <span class="field-text px-2">{{
                  cardData?.start_date | date : "d MMM y, h:mm"
                }}</span>
              </div>
              <!-- <div *ngIf="!cardData?.last_updated_on">
                <img src="./assets/Icons/table-card/calendar.svg">
                <span class="field-text px-2">N/A</span>
              </div> -->
            </div>
          </div>

          <table
            class="table vendor-tbl"
            *ngIf="
              cardData?.vendor_published_details &&
              cardData?.vendor_published_details?.length > 0
            "
          >
            <thead>
              <tr class="row-hide">
                <th class="text-center">GDP/Vendor</th>
                <th class="text-center">Published Date</th>
                <th class="text-center">Response Date</th>
                <th class="text-center">Elapsed Days</th>
                <th class="text-center">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of cardData?.vendor_published_details">
                <td class="content">
                  <div class="fs-14">{{ item.vendor }}</div>
                </td>
                <td class="input-fld">
                  <div class="fs-14">
                    {{ (item.published_date | date : "dd MMM yyyy") || "-" }}
                  </div>
                </td>
                <td class="input-fld">
                  <div class="fs-14">
                    {{ (item.response_date | date : "dd MMM yyyy") || "-" }}
                  </div>
                </td>
                <td class="content input-fld number">
                  <div class="fs-14">{{ item.elapsed_days }}</div>
                </td>
                <td class="p-0 vendor-status">
                  <div>
                    <span
                      class="link"
                      *ngIf="
                        item.vendor_status == 'SoW Signed';
                        else otherStatus
                      "
                      >{{ item.vendor_status }}</span
                    >
                    <!--(click)="navigateToProject()"-->
                    <ng-template #otherStatus>{{
                      item.vendor_status
                    }}</ng-template>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <hr class="mb-3" />
          <div class="col-12 pb-3">
            <div class="pb-3 row justify-content-between align-items-center">
              <div class="col-md-6 pb-2">
                <div>
                  <label class="field-label">Start Date</label>
                </div>
                <div *ngIf="cardData?.start_date">
                  <span class="field-text">{{
                    cardData?.start_date | date : "dd-MM-yyyy  HH:mm"
                  }}</span>
                </div>
                <div *ngIf="!cardData?.start_date">
                  <span class="field-text">N/A</span>
                </div>
              </div>
              <div class="col-md-6 pb-2">
                <div>
                  <label class="field-label">Closure Date</label>
                </div>
                <div *ngIf="cardData?.closure_date">
                  <span class="field-text">{{ cardData?.closure_date }}</span>
                </div>
                <div *ngIf="!cardData?.closure_date">
                  <span class="field-text">N/A</span>
                </div>
              </div>
              <div class="col-md-6">
                <div>
                  <label class="field-label">Manager</label>
                </div>
                <span class="field-text">{{
                  cardData?.proposal_manager_details?.full_name || "N/A"
                }}</span>
              </div>
              <div class="col-md-6">
                <div>
                  <label class="field-label">Initiate by </label>
                </div>
                <span class="field-text">
                  {{ cardData?.initiated_by.full_name || "N/A" }}</span
                >
              </div>
            </div>
          </div>
          <hr class="m-0" />
        </div>
      </div>
      <div class="modal-footer border-0">
        <div class="px-2">
          <button
            type="button"
            class="btn font-weight-bold text-primary"
            (click)="closeDialog()"
          >
            Close
          </button>
        </div>
        <div class="px-2">
          <button
            type="button"
            class="btn btn-primary"
            (click)="navigateTo(cardData?.id)"
          >
            Overview
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #viewOpportunity>
  <div class="modal-dialog view-screen custom-width m-0">
    <div class="modal-content">
      <div class="modal-body">
        <div class="modal-popup">
          <div
            class="d-flex justify-content-between align-items-center header-wrapper pr-0"
          >
            <div class="px-2">
              <h5 class="font-weight-bold">
                {{ cardData?.opportunity_number }}
              </h5>
            </div>
            <div class="d-flex align-items-center justify-content-center">
              <div class="">
                <div class="col-12 status-text p-0">
                  <p
                    class="status-wrap"
                    [ngClass]="{
                      red:
                        cardData.status?.label == 'Draft' ||
                        cardData.status?.label == 'Cancelled',
                      empty: cardData.status?.label == '',
                      hold:
                        cardData.status?.label == 'Create' ||
                        cardData.status?.label == 'Modified',
                      'partially-closed':
                        cardData.status?.label == 'Evaluating',
                      initiated: cardData.status?.label == 'Published',
                      green: cardData.status?.label == 'SoW Signed'
                    }"
                  >
                    {{ cardData?.status?.action_complete_label }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 pb-3 sub-heading">
            {{ cardData?.opportunity_name }}
          </div>
          <div class="col-xl-auto section-portfolio pb-2">
            <img
              src="./assets/Icons/table-card/portfolio.svg"
              class="image-portfolio"
            />
            <span
              class="content"
              >{{cardData?.portfolio_details?.[0]?.display_name}}</span
            >
          </div>
          <div class="col-12 pb-2">
            <img
              src="./assets/Icons/table-card/reservations.svg"
              class="image-sub-portfolio"
            />
            <span
              class="content"
              >{{cardData?.portfolio_details?.[1]?.display_name || 'N/A'}}</span
            >
          </div>

          <div class="col-12 pb-3" matTooltip="Opportunity Start Date">
            <img
              class="mr-2 date-icons"
              src="./assets/Icons/table-card/start_date.svg"
            />
            <span class="content">{{
              cardData?.created_on | date : "d MMM y, hh:mm"
            }}</span>
          </div>

          <table
            class="table vendor-tbl"
            *ngIf="
              cardData?.vendor_published_details &&
              cardData?.vendor_published_details?.length > 0
            "
          >
            <thead>
              <tr class="row-hide">
                <th class="text-center">GDP/Vendor</th>
                <th class="text-center">Published Date</th>
                <th class="text-center">Response Date</th>
                <th class="text-center">Elapsed Days</th>
                <th class="text-center">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of cardData?.vendor_published_details">
                <td class="content">
                  <div class="fs-14">{{ item.vendor }}</div>
                </td>
                <td class="input-fld">
                  <div class="fs-14">
                    {{ (item.published_date | date : "dd MMM yyyy") || "-" }}
                  </div>
                </td>
                <td class="input-fld">
                  <div class="fs-14">
                    {{ (item.response_date | date : "dd MMM yyyy") || "-" }}
                  </div>
                </td>
                <td class="content input-fld number">
                  <div class="fs-14">{{ item.elapsed_days }}</div>
                </td>
                <!-- <td class="date-fld"><div>
                  <mat-form-field appearance="outline" class="w-100 p-0">
                    <input matInput [matDatepicker]="picker" readonly [value]="item.published_date"
                        fullWidth status="basic" (click)="picker.open()">
                    <mat-datepicker-toggle matSuffix [for]="picker"> </mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                  </div>
                </td>
                <td class="date-fld">
                  <div>
                  <mat-form-field appearance="outline" class="w-100 p-0">
                    <input matInput [matDatepicker]="picker2" readonly [value]="item.response_date"
                        fullWidth status="basic" (click)="picker2.open()">
                    <mat-datepicker-toggle matSuffix [for]="picker2"> </mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                  </mat-form-field>
                  </div>
                </td>
                <td class="input-fld number"><div><input type="number" [value]="item.elapsed_days"></div></td> -->
                <td class="p-0 vendor-status">
                  <div>
                    <span
                      class="link"
                      *ngIf="
                        item.vendor_status == 'SoW Signed';
                        else otherStatus
                      "
                      >{{ item.vendor_status }}</span
                    >
                    <!--(click)="navigateToProject()"-->
                    <ng-template #otherStatus>{{
                      item.vendor_status
                    }}</ng-template>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <hr class="mb-3" />
          <div class="col-12 pb-3">
            <div class="pb-3 d-flex justify-content-between align-items-center">
              <div>
                <div class="d-flex">
                  <label class="field-label">Created by</label>
                </div>
                <span class="field-text">{{
                  cardData?.created_user_details?.full_name
                }}</span>
              </div>
              <div class="datepicker">
                <div class="d-flex">
                  <label class="field-label">Last Modified </label>
                </div>
                <span class="field-text">
                  {{
                    cardData?.last_updated_on | date : "d MMM y, hh:mm"
                  }}</span
                >
              </div>
            </div>
          </div>
          <hr class="m-0" />
        </div>
      </div>
      <div class="modal-footer border-0">
        <div class="d-inline-flex">
          <div class="px-2">
            <button
              type="button"
              class="btn font-weight-bold text-primary"
              (click)="closeDialog()"
            >
              Close
            </button>
          </div>
          <div class="col-md-6 pl-0">
            <button
              type="button"
              class="btn btn-primary"
              (click)="viewPreview(cardData?.id)"
            >
              Overview
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #view>
  <div class="modal-dialog view-screen custom-width m-0">
    <div class="modal-content">
      <div class="modal-body">
        <div class="modal-popup">
          <div
            class="d-flex justify-content-between align-items-center header-wrapper pr-0"
          >
            <div class="px-2">
              <h5 class="font-weight-bold">
                {{ cardData?.opportunity_number }}
              </h5>
            </div>
            <div class="d-flex align-items-center justify-content-center">
              <div class="">
                <p
                  class="status-wrap"
                  [ngClass]="{
                    red:
                      cardData?.status?.label == 'Draft' ||
                      cardData?.status?.label == 'Cancelled',
                    empty: cardData?.status?.label == '',
                    initiated: cardData?.status?.label == 'Published',
                    'partially-closed': cardData?.status?.label == 'Evaluating',
                    hold:
                      cardData?.status?.label === 'Create' ||
                      cardData?.status?.label === 'Modified',
                    green: cardData?.status?.label == 'SoW Signed'
                  }"
                >
                  {{ cardData.status?.action_complete_label }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-12 pb-3 sub-heading">
            {{ cardData?.opportunity_name }}
          </div>
          <div class="col-xl-auto section-portfolio pb-2">
            <img
              src="./assets/Icons/table-card/portfolio.svg"
              class="image-portfolio"
            />
            <span
              class="content"
              >{{cardData?.portfolio_details?.[0].display_name}}</span
            >
          </div>
          <div class="col-12 pb-3">
            <img
              src="./assets/Icons/table-card/reservations.svg"
              class="image-sub-portfolio"
            />
            <span
              class="content"
              >{{cardData?.portfolio_details?.[1]?.display_name || 'N/A'}}</span
            >
          </div>
          <div class="col-12 pb-3">
            <div class="d-flex">
              <div matTooltip="Initiation Date">
                <img src="./assets/Icons/table-card/start_date.svg" />
                <span class="content">{{
                  (cardData?.initiation_date | date : "d MMM y, h:mm") || "N/A"
                }}</span>
              </div>
            </div>
          </div>

          <table
            class="table vendor-tbl"
            *ngIf="
              cardData?.vendor_published_details &&
              cardData?.vendor_published_details?.length > 0
            "
          >
            <thead>
              <tr class="row-hide">
                <th class="text-center">GDP/Vendor</th>
                <th class="text-center">Published Date</th>
                <th class="text-center">Response Date</th>
                <th class="text-center">Elapsed Days</th>
                <th class="text-center">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of cardData?.vendor_published_details">
                <td class="content">
                  <div class="fs-14">{{ item.vendor }}</div>
                </td>
                <td class="input-fld">
                  <div class="fs-14">
                    {{ (item.published_date | date : "dd MMM yyyy") || "-" }}
                  </div>
                </td>
                <td class="input-fld">
                  <div class="fs-14">
                    {{ (item.response_date | date : "dd MMM yyyy") || "-" }}
                  </div>
                </td>
                <td class="content input-fld number">
                  <div class="fs-14">{{ item.elapsed_days }}</div>
                </td>
                <td class="p-0 vendor-status">
                  <div>
                    <span
                      class="link"
                      *ngIf="
                        item.vendor_status == 'SoW Signed';
                        else otherStatus
                      "
                      >{{ item.vendor_status }}</span
                    >
                    <!--(click)="navigateToProject()"-->
                    <ng-template #otherStatus>{{
                      item.vendor_status
                    }}</ng-template>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <hr class="mb-3" />
          <div class="col-12 pb-3">
            <div class="pb-3 d-flex justify-content-between align-items-center">
              <div>
                <div class="d-flex">
                  <label class="field-label">Initiated by</label>
                </div>
                <p
                  class="field-text text-truncate mb-0"
                  matTooltip="{{ cardData?.initiated_by?.username }}"
                >
                  {{ cardData?.initiated_by?.first_name || "N/A" }}
                  {{ cardData?.initiated_by?.last_name }}
                </p>
              </div>
              <div class="datepicker">
                <div class="d-flex">
                  <label class="field-label">Last Modified </label>
                </div>
                <span class="field-text">
                  {{
                    (cardData?.last_updated_on | date : "d MMM y, hh:mm") ||
                      "N/A"
                  }}</span
                >
              </div>
            </div>
            <div class="row">
              <div class="d-flex">
                <label class="field-label">Last Modified by</label>
              </div>
              <p
                class="field-text text-truncate mb-0"
                matTooltip="{{ cardData?.last_updated_by?.first_name }}"
              >
                {{ cardData?.last_updated_by?.first_name || "N/A" }}
                {{ cardData?.last_updated_by?.last_name }}
              </p>
            </div>
          </div>
          <hr class="m-0" />
        </div>
      </div>
      <div class="modal-footer border-0">
        <div class="d-flex">
          <div class="px-2">
            <button
              type="button"
              class="btn font-weight-bold text-primary"
              (click)="closeDialog()"
            >
              Close
            </button>
          </div>
          <div class="px-2">
            <button
              type="button"
              class="btn btn-primary"
              (click)="screenInfo(cardData?.id)"
            >
              Patner Approval
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
