import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { SurveyService } from "../../../shared/services/survey.service";
import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from "@angular/common";
import { Observable } from "rxjs";
import { startWith, map, filter } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import textConfiguration from "src/assets/static-text-configuration.json";
import {
  DateAdapter,
  MatDateFormats,
  MAT_DATE_FORMATS,
  NativeDateAdapter,
} from "@angular/material/core";
import { MatDialogRef } from "@angular/material/dialog";

export class AppDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: Object): string {
    let day: any = date.getDate().toString();
    if (+day < 10) {
      day = "0" + day;
    }
    let year: any = date.getFullYear();
    let shortMonth = date.toLocaleString("en-us", { month: "short" });
    if (displayFormat === "input") {
      return `${day} ${shortMonth} ${year}`;
    }
    if (displayFormat === "inputMonth") {
      return `${shortMonth} ${year}`;
    }
    return date.toDateString();
  }
}
export const APP_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: { month: "short", year: "numeric", day: "numeric" },
  },
  display: {
    dateInput: "input",
    monthYearLabel: "inputMonth",
    dateA11yLabel: { month: "short", year: "numeric", day: "numeric" },
    monthYearA11yLabel: { month: "short", year: "numeric", day: "numeric" },
  },
};
@Component({
  selector: "app-publish-survey",
  templateUrl: "./publish-survey.component.html",
  styleUrls: ["./publish-survey.component.scss"],
  providers: [
    DatePipe,
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
  ],
  encapsulation: ViewEncapsulation.Emulated,
})
export class PublishSurveyComponent implements OnInit {
  surveyForm: any;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tags: any = [];
  searchCtrl = new FormControl("");
  surveyId: any;
  distributorList: any = [];
  filterOptions: Observable<any[]>;
  staticText: any = textConfiguration;
  constructor(
    private readonly surveyService: SurveyService,
    private readonly spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<PublishSurveyComponent>,
    private readonly toastrService: ToastrService,
    private readonly datepipe: DatePipe
  ) {
    this.surveyForm = new FormGroup({
      survey_start_date: new FormControl("", Validators.required),
      survey_end_date: new FormControl("", Validators.required),
      respondents: new FormControl("", Validators.required),
    });
  }

  ngOnInit(): void {
    this.getDistributorsList();
    this.surveyService.getFormId().subscribe((id) => (this.surveyId = id));
  }
  getDistributorsList() {
    this.surveyService.getDistributors().subscribe(
      (response: any) => {
        this.spinner.hide();
        this.distributorList = response["records"].find(
          (i: any) => i.field_name === "opportunity_team"
        ).field_values;
        if (this.searchCtrl) {
          this.filterOptions = this.searchCtrl.valueChanges.pipe(
            startWith(""),
            map((value: any) => {
              if (typeof value === "string") {
                return value;
              } else {
                return value?.name;
              }
            }),
            map((name: any) => {
              if (name) {
                return this._filter(name);
              } else {
                return this.distributorList.slice();
              }
            })
          );
        }
      },
      () => {
        this.spinner.hide();
      }
    );
  }

  _filter(value: string): any {
    const filterValue = value.toLowerCase();
    return this.distributorList.filter((i: any) =>
      i.name.toLowerCase().includes(filterValue)
    );
  }
  addTag(event: any, searchInput: any): void {
    const value = event.option.value || "";
    if (value) {
      this.tags.push(value);
    }
    searchInput.value = "";
    this.searchCtrl.setValue(null);
    this.distributorList = this.distributorList.filter((o: any) => o !== value);
    if (!this.distributorList) {
      this.distributorList = [];
    }
    this.filterOptions = this.filterOptions.pipe(
      filter((o: any) => o !== value)
    );
  }

  removeTag(tag: any): void {
    const index = this.tags.indexOf(tag);
    if (index >= 0) {
      this.tags.splice(index, 1);
      this.distributorList.push(tag);
      this.filterOptions = this.filterOptions.pipe(
        filter((o: any) => o !== tag)
      );
    }
  }

  publishSurvey() {
    this.surveyForm.get("respondents").setValue(this.tags);
    if (this.surveyForm.invalid) {
      return;
    } else if (
      new Date(this.surveyForm.get("survey_start_date").value) >
      new Date(this.surveyForm.get("survey_end_date").value)
    ) {
      return;
    } else {
      this.spinner.show();
      let payload = this.surveyForm.value;
      payload["survey_start_date"] = this.datepipe.transform(
        this.surveyForm.value.survey_start_date,
        "yyyy-MM-dd"
      );
      payload["survey_end_date"] = this.datepipe.transform(
        this.surveyForm.value.survey_end_date,
        "yyyy-MM-dd"
      );
      this.surveyService.publishSurvey(this.surveyId, payload).subscribe(
        (response: any) => {
          this.spinner.hide();
          if (response) {
            this.toastrService.success("Survey Published Succesfully");
            this.dialogRef.close(true);
          }
        },
        () => {
          this.spinner.hide();
        }
      );
    }
  }
  cancelForm() {
    this.dialogRef.close(false);
  }
}
