<app-modal-header-common [title]="'File Label'"> </app-modal-header-common>
<div style="padding: 20px">
    <form [formGroup]="fileForm">
        <mat-form-field class="title" appearance="outline" style="width: 100%">
            <mat-label>Label</mat-label>
            <input type="text" matInput formControlName="label"
                placeholder="Enter label..." required />
        </mat-form-field>
    </form>
    <div style="display: flex; justify-content: end" class="mt-3">
        <button mat-stroked-button color="primary" class="templ-btn mx-2" (click)="cancel()">
            Cancel
        </button>
        <button mat-raised-button color="primary" class="templ-btn" (click)="saveTemplate()" [disabled]="fileForm.invalid">
            Save
        </button>
    </div>
</div>