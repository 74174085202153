import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { Observable, of, Subject } from "rxjs";
import { tap } from "rxjs/operators";
import { TransferState, makeStateKey } from "@angular/platform-browser";
import { FilterService } from "./filter.service";

@Injectable({
  providedIn: "root",
})
export class ProgramService {
  private readonly selectedLabel: Subject<number> = new Subject();
  private readonly currentStatus: Subject<any> = new Subject();

  filterObj: any;

  opportunityDetails: any = {};
  constructor(
    private readonly http: HttpClient,
    private readonly router: Router,
    private readonly transferState: TransferState,
    private readonly filterService: FilterService
  ) {
    this.filterService.filterSubject.subscribe((res: any) => {
      this.filterObj = res;
    });
  }
  public getValue(): Observable<number> {
    return this.selectedLabel.asObservable();
  }
  public setValue(value: number): void {
    this.selectedLabel.next(value);
  }
  public getStatusValue(): Observable<any> {
    return this.currentStatus.asObservable();
  }
  public setStatusValue(value: any): void {
    this.currentStatus.next(value);
  }

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    let id = route.params["id"];
    try {
      id = atob(id);
    } catch (e) {
      this.router.navigate(["404"]);
    }
    const detailKey = makeStateKey(`opportunities/${id}/status/`);
    return this.http.get(`opportunities/${id}/status/`).pipe(
      tap((res: any) => {
        this.transferState.set(detailKey, res);
      })
    );
  }

  getOppStatus(id: number) {
    const detailKey = makeStateKey(`opportunities/${id}/status/`);
    return this.http
      .get(`opportunities/${id}/status/`)
      .pipe(tap((res: any) => this.transferState.set(detailKey, res)));
  }

  getSingleOpportunityById(id: any) {
    const detailKey = makeStateKey(`opportunities/${id}/single`);
    return this.http
      .get(`opportunities/${id}/single`)
      .pipe(tap((res: any) => this.transferState.set(detailKey, res)));
  }

  getOpportunityById(id: any) {
    const detailKey = makeStateKey(`opportunities/${id}/`);

    return this.http
      .get(`opportunities/${id}/`)
      .pipe(tap((res: any) => this.transferState.set(detailKey, res)));
  }

  getOpportunityByIdWithoutChachedResponse(id: any) {
    return this.http.get(`opportunities/${id}/`);
  }

  getOpportunityMoreInfo(id: any) {
    return this.http.get(`opportunities/${id}/more-info/`);
  }

  getOpportunityEvoluationData(id: any) {
    return this.http.get(`opportunities/${id}/evaluation-matrix/`);
  }

  getOpportunityScopeData(id: any) {
    return this.http.get(`opportunities/${id}/scopes/`);
  }
  getProgramData() {
    return this.http.get(`org/programs/`);
  }

  getProjectScopeData(id: any) {
    return this.http.get(`scope/project-scopes/?project_id=${id}`);
  }

  getProject(id: any) {
    return this.http.get(`scope/project-scopes/?project_id=${id}`);
  }

  getOpportunityFormData(query?: any, query2?: any) {
    const dataKey = makeStateKey(`form_name=${query}`);
    return this.http
      .get(`dynamic-form/?form_name=${query}` + (query2 || ""))
      .pipe(tap((res: any) => this.transferState.set(dataKey, res)));
  }

  getOpportunityFormDataWithoutChache(query?: any) {
    return this.http.get(`dynamic-form/?form_name=${query}`);
  }
  getOpportunityHierarchy() {
    return this.http.get(`org/portfolios/hierarchy/`);
  }
  getOpportunityType() {
    return this.http.get(`valuelists/?type=opportunity_type`);
  }

  createOpportunity(payload: any) {
    return this.http.post("opportunities/", payload);
  }

  updateOpportunity(id: any, payload: any) {
    return this.http.put(`opportunities/${id}/`, payload);
  }

  updateProjectScope(id: any, payload: any) {
    return this.http.put(`scope/project-scopes/update/${id}/`, payload);
  }

  getDynamicForm() {
    const detailKey = makeStateKey(`dynamic-form/`);
    const cachedResponse = this.transferState.get(detailKey, null);
    if (!cachedResponse) {
      return this.http
        .get(`dynamic-form/`)
        .pipe(tap((res: any) => this.transferState.set(detailKey, res)));
    }
    return of(cachedResponse);
  }
  getProgramValues(id: any) {
    return this.http.get(
      `opportunities/get-grouped-subportfolio?sub_portfolio_child=${id}`
    );
  }

  deleteCustomKeyResutls(uuid: any, goalUuid: any) {
    return this.http.delete(
      `opportunities/delete-custom-keyresult/${uuid}/${goalUuid}`
    );
  }

  createFunction(payload: any) {
    return this.http.post(`opportunities/create-function/`, payload);
  }

  createTimeline(payload: any) {
    return this.http.post(`opportunities/create-timeline/`, payload);
  }
  deleteCustomTimeline(id: any) {
    return this.http.delete(`opportunities/delete-timeline/${id}`);
  }

  searchOpportunity(
    portId: any,
    subPortId: any,
    searchText: any,
    offset = 0,
    end = 10,
    program = 1
  ) {
    return this.http.get(
      `opportunities/?portfolio=${portId}&portfolio=${subPortId}&program=${program}&search_text=${searchText}&offset=${offset}&end=${end}`
    );
  }

  deleteOpportunity(id: any) {
    return this.http.delete(`opportunities/${id}`);
  }

  getStatus() {
    const statusKey = makeStateKey("opportunities/status");
    const cachedResponse = this.transferState.get(statusKey, null);
    if (!cachedResponse) {
      return this.http
        .get(`opportunities/status`)
        .pipe(tap((res: any) => this.transferState.set(statusKey, res)));
    }
    return of(cachedResponse);
  }

  getOpportunityToe(id: any) {
    return this.http.get(`opportunity-toe/?opportunity_id=${id}`);
  }

  getToeSettings(id: any) {
    return this.http.get(`opportunity-toe/?opportunity_id=${id}`);
  }

  mapOpportunityToe(id: any, payload: any) {
    return this.http.post(`opportunity-toe/?opportunity_id=${id}`, payload);
  }

  updateToeStatus(payload: any) {
    return this.http.post(`terms-of-engagement/update-toe-status/`, payload);
  }
  updateStatus(data: any) {
    return this.http.post("action-tracker/update-action-tracker-status/", data);
  }

  getToelist(
    offset = 0,
    end = 10,
    subPortId?: any,
    searchText?: any,
    portId?: any,
    status?: any
  ) {
    let params = `offset=${offset}&end=${end}`;
    if (portId) {
      params = `portfolio=${portId}`;
    }
    if (subPortId) {
      params = `${params}&portfolio=${subPortId}`;
    }
    if (status) {
      params = `${params}&status=${status}`;
    }
    if (searchText) {
      params = `search_text=${searchText}`;
    }
    return this.http.get(`terms-of-engagement/get-toe-list/?${params}`);
  }

  treelisting(data: any) {
    return this.http.post("users/get-user-hierarchy", data);
  }

  // used to save opportunity file in s3 and send mail to opportunity vendors
  opportunityActions(id: any, payload: any) {
    return this.http.post(
      `opportunities/${id}/send-to-vendors/?opp_id=${id}`,
      payload,
      { observe: "response" }
    );
  }

  // To download opportunity file
  opportunityFileDownload(id: any, payload: any) {
    return this.http.post(
      `opportunities/${id}/send-to-vendors/?opp_id=${id}`,
      payload,
      { responseType: "blob" }
    );
  }

  exportOpportunities(id: any, payload: any) {
    return this.http.put(`opportunities/${id}/`, payload);
  }
  getInitiativeData() {
    const statusKey = makeStateKey("get-initiative-types");
    const cachedResponse = this.transferState.get(statusKey, null);
    if (!cachedResponse) {
      return this.http
        .get(`innovation/get-initiative-types`)
        .pipe(tap((res: any) => this.transferState.set(statusKey, res)));
    }
    return of(cachedResponse);
  }

  // Below api for top filter disable based on user accessiblity / permisssion (top filter:all of it, channels);

  getPortfolioList(queryParams: any = "") {
    return this.http
      .get(`org/portfolios/opportunity-portfolio/` + queryParams)
      .toPromise();
  }

  getDealPortfolioList(queryParams: any = "") {
    let qParam = "";
    if (queryParams) {
      qParam = `&${queryParams}`;
    }
    return this.http
      .get(`org/portfolios/opportunity-portfolio/?page=deal${qParam}`)
      .toPromise();
  }

  getSubPortfolioList(payload: any, queryParams: any = "") {
    return this.http
      .get(`org/portfolios/subportfolios/?parent=${payload.id}` + queryParams)
      .toPromise();
  }

  getPortfolioPrograms(payload: any, queryParams: any = "") {
    if (this.filterObj?.subportfolio?.array?.length > 0) {
      const body: any = { portfolio: this.filterObj.subportfolio };
      return this.http
        .post(`opportunities/hierarchy/?` + queryParams, body)
        .toPromise();
    }
    return this.http
      .get(
        `org/programs/opportunity-programs/?portfolio=${payload.id}` +
          queryParams
      )
      .toPromise();
  }

  getOpportunityList(payload: any, queryParams: any = "") {
    return this.http
      .get(
        `opportunities/with-portfolio-program/?portfolio=${payload?.parent?.id}&program=${payload.id}` +
          queryParams
      )
      .toPromise();
  }

  getOpportunityKrData(opportunityId: any) {
    return this.http.get(
      `okr/opportunity-goals/hierarchy/${opportunityId}/`
    );
  }

  selectOpportunityGoals(payload: any) {
    return this.http.post(`okr/opportunity-goals/`, payload);
  }

  getOpportunityToeData(opportunityId: any) {
    return this.http.get(`terms/category/?opportunity_id=${opportunityId}`);
  }

  selectOpportunityToe(payload: any) {
    return this.http.post(`terms/opportunity-terms/`, payload);
  }

  getFilteredList(queryParam: any, bodyParam: any = {}) {
    return this.http
      .post(`opportunities/hierarchy/?${queryParam}`, bodyParam)
      .toPromise();
  }

  deleteOpportunityById(item: any) {
    return this.http
      .delete(`opportunities/${item}/`)
      .toPromise();
  }

  archiveOpportunityById(item: any) {
    return this.http.put(`opportunities/${item}/archive/`, []).toPromise();
  }

  getArchiveList(portfolio: any, subportfolio: any, program: any) {
    return this.http
      .get(
        `opportunities/archived?portfolio=${portfolio}&sub_portfolio=${subportfolio}&program=${program}`
      )
      .toPromise();
  }

  unarchiveOppo(payload: any) {
    return this.http.put(`opportunities/unarchive/`, payload).toPromise();
  }

  getTermMatrix(oppId: any) {
    return this.http.get(`terms/matrix/?opportunity_id=${oppId}`);
  }
  updateTermMatrix(data: any) {
    return this.http.post(`terms/matrix/`, data);
  }
  getSelectedTerms(oppId: any) {
    return this.http.get(`terms/category/?opportunity_id=${oppId}`);
  }
}
