import { Chart } from "angular-highcharts";

export function getDisplayLables(this: any, commonService: any) {
  this.displayLabels = commonService.getLabels();
}

export function getGMapRandomColor() {
  const colorNum = 360;
  const color = Math.floor(Math.random() * colorNum);
  return `hsla(${color}, 50%, 25%, 90%)`;
}

export function getPerformanceImpact(pulse: any) {
  let perfImpact = "";
  pulse.performance_impact.forEach((impact: any) => {
    perfImpact += impact.display_name + ", ";
  });
  perfImpact = perfImpact.trimEnd();
  if (perfImpact.endsWith(",")) {
    perfImpact = perfImpact.slice(0, -1);
  }
  return perfImpact;
}

export function intializeData(this: any) {
  this.pulsesList.forEach((pulse: any) => {
    pulse["color"] = getGMapRandomColor();
    pulse["impact"] = getPerformanceImpact(pulse);
  });
}

export function getPulseCounts(this: any, projectId: any = null) {
  let qParam = "";
  if (projectId) {
    qParam = `project_id=${projectId}`;
  }
  this.projectService.getPulseCounts(qParam).subscribe((resp: any) => {
    this.pulseCounts = resp;
  });
}

export function initializeMetrics(this: any): void {
  this.metricValue = [];
  this.percentValue = [];
  this.month = [];
}

export function roundToTwoDecimals(value: number): number {
  return Math.round(value * 100) / 100;
}

export function getActualValue(val: any) {
  const lastValue = val?.actual_value[val?.actual_value.length - 1];
  if (
    [
      "attrition_currentmonth",
      "krr_tt",
      "attrition_ltm",
      "attrition_ytd",
    ].includes(val.name)
  ) {
    return roundToTwoDecimals(lastValue?.percentage || lastValue?.actual_value);
  } else if (val.name === "experience_junior") {
    return roundToTwoDecimals(lastValue?.data?.ratio);
  } else if (["gdp_survey_rating", "bvt_survey_rating"].includes(val.name)) {
    return roundToTwoDecimals(lastValue?.actual_value);
  } else {
    return roundToTwoDecimals(lastValue?.actual_value);
  }
}

export function processActualValues(
  this: any,
  actualValues: any[]
): {
  gdpName: any[];
  gdpValue: any[];
} {
  const gdpName: any[] = [];
  const gdpValue: any[] = [];

  actualValues.forEach((matric: any) => {
    this.metricValue.push(roundToTwoDecimals(matric.actual_value));
    this.month.push(matric.month_short);

    if (matric.percentage) {
      this.percentValue.push(roundToTwoDecimals(matric.percentage));
    }

    if (matric.gdp_name !== "ALL") {
      gdpValue.push(roundToTwoDecimals(matric.actual_value));
      gdpName.push(matric.gdp_name);
    }
  });

  return { gdpName, gdpValue };
}

export function formatNumber(number: any, decimalPlaces: number): string {
  // Ensure the number is a valid number
  if (!isNaN(number)) {
    // Use toFixed to format the number with the specified decimal places
    return number.toFixed(decimalPlaces);
  } else {
    // Handle the case where the input is not a valid number
    return number;
  }
}

export function constructDataObject(
  val: any,
  actualValue: any,
  change: any,
  lineTrendGrph: any,
  lineTrendOverview: any
): any {
  let variance = 0;
  if (val?.variance_percent) {
    variance = roundToTwoDecimals(val?.variance_percent);
  }
  return {
    change,
    trend: lineTrendGrph,
    overviewTrend: lineTrendOverview,
    id: val.id,
    metric_name: val.name,
    name: val.display_name,
    desc: val.description,
    actual_value: formatNumber(actualValue, 2),
    variance_per: variance,
    is_update: val.is_uptrend,
    powerBiReport: val?.powerbireport,
    unit_name: val?.unit_name,
    value: val,
  };
}

export function getChange(val: any): any {
  if (
    [
      "experience_junior",
      "req_aging",
      "gdp_survey_rating",
      "bvt_survey_rating",
    ].includes(val.name)
  ) {
    return "false";
  } else {
    return val.variance;
  }
}

export function reqAgingGraph(
  width: any,
  height: any,
  row: any,
  column: any,
  tooltipName: any
) {
  return new Chart({
    title: {
      text: "",
    },
    chart: {
      type: "column",
      width,
      height,
    },
    credits: false,
    yAxis: {
      min: 0,
      title: "",
    },
    xAxis: {
      categories: row,
      crosshair: true,
    },

    tooltip: {
      // valueSuffix: ' (1000 MT)'
    },
    plotOptions: {
      column: {
        // pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    legend: {
      enabled: false,
    },
    series: [
      {
        name: tooltipName,
        data: column,
      },
    ],
    exporting: { enabled: false },
  } as any);
}

export function returnSparkLineChart(
  seriesData: any,
  type: any,
  categories: any,
  forPdf = false
) {
  let plotSeriesOpt: any = {
    marker: {
      enabled: false,
    },
  };
  if (forPdf) {
    plotSeriesOpt = {
      marker: {
        enabled: true,
        radius: 2,
      },
      dataLabels: {
        enabled: true,
        formatter(this: any): string {
          if (this.y >= 1e9) {
            return (this.y / 1e9).toFixed(1) + "B";
          } else if (this.y >= 1e6) {
            return (this.y / 1e6).toFixed(1) + "M";
          } else if (this.y >= 1e3) {
            return (this.y / 1e3).toFixed(1) + "K";
          } else {
            return this.y.toString();
          }
        },
        style: {
          fontSize: "5px",
        },
      },
    };
  }
  return new Chart({
    title: {
      text: "",
    },
    credits: false,
    chart: {
      type,
      width: 120,
      height: 30,
      margin: [0, 0, 8, 0],
      style: {
        overflow: "visible",
      },
      skipClone: true,
    },
    yAxis: {
      endOnTick: false,
      startOnTick: false,
      labels: {
        enabled: false,
      },
      title: {
        text: null,
      },
      tickPositions: [0],
    },
    xAxis: {
      categories,
      lineWidth: 0,
      minorGridLineWidth: 0,
      lineColor: "transparent",
      labels: {
        enabled: false,
      },
      title: {
        text: null,
      },
      startOnTick: false,
      endOnTick: false,
      tickPositions: [],
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: plotSeriesOpt,
    },
    tooltip: {
      enabled: true,
      outside: true,
      formatter(this: any): string {
        return `<b>${this.x}</b></br>${this.y.toLocaleString()}`;
      },
    },
    series: seriesData,
    exporting: { enabled: false },
  } as any);
}

export function returnSparkLineChartOverView(
  seriesData: any,
  type: any,
  categories: any
) {
  const marginLeft = 6;
  return new Chart({
    title: {
      text: "",
    },
    credits: false,
    chart: {
      type,
      margin: [2, 0, marginLeft, 0],
      backgroundColor: "#f9f9f9",
      renderTo: "container",
      height: 60,
      style: {
        overflow: "visible",
      },
      skipClone: true,
    },
    yAxis: {
      endOnTick: false,
      startOnTick: false,
      labels: {
        enabled: false,
      },
      title: {
        text: null,
      },
      tickPositions: [0],
    },
    xAxis: {
      categories,
      lineWidth: 0,
      minorGridLineWidth: 0,
      lineColor: "transparent",
      labels: {
        enabled: false,
      },
      title: {
        text: null,
      },
      startOnTick: false,
      endOnTick: false,
      tickPositions: [],
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        marker: {
          enabled: true,
        },
        dataLabels: {
          shape: "connector",
          enabled: true,
          formatter(this: any): string {
            return `<span style="font-size:8px;font-weight:100">${this.x}</span>`;
          },
          y: -60,
          allowOverlap: true,
          useHTML: false,
        },
      },
    },
    tooltip: {
      enabled: true,
      outside: true,
      formatter(this: any): string {
        return `<b>${this.x}</b></br>${this.y.toLocaleString()}`;
      },
    },
    series: seriesData,
    exporting: { enabled: false },
  } as any);
}

export function reqAgingGraphOverview(
  row: any,
  column: any,
  displayName: any,
  xAxisName: any,
  yAxisName: any
) {
  return new Chart({
    title: {
      text: "",
    },
    chart: {
      type: "column",
      height: "300",
    },
    credits: false,
    yAxis: {
      min: 0,
      title: {
        text: yAxisName,
      },
    },
    xAxis: {
      categories: row,
      crosshair: true,
      title: {
        text: xAxisName,
      },
    },

    tooltip: {
      // valueSuffix: ' (1000 MT)'
    },
    plotOptions: {
      column: {
        // pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    legend: {
      enabled: true,
    },
    series: [
      {
        name: displayName,
        data: column,
      },
    ],
    exporting: { enabled: false },
  } as any);
}

export function pushDataToRelevantType(
  this: any,
  type: string,
  data: any
): void {
  switch (type) {
    case "engg":
      this.engineerData.push(data);
      break;
    case "people":
      this.peopleData.push(data);
      break;
    case "finance":
      this.financeData.push(data);
      break;
    case "customer":
      this.customerData.push(data);
      break;
    default:
      break;
  }
}

export function reloadMetricDataOnOverview(
  this: any,
  quadrantId: any,
  quadrantMetric: any
) {
  if (this.setMetricOverviewDetails?.quadrant_id && this.matrixOverView) {
    let metric;
    if (quadrantId === this.setMetricOverviewDetails?.quadrant_id) {
      metric = quadrantMetric.find(
        (x: any) => x.id === this.setMetricOverviewDetails?.metric_id
      );
      const data: any = {
        metric,
        cardDetails: quadrantMetric,
        cardName: this.setMetricOverviewDetails?.quadrant_name,
      };
      this.scorecardService.changeMessage(data);
    }
  }
}

export function saveSortedMatricsData(this: any, data: any, quadrantId: any) {
  this.spinner.show();
  const matrics: any = [];
  data.forEach((mat: any) => {
    matrics.push(mat.id);
  });
  const payload = { quadrant_id: quadrantId, sort_order: matrics };
  this.projectService.saveSortedQuadrant(payload).subscribe(
    () => {
      this.spinner.hide();
    },
    () => {
      this.spinner.hide();
    }
  );
}

function loadPowerBIReportGraph(
  reportData: any,
  tokenData: any,
  basicFilter: any,
  pageName: any,
  models: any
) {
  return {
    pageName,
    type: "report",
    id: reportData.id,
    embedUrl: reportData.embedUrl,
    accessToken: tokenData.token,
    tokenType: models.TokenType.Embed,
    filters: [basicFilter],
    settings: {
      panes: {
        filters: {
          expanded: false,
          visible: false,
        },
        pageNavigation: {
          visible: false,
        },
      },
      zoomLevel: 0.6,
      background: models.BackgroundType.Transparent,
    },
  };
}
export function loadPowerBIReport(this: any, metric: any, models: any) {
  if (metric?.powerBiReport?.length > 0) {
    let metricValues: any = [];
    if (metric?.powerBiReport[0]) {
      metricValues = metric?.powerBiReport[0];
    }
    this.reportConfig = this.reportModel;
    this.setBIZoomLevel();
    const basicFilter: any = {
      $schema: "http://powerbi.com/product/schema#basic",
    };

    this.powerBiService.getTokenForBI().subscribe((res: any) => {
      this.powerBiService
        .getBIEmbedURL(res.access_token, metricValues?.report)
        .subscribe((reportData: any) => {
          this.powerBiService
            .getBIEmbedToken(
              res.access_token,
              metricValues?.report,
              metricValues?.group
            )
            .subscribe((tokenData: any) => {
              this.reportConfig = loadPowerBIReportGraph(
                reportData,
                tokenData,
                basicFilter,
                metricValues?.report_name,
                models
              );
            });
        });
    });
  } else {
    return;
  }
}

export async function loadLinkedMatricsData(this: any, metricId: any) {
  this.linkedMatrics = [];
  this.projectService
    .getLinkedMatricsData(
      metricId,
      this.projectId,
      this.portfolioId,
      this.subportfolioId,
      this.programId
    )
    .subscribe((response: any) => {
      let matricValue: any = [];
      let month: any = [];
      response.forEach((val: any) => {
        matricValue = [];
        month = [];
        val.actual_value.forEach((matric: any) => {
          matricValue.push(roundToTwoDecimals(matric.actual_value));
          month.push(matric.month_short);
        });
        val.trend = returnSparkLineChart(
          [{ data: matricValue }],
          "line",
          month
        );
        val.change =
          val.actual_value[1]?.actual_value - val.actual_value[0]?.actual_value;
        if (val.change) {
          val.change = roundToTwoDecimals(val.change);
        } else {
          val.change = 0;
        }
        let denominator = 1;
        if (val.actual_value[0].actual_value) {
          denominator = val.actual_value[0].actual_value;
        }
        val.percent_change = roundToTwoDecimals(
          (val.change / denominator) * 100
        );
        this.linkedMatrics.push(val);
      });
    });
}

export async function loadPerformanceBenchMarcData(this: any, metricId: any) {
  if (this.vendorId) {
    this.benchaMarkType = "OU/GDP";
  } else if (this.programId) {
    this.benchaMarkType = "Project";
  } else if (this.subportfolioId) {
    this.benchaMarkType = "Program";
  } else if (this.portfolioId) {
    this.benchaMarkType = "Sub Portfolio";
  } else {
    this.benchaMarkType = "Portfolio";
  }
  this.projectService
    .getPerformanceBenchMarkData(
      metricId,
      this.projectId,
      this.portfolioId,
      this.subportfolioId,
      this.programId
    )
    .subscribe((response: any) => {
      this.performanceBenchMark = [];
      let metricValue: any = [];
      let month: any = [];
      response.forEach((val: any) => {
        metricValue = [];
        month = [];
        val.actual_value.forEach((matric: any) => {
          metricValue.push(matric.actual_value);
          month.push(matric.month_short);
        });
        val.trend = returnSparkLineChart(
          [{ data: metricValue }],
          "line",
          month
        );
        val.change =
          val.actual_value[1]?.actual_value - val.actual_value[0]?.actual_value;
        if (val.change) {
          val.change = roundToTwoDecimals(val.change);
        } else {
          val.change = 0;
        }
        let denominator = 1;
        if (val.actual_value[0].actual_value) {
          denominator = val.actual_value[0].actual_value;
        }
        val.percent_change = roundToTwoDecimals(
          (val.change / denominator) * 100
        );
        this.performanceBenchMark.push(val);
      });
    });
}

export function getChartData(responses: any, audits = false) {
  const xAxis = Object.keys(responses);
  const seriesData: any = [];
  for (const item of xAxis) {
    const obj = {
      name: item,
      data: [responses[item]],
    };
    seriesData.push(obj);
  }
  return {
    chart: {
      type: "bar",
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: xAxis,
      visible: false,
    },
    yAxis: {
      title: {
        text: "Severity",
      },
      visible: !!audits,
    },
    legend: {
      reversed: true,
    },
    plotOptions: {
      series: {
        stacking: "normal",
      },
    },
    series: seriesData,
  };
}
