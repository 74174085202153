import { Validators } from "@angular/forms";

export function getDisplayLables(this: any) {
  this.displayLabels = this.commonService.getLabels();
}

export function createForm(this: any) {
  this.opportunityForm = this.fb.group({
    name: ["", [Validators.required]],
    opportunity: [
      "",
      [Validators.required, this._formValidator.autocompleteObjectValidator()],
    ],
    portfolio: [
      "",
      [Validators.required, this._formValidator.autocompleteObjectValidator()],
    ],
    subPortfolio: [
      "",
      [Validators.required, this._formValidator.autocompleteObjectValidator()],
    ],
    program: [""],
    owners: [
      "",
      [Validators.required, this._formValidator.autocompleteObjectValidator()],
    ],
    details_id: [""],
  });
  this.opportunityForm.get("owners").disable();
}

export function handleOpportunityName(this: any, i: any) {
  this.data.name = i.field_status;
  this.data.disName = i.display_name;
  if (this.type === "AddProject") {
    this.data.disName = "Project Name";
  } else {
    this.loggingService.warn(this.errorText);
  }
  if (!i.field_status) {
    this.opportunityForm.removeControl("name");
  }
}

export function handleOpportunityType(this: any, i: any) {
  this.data.opportunity = i.field_status;
  this.options2 = i.field_values;
  this.filteredOptions2 = this.options2;
  this.data.disOpportunity = i.display_name;
  if (!i.field_status) {
    this.opportunityForm.removeControl("opportunity");
  }
}

export function handleProgramName(this: any, i: any) {
  this.data.program = i.field_status;
  this.options3 = i.field_values;
  this.filteredOptions3 = this.options3;
  this.data.disProgram = i.display_name;

  if (!i.field_status) {
    this.opportunityForm.removeControl("program");
  }
}

export function handleOpportunityPortfolio(
  this: any,
  i: any,
  asyncOperations: any
) {
  this.data.portfolio = i.field_status;
  this.data.disPortfolio = i.display_name;

  asyncOperations.push(
    this.filterService
      .getPortfolioList()
      .toPromise()
      .then((resp: any) => {
        this.options1 = resp.records;
        this.filteredOptions1 = this.options1;

        if (!i.field_status) {
          this.opportunityForm.removeControl("portfolio");
        }
      })
      .catch((error: any) => {
        this.loggingService.error("Error fetching portfolios: ", error);
      })
  );
}

export function handleOpportunitySubPortfolio(this: any, i: any) {
  this.data.subPortfolio = i.field_status;
  this.subPortOptions = i.field_values;
  this.subPortfilteredOptions = this.subPortOptions;
  this.data.disSubPortfolio = i.display_name;

  if (!i.field_status) {
    this.opportunityForm.removeControl("subPortfolio");
  }
}

export function handleOpportunityOwners(this: any, i: any) {
  this.data.owners = i.field_status;
  this.ownersOptions = i.field_values;
  this.ownersfilteredOptions = this.ownersOptions;
  this.data.disOwners = i.display_name;

  if (!i.field_status) {
    this.opportunityForm.removeControl("owners");
  }
}

export function addOpportunity(this: any) {
  if (this.tempTags.length <= 0) {
    this.toastrService.error(`Please select tags`);
    return;
  }
  this.spinner.show();
  const payload: any = {
    opportunity_name: this.opportunityForm.value.name,
  };
  if (this.opportunityForm.value.opportunity) {
    payload.opportunity_type = this.opportunityForm.value.opportunity.id;
  }
  if (this.opportunityForm.value.program) {
    payload.program = this.opportunityForm.value.program.id;
  }
  if (this.opportunityForm.value.portfolio) {
    const childID =
      this.subPortFolioChild[this.subPortFolioChild?.length - 1]?.id;
    payload.portfolio = this.opportunityForm.value.subPortfolio.id;
    if (childID >= 0) {
      payload.portfolio = childID;
    }
  }

  if (this.opportunityForm.value.details_id) {
    payload.details_id = this.opportunityForm.value.details_id;
  }
  payload.owners = [this.ownerDetails];
  this.ps.createOpportunity(payload).subscribe(
    async (res: any) => {
      localStorage.setItem("opportunityId", res[0].id);
      await this.tempTags.forEach(async (element: any) => {
        const pst = {
          entity_id: res[0].id,
          entity_type: "opportunity",
          tag_id: element.id,
        };
        this.tagService.magTag(pst).subscribe(() => {});
      });

      this.router.navigate([`rfx/${btoa(res[0].id)}/opportunity/setup`]);
      this.dialogRef.close();
      this.spinner.hide();
    },
    (error: any) => {
      const errStatus = 500;
      this.spinner.hide();
      if (error.status === errStatus) {
        this.toastrService.error("Internal server error");
      } else {
        this.toastrService.error(error.message);
      }
      throw error;
    }
  );
}

export function typeFilter(this: any, value: string): any {
  const filterValue = value.toLowerCase();
  return this.filteredOptions2.filter((i: any) =>
    i.name.toLowerCase().includes(filterValue)
  );
}

export function programFilter(this: any, value: string): any {
  const filterValue = value.toLowerCase();
  return this.filteredOptions3.filter((i: any) =>
    i.name.toLowerCase().includes(filterValue)
  );
}
export function portfolioFilter(this: any, value: string): any {
  const filterValue = value.toLowerCase();
  return this.filteredOptions1.filter((i: any) =>
    i.name.toLowerCase().includes(filterValue)
  );
}

export function subPortfolioFilter(this: any, value: string): any {
  const filterValue = value.toLowerCase();
  return this.subPortfilteredOptions.filter((i: any) =>
    i.name.toLowerCase().includes(filterValue)
  );
}

export async function getProjectType(this: any) {
  this.projectService.getProjectType().subscribe((res: any) => {
    this.projectType = res;
  });
}

export async function getOwnerList(this: any) {
  this.projectService.getOwners().subscribe((res: any) => {
    this.ownerList = res;
  });
}

export async function getProjectFundingType(this: any) {
  this.projectService.getProjectFundingType().subscribe((res: any) => {
    this.projectFundingType = res;
  });
}
