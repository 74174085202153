import { Component } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-ticket",
  templateUrl: "./ticket.component.html",
  styleUrls: ["./ticket.component.scss"],
})
export class TicketComponent {
  constructor(private readonly router: Router) {}

  navigateTo(link: string) {
    this.router.navigate([link]);
  }
}
