import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class PowerBiService {
  constructor(private readonly http: HttpClient) {}

  getTokenForBI() {
    return this.http.get(`users/get-powerbi-token`);
  }
  getPowerBIReportDetails(page: any) {
    return this.http.get(`powerbi/general?page=${page}`);
  }
  getBIEmbedURL(token: any, reportId: any) {
    return this.http.get(
      `https://api.powerbi.com/v1.0/myorg/reports/${reportId}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
  }
  getBIEmbedToken(token: any, reportId: any, groupId: any) {
    return this.http.post(
      `https://api.powerbi.com/v1.0/myorg/groups/${groupId}/reports/${reportId}/GenerateToken`,
      {},
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }
  getBIEMbedReportPages(token: any, reportId: any) {
    return this.http.get(
      `https://api.powerbi.com/v1.0/myorg/reports/${reportId}/pages  `,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
  }
}
