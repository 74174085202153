import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { Chart } from "angular-highcharts";
import { ProjectService } from "src/app/shared/services/project.service";
import { NgxSpinnerService } from "ngx-spinner";
import textConfiguration from "src/assets/static-text-configuration.json";
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import { ScorecardService } from "src/app/shared/services/scorecard.service";
import { models } from "powerbi-client";
import { PowerBiService } from "src/app/shared/services/power-bi.service";
import { MatSlider } from "@angular/material/slider";
import { FilterService } from "src/app/shared/services/filter.service";
import { MatDialog } from "@angular/material/dialog";
import { Subject } from "rxjs";
import { WhatIfComponent } from "src/app/pages/kepler/what-if/what-if.component";
import { PulsesDialogComponent } from "src/app/pages/delivery-functionality/pulses-dialog/pulses-dialog.component";
import { CommonService } from "src/app/shared/services/common.service";
import { IncidentTrackerService } from "src/app/shared/services/incident-tracker.service";
import { GovernanceLighthouseService } from "src/app/shared/services/governance-lighthouse.service";
import { AssessmentCentralService } from "src/app/shared/services/assessment-central.service";
import Highcharts from "highcharts";
import noData from "highcharts/modules/no-data-to-display";
import { takeUntil } from "rxjs/operators";
import { LoggingService } from "src/app/logging.service";
noData(Highcharts);
@Component({
  selector: "app-scorecard-dashboard",
  templateUrl: "./scorecard.component.html",
  styleUrls: ["./scorecard.component.scss"],
})
export class ScorecardComponent implements OnInit {
  Highcharts: typeof Highcharts = Highcharts;
  @ViewChild("slider") public slider: MatSlider;
  @Input() projectId: string;
  @Input() pageType: string;
  displayLabels: any;
  deliveryText: any = (textConfiguration as any).delivery;
  commonText: any = (textConfiguration as any).common;
  balanceScorecardText: any = (textConfiguration as any).balance_scorecard;
  headerText: string = this.deliveryText?.left_bar.performance_dashboard;
  noDataCustomer = false;
  noDataPeople = false;
  noDataFinance = false;
  noDataDelivery = false;
  matrixOverView = false;
  cardMatric: any;
  whatIfValues: any;
  reportClass = "report-container";
  quadrants: any = {
    customer: "",
    finance: "",
    engineering: "",
    people: "",
  };
  customerData: any;
  financeData: any;
  engineerData: any;
  peopleData: any;
  dragPeopleEnableFlag: any[] = [];
  dragEnggEnableFlag: any[] = [];
  dragFinanceEnableFlag: any[] = [];
  dragCustomerEnableFlag: any[] = [];
  performanceBenchMark: any;
  reportModel: any = {
    type: "report",
    embedUrl: null,
    tokenType: models.TokenType.Embed,
    accessToken: null,
    settings: null,
  };
  reportConfig = this.reportModel;
  reportConfigSUnBurst = this.reportModel;
  sentimentGraph = this.reportModel;
  reportConfigRadarChart = this.reportModel;
  rightPanelBiReport: any;
  zoomLevel: any;
  linkedMatrics: any = [];
  minValue = 0;
  maxValue = 100;
  sliderValue = 0;
  filterObj: any;
  portfolioId: any = null;
  programId: any = null;
  subportfolioId: any = null;
  vendorId: any = null;
  benchaMarkType: any = "Portfolio";
  setMetricOverviewDetails: any;
  infoQuadrantName: any;
  quadrantInfoDetails: any;
  user: any;
  currentMetricId: any;
  pulseCounts: any;
  dataLoaded = false;
  pulsesList: any;
  activeTab: any = 0;
  tableColumns: any = ["Group", "Question", "Responses"];
  auditTableColumns: any = [
    "Audit name",
    "Owner",
    "Total no. of Observations",
    "Responses",
  ];
  currentAssessment: any;
  currentAudit: any;
  currentGovAssessment: any;
  assessmentsList: any = [];
  auditsList: any = [];
  govAssessmentsList: any = [];
  auditInstanceInfo: any;
  govAggregateData: any;
  assessmentAggregateData: any;
  enggId = 139;
  peopleId = 137;
  financeId = 138;
  customerId = 140;
  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly commonService: CommonService,
    private readonly spinner: NgxSpinnerService,
    private readonly projectService: ProjectService,
    private readonly scorecardService: ScorecardService,
    private readonly powerBiService: PowerBiService,
    private readonly filterService: FilterService,
    private readonly dialog: MatDialog,
    private readonly incidentTrackerService: IncidentTrackerService,
    private readonly governanceLighthouseService: GovernanceLighthouseService,
    private readonly assessmentCentralService: AssessmentCentralService,
    private readonly loggingService: LoggingService
  ) {
    this.scorecardService.changeMessage("nodata");
    this.loadMetricValueOnclick();
  }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem("permission") ?? "{}");
    this.getDisplayLables();
    if (this.pageType !== "projectLevel") {
      this.filterService.filterValues
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res: any) => {
          this.filterObj = res;
          this.portfolioId = "";
          this.subportfolioId = "";
          this.programId = "";
          this.vendorId = "";
          this.filterObj?.inputs?.forEach((element: any) => {
            if (element.field === "portfolio") {
              this.portfolioId = element.value?.id;
            }
            if (element.field === "subportfolio") {
              this.subportfolioId = element.value?.id;
            }
            if (element.field === "program") {
              this.programId = element.value?.id;
            }
            if (element.field === "vendor") {
              this.vendorId = element.value?.id;
            }
          });
          if (this.activeTab === 0) {
            this.getScoreCardQuadrantData();
          } else {
            this.getAssessmentsAndAuditsDetails();
          }
        });
    } else {
      this.getScoreCardQuadrantData();
    }
    this.getPulseCounts();
  }

  getDisplayLables() {
    this.displayLabels = this.commonService.getLabels();
  }

  getGMapRandomColor() {
    const colorNum = 360;
    const color = Math.floor(Math.random() * colorNum);
    return `hsla(${color}, 50%, 25%, 90%)`;
  }

  getPerformanceImpact(pulse: any) {
    let perfImpact = "";
    pulse.performance_impact.forEach((impact: any) => {
      perfImpact += impact.display_name + ", ";
    });
    perfImpact = perfImpact.trimEnd();
    if (perfImpact.endsWith(",")) {
      perfImpact = perfImpact.slice(0, -1);
    }
    return perfImpact;
  }

  intializeData() {
    this.pulsesList.forEach((pulse: any) => {
      pulse["color"] = this.getGMapRandomColor();
      pulse["impact"] = this.getPerformanceImpact(pulse);
    });
  }

  getPulse(card: any, callback: () => void) {
    this.projectService.getCardPulse(card).subscribe((res: any) => {
      this.pulsesList = res;
      this.intializeData();
      callback();
    });
  }

  showPulses(card: any) {
    this.getPulse(card, () => {
      const dialog = this.dialog.open(PulsesDialogComponent, {
        panelClass: "no-scroll-dialog",
        height: "auto",
        width: "auto",
        data: {
          pulsesList: this.pulsesList,
          card,
        },
      });
      dialog.afterClosed().subscribe(() => {
        this.getPulse(card, () => {});
      });
    });
  }

  getPulseCounts() {
    this.projectService.getPulseCounts().subscribe((resp: any) => {
      this.pulseCounts = resp;
    });
  }

  getScoreCardQuadrantData() {
    this.spinner.show();
    this.dataLoaded = false;
    this.projectService
      .getscoreCardQuadrant(
        this.projectId,
        this.portfolioId,
        this.subportfolioId,
        this.programId,
        this.vendorId
      )
      .subscribe(
        (response: any) => {
          this.customerData = [];
          this.financeData = [];
          this.engineerData = [];
          this.peopleData = [];
          this.spinner.hide();
          this.scorecardService.okrSpread(response?.objectives);
          this.scorecardService.riskSpread(response?.risk);
          if (response?.metrics?.length > 0) {
            response?.metrics.forEach((res: any) => {
              if (res.id === this.enggId) {
                this.quadrants.engineering = res.display_name;
                this.dataToDelivery("engg", res.metrics);
                this.reloadMetricDataOnOverview(res.id, this.engineerData);
              }
              if (res.id === this.peopleId) {
                this.quadrants.people = res.display_name;
                this.dataToDelivery("people", res.metrics);
                this.reloadMetricDataOnOverview(res.id, this.peopleData);
              }
              if (res.id === this.financeId) {
                this.quadrants.finance = res.display_name;
                this.dataToDelivery("finance", res.metrics);
                this.reloadMetricDataOnOverview(res.id, this.financeData);
              }
              if (res.id === this.customerId) {
                this.quadrants.customer = res.display_name;
                this.dataToDelivery("customer", res.metrics);
                this.reloadMetricDataOnOverview(res.id, this.customerData);
              }
            });
          } else {
            this.noDataCustomer = true;
            this.noDataFinance = true;
            this.noDataDelivery = true;
            this.noDataPeople = true;
          }
          this.dataLoaded = true;
        },
        (error) => {
          this.noDataCustomer = true;
          this.noDataFinance = true;
          this.noDataPeople = true;
          this.noDataDelivery = true;
          this.dataLoaded = true;
          this.loggingService.error(error);
        }
      );
  }

  metricValue: any;
  month: any;
  percentValue: any;

  roundToTwoDecimals(value: number): number {
    return Math.round(value * 100) / 100;
  }

  dataToDelivery(type: any, res: any) {
    res.forEach((val: any) => {
      this.initializeMetrics();

      const { gdpName, gdpValue } = this.processActualValues(val.actual_value);

      const lineTrendGrph = this.getLineTrendGraph(val, gdpName, gdpValue);
      const lineTrendOverview = this.getLineTrendOverview(
        val,
        gdpName,
        gdpValue
      );

      const actualValue = this.getActualValue(val);
      const change = this.getChange(val);

      const data = this.constructDataObject(
        val,
        actualValue,
        change,
        lineTrendGrph,
        lineTrendOverview
      );

      this.pushDataToRelevantType(type, data);
    });
  }

  private initializeMetrics(): void {
    this.metricValue = [];
    this.percentValue = [];
    this.month = [];
  }

  private processActualValues(actualValues: any[]): {
    gdpName: any[];
    gdpValue: any[];
  } {
    const gdpName: any[] = [];
    const gdpValue: any[] = [];

    actualValues.forEach((matric: any) => {
      this.metricValue.push(this.roundToTwoDecimals(matric.actual_value));
      this.month.push(matric.month_short);

      if (matric.percentage) {
        this.percentValue.push(this.roundToTwoDecimals(matric.percentage));
      }

      if (matric.gdp_name !== "ALL") {
        gdpValue.push(this.roundToTwoDecimals(matric.actual_value));
        gdpName.push(matric.gdp_name);
      }
    });

    return { gdpName, gdpValue };
  }

  private getLineTrendGraph(val: any, gdpName: any[], gdpValue: any[]): any {
    const graphWidth = 200;
    if (val.name === "req_aging") {
      return this.reqAgingGraph(
        graphWidth,
        100,
        val?.data?.range,
        val?.data?.value.map(this.roundToTwoDecimals),
        "Days"
      );
    } else if (val.name === "gdp_survey_rating") {
      return this.reqAgingGraph(
        graphWidth,
        100,
        gdpName,
        gdpValue,
        "Avg. Score"
      );
    } else if (val.name === "experience_junior") {
      return this.returnSparkLineChart(
        [
          {
            data: val?.actual_value[
              val?.actual_value.length - 1
            ]?.data?.value.map(this.roundToTwoDecimals),
          },
        ],
        "bar",
        val?.actual_value[val?.actual_value.length - 1]?.data?.range
      );
    } else {
      return this.returnSparkLineChart(
        [{ data: this.metricValue.map(this.roundToTwoDecimals) }],
        "line",
        this.month
      );
    }
  }

  private getLineTrendOverview(val: any, gdpName: any[], gdpValue: any[]): any {
    if (val.name === "req_aging") {
      return this.reqAgingGraphOverview(
        val?.data?.range,
        val?.data?.value.map(this.roundToTwoDecimals),
        val?.display_name,
        "Days Open",
        "Count of Open Reqs"
      );
    } else if (val.name === "gdp_survey_rating") {
      return this.reqAgingGraphOverview(
        gdpName,
        gdpValue,
        val?.display_name,
        "GDP Name",
        "Actual Value"
      );
    } else if (val.name === "experience_junior") {
      return this.returnSparkLineChartOverView(
        [
          {
            data: val?.actual_value[
              val?.actual_value.length - 1
            ]?.data?.value.map(this.roundToTwoDecimals),
          },
        ],
        "bar",
        val?.actual_value[val?.actual_value.length - 1]?.data?.range
      );
    } else {
      return this.returnSparkLineChartOverView(
        [{ data: this.metricValue.map(this.roundToTwoDecimals) }],
        "line",
        this.month
      );
    }
  }

  private getActualValue(val: any): any {
    const lastValue = val?.actual_value[val?.actual_value.length - 1];
    if (
      [
        "attrition_currentmonth",
        "krr_tt",
        "attrition_ltm",
        "attrition_ytd",
      ].includes(val.name)
    ) {
      return this.roundToTwoDecimals(
        lastValue?.percentage || lastValue?.actual_value
      );
    } else if (val.name === "experience_junior") {
      return this.roundToTwoDecimals(lastValue?.data?.ratio);
    } else if (["gdp_survey_rating", "bvt_survey_rating"].includes(val.name)) {
      return this.roundToTwoDecimals(lastValue?.actual_value);
    } else {
      return this.roundToTwoDecimals(lastValue?.actual_value);
    }
  }

  private getChange(val: any): any {
    if (
      [
        "experience_junior",
        "req_aging",
        "gdp_survey_rating",
        "bvt_survey_rating",
      ].includes(val.name)
    ) {
      return "false";
    } else {
      return val.variance;
    }
  }

  private constructDataObject(
    val: any,
    actualValue: any,
    change: any,
    lineTrendGrph: any,
    lineTrendOverview: any
  ): any {
    let variance = 0;
    if (val?.variance_percent) {
      variance = this.roundToTwoDecimals(val?.variance_percent);
    }
    return {
      change,
      trend: lineTrendGrph,
      overviewTrend: lineTrendOverview,
      id: val.id,
      metric_name: val.name,
      name: val.display_name,
      desc: val.description,
      actual_value: this.formatNumber(actualValue, 2),
      variance_per: variance,
      is_update: val.is_uptrend,
      powerBiReport: val?.powerbireport,
      unit_name: val?.unit_name,
    };
  }

  private pushDataToRelevantType(type: string, data: any): void {
    switch (type) {
      case "engg":
        this.engineerData.push(data);
        break;
      case "people":
        this.peopleData.push(data);
        break;
      case "finance":
        this.financeData.push(data);
        break;
      case "customer":
        this.customerData.push(data);
        break;
      default:
        break;
    }
  }

  reloadMetricDataOnOverview(quadrantId: any, quadrantMetric: any) {
    if (this.setMetricOverviewDetails?.quadrant_id && this.matrixOverView) {
      let metric;
      if (quadrantId === this.setMetricOverviewDetails?.quadrant_id) {
        metric = quadrantMetric.find(
          (x: any) => x.id === this.setMetricOverviewDetails?.metric_id
        );
        const data: any = {
          metric,
          cardDetails: quadrantMetric,
          cardName: this.setMetricOverviewDetails?.quadrant_name,
        };
        this.scorecardService.changeMessage(data);
      }
    }
  }

  returnSparkLineChart(seriesData: any, type: any, categories: any) {
    return new Chart({
      title: {
        text: "",
      },
      credits: false,
      chart: {
        type,
        width: 120,
        height: 30,
        margin: [0, 0, 8, 0],
        style: {
          overflow: "visible",
        },
        skipClone: true,
      },
      yAxis: {
        endOnTick: false,
        startOnTick: false,
        labels: {
          enabled: false,
        },
        title: {
          text: null,
        },
        tickPositions: [0],
      },
      xAxis: {
        categories,
        lineWidth: 0,
        minorGridLineWidth: 0,
        lineColor: "transparent",
        labels: {
          enabled: false,
        },
        title: {
          text: null,
        },
        startOnTick: false,
        endOnTick: false,
        tickPositions: [],
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          marker: {
            enabled: false,
          },
        },
      },
      tooltip: {
        enabled: true,
        outside: true,
        formatter(this: any): string {
          return `<b>${this.x}</b></br>${this.y.toLocaleString()}`;
        },
      },
      series: seriesData,
      exporting: { enabled: false },
    } as any);
  }

  returnSparkLineChartOverView(seriesData: any, type: any, categories: any) {
    const marginLeft = 6;
    return new Chart({
      title: {
        text: "",
      },
      credits: false,
      chart: {
        type,
        margin: [2, 0, marginLeft, 0],
        backgroundColor: "#f9f9f9",
        renderTo: "container",
        height: 60,
        style: {
          overflow: "visible",
        },
        skipClone: true,
      },
      yAxis: {
        endOnTick: false,
        startOnTick: false,
        labels: {
          enabled: false,
        },
        title: {
          text: null,
        },
        tickPositions: [0],
      },
      xAxis: {
        categories,
        lineWidth: 0,
        minorGridLineWidth: 0,
        lineColor: "transparent",
        labels: {
          enabled: false,
        },
        title: {
          text: null,
        },
        startOnTick: false,
        endOnTick: false,
        tickPositions: [],
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          marker: {
            enabled: true,
          },
          dataLabels: {
            shape: "connector",
            enabled: true,
            formatter(this: any): string {
              return `<span style="font-size:8px;font-weight:100">${this.x}</span>`;
            },
            y: -60,
            allowOverlap: true,
            useHTML: false,
          },
        },
      },
      tooltip: {
        enabled: true,
        outside: true,
        formatter(this: any): string {
          return `<b>${this.x}</b></br>${this.y.toLocaleString()}`;
        },
      },
      series: seriesData,
      exporting: { enabled: false },
    } as any);
  }
  drop(event: CdkDragDrop<string[]>, quadrantId: any) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      this.saveSortedMatricsData(event.container.data, quadrantId);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      this.saveSortedMatricsData(event.container.data, quadrantId);
    }

    if (quadrantId === this.customerId) {
      this.dragCustomerEnableFlag[event.previousIndex] = "true";
    }
    if (quadrantId === this.financeId) {
      this.dragFinanceEnableFlag[event.previousIndex] = "true";
    }
    if (quadrantId === this.enggId) {
      this.dragEnggEnableFlag[event.previousIndex] = "true";
    }
    if (quadrantId === this.peopleId) {
      this.dragPeopleEnableFlag[event.previousIndex] = "true";
    }
  }

  enableDragClick(i: any, type: any) {
    if (type === this.customerId) {
      this.dragCustomerEnableFlag[i] = "false";
    }
    if (type === this.financeId) {
      this.dragFinanceEnableFlag[i] = "false";
    }
    if (type === this.enggId) {
      this.dragEnggEnableFlag[i] = "false";
    }
    if (type === this.peopleId) {
      this.dragPeopleEnableFlag[i] = "false";
    }
  }

  clickOverView(type: any, metric: any, cardDetails: any, cardName: any) {
    if (this.user?.is_vendor) {
      return;
    }
    this.whatIfValues = cardDetails;
    const data: any = {
      metric,
      cardDetails,
      cardName,
    };
    this.setMetricOverviewDetails = {
      metric_id: metric?.id,
      quadrant_id: type,
      quadrant_name: cardName,
    };
    this.scorecardService.changeMessage(data);
    this.currentMetricId = metric.id;
  }

  saveSortedMatricsData(data: any, quadrantId: any) {
    this.spinner.show();
    const matrics: any = [];
    data.forEach((mat: any) => {
      matrics.push(mat.id);
    });
    const payload = { quadrant_id: quadrantId, sort_order: matrics };
    this.projectService.saveSortedQuadrant(payload).subscribe(
      () => {
        this.spinner.hide();
      },
      () => {
        this.spinner.hide();
      }
    );
  }

  loadPowerBIReport(metric: any) {
    if (metric?.powerBiReport?.length > 0) {
      let metricValues: any = [];
      if (metric?.powerBiReport[0]) {
        metricValues = metric?.powerBiReport[0];
      }
      this.reportConfig = this.reportModel;
      this.setBIZoomLevel();
      const basicFilter: any = {
        $schema: "http://powerbi.com/product/schema#basic",
      };

      this.powerBiService.getTokenForBI().subscribe((res: any) => {
        this.powerBiService
          .getBIEmbedURL(res.access_token, metricValues?.report)
          .subscribe((reportData: any) => {
            this.powerBiService
              .getBIEmbedToken(
                res.access_token,
                metricValues?.report,
                metricValues?.group
              )
              .subscribe((tokenData: any) => {
                this.reportConfig = this.loadPowerBIReportGraph(
                  reportData,
                  tokenData,
                  basicFilter,
                  metricValues?.report_name
                );
              });
          });
      });
    } else {
      return;
    }
  }
  loadPowerBIReportGraph(
    reportData: any,
    tokenData: any,
    basicFilter: any,
    pageName: any
  ) {
    return {
      pageName,
      type: "report",
      id: reportData.id,
      embedUrl: reportData.embedUrl,
      accessToken: tokenData.token,
      tokenType: models.TokenType.Embed,
      filters: [basicFilter],
      settings: {
        panes: {
          filters: {
            expanded: false,
            visible: false,
          },
          pageNavigation: {
            visible: false,
          },
        },
        zoomLevel: 0.6,
        background: models.BackgroundType.Transparent,
      },
    };
  }
  setBIZoomLevel() {
    const large = 1500;
    const mid = 1246;
    if (window.innerWidth > large) {
      this.zoomLevel = 0.6;
    } else if (window.innerWidth <= large && window.innerWidth > mid) {
      this.zoomLevel = 0.5;
    } else if (window.innerWidth < mid) {
      this.zoomLevel = 0.4;
    } else {
      this.loggingService.warn("Unexpected value");
    }
  }

  async loadLinkedMatricsData(metricId: any) {
    this.linkedMatrics = [];
    this.projectService
      .getLinkedMatricsData(
        metricId,
        this.projectId,
        this.portfolioId,
        this.subportfolioId,
        this.programId
      )
      .subscribe((response: any) => {
        let matricValue: any = [];
        let month: any = [];
        response.forEach((val: any) => {
          matricValue = [];
          month = [];
          val.actual_value.forEach((matric: any) => {
            matricValue.push(this.roundToTwoDecimals(matric.actual_value));
            month.push(matric.month_short);
          });
          val.trend = this.returnSparkLineChart(
            [{ data: matricValue }],
            "line",
            month
          );
          val.change =
            val.actual_value[1]?.actual_value -
            val.actual_value[0]?.actual_value;
          if (val.change) {
            val.change = this.roundToTwoDecimals(val.change);
          } else {
            val.change = 0;
          }
          let denominator = 1;
          if (val.actual_value[0].actual_value) {
            denominator = val.actual_value[0].actual_value;
          }
          val.percent_change = this.roundToTwoDecimals(
            (val.change / denominator) * 100
          );
          this.linkedMatrics.push(val);
        });
      });
  }

  async loadPerformanceBenchMarcData(metricId: any) {
    if (this.vendorId) {
      this.benchaMarkType = "OU/GDP";
    } else if (this.programId) {
      this.benchaMarkType = "Project";
    } else if (this.subportfolioId) {
      this.benchaMarkType = "Program";
    } else if (this.portfolioId) {
      this.benchaMarkType = "Sub Portfolio";
    } else {
      this.benchaMarkType = "Portfolio";
    }
    this.projectService
      .getPerformanceBenchMarkData(
        metricId,
        this.projectId,
        this.portfolioId,
        this.subportfolioId,
        this.programId
      )
      .subscribe((response: any) => {
        this.performanceBenchMark = [];
        let metricValue: any = [];
        let month: any = [];
        response.forEach((val: any) => {
          metricValue = [];
          month = [];
          val.actual_value.forEach((matric: any) => {
            metricValue.push(matric.actual_value);
            month.push(matric.month_short);
          });
          val.trend = this.returnSparkLineChart(
            [{ data: metricValue }],
            "line",
            month
          );
          val.change =
            val.actual_value[1]?.actual_value -
            val.actual_value[0]?.actual_value;
          if (val.change) {
            val.change = this.roundToTwoDecimals(val.change);
          } else {
            val.change = 0;
          }
          let denominator = 1;
          if (val.actual_value[0].actual_value) {
            denominator = val.actual_value[0].actual_value;
          }
          val.percent_change = this.roundToTwoDecimals(
            (val.change / denominator) * 100
          );
          this.performanceBenchMark.push(val);
        });
      });
  }
  loadMetricValueOnclick() {
    this.scorecardService.cardDetails.subscribe((cards: any) => {
      if (cards?.cardName) {
        this.matrixOverView = true;
        this.cardMatric = cards?.metric;
        this.loadPowerBIReport(cards?.metric);
        this.loadLinkedMatricsData(cards?.metric?.id);
        this.loadPerformanceBenchMarcData(cards?.metric?.id);
      } else {
        this.matrixOverView = false;
      }
    });
  }

  closeMatrixOverview() {
    this.scorecardService.changeMessage("nodata");
    this.matrixOverView = false;

    this.getScoreCardQuadrantData();
  }

  getDifference(itemActualValue: any, metricActualValue: any) {
    let val: any = itemActualValue - metricActualValue;
    val = parseFloat(val).toFixed(2);
    return val || 0;
  }

  quadrantInfo(type: any, modal: any, quadrantName: any) {
    this.infoQuadrantName = quadrantName;
    this.dialog.open(modal, {
      width: "auto",
      height: "auto",
    });
    this.spinner.show();
    this.projectService.quadrantIfo(type).subscribe((res: any) => {
      this.spinner.hide();
      this.quadrantInfoDetails = res;
    });
  }

  dialogClose() {
    this.dialog.closeAll();
  }

  reqAgingGraph(
    width: any,
    height: any,
    row: any,
    column: any,
    tooltipName: any
  ) {
    return new Chart({
      title: {
        text: "",
      },
      chart: {
        type: "column",
        width,
        height,
      },
      credits: false,
      yAxis: {
        min: 0,
        title: "",
      },
      xAxis: {
        categories: row,
        crosshair: true,
      },

      tooltip: {
        // valueSuffix: ' (1000 MT)'
      },
      plotOptions: {
        column: {
          // pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      legend: {
        enabled: false,
      },
      series: [
        {
          name: tooltipName,
          data: column,
        },
      ],
      exporting: { enabled: false },
    } as any);
  }

  reqAgingGraphOverview(
    row: any,
    column: any,
    displayName: any,
    xAxisName: any,
    yAxisName: any
  ) {
    return new Chart({
      title: {
        text: "",
      },
      chart: {
        type: "column",
        height: "300",
      },
      credits: false,
      yAxis: {
        min: 0,
        title: {
          text: yAxisName,
        },
      },
      xAxis: {
        categories: row,
        crosshair: true,
        title: {
          text: xAxisName,
        },
      },

      tooltip: {
        // valueSuffix: ' (1000 MT)'
      },
      plotOptions: {
        column: {
          // pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      legend: {
        enabled: true,
      },
      series: [
        {
          name: displayName,
          data: column,
        },
      ],
      exporting: { enabled: false },
    } as any);
  }

  private formatNumber(number: any, decimalPlaces: number): string {
    // Ensure the number is a valid number
    if (!isNaN(number)) {
      // Use toFixed to format the number with the specified decimal places
      return number.toFixed(decimalPlaces);
    } else {
      // Handle the case where the input is not a valid number
      return number;
    }
  }

  openWhatIfModel() {
    this.dialog.open(WhatIfComponent, {
      width: "auto",
      minWidth: "60vw",
      height: "auto",
      data: {
        metricId: this.currentMetricId,
      },
    });
  }

  getContribution(item: any, cardMatricValue: any) {
    let currentItemValue = 0;
    const actualVal =
      item?.actual_value[item?.actual_value.length - 1]?.actual_value;
    if (actualVal) {
      currentItemValue = actualVal;
    }
    const contri = (currentItemValue / cardMatricValue) * 100;
    return this.roundToTwoDecimals(contri);
  }

  getAssessmentsList() {
    this.projectService
      .getAssessments(
        this.projectId,
        this.portfolioId,
        this.subportfolioId,
        this.programId,
        this.vendorId
      )
      .subscribe((resp: any) => {
        this.assessmentsList = resp.records;
      });
  }

  getAuditsList() {
    this.projectService
      .getAudits(
        this.projectId,
        this.portfolioId,
        this.subportfolioId,
        this.programId,
        this.vendorId
      )
      .subscribe((resp: any) => {
        this.auditsList = resp.records;
      });
  }

  getGovAssessmentsList() {
    this.projectService
      .getGovAssessments(
        this.projectId,
        this.portfolioId,
        this.subportfolioId,
        this.programId,
        this.vendorId
      )
      .subscribe((resp: any) => {
        this.govAssessmentsList = resp.records;
      });
  }

  getAssessmentsAndAuditsDetails() {
    this.govAggregateData = null;
    this.assessmentAggregateData = null;
    this.auditInstanceInfo = null;
    this.getAssessmentsList();
    this.getAuditsList();
    this.getGovAssessmentsList();
  }

  changeTab(event: any) {
    this.activeTab = event.index;
    if (this.activeTab === 1) {
      this.getAssessmentsAndAuditsDetails();
    }
  }

  getFormattedStatus(status: string): string {
    switch (status) {
      case "open":
        return "Open";
      case "in-progress":
        return "In Progress";
      case "close":
        return "Close";
      default:
        return status;
    }
  }

  downloadEvidence(instanceId: any, evidenceMetadata: any) {
    this.incidentTrackerService.downloadEvidence(instanceId).subscribe(
      (res: any) => {
        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(res);
        const fileName = evidenceMetadata[0];
        downloadLink.setAttribute("download", fileName);
        document.body.appendChild(downloadLink);
        downloadLink.click();
      },
      (error: any) => {
        throw error;
      }
    );
  }

  getAuditInstanceInfo() {
    this.incidentTrackerService
      .getSeverityReport(this.currentAudit)
      .subscribe((resp: any) => {
        this.auditInstanceInfo = resp;
        this.auditInstanceInfo.forEach((item: any) => {
          item["chart"] = this.getChartData(item.analytics, true);
        });
      });
  }

  getGovAggregateUserResponse() {
    this.governanceLighthouseService
      .getAggregateResponseDetails(this.currentGovAssessment)
      .subscribe((response: any) => {
        this.govAggregateData = response.group_wise_data;
        this.govAggregateData.forEach((group: any) => {
          group.questions.forEach((question: any) => {
            question["chart"] = this.getChartData(question.display_score_data);
          });
        });
      });
  }

  getChartData(responses: any, audits = false) {
    const xAxis = Object.keys(responses);
    const seriesData: any = [];
    for (const item of xAxis) {
      const obj = {
        name: item,
        data: [responses[item]],
      };
      seriesData.push(obj);
    }
    return {
      chart: {
        type: "bar",
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      title: {
        text: "",
      },
      xAxis: {
        categories: xAxis,
        visible: false,
      },
      yAxis: {
        title: {
          text: "Severity",
        },
        visible: !!audits,
      },
      legend: {
        reversed: true,
      },
      plotOptions: {
        series: {
          stacking: "normal",
        },
      },
      series: seriesData,
    };
  }

  getAssessmentAggregateUserResponse() {
    this.assessmentCentralService
      .getAggregateResponseDetails(this.currentAssessment)
      .subscribe((response: any) => {
        this.assessmentAggregateData = response.group_wise_data;
        this.assessmentAggregateData.forEach((group: any) => {
          group.questions.forEach((question: any) => {
            question["chart"] = this.getChartData(question.display_score_data);
          });
        });
      });
  }

  ngOnDestroy() {
    this.programId = null;
    this.portfolioId = null;
    this.subportfolioId = null;
    this.programId = null;
    this.scorecardService.changeMessage("nodata");
    this.scorecardService.okrSpread("");
    this.scorecardService.riskSpread("");
    this.filterService.filterCfgs.next({});
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.filterService.setFilterValue("");
  }
}
