import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AssessmentCentralService {
  constructor(
    private readonly http: HttpClient,
    private readonly router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    let id = route.params["id"];
    try {
      id = atob(id);
    } catch (e) {
      this.router.navigate(["404"]);
    }
    return this.http.get(`assessment/assignement_instance/${id}`);
  }

  getAllAssessments() {
    return this.http.get(`assessment/assignement_instance/`);
  }

  getTemplates() {
    return this.http.get(`assessment/assignment_template/`);
  }

  downloadTemplate() {
    return this.http.get(
      `assessment/assignment_template/assessment_file_download/7/`,
      { responseType: "blob" }
    );
  }

  uploadFile(payload: any) {
    return this.http.post(`assessment/assignment_template/`, payload);
  }

  createAssessment(payload: any) {
    return this.http.post(`assessment/assignement_instance/`, payload);
  }

  deleteTemplate(id: any) {
    return this.http.delete(`assessment/assignment_template/` + id);
  }

  getAssessmentById(id: any) {
    return this.http.get(`assessment/assignement_instance/${id}/`);
  }

  submitAnswer(payload: any) {
    return this.http.post(`assessment/assessment_response/`, payload);
  }

  getUsers() {
    return this.http.get(`users/user-list/`);
  }

  uploadEvidence(payload: any) {
    return this.http.post(
      `assessment/assessment_response/upload_evidence/`,
      payload
    );
  }

  deleteAssessment(id: any) {
    return this.http.delete(`assessment/assignement_instance/` + id);
  }

  getMasterForm() {
    return this.http.get(`dynamic-form/?form_name=new_opportunity`);
  }

  getProgram() {
    return this.http.get(`org/programs/`);
  }

  getProject() {
    return this.http.get(`projects/`);
  }

  getVendor() {
    return this.http.get(
      `dynamic-form/?form_name=opportunity_empanelled_vendors`
    );
  }

  fileDownload(instanceId: any, quesId: any, userId: any) {
    return this.http.get(
      `assessment/assessment_response/download_evidence/?instance_id=${instanceId}&question_id=${quesId}&user_id=${userId}`,
      { responseType: "blob" }
    );
  }

  getAssessmentTemplateCategory() {
    return this.http.get("base_value/?type=assessment_category");
  }

  getFilteredAssessmentTemplates(qParam: string) {
    return this.http.get(`assessment/assignment_template/?${qParam}`);
  }

  getFilteredAssessmentCentral(qParam: string) {
    return this.http.get(`assessment/assignement_instance/?${qParam}`);
  }

  getAggregateResponseDetails(assessmentId: any) {
    return this.http.get(
      `assessment/assignement_instance/response-details/${assessmentId}/`
    );
  }

  getAnswerOptions() {
    return this.http.get("base_value/?type=assessment_response_option");
  }

  getOwnersList() {
    return this.http.get("users/");
  }

  getAssessmentsByProject(projectId: any) {
    return this.http.get(
      `assessment/assignement_instance/all/?search=project:${projectId}`
    );
  }

  getCategoriesList(categoryType: any) {
    return this.http.get(`base_value/?type=${categoryType}`);
  }

  getAssesmentCentralNamesList(nameType: any) {
    return this.http.get(`assessment/${nameType}/names/`);
  }

  getGovernanceLightHouseNamesList(nameType: any) {
    return this.http.get(`gov-lighthouse/${nameType}/names/`);
  }
}
