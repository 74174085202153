import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "getFilesize",
})
export class GetFilesizePipe implements PipeTransform {
  transform(size: any): unknown {
    if (size) {
      if (size > 1024 * 1024) {
        let sizeinMB = size / (1024 * 1024);
        sizeinMB = Math.round(sizeinMB);
        return sizeinMB + " MB";
      } else {
        let sizeinKB = size / 1024;
        sizeinKB = Math.round(sizeinKB);
        return sizeinKB + " KB";
      }
    }
    return size;
  }
}
