<h6 *ngIf="displayHeader == true">Curated from the Web</h6>
<div class="gnhold">
  <app-loading *ngIf="loading"></app-loading>
  <h6 style="font-size: 14px; line-height: 1.5">{{ queryString }}</h6>
  <ul class="crawl" [style.maxHeight]="isExpanded ? '60vh' : 'none'">
    <li *ngFor="let data of llmformattedresponse">
      <p
        [innerHTML]="data"
        class="mb-1"
        style="font-size: 13px; font-style: italic"
      ></p>
    </li>
  </ul>
  <div class="text-center" *ngIf="llmformattedresponse"></div>
  <div class="mt-2" style="overflow-y: scroll; max-height: 30vh">
    <a
      class="mb-1 slinks"
      target="_blank"
      href="{{ data.url }}"
      *ngFor="let data of llmlinks; let i = index"
    >
      <p
        style="font-size: 12px; height: 40px; overflow: hidden; color: black"
        class="m-0 mb-1"
      >
        {{ data.name }}
      </p>
      <a href="{{ data.url }}" target="_blank" class="hurl"
        ><span class="citation-links">{{ i + 1 }}</span> {{ data.url }}
      </a>
    </a>
  </div>
</div>
