<div *ngIf="!iframeEmbedded">
  <nav
    class="navbar navbar-expand-lg navbar-dark bg-lavendar py-0 justify-content-between fixed-top"
  >
    <div class="w-100 d-flex nav-lg-holder">
      <div class="navbar-brand mr-1">
        <span class="pr-2 d-inline-block">
          <a href="https://www.kairhos.com/" target="_blank">
            <img
              src="assets/images/logo.svg"
              class="img-header cursor-pointer"
              width="100"
            />
          </a>
        </span>
        <span class="pl-2 border-left-nav d-inline-block">
          <a href="/" class="text-decoration-none">
            <img src="assets/images/icon.png" class="keplerLogo" />
          </a>
        </span>
      </div>
      <!--Desktop-->
      <div
        class="collapse navbar-collapse d-none d-sm-none d-md-none d-lg-block display-xl-none ml-4 hsace"
        *ngIf="authenticated"
      >
        <div
          class="d-flex"
          style="width: 100%"
          [ngClass]="{ 'justify-content-center': !user.is_vendor }"
        >
          <ul
            class="navbar-nav d-flex align-items-center menu-nav"
            [ngStyle]="{ flex: !user.is_vendor ? 'auto' : '' }"
          >
            <li
              class="nav-item d-flex justify-content-between align-items-center border-left-nav"
            >
              <span class="nav-link" (click)="navigateToHome()">
                <img
                  src="assets/images/header/home.svg"
                  alt="image"
                  width="18"
                />
              </span>
            </li>
            <li
              class="nav-item dropdown d-flex justify-content-between align-items-center"
              style="width: 100%; justify-content: center !important"
            >
              <a
                href="#"
                class="nav-link dropdown-toggle d-flex justify-content-between align-items-center"
                id="navbarDropdown1"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img
                  src="assets/images/header/newopportunities.svg"
                  alt="image"
                  class="mr-2"
                  width="17"
                />
                <span [innerHTML]="menuText.mainMenu1"></span>
              </a>
              <div class="dropdown-menu p-0" aria-labelledby="navbarDropdown1">
                <div class="row m-0">
                  <div
                    class="col-md-7 bg-nav-menu image-1 d-flex align-items-center"
                  >
                    <h5
                      class="fs-20 text-white px-2 py-4 bg-nav-menu-heading fw-normal"
                    >
                      {{ menuText.mainMenuBannerText1 }}
                    </h5>
                  </div>
                  <div class="col-md-5">
                    <div class="py-4">
                      <p
                        class="fs-16 fw-500 mb-2 menu-over nohover cp"
                        (click)="clickOpportunity()"
                      >
                        {{ menuText.subTitleMenu1 }}
                      </p>
                      <p
                        class="fs-16 fw-500 cursor-pointer mb-2 cp"
                        (click)="clickEngagement()"
                        *ngIf="!this.user?.is_vendor"
                      >
                        {{ menuText.subTitleMenu2 }}
                      </p>
                      <p
                        class="fs-16 fw-500 mb-2 menu-over nohover cp"
                        (click)="
                          navigateTo('challenge-workbench/challenge-statements')
                        "
                      >
                        {{ menuText.Hackathon }}
                      </p>
                      <p
                        class="fs-16 fw-500 mb-2 menu-over nohover cp"
                        (click)="navigateTo('ideapitch')"
                        *ngIf="!this.user?.is_vendor"
                      >
                        {{ menuText.Idea_Pitch }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li
              class="nav-item dropdown d-flex justify-content-between align-items-center"
              style="width: 100%; justify-content: center !important"
              *ngIf="!this.user?.is_vendor"
            >
              <a
                href="#"
                class="nav-link dropdown-toggle d-flex justify-content-between align-items-center"
                id="navbarDropdown2"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img
                  src="assets/images/header/insights.svg"
                  alt="image"
                  class="mr-2"
                  width="17"
                />
                <span [innerHTML]="menuText.mainMenu2"></span>
              </a>
              <div class="dropdown-menu p-0" aria-labelledby="navbarDropdown2">
                <div class="row m-0">
                  <div
                    class="col-md-7 bg-nav-menu image-2 d-flex align-items-center"
                  >
                    <h5
                      class="fs-20 text-white px-2 py-4 bg-nav-menu-heading fw-normal"
                    >
                      {{ menuText.mainMenuBannerText2 }}
                    </h5>
                  </div>
                  <div class="col-md-5 pr-0">
                    <div class="py-4">
                      <p
                        class="fs-16 fw-500 cursor-pointer mb-2"
                        (click)="navigateTo('balancedscorecard/dashboard')"
                      >
                        {{ menuText.subTitleMenu3 }}
                      </p>
                      <p
                        class="fs-16 fw-500 cursor-pointer mb-2"
                        (click)="navigateTo('riskconvergence')"
                      >
                        {{ menuText.subTitleMenu4 }}
                      </p>
                      <p
                        class="fs-16 fw-500 cursor-pointer mb-2"
                        (click)="navigateTo('workforce')"
                      >
                        {{ menuText.people_insights }}
                      </p>
                      <p
                        class="fs-16 fw-500 cursor-pointer mb-2"
                        (click)="navigateTo('performanceInsights/hubble')"
                      >
                        {{ menuText.qualitative_insights }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li
              class="nav-item dropdown d-flex justify-content-between align-items-center"
              style="width: 100%; justify-content: center !important"
              *ngIf="!this.user?.is_vendor"
            >
              <a
                href="#"
                class="nav-link dropdown-toggle d-flex justify-content-between align-items-center"
                id="navbarDropdown3"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img
                  src="assets/images/header/strategicrelevance.svg"
                  alt="image"
                  class="mr-2"
                  width="17"
                />
                <span [innerHTML]="menuText.mainMenu3"></span>
              </a>
              <div
                class="dropdown-menu p-0"
                aria-labelledby="navbarDropdown3"
                style="min-width: 430px"
              >
                <div class="row m-0">
                  <div
                    class="col-md-7 bg-nav-menu image-3 d-flex align-items-center"
                  >
                    <h5
                      class="fs-20 text-white px-2 py-4 bg-nav-menu-heading fw-normal"
                    >
                      {{ menuText.mainMenuBannerText3 }}
                    </h5>
                  </div>
                  <div class="col-md-5">
                    <div class="py-4">
                      <p
                        class="fs-16 fw-500 cursor-pointer mb-2"
                        (click)="navigateTo('okrconvergence')"
                      >
                        {{ menuText.subTitleMenu7 }}
                      </p>
                      <p
                        class="fs-16 fw-500 cursor-pointer mb-2"
                        (click)="
                          navigateTo('strategicRelevance/assessment-central')
                        "
                      >
                        {{ menuText.it_north_star }}
                      </p>
                      <p
                        class="fs-16 fw-500 cursor-pointer mb-2"
                        (click)="navigateTo('strategicRelevance/governancect')"
                      >
                        {{ menuText.governance_CT }}
                      </p>
                      <p
                        class="fs-16 fw-500 cursor-pointer mb-2"
                        (click)="navigateTo('strategicRelevance/maven')"
                      >
                        {{ menuText.maven }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li
              class="nav-item dropdown d-flex align-items-center"
              style="width: 100%"
              [ngClass]="{ 'justify-content-center': !user?.is_vendor }"
            >
              <a
                href="#"
                class="nav-link dropdown-toggle d-flex justify-content-between align-items-center"
                id="navbarDropdown4"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img
                  src="assets/images/header/learn.svg"
                  alt="image"
                  class="mr-2"
                />
                <span [innerHTML]="menuText.mainMenu4"></span>
              </a>
              <div
                class="dropdown-menu p-0"
                aria-labelledby="navbarDropdown4"
                style="min-width: 430px"
              >
                <div class="row m-0">
                  <div
                    class="col-md-6 bg-nav-menu image-4 d-flex align-items-center"
                  >
                    <h5
                      class="fs-20 text-white px-2 py-4 bg-nav-menu-heading fw-normal"
                    >
                      {{ menuText.mainMenuBannerText4 }}
                    </h5>
                  </div>
                  <div class="col-md-6">
                    <div class="py-4">
                      <p
                        class="fs-16 fw-500 cursor-pointer mb-2"
                        style="white-space: nowrap"
                        (click)="navigateTo('delivery-accelerator/dashboard')"
                        *ngIf="!this.user?.is_vendor"
                      >
                        {{ menuText.subTitleMenu10 }}
                      </p>
                      <p
                        class="fs-16 fw-500 cursor-pointer mb-2"
                        style="white-space: nowrap"
                        (click)="navigateTo('confluence/teamplantracker')"
                        *ngIf="!this.user?.is_vendor"
                      >
                        {{ menuText.team_plan_tracker }}
                      </p>
                      <p
                        class="fs-16 fw-500 cursor-pointer mb-2"
                        style="white-space: nowrap"
                        (click)="navigateTo('parade')"
                        *ngIf="!this.user?.is_vendor"
                      >
                        {{ menuText.intractive_reviews }}
                      </p>

                      <p
                        class="fs-16 fw-500 cursor-pointer mb-2"
                        style="white-space: nowrap"
                        (click)="navigateToSP()"
                      >
                        {{ menuText.subTitleMenu9 }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div
        class="d-flex align-items-centered d-none d-sm-none d-md-none d-lg-none d-xl-block display-xl-none hsace"
        id="login"
      >
        <ul class="navbar-nav d-flex align-items-center">
          <li
            class="nav-item"
            matTooltip="Project Central"
            matTooltip="Idea pitch Login"
            matTooltipClass="example-tooltip"
            (click)="openBlank(ideapitchUrl)"
            *ngIf="!this.user?.is_vendor"
          >
            <mat-icon>swap_horiz</mat-icon>
          </li>
          <li
            class="nav-item"
            matTooltip="Project Central"
            matTooltipClass="example-tooltip"
            (click)="navigateTo('delivery/projects')"
          >
            <img src="assets/Icons/free_icon_1.svg" alt="image" width="20px" />
          </li>
          <li
            class="nav-item"
            matTooltip="IPDB"
            matTooltipClass="example-tooltip"
            (click)="navigateTo('prism/ipdb')"
            *ngIf="!this.user?.is_vendor"
          >
            <img src="assets/Icons/ipdb.svg" width="15px" alt="image" />
          </li>
          <li
            class="nav-item"
            matTooltip="Masters"
            matTooltipClass="example-tooltip"
            (click)="navigateTo('prism/masters')"
            *ngIf="!this.user?.is_vendor"
          >
            <img src="assets/Icons/masterIcon.svg" width="15px" alt="image" />
          </li>
          <li
            class="nav-item"
            matTooltip="Action Central"
            matTooltipClass="example-tooltip"
            *ngIf="!this.user?.is_vendor"
          >
            <img
              src="./assets/images/action-tracker.svg"
              class="action-tracker-ico"
              style="margin-top: 5px"
              (click)="navigateTo('actiontracker')"
            />
          </li>
          <li
            class="nav-item"
            matTooltip="GRC Workbench"
            matTooltipClass="example-tooltip"
            (click)="navigateTo('incidentracker')"
          >
            <mat-icon style="color: rgb(169, 225, 206)">track_changes</mat-icon>
          </li>
          <li
            class="nav-item"
            matTooltip="Airo"
            matTooltipClass="example-tooltip"
            *ngIf="!this.user?.is_vendor"
            (click)="openAiro()"
          >
            <img
              src="./assets/images/airo.png"
              class="action-tracker-ico"
              style="margin-top: 5px"
            />
          </li>
          <li matTooltip="Show Tags">
            <mat-slide-toggle
              class="tags-toggle"
              (change)="toggleTag($event)"
              [checked]="isChecked"
            ></mat-slide-toggle>
          </li>
          <li class="nav-item" *ngIf="authenticated && !this.user?.is_vendor">
            <span
              class="p-1 notification-Status bg-danger border border-light rounded-circle"
              *ngIf="notficationsList?.length"
            ></span>
            <mat-icon
              aria-hidden="false"
              aria-label="material-icons-filled"
              class="cursor-pointer"
              id="notificationbell"
              [matMenuTriggerFor]="notificationmenu"
            >
              notifications
            </mat-icon>
          </li>
          <li
            class="nav-item dropdown d-flex flex-row align-items-center"
            (click)="showProfilePopUp = !showProfilePopUp"
            id="profile"
            *ngIf="authenticated"
          >
            <ngx-avatar
              class="text-truncate"
              matTooltip="{{ user?.full_name }}"
              matTooltipClass="example-tooltip"
              [style]="customStyle"
              size="40"
              [name]="user?.full_name ? user?.full_name : user?.email"
            >
            </ngx-avatar>
            <mat-icon
              aria-label="material-icons-filled "
              class="cursor-pointer fs-5 ml-2 mt-2"
            >
              expand_more
            </mat-icon>
          </li>
          <li>
            <a
              class="btn d-flex p-0"
              style="align-items: center"
              *ngIf="!authenticated"
              (click)="showLoginPopUp = !showLoginPopUp"
            >
              <img
                src="assets/images/logout_trans.png"
                class="cursor-pointer fs-4"
                alt="logout"
                width="30"
                height="30"
              />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <mat-menu #notificationmenu="matMenu" xPosition="before" class="panel-notify">
    <div *ngIf="authenticated">
      <div class="arrow-top"></div>
      <div class="notification-body p-2">
        <div
          class="d-flex flex-row justify-content-between align-items-center pe-2 ps-2"
        >
          <h5 class="fs-16 color-Port-Gore text-capitalize fw-normal">
            {{ homeText?.notifications }}
          </h5>
        </div>
        <div class="notification-list" *ngIf="notficationsList?.length">
          <div
            class="notification-box p-1 cursor-pointer"
            [routerLink]="[notificationPopUplist?.routepath]"
            *ngFor="
              let notificationPopUplist of notficationsList | slice : 0 : 3;
              index as notificaitonIndex
            "
            (click)="markAsReadNotification(notificationPopUplist?.id)"
          >
            <div
              class="d-flex flex-row justify-content-start align-items-start pt-2 pb-2"
            >
              <div class="user-icon p-1">
                <ngx-avatar
                  [name]="
                    notificationPopUplist?.posted_by_details?.full_name ||
                    notificationPopUplist?.posted_by_details?.username
                  "
                  [bgColor]="notificaitonIndex | getRandomColor"
                  size="38"
                  [round]="true"
                >
                </ngx-avatar>
              </div>
              <div class="notification-msg d-flex flex-column p-1">
                <h5 class="color-black-olive fs-14">
                  <!-- <span class="fw-500">Abhishek Joshi</span> -->
                  <span class="fw-normal">{{
                    notificationPopUplist?.purpose
                  }}</span>
                </h5>
                <p class="fs-12 color-black-olive fw-500 mb-0">
                  {{ notificationPopUplist?.posted_at | dateAgo }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="d-flex justify-content-center my-4"
          *ngIf="!notficationsList?.length"
        >
          <span
            class="text-capitalize text-dark text-center text-muted fs-18"
            >{{ headerText?.no_notifications }}</span
          >
        </div>
      </div>
    </div>
  </mat-menu>

  <ng-container *ngIf="showProfilePopUp && authenticated">
    <div class="log-container">
      <div class="arrow-top"></div>
      <div class="profile-body pt-4 p-2">
        <div class="d-flex flex-column cursor-pointer">
          <div class="p-1 border-bottom">
            <h5 class="color-Port-Gore fs-14">
              {{ headerText?.user_profile }}
            </h5>
          </div>
          <div class="pt-3 pb-3 border-bottom">
            <div
              class="d-flex flex-row justify-content-start align-items-start"
            >
              <div class="user-icon p-1">
                <ngx-avatar
                  [name]="user?.full_name"
                  bgColor="#92c353"
                  size="38"
                  [round]="true"
                >
                </ngx-avatar>
              </div>
              <div class="user-info d-flex flex-column p-1 mx-2">
                <h5 class="fs-16 text-dark">
                  {{ user?.full_name }}
                </h5>
                <p
                  class="fs-14 fw-500 mb-0 text-dark mb-2 words-ellipse"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  [title]="user?.email"
                >
                  {{ user?.email }}
                </p>
              </div>
            </div>
          </div>
          <div class="pt-3 pb-3">
            <div
              class="d-flex flex-row justify-content-start align-items-center hover-box p-2"
              (click)="logOut()"
            >
              <div class="pe-2 ps-2">
                <img
                  src="assets/images/header/logout.svg"
                  alt="logout"
                  width="18"
                  height="18"
                />
              </div>
              <div class="pe-2 ps-2 text-capitalize">
                <p class="color-black-olive fs-16 fw-500 mb-0">
                  {{ headerText?.logout }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="showLoginPopUp">
    <div class="login-container" id="login">
      <div class="arrow-top"></div>
      <div class="profile-body pt-1 p-1">
        <div class="d-flex flex-column cursor-pointer">
          <div class="pt-2 pb-1">
            <div
              class="d-flex flex-row justify-content-start align-items-center hover-box p-1"
              (click)="signIn()"
            >
              <div class="pe-2 ps-2" style="color: white">
                <mat-icon
                  aria-hidden="false"
                  aria-label="material-icons-filled"
                  class="cursor-pointer fs-4 mr-2"
                >
                  login
                </mat-icon>
              </div>
              <div class="pe-2 ps-2 text-capitalize">
                <p
                  class="color-black-olive fs-16 fw-500 mb-0"
                  style="color: white"
                >
                  Sign In
                </p>
              </div>
            </div>
          </div>
          <div class="pt-1 pb-1">
            <div
              class="d-flex flex-row justify-content-start align-items-center hover-box p-1"
              (click)="signUp()"
            >
              <div class="pe-2 ps-2" style="color: white">
                <img
                  src="assets/images/signup.png"
                  class="cursor-pointer fs-4 mr-2"
                  alt="logout"
                  width="24"
                  height="24"
                />
              </div>
              <div class="pe-2 ps-2 text-capitalize">
                <p
                  class="color-black-olive fs-16 fw-500 mb-0"
                  style="color: white"
                >
                  Sign Up
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
