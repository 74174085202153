import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class TermsService {
  constructor(private readonly http: HttpClient) {}

  uploadFile(payload: any, id: any) {
    return this.http.post(`opportunities/terms-file-upload/${id}/`, payload);
  }

  deleteFile(payload: any, id: any) {
    return this.http.post(`opportunities/terms-file-delete/${id}/`, payload);
  }

  downloadFile(payload: any, id: any) {
    return this.http.post(`opportunities/terms-file-download/${id}/`, payload, {
      responseType: "blob",
    });
  }

  updateTerms(payload: any) {
    return this.http.post(`terms/category/`, payload);
  }
  getTermsMaster() {
    return this.http.get(`terms/category/`);
  }

  getBaseValue(type: any) {
    return this.http.get(`base_value/?type=${type}`);
  }

  getMosMaster() {
    return this.http.get(`metrics/metric-value/okr_metrics`);
  }
  createMosValue(payload: any) {
    return this.http.post(`terms/toe-metrics/`, payload);
  }
  deleteMosValue(id: any) {
    return this.http.delete(`terms/toe-metrics/${id}`);
  }

  getTermMetrics(projectId: any, termId: any) {
    return this.http.get(
      `terms/project-term-metrics?project_id=${projectId}&term_id=${termId}`
    );
  }
  setTermMetrics(payload: any) {
    return this.http.post(`terms/project-term-metrics/`, payload);
  }
  getTermEvidence(termId: any) {
    return this.http.get(`terms/project-evidences?projectterm_id=${termId}`);
  }
  createTermEvidence(payload: any) {
    return this.http.post(`terms/project-evidences/`, payload);
  }
}
