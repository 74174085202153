import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { SearchableMultiselectComponent } from "../searchable-select/searchable-multiselect/searchable-multiselect.component";
import { FilterService } from "../../services/filter.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { SearchableSelectComponent } from "../searchable-select/searchable-select/searchable-select.component";
import { DatepickerComponent } from "../datepicker/datepicker/datepicker.component";
import { InputComponent } from "../input/input/input.component";
import { LoggingService } from "src/app/logging.service";

@Component({
  selector: "app-filter",
  templateUrl: "./filter.component.html",
  styleUrls: ["./filter.component.scss"],
})
export class FilterComponent implements OnInit {
  @Output() portfolioChange = new EventEmitter();
  @Output() subportfolioChange = new EventEmitter();
  @Output() programChange = new EventEmitter();
  @ViewChildren(SearchableMultiselectComponent)
  searchableMultiSelects: QueryList<SearchableMultiselectComponent>;
  @ViewChildren(SearchableSelectComponent)
  searchableSelects: QueryList<SearchableSelectComponent>;
  @ViewChildren(DatepickerComponent)
  datepickers: QueryList<DatepickerComponent>;
  @ViewChildren(InputComponent)
  inputs: QueryList<InputComponent>;
  filterCfg: any;
  filterForm: any;
  isFilterhasValue = false;
  warningText = "Unexpected value";
  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly filterService: FilterService,
    private readonly loggingService: LoggingService
  ) {}

  ngOnInit(): void {
    this.filterService.filterCfgs
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.filterCfg = res;
      });
  }

  createFilterGroup(): FormGroup {
    return this.formBuilder.group({
      array: this.formBuilder.array([]),
      input: new FormControl(""),
    });
  }

  hasValue(val: any) {
    if (Array.isArray(val)) {
      if (val.length) {
        return true;
      } else {
        return false;
      }
    } else if (val) {
      return true;
    } else {
      this.loggingService.warn(this.warningText);
    }
    return false;
  }

  checkFilterHasValues() {
    let hasValue = false;
    for (const item of this.filterCfg.inputs) {
      hasValue = this.hasValue(item.value);
      if (hasValue) {
        break;
      }
    }
    return hasValue;
  }

  handleSearchableMultiSelectValue(value: any, field: string) {
    const currentValue = value.currentValue?.id;
    const inputItem = this.filterCfg.inputs.find(
      (item: any) => item.field === field
    );
    inputItem.value = value.values;
    this.isFilterhasValue = this.checkFilterHasValues();
    if (field === "portfolio") {
      this.portfolioChange.emit(currentValue);
    } else if (field === "subportfolio") {
      this.subportfolioChange.emit(currentValue);
    } else if (field === "program") {
      const subportfolioField = this.filterCfg.inputs.find(
        (item: any) => item.field === "subportfolio"
      );
      const subportfolioValues = subportfolioField.value;
      let currentSubportfolioValue = null;
      if (subportfolioValues.length) {
        currentSubportfolioValue =
          subportfolioValues[subportfolioValues.length - 1].id;
      }
      this.programChange.emit({ currentValue, currentSubportfolioValue });
    } else {
      this.loggingService.warn(this.warningText);
    }
    this.filterService.setFilterValue(this.filterCfg);
  }

  manipulateSearchableMultiSelect(val: any, field: string) {
    const searchableMultiSelect: any = this.searchableMultiSelects.find(
      (child: any) => child.controlName === field
    );
    searchableMultiSelect.manipulateValue(val);
  }

  manipulateSearchableSelect(val: any, field: string) {
    const searchableSelect: any = this.searchableSelects.find(
      (child: any) => child.controlName === field
    );
    searchableSelect.manipulateValue(val);
  }

  handleSearchableSelectValue(value: any, field: string) {
    const inputItem = this.filterCfg.inputs.find(
      (item: any) => item.field === field
    );
    inputItem.value = value;
    const val = value?.id;
    this.isFilterhasValue = this.checkFilterHasValues();
    if (field === "portfolio") {
      this.portfolioChange.emit(val);
    } else if (field === "subportfolio") {
      this.subportfolioChange.emit(val);
    } else if (field === "program") {
      const subportfolioField = this.filterCfg.inputs.find(
        (item: any) => item.field === "subportfolio"
      );
      const subportfolioValue = subportfolioField.value?.id;
      this.programChange.emit({ val, subportfolioValue });
    } else {
      this.loggingService.warn(this.warningText);
    }
    this.filterService.setFilterValue(this.filterCfg);
  }

  handleInputOrDateValue(value: any, field: string) {
    const inputItem = this.filterCfg.inputs.find(
      (item: any) => item.field === field
    );
    inputItem.value = value;
    this.isFilterhasValue = this.checkFilterHasValues();
    this.filterService.setFilterValue(this.filterCfg);
  }

  clearAllFilter() {
    this.isFilterhasValue = false;
    this.filterCfg.inputs.forEach((element: any) => {
      if (element.type === "searchable-multiselect") {
        element.value = [];
      } else {
        element.value = null;
      }
    });
    this.searchableMultiSelects.forEach((multiSelect) => {
      multiSelect.selectedValues = [];
    });
    this.searchableSelects.forEach((searchableSelect) => {
      searchableSelect.selectedValue = null;
    });
    this.datepickers.forEach((datepicker) => {
      datepicker.selectedDate = null;
    });
    this.inputs.forEach((input) => {
      input.value = null;
    });
    this.filterService.setFilterValue(this.filterCfg);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
