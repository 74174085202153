<div class="p-5">
  <div class="icon">
    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
  </div>
  <h5>Are You Sure?</h5>
  <mat-dialog-content>
    <p>{{message}}</p>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button mat-raised-button mat-dialog-close tabindex="-1">{{cancelButtonText}}</button>
      <button mat-raised-button color="warn" (click)="onConfirmClick()" tabindex="1">{{confirmButtonText}}</button>
      
  </mat-dialog-actions>
</div>