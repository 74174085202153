<div style="width: 400px" *ngIf="type == 'Delete'">
  <h2 mat-dialog-title class="mb-0">
    {{ modalPopupText?.confirmation_title }}
  </h2>
  <mat-dialog-content class="mat-typography text-center">
    <p class="primary">{{ modalPopupText?.del_msg }}</p>
    <p>{{ modalPopupText?.cant_retrieve }}</p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <div class="my-3 d-flex justify-content-center">
      <button (click)="cancel()" mat-flat-button class="me-3">
        {{ buttonsText?.cancel_btn }}
      </button>
      <button (click)="done()" mat-flat-button color="primary">
        {{ buttonsText?.delete_btn }}
      </button>
    </div>
  </mat-dialog-actions>
</div>

<div *ngIf="type == 'Logout'">
  <div class="modal-dialog m-0 logout-width">
    <div class="modal-content">
      <app-modal-header-common
        [title]="modalPopupText?.confirmation_title"
      ></app-modal-header-common>

      <div class="modal-body">
        <h5 class="py-3 text-center primary m-0">
          {{ modalPopupText?.logout_msg }}
        </h5>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="col-md-6">
            <button
              (click)="cancel()"
              mat-flat-button
              class="w-100 border bg-light"
            >
              {{ buttonsText?.cancel_btn }}
            </button>
          </div>
          <div class="col-md-6">
            <button
              (click)="done()"
              mat-flat-button
              class="w-100"
              color="primary"
            >
              {{ modalPopupText?.logout_btn }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="type.name == 'empty_goal'">
  <div class="modal-dialog m-0 logout-width">
    <div class="modal-content">
      <app-modal-header-common [title]=""> </app-modal-header-common>

      <div class="modal-body" *ngIf="type.section == 'training'">
        <h5 class="py-3 text-center primary m-0">
          {{ modalPopupText?.training_empty_goal }}
        </h5>
      </div>
      <div class="modal-body" *ngIf="type.section == 'release_tracker'">
        <h5 class="py-3 text-center primary m-0">
          {{ modalPopupText?.release_tracker_empty_goal }}
        </h5>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="col-md-6">
            <button
              (click)="cancel()"
              mat-flat-button
              class="w-100 border bg-light"
            >
              {{ buttonsText?.cancel_btn }}
            </button>
          </div>
          <div class="col-md-6">
            <button
              (click)="done()"
              mat-flat-button
              class="w-100"
              color="primary"
            >
              {{ modalPopupText?.okay_btn }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="type == 'Addopperuntiy'">
  <div class="modal-dialog m-0" style="max-width: 700px">
    <div class="modal-content" style="border: 0px">
      <app-modal-header-common [title]="'New Opportunity'">
      </app-modal-header-common>
      <div class="modal-body modal-oppz field-adjust opportunity-cls">
        <mat-stepper orientation="vertical" [linear]="isLinear" #stepper>
          <mat-step>
            <ng-template matStepLabel>Create Opportunity</ng-template>
            <form [formGroup]="opportunityForm" class="mt-3">
              <div class="row">
                <div class="col-sm-12" *ngIf="data.name">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{ data.disName }}</mat-label>
                    <input
                      type="text"
                      matInput
                      fullWidth
                      [maxlength]="maxChars"
                      formControlName="name"
                      id="input-0"
                    />
                    <mat-error
                      *ngIf="(submitted || f.name.touched) && f.name.errors"
                    >
                      <small class="text-danger" *ngIf="f.name.errors.required"
                        >{{ data.disName }} is required</small
                      >
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-sm-6" *ngIf="data.opportunity">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{ data.disOpportunity }}</mat-label>
                    <input
                      matInput
                      [matAutocomplete]="auto"
                      type="text"
                      formControlName="opportunity"
                    />
                    <mat-icon matSuffix class="muted">search</mat-icon>
                    <mat-autocomplete
                      #auto="matAutocomplete"
                      [displayWith]="displayFn"
                    >
                      <mat-option
                        *ngFor="let option of filterOptionType | async"
                        [value]="option"
                      >
                        {{ option.name }}
                      </mat-option>
                    </mat-autocomplete>
                    <mat-error
                      *ngIf="
                        (submitted || f.opportunity.touched) &&
                        f.opportunity.errors
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="f.opportunity.errors.required"
                        >{{ data.disOpportunity }} is required</small
                      >
                      <small
                        class="text-danger"
                        *ngIf="f.opportunity.errors.optionError"
                        >{{ commonText?.autocomplete_option_error }}</small
                      >
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-sm-6" *ngIf="data.portfolio">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{ displayLabels.portfolio }}</mat-label>
                    <input
                      matInput
                      [matAutocomplete]="auto"
                      type="text"
                      formControlName="portfolio"
                    />
                    <mat-icon matSuffix class="muted">search</mat-icon>
                    <mat-autocomplete
                      #auto="matAutocomplete"
                      [displayWith]="displayFn"
                    >
                      <mat-option
                        *ngFor="let option of filterOptionPortfolio | async"
                        [value]="option"
                        (onSelectionChange)="onSelectionChangePortfolio($event)"
                      >
                        {{ option.name }}
                      </mat-option>
                    </mat-autocomplete>
                    <mat-error
                      *ngIf="
                        (submitted || f.portfolio.touched) && f.portfolio.errors
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="f.portfolio.errors.required"
                        >{{ displayLabels.portfolio }} is required</small
                      >
                      <small
                        class="text-danger"
                        *ngIf="f.portfolio.errors.optionError"
                        >{{ commonText?.autocomplete_option_error }}</small
                      >
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-sm-6" *ngIf="data.subPortfolio">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{ displayLabels.sub_portfolio }}</mat-label>
                    <input
                      matInput
                      [matAutocomplete]="auto1"
                      type="text"
                      formControlName="subPortfolio"
                    />
                    <mat-icon matSuffix class="muted">search</mat-icon>
                    <mat-autocomplete
                      #auto1="matAutocomplete"
                      [displayWith]="displayFn"
                    >
                      <mat-option
                        *ngFor="let option of filterOptionSubPortfolio | async"
                        [value]="option"
                        (onSelectionChange)="
                          onSelectionChangeSubportPortfolio($event)
                        "
                      >
                        {{ option.name }}
                      </mat-option>
                    </mat-autocomplete>
                    <mat-error
                      *ngIf="
                        (submitted || f.subPortfolio.touched) &&
                        f.subPortfolio.errors
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="f.subPortfolio.errors.required"
                        >{{ displayLabels.sub_portfolio }} is required</small
                      >
                      <small
                        class="text-danger"
                        *ngIf="f.subPortfolio.errors.optionError"
                        >{{ commonText?.autocomplete_option_error }}</small
                      >
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-sm-6" *ngIf="data.program">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{ displayLabels.program }}</mat-label>
                    <input
                      matInput
                      [matAutocomplete]="auto5"
                      type="text"
                      formControlName="program"
                    />
                    <mat-icon matSuffix class="muted">search</mat-icon>
                    <mat-autocomplete
                      #auto5="matAutocomplete"
                      [displayWith]="displayFn"
                    >
                      <mat-option
                        *ngFor="let option of filterOptionProgram | async"
                        [value]="option"
                      >
                        {{ option.name }}
                      </mat-option>
                    </mat-autocomplete>
                    <mat-error
                      *ngIf="
                        (submitted || f.program.touched) && f.program.errors
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="f.program.errors.required"
                        >{{ displayLabels.program }} is required</small
                      >
                      <small
                        class="text-danger"
                        *ngIf="f.program.errors.optionError"
                        >{{ commonText?.autocomplete_option_error }}</small
                      >
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-sm-6" *ngIf="data.owners">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{ data.disOwners }}</mat-label>
                    <input
                      matInput
                      type="text"
                      readonly
                      formControlName="owners"
                    />
                  </mat-form-field>
                </div>
                <div class="col-sm-6"></div>
              </div>
            </form>
            <div style="display: flex; justify-content: end">
              <button mat-flat-button color="primary" matStepperNext>
                Next
              </button>
            </div>
          </mat-step>
          <mat-step [stepControl]="secondFormGroup">
            <ng-template matStepLabel>Map Tag</ng-template>
            <div class="my-2 mb-4">
              <p style="font-size: 12px">
                <span *ngIf="opportunityForm?.value?.name"
                  >{{ opportunityForm?.value?.name }}
                </span>
                <span *ngIf="opportunityForm?.value?.opportunity?.name"
                  >&nbsp;| {{ opportunityForm?.value?.opportunity?.name }}</span
                >
                <span *ngIf="opportunityForm?.value?.portfolio?.name"
                  >&nbsp;| {{ opportunityForm?.value?.portfolio?.name }}</span
                >
                <span *ngIf="opportunityForm?.value?.subPortfolio?.name"
                  >&nbsp;|
                  {{ opportunityForm?.value?.subPortfolio?.name }}</span
                >
                <span *ngIf="opportunityForm?.value?.program?.name"
                  >&nbsp;| {{ opportunityForm?.value?.program?.name }}</span
                >
                <span *ngIf="ownerDetails?.first_name"
                  >&nbsp;|
                  {{
                    ownerDetails?.first_name + " " + ownerDetails?.last_name
                  }}</span
                >
              </p>
              <app-tag
                [noIds]="true"
                [tagsIds]=""
                (childTags)="getChildTags($event)"
              ></app-tag>
            </div>
            <div style="display: flex; justify-content: end">
              <button mat-button matStepperPrevious>Back</button>

              <button
                (click)="cancel()"
                mat-button
                mat-stroked-button
                color="primary"
                class="mx-2"
              >
                Close
              </button>
              <button
                (click)="add()"
                color="primary"
                status="primary"
                mat-button
                mat-flat-button
              >
                Create Opportunity
              </button>
            </div>
          </mat-step>
        </mat-stepper>
      </div>
    </div>
  </div>
</div>

<div *ngIf="type == 'AddProject'">
  <div class="modal-dialog m-0" style="max-width: 700px">
    <div class="modal-content" style="border: 0px">
      <app-modal-header-common [title]="'New Project'">
      </app-modal-header-common>
      <div class="modal-body modal-oppz field-adjust projectCreate">
        <mat-stepper orientation="vertical" [linear]="isLinear" #stepper>
          <mat-step>
            <ng-template matStepLabel>Create Project</ng-template>
            <form [formGroup]="projectForm" class="mt-3">
              <div class="row">
                <div class="col-md-12">
                  <mat-form-field appearance="outline" class="w-50 pr-1">
                    <mat-label>Project Name</mat-label>
                    <input
                      type="text"
                      matInput
                      formControlName="project_name"
                    />
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="w-25 pl-1 pr-1">
                    <mat-label>Project Number</mat-label>
                    <input type="text" matInput formControlName="project_id" />
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="w-25 pl-1">
                    <mat-icon matPrefix>attach_money</mat-icon>
                    <mat-label>Project Value</mat-label>
                    <input
                      type="text"
                      matInput
                      formControlName="project_value"
                    />
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="w-50 pr-1">
                    <mat-label>Project Type</mat-label>
                    <mat-select type="text" formControlName="project_type_id">
                      <mat-option
                        *ngFor="let option of projectType"
                        [value]="option.id"
                        >{{ option.display_name }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="w-50 pl-1">
                    <mat-label>Project Funding Type</mat-label>
                    <mat-select
                      type="text"
                      formControlName="project_funding_type_id"
                    >
                      <mat-option
                        *ngFor="let option of projectFundingType"
                        [value]="option.id"
                        >{{ option.display_name }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="w-50 pr-1">
                    <mat-label>Start Date</mat-label>
                    <input
                      matInput
                      [matDatepicker]="startDate"
                      (focus)="startDate.open()"
                      readonly
                      formControlName="begin_date"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="startDate"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #startDate></mat-datepicker>
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="w-50 pl-1">
                    <mat-label>End Date</mat-label>
                    <input
                      matInput
                      [matDatepicker]="endDate"
                      (focus)="endDate.open()"
                      readonly
                      formControlName="expected_end_date"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="endDate"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #endDate></mat-datepicker>
                  </mat-form-field>
                  <mat-form-field
                    appearance="outline"
                    class="w-50 pr-1"
                    *ngIf="data.portfolio"
                  >
                    <mat-label>{{ data.disPortfolio }}</mat-label>
                    <input
                      matInput
                      [matAutocomplete]="auto"
                      type="text"
                      formControlName="portfolio_id"
                    />
                    <mat-icon matSuffix class="muted">search</mat-icon>
                    <mat-autocomplete
                      #auto="matAutocomplete"
                      [displayWith]="displayFn"
                    >
                      <mat-option
                        *ngFor="let option of filterOptionPortfolio | async"
                        [value]="option"
                        (onSelectionChange)="
                          onSelectionChangePortfolioProject($event)
                        "
                      >
                        {{ option.name }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                  <mat-form-field
                    appearance="outline"
                    class="w-50 pl-1"
                    *ngIf="data.subPortfolio"
                  >
                    <mat-label>{{ data.disSubPortfolio }}</mat-label>
                    <input
                      matInput
                      [matAutocomplete]="auto1"
                      type="text"
                      formControlName="subportfolio_id"
                    />
                    <mat-icon matSuffix class="muted">search</mat-icon>
                    <mat-autocomplete
                      #auto1="matAutocomplete"
                      [displayWith]="displayFn"
                    >
                      <mat-option
                        *ngFor="let option of filterOptionSubPortfolio | async"
                        [value]="option"
                        (onSelectionChange)="
                          onSelectionChangeSubportPortfolioProject($event)
                        "
                      >
                        {{ option.name }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                  <mat-form-field
                    appearance="outline"
                    class="w-50 pr-1"
                    *ngIf="data.program"
                  >
                    <mat-label>{{ displayLabels.program }}</mat-label>
                    <input
                      matInput
                      [matAutocomplete]="program"
                      type="text"
                      formControlName="program_id"
                    />
                    <mat-icon matSuffix class="muted">search</mat-icon>
                    <mat-autocomplete
                      #program="matAutocomplete"
                      [displayWith]="displayFn"
                    >
                      <mat-option
                        *ngFor="let option of filterOptionProgram | async"
                        [value]="option"
                      >
                        {{ option.name }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="w-50 pl-1">
                    <mat-label>Project Owner</mat-label>
                    <mat-select type="text" formControlName="owner_id">
                      <mat-option
                        *ngFor="let option of ownerList"
                        [value]="option.id"
                        >{{ option.first_name }}
                        {{ option.last_name }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </form>
            <div style="display: flex; justify-content: end">
              <button
                mat-flat-button
                mat-button
                color="primary"
                class="bg-primary text-white"
                matStepperNext
              >
                Next
              </button>
            </div>
          </mat-step>
          <mat-step [stepControl]="secondFormGroup">
            <ng-template matStepLabel>Map Tags</ng-template>
            <div class="my-2 mb-4">
              <p style="font-size: 12px">
                <span *ngIf="projectForm?.value?.project_name">{{
                  projectForm?.value?.project_name
                }}</span>
                <span *ngIf="projectForm?.value?.project_id"
                  >&nbsp;| {{ projectForm?.value?.project_id }}</span
                >
                <span *ngIf="projectForm?.value?.project_value"
                  >&nbsp;| {{ projectForm?.value?.project_value }}</span
                >
                <span *ngIf="projectForm?.value?.portfolio_id?.name"
                  >&nbsp;| {{ projectForm?.value?.portfolio_id?.name }}</span
                >
                <span *ngIf="projectForm?.value?.subportfolio_id?.name"
                  >&nbsp;| {{ projectForm?.value?.subportfolio_id?.name }}</span
                >
                <span *ngIf="projectForm?.value?.program_id?.name"
                  >&nbsp;| {{ projectForm?.value?.program_id?.name }}</span
                >
              </p>
              <app-tag
                [noIds]="true"
                [tagsIds]=""
                (childTags)="getChildProjectTags($event)"
              ></app-tag>
            </div>
            <div style="display: flex; justify-content: end">
              <button mat-button matStepperPrevious>Back</button>

              <button
                (click)="cancel()"
                mat-button
                mat-stroked-button
                color="primary"
                class="mx-2"
              >
                Close
              </button>
              <button
                (click)="addProject()"
                color="primary"
                class="bg-primary text-white"
                status="primary"
                mat-button
                mat-flat-button
              >
                Create Project
              </button>
            </div>
          </mat-step>
        </mat-stepper>
      </div>
    </div>
  </div>
</div>

<div *ngIf="type.name == 'evaluation_empty'">
  <div class="modal-dialog m-0 logout-width">
    <div class="modal-content">
      <app-modal-header-common [title]=""> </app-modal-header-common>

      <div class="modal-body">
        <h5 class="py-3 text-center primary m-0">
          {{ modalPopupText?.evaluation_empty_scope }}
        </h5>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="col-md-6">
            <button (click)="done()" mat-flat-button color="primary">
              {{ modalPopupText?.okay_btn }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<section class="balancequadrant" *ngIf="type == 'balancequadrant'">
  <div class="container-fluid customwidth">
    <div class="modal-dialog m-0 logout-width">
      <div class="modal-content">
        <app-modal-header-common [title]="'Customer chart view'">
        </app-modal-header-common>

        <div class="modal-body">
          <h5 class="py-3 text-center primary m-0">
            Are you sure you want to logout?
          </h5>
        </div>
        <div class="modal-footer">
          <div class="row">
            <div class="col-md-6">
              <button
                (click)="cancel()"
                mat-flat-button
                class="w-100 border bg-light"
              >
                Cancel
              </button>
            </div>
            <div class="col-md-6">
              <button
                (click)="done()"
                mat-flat-button
                class="w-100"
                color="primary"
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div *ngIf="type == 'ArchiveList'">
  <div class="modal-dialog m-0 logout-width">
    <div class="modal-content">
      <app-modal-header-common [title]="'Archive List'">
      </app-modal-header-common>

      <div class="modal-body">
        <div class="custom-table">
          <table class="table table-bordered">
            <thead style="position: sticky; top: 0; z-index: 1">
              <tr>
                <th class="fixed-header text-center" scope="col">#</th>
                <th class="fixed-header text-center w-75" scope="col">
                  {{ typeName }}
                </th>
                <th class="fixed-header text-center" scope="col">Select</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let option of archiveList; let i = index">
                <td class="align-middle" style="font-style: italic">
                  {{ i + 1 }}
                </td>
                <td class="align-middle w-75">
                  <span style="font-size: 1em">{{
                    option.opportunity_name || option.project_name
                  }}</span>
                  <span style="font-size: 0.8em; display: block">{{
                    option.opportunity_number || option.project_id
                  }}</span>
                </td>
                <td class="align-middle">
                  <input
                    #checkboxes
                    type="checkbox"
                    [checked]="option.selected"
                    (change)="option.selected = !option.selected"
                    (click)="$event.stopPropagation()"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="col-md-6">
            <button (click)="cancel()" mat-flat-button color="secondary">
              Cancel
            </button>
          </div>
          <div class="col-md-6">
            <button (click)="done()" mat-flat-button color="primary">
              Unarchive
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="type.name == 'user_owner'">
  <div class="modal-dialog m-0 logout-width">
    <div class="modal-content">
      <app-modal-header-common [title]="type?.header_name">
      </app-modal-header-common>

      <div class="modal-body" *ngIf="type.team_list">
        <input
          type="text"
          placeholder="Search user"
          class="form-control mb-2"
          [(ngModel)]="userSearchText"
        />
        <div style="height: 30vh; overflow-y: scroll">
          <div *ngFor="let team of type.team_list; let i = index">
            <div
              class="mt-2"
              style="display: flex; font-size: 12px"
              [hidden]="!searchUser(team, userSearchText)"
            >
              <input
                type="checkbox"
                id="sqw{{ i }}"
                name="sqw{{ i }}"
                [(ngModel)]="team.selected"
                [disabled]="!type.allowed"
              />
              <label for="sqw{{ i }}"> {{ team.name }}</label
              ><br />
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="col-md-6">
            <button (click)="cancel()" mat-flat-button color="secondary">
              Close
            </button>
          </div>
          <div class="col-md-6" *ngIf="type.allowed">
            <button (click)="done()" mat-flat-button color="primary">
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="type.name == 'map_sow_to_project'">
  <div class="modal-dialog m-0" style="max-width: 800px">
    <div class="modal-content">
      <app-modal-header-common [title]="'Map SOW to Project'">
      </app-modal-header-common>

      <div class="modal-body" *ngIf="type.contract_List">
        <input
          type="text"
          placeholder="Search SOW"
          class="form-control mb-2"
          [(ngModel)]="contractSearchText"
        />
        <div style="height: 50vh; overflow-y: scroll">
          <div *ngFor="let contract of type.contract_List; let i = index">
            <div
              class="mt-2"
              style="display: flex; font-size: 12px"
              [hidden]="!searchContract(contract, contractSearchText)"
            >
              <input
                type="checkbox"
                id="cont{{ i }}"
                name="cont{{ i }}"
                [(ngModel)]="contract.selected"
                (ngModelChange)="getResourceListFromContract(i, $event)"
              />
              <label for="cont{{ i }}"> {{ contract.contract_name }}</label
              ><br />
            </div>
            <span
              *ngIf="contract?.resourseList?.length > 0"
              class="ml-4"
              style="font-size: 12px"
              >Select Resource</span
            >

            <div
              *ngFor="let resource of contract?.resourseList; let j = index"
              class="ml-4"
            >
              <div class="mt-2" style="display: flex; font-size: 12px">
                <input
                  type="checkbox"
                  id="res{{ i }}{{ j }}"
                  name="res{{ i }}{{ j }}"
                  [(ngModel)]="resource.selected"
                  (ngModelChange)="selectedResource(resource, i, $event)"
                />
                <label for="res{{ i }}{{ j }}">
                  {{ resource?.resource_name }}</label
                ><br />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="col-md-6">
            <button
              (click)="cancel()"
              mat-stroked-button
              mat-button
              color="primary"
            >
              Close
            </button>
          </div>
          <div class="col-md-6">
            <button
              (click)="done()"
              mat-flat-button
              mat-button
              color="primary"
              class="bg-primary text-white"
            >
              Map
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="type.name == 'surveyComments'">
  <div
    class="modal-dialog m-0 logout-width"
    style="max-width: 600px !important; max-height: 450px !important"
  >
    <div class="modal-content">
      <app-modal-header-common [title]="'Comments'"> </app-modal-header-common>

      <div
        class="modal-body"
        style="max-height: 300px !important; overflow: auto"
      >
        <div class="mb-2" *ngFor="let comments of surveyComments">
          <h6 id="{{ comments?.id }}">{{ comments?.display_text }}</h6>
          <div class="mt-2 custom-table fixed-table-header">
            <table class="table table-bordered">
              <tr>
                <ng-container *ngFor="let option of comments.options">
                  <td class="p-0">
                    <tr>
                      <th
                        style="
                          font-size: 0.8rem !important;
                          padding: 4px;
                          text-align: center;
                        "
                      >
                        {{ option.display_text }}
                      </th>
                    </tr>
                    <tr *ngFor="let comment of option.comments">
                      <td style="font-size: 12px !important; padding: 4px">
                        {{ comment.answer_text }}
                      </td>
                    </tr>
                  </td>
                </ng-container>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="col-md-6">
            <button (click)="cancel()" mat-flat-button color="secondary">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="type.name == 'CreatePlan'">
  <div
    class="modal-dialog m-0 logout-width"
    style="max-width: 600px !important; max-height: 450px !important"
  >
    <div class="modal-content">
      <app-modal-header-common [title]="'Create Plan'">
      </app-modal-header-common>
      <div
        class="modal-body"
        style="max-height: 300px !important; overflow: auto"
      >
        <mat-form-field appearance="legacy" class="w-100">
          <mat-label class="label-design">Plan Name</mat-label>
          <input type="text" matInput fullWidth formControlName="plan_name" />
        </mat-form-field>
        <mat-form-field
          class="example-full-width"
          style="width: 100%"
          appearance="outline"
          formGroupName="owner"
        >
          <mat-chip-list #chipList1 aria-label="owner selection">
            <mat-chip
              *ngFor="let item of filterForm?.value.owner.array"
              [selectable]="selectable"
              (removed)="remove(item, 'owner')"
            >
              {{ item.display_name }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <input
              placeholder="Search owner..."
              formControlName="input"
              [matAutocomplete]="auto"
              [matChipInputFor]="chipList1"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="addOnBlur"
              name="owner_name"
            />
          </mat-chip-list>
          <mat-autocomplete
            #auto="matAutocomplete"
            (optionSelected)="search($event, 'owner')"
          >
            <mat-option
              *ngFor="let option of filteredList(filteredOwnerList, 'owner')"
              [value]="option"
              style="font-size: 0.8rem; height: 30px"
            >
              {{ option.display_name }}
            </mat-option>
          </mat-autocomplete>
          <button
            mat-button
            mat-icon-button
            matSuffix
            class="close-btn"
            (click)="clearSearchField('owner')"
            *ngIf="filterForm.value.owner.array.length > 0"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <mat-form-field appearance="legacy" class="w-50">
          <mat-label class="label-design">Start Date</mat-label>
          <input
            matInput
            [matDatepicker]="startDate"
            (focus)="startDate.open()"
            readonly
            formControlName="start_date"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="startDate"
          ></mat-datepicker-toggle>
          <mat-datepicker #startDate></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="legacy" class="w-50">
          <mat-label class="label-design">End Date</mat-label>
          <input
            matInput
            [matDatepicker]="endDate"
            (focus)="endDate.open()"
            readonly
            formControlName="end_date"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="endDate"
          ></mat-datepicker-toggle>
          <mat-datepicker #endDate></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="col-md-6">
            <button (click)="cancel()" mat-flat-button color="secondary">
              Close
            </button>
          </div>
          <div class="col-md-6">
            <button (click)="done()" mat-flat-button color="primary">
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="type.name == 'projectpop'">
  <div
    class="modal-dialog m-0 logout-width"
    style="max-width: 80vw !important; height: 90vh !important; overflow: auto"
  >
    <div class="modal-content" *ngIf="iframeUrl">
      <app-loading *ngIf="!iframeLoaded && !iframeLoadingError"></app-loading>
      <div *ngIf="iframeLoaded" class="open-icon" (click)="close()">
        <mat-icon>close</mat-icon>
      </div>
      <iframe
        [src]="iframeUrl"
        id="emberIfram"
        style="height: 100vh !important"
        title="Project Info"
      ></iframe>
    </div>
  </div>
</div>
<div *ngIf="type.name == 'assessmentpop'">
  <div
    class="modal-dialog m-0 logout-width"
    style="
      max-width: 80vw !important;
      height: 80vh !important;
      overflow: hidden;
    "
  >
    <div class="modal-content" *ngIf="iframeUrl">
      <app-loading *ngIf="!iframeLoaded && !iframeLoadingError"></app-loading>
      <div *ngIf="iframeLoaded" class="open-icon" (click)="close()">
        <mat-icon>close</mat-icon>
      </div>
      <iframe
        [src]="iframeUrl"
        id="emberIfram"
        style="height: 80vh !important"
        title="Assessment Info"
      ></iframe>
    </div>
  </div>
</div>
<div *ngIf="type.name == 'instancepop'">
  <div
    class="modal-dialog m-0 logout-width"
    style="
      max-width: 80vw !important;
      height: 80vh !important;
      overflow: hidden;
    "
  >
    <div class="modal-content" *ngIf="iframeInstanceUrl">
      <app-loading *ngIf="!iframeLoaded && !iframeLoadingError"></app-loading>
      <div *ngIf="iframeLoaded" class="open-icon" (click)="close()">
        <mat-icon>close</mat-icon>
      </div>
      <iframe
        [src]="iframeInstanceUrl"
        id="emberIfram"
        style="height: 80vh !important"
        title="Instance Info"
      ></iframe>
    </div>
  </div>
</div>
<div *ngIf="type.name == 'opportunitypop'">
  <div
    class="modal-dialog m-0 logout-width"
    style="max-width: 80vw !important; height: 90vh !important; overflow: auto"
  >
    <div class="modal-content" *ngIf="iframeUrl">
      <div class="open-icon" (click)="close()">
        <mat-icon>close</mat-icon>
      </div>
      <iframe
        [src]="iframeUrl"
        *ngIf="iframeUrl"
        id="emberIframId"
        style="height: 100vh !important"
        title="Opportunity Info"
      ></iframe>
    </div>
  </div>
</div>
<div *ngIf="type == 'ShowInvestment'">
  <div
    class="modal-dialog m-0 logout-width"
    style="max-width: 700px !important; max-height: 450px !important"
  >
    <div class="modal-content">
      <app-modal-header-common [title]="'Investment History'">
      </app-modal-header-common>

      <div
        class="modal-body"
        style="max-height: 300px !important; overflow: auto"
      >
        <div class="mt-2 custom-table fixed-table-header">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th class="text-center">Description</th>
                <th class="text-center">Commitment</th>
                <th class="text-center">Consumed</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of modalData">
                <td>
                  {{ data.description }}
                </td>
                <td>
                  {{ data.amount | currency : "$" }}
                </td>
                <td>
                  {{ getConsumed(data.blended_costs) | currency : "$" }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="col-md-6">
            <button (click)="cancel()" mat-flat-button color="secondary">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
