import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { NgxPermissionsService } from "ngx-permissions";
import { environment } from "src/environments/environment";
@Injectable({
  providedIn: "root",
})
export class SurveyService {
  activeTab$ = new BehaviorSubject<string>("results");
  formID$ = new BehaviorSubject<string>("");
  surveyUrl = environment.surveyServerURL;
  formData: any;

  constructor(
    private readonly http: HttpClient,
    private readonly permissionsService: NgxPermissionsService
  ) {}

  getPermissions() {
    const perm = JSON.parse(localStorage.getItem("permission") ?? "{}");
    this.permissionsService.loadPermissions(perm.permissions);
  }
  getList() {
    return this.http.get(`forms`);
  }
  getSurveyList() {
    return this.http.get(`surveys/list/`);
  }

  getSurveyListSurveyCentral() {
    return this.http.get(`nps-survey-template`);
  }
  getSurveyDetails(id: any) {
    return this.http.get("surveys/" + id);
  }

  createSurvey(data: any) {
    return this.http.post("nps-survey-template/", data);
  }

  editSurvey(data: any, surveyId: any) {
    return this.http.patch(`nps-survey-template/${surveyId}/`, data);
  }

  publishSurvey(uuid: any, payload: any) {
    return this.http.post(`forms/${uuid}/publish_survey`, payload);
  }
  getDistributors() {
    return this.http.get(`dynamic-form/?form_name=opportunity_teams`);
  }

  public getActiveTab(): Observable<string> {
    return this.activeTab$.asObservable();
  }
  public setActiveTab(value: string): void {
    this.activeTab$.next(value);
  }
  public getFormId(): Observable<string> {
    return this.formID$.asObservable();
  }
  public setFormId(value: string): void {
    this.formID$.next(value);
  }

  getTypeList() {
    return this.http.get("base_value/?type=survey_template_type");
  }
  getVendorList() {
    return this.http.get(
      "dynamic-form/?form_name=opportunity_empanelled_vendors"
    );
  }
  getResponses(id: any, payload: any) {
    return this.http.post(`forms/${id}/survey_respondents`, payload);
  }
  getOverallSummary(id: any) {
    return this.http.get(`forms/${id}/detailed_executive_summary`);
  }
  getDetailSummary(id: any) {
    return this.http.get(`forms/${id}/executive_summary`);
  }
  getScoringQuestion(id: any) {
    return this.http.get(`forms/${id}/scoring_by_question`);
  }
  getSurveyResponseById(id: any) {
    return this.http.get(`survey-response/${id}/report/`);
  }
  getSurveyCommentsByQuestion(surveyId: any, qstId: any) {
    return this.http.get(
      `survey-response/${surveyId}/survey/${qstId}/question/comments/`
    );
  }

  saveSurveyDetail(payload: any) {
    return this.http.post(`survey-respondent/update-respondents/`, payload);
  }
  getQuestionAnswers(event: any) {
    return this.http.get(
      `survey-response/list?survey_id=${event.surveyId}&user_id=${event.userId}&respondent_id=${event.userId}`
    );
  }

  refreshData(surveyId: any) {
    return this.http.post(
      `surveys/insert-responses/?survey_id=${surveyId}`,
      []
    );
  }
  loadSurveyById(surveyId: any) {
    return this.http.get(`survey-response/${surveyId}/answer-frequency/`);
  }
  getSurveySummary(surveyId: any) {
    return this.http.get(`survey-response/${surveyId}/summary/`);
  }

  createHubbleSurvey(payload: any) {
    return this.http.post("nps-new-survey/", payload);
  }

  editHubbleSurvey(payload: any, surveyId: any) {
    return this.http.patch(`nps-new-survey/${surveyId}/`, payload);
  }

  getAllSurveys(tabName: any) {
    return this.http.get(`nps-new-survey/?tab=${tabName}`);
  }

  downloadFile(surveyId: any, isTemplate: boolean) {
    if (isTemplate) {
      return this.http.get(`nps-survey-template/${surveyId}/download_file`, {
        responseType: "blob",
      });
    } else {
      return this.http.get(`nps-new-survey/${surveyId}/download_file`, {
        responseType: "blob",
      });
    }
  }

  downloadPulseFile(pulseId: any) {
    return this.http.get(`nps/pulses/${pulseId}/download`, {
      responseType: "blob",
    });
  }

  getUsers() {
    return this.http.get(`users/user-list/`);
  }

  deleteSurveyTemplate(id: any) {
    return this.http.delete(`nps-survey-template/${id}/ `);
  }

  getFilteredHubbleSurveys(qParam: string) {
    return this.http.get(`nps-new-survey/?${qParam}`);
  }

  getFilteredSurveyCentral(qParam: string) {
    return this.http.get(`nps-survey-template/?${qParam}`);
  }

  getPulse() {
    return this.http.get("nps/pulses");
  }

  createPulse(payload: any) {
    return this.http.post("nps/pulses/", payload);
  }

  updatePulse(payload: any, id: any) {
    return this.http.put(`nps/pulses/${id}/`, payload);
  }

  getPerformanceImpactList() {
    return this.http.get("base_value/?type=pulse_indicator_type");
  }

  getPulseInfo(id: any) {
    return this.http.get(`nps/pulses/${id}`);
  }

  getFilteredPulses(qParam: string) {
    return this.http.get(`nps/pulses/?${qParam}`);
  }

  deletePulse(id: any) {
    return this.http.delete(`nps/pulses/${id}`);
  }
}
