import {
  Component,
  EventEmitter,
  Output,
  Input,
  SimpleChanges,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { ToastrService } from "ngx-toastr";
import html2canvas from "html2canvas";

@Component({
  selector: "app-comments",
  templateUrl: "comments.component.html",
  styleUrls: ["comments.component.scss"],
})
export class CommentsComponent {
  @Output() dataEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() downloadFileEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() copyFileEvent: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild("focusDiv", { static: false }) focusDiv: ElementRef;

  constructor(private readonly toastrService: ToastrService) {}
  content: any;
  attachementName: any;
  attachement: any;
  @Input() comments: string;
  @Input() refresh: boolean;
  @Input() noEditor: string;
  @Input() copyAllowed: boolean;
  HEADER_LEVEL_ONE = 1;
  HEADER_LEVEL_TWO = 2;
  HEADER_LEVEL_THREE = 3;
  HEADER_LEVEL_FOUR = 4;
  HEADER_LEVEL_FIVE = 5;
  HEADER_LEVEL_SIX = 6;
  modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],

      [{ size: ["small", false, "large", "huge"] }],
      [
        {
          header: [
            this.HEADER_LEVEL_ONE,
            this.HEADER_LEVEL_TWO,
            this.HEADER_LEVEL_THREE,
            this.HEADER_LEVEL_FOUR,
            this.HEADER_LEVEL_FIVE,
            this.HEADER_LEVEL_SIX,
            false,
          ],
        },
      ],

      [{ font: [] }],
      [{ align: [] }],

      ["clean"],
    ],
  };

  addComment() {
    this.dataEvent.emit({ text: this.content, document: this.attachement });
  }

  onFileSelected(event: any) {
    const inputElement = event.target as HTMLInputElement;
    const MAX_FILE_SIZE = 5000000;
    if (inputElement.files && inputElement.files.length > 0) {
      const file = inputElement.files[0];
      if (file.size > MAX_FILE_SIZE) {
        this.toastrService.error("Please upload less than 5MB");
        return;
      }
      this.attachementName = file.name;
      this.attachement = file;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["refresh"]) {
      this.attachement = "";
      this.attachementName = "";
      this.content = "";
    }
  }

  getFileType(file: any) {
    const ext = file.substring(file.lastIndexOf(".") + 1);
    return ext?.toLowerCase();
  }

  downloadFeedbackDocument(comment: any) {
    this.downloadFileEvent.emit(comment);
  }
  convertToImage(id: any) {
    if (this.copyAllowed) {
      const divToCapture: any = document.getElementById(id);
      html2canvas(divToCapture).then((canvas: any) => {
        canvas.toBlob(async (blob: any) => {
          if (document.hasFocus()) {
            const clipboardItem = new ClipboardItem({ "image/png": blob });
            await navigator.clipboard.write([clipboardItem]);
          }
        });
      });
    }
  }
}
