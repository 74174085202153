import { Component, Input, ViewEncapsulation } from "@angular/core";
import { Location } from "@angular/common";
import { Observable } from "rxjs";
import { Breadcrumb } from "../../model/breadcrumb.model";
import { BreadcrumbService } from "../../services/breadcrumb.service";
import { Router } from "@angular/router";
import { MessageService } from "../../services/message.service";
@Component({
  selector: "app-bread-crumb",
  templateUrl: "./bread-crumb.component.html",
  styleUrls: ["./bread-crumb.component.scss"],
  encapsulation: ViewEncapsulation.Emulated,
})
export class BreadCrumbComponent {
  url: any;
  breadcrumbs$: Observable<Breadcrumb[]>;
  iframeEmbedded = false;

  @Input() showColorBlue: any;
  constructor(
    private readonly breadcrumbService: BreadcrumbService,
    private readonly _location: Location,
    private readonly router: Router,
    private readonly messageService: MessageService
  ) {
    this.messageService.getIframeData().subscribe((res: any) => {
      this.iframeEmbedded = typeof res != "object";
    });
    this.breadcrumbs$ = this.breadcrumbService.breadcrumbs$;
    this.url = this.router.url;
  }

  goBack() {
    this._location.back();
  }
}
