import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class GovernanceLighthouseService {
  constructor(
    private readonly http: HttpClient,
    private readonly router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    let id = route.params["id"];
    try {
      id = atob(id);
    } catch (e) {
      this.router.navigate(["404"]);
    }
    return this.http.get(`gov-lighthouse/governance_lighthouse_instance/${id}`);
  }

  getAllAssessments() {
    return this.http.get(`gov-lighthouse/governance_lighthouse_instance/`);
  }

  getTemplates() {
    return this.http.get(`gov-lighthouse/governance_lighthouse_template/`);
  }

  downloadTemplate() {
    return this.http.get(
      `gov-lighthouse/governance_lighthouse_template/assessment_file_download/7/`,
      { responseType: "blob" }
    );
  }

  uploadFile(payload: any) {
    return this.http.post(
      `gov-lighthouse/governance_lighthouse_template/`,
      payload
    );
  }

  createAssessment(payload: any) {
    return this.http.post(
      `gov-lighthouse/governance_lighthouse_instance/`,
      payload
    );
  }

  deleteTemplate(id: any) {
    return this.http.delete(
      `gov-lighthouse/governance_lighthouse_template/` + id
    );
  }

  getAssessmentById(id: any) {
    return this.http.get(
      `gov-lighthouse/governance_lighthouse_instance/${id}/`
    );
  }

  submitAnswer(payload: any) {
    return this.http.post(
      `gov-lighthouse/governance_lighthouse_response/`,
      payload
    );
  }

  getUsers() {
    return this.http.get(`users/user-list/`);
  }

  uploadEvidence(payload: any) {
    return this.http.post(
      `gov-lighthouse/governance_lighthouse_response/upload_evidence/`,
      payload
    );
  }

  deleteAssessment(id: any) {
    return this.http.delete(
      `gov-lighthouse/governance_lighthouse_instance/` + id
    );
  }

  getMasterForm() {
    return this.http.get(`dynamic-form/?form_name=new_opportunity`);
  }

  getProgram() {
    return this.http.get(`org/programs/`);
  }

  getProject() {
    return this.http.get(`projects/`);
  }

  getVendor() {
    return this.http.get(
      `dynamic-form/?form_name=opportunity_empanelled_vendors`
    );
  }

  fileDownload(instanceId: any, quesId: any, userId: any) {
    return this.http.get(
      `gov-lighthouse/governance_lighthouse_response/download_evidence/?instance_id=${instanceId}&question_id=${quesId}&user_id=${userId}`,
      { responseType: "blob" }
    );
  }

  getAssessmentTemplateCategory() {
    return this.http.get("base_value/?type=gl_category");
  }

  getFilteredAssessmentTemplates(qParam: string) {
    return this.http.get(
      `gov-lighthouse/governance_lighthouse_template/?${qParam}`
    );
  }

  getFilteredAssessmentCentral(qParam: string) {
    return this.http.get(
      `gov-lighthouse/governance_lighthouse_instance/?${qParam}`
    );
  }

  getAggregateResponseDetails(assessmentId: any) {
    return this.http.get(
      `gov-lighthouse/governance_lighthouse_instance/response-details/${assessmentId}/`
    );
  }

  getAnswerOptions() {
    return this.http.get("base_value/?type=gl_response_option");
  }
}
