<footer *ngIf="!iframeEmbedded">
    <div class="footer-holder">
        <p>Copyright &#169; <a href="https://www.kairhos.com/"> Kairhos Labs Pvt Ltd</a>. All rights reserved.</p>
        <span class="d-none d-sm-none d-md-none d-lg-block"></span>
        <a class="d-none d-sm-none d-md-none d-lg-block">Terms</a>
        <span class="d-none d-sm-none d-md-none d-lg-block"></span>
        <a class="d-none d-sm-none d-md-none d-lg-block">Privacy</a>
        <span class="d-none d-sm-none d-md-none d-lg-block"></span>
        <a class="d-none d-sm-none d-md-none d-lg-block">Trust Center</a>
        <span class="d-none d-sm-none d-md-none d-lg-block"></span>
        <a class="d-none d-sm-none d-md-none d-lg-block">Acceptable Use Guidelines</a>
        <span class="d-none d-sm-none d-md-none d-lg-block"></span>
        <a class="d-none d-sm-none d-md-none d-lg-block">Legal & Compliance</a>
        <span class="d-none d-sm-none d-md-none d-lg-block"></span>
        <a class="d-none d-sm-none d-md-none d-lg-block">Do Not Sell/Share My Personal Information</a>
        <span class="d-none d-sm-none d-md-none d-lg-block"></span>
        <a class="d-none d-sm-none d-md-none d-lg-block">Cookie Preferences</a>
    </div>
</footer>

<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
