import { Component, Input } from "@angular/core";
import { ChallengeWorkbenchService } from "src/app/shared/services/challenge-workbench.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
@Component({
  selector: "app-statement-response-details",
  templateUrl: "./statement-response-details.component.html",
  styleUrls: ["./statement-response-details.component.scss"],
})
export class StatementResponseDetailsComponent {
  @Input() resDetails: any;
  statements: any;
  panelOpenState = false;
  panelOpenState1 = false;
  panelOpenState2 = true;
  constructor(
    private readonly cw: ChallengeWorkbenchService,
    private readonly spinner: NgxSpinnerService,
    private readonly toastrService: ToastrService
  ) {}

  openLink(x: any) {
    if (x) {
      window.open(x, "_blank");
    }
  }

  downloadFile(file: any) {
    this.spinner.show();
    this.cw.downloadResponseAttachmentFile(file.id).subscribe(
      (res: any) => {
        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(res);
        downloadLink.setAttribute("download", file.file_name);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        this.toastrService.success("File downloaded successfully");
        this.spinner.hide();
      },
      (error: any) => {
        this.spinner.hide();
        throw error;
      }
    );
  }

  downloadIdeaPitchFile(file: any) {
    this.spinner.show();
    this.cw.downloadIdeaPitchFile(file.id).subscribe(
      (res: any) => {
        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(res);
        downloadLink.setAttribute("download", file.file_name);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        this.toastrService.success("File downloaded successfully");
        this.spinner.hide();
      },
      (error: any) => {
        this.spinner.hide();
        throw error;
      }
    );
  }
}
