import { DatePipe } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-datepicker",
  templateUrl: "./datepicker.component.html",
  styleUrls: ["./datepicker.component.scss"],
  providers: [DatePipe],
})
export class DatepickerComponent {
  @Input() controlName: any;
  @Input() placeholderText: string;
  @Output() selectDate = new EventEmitter();
  selectedDate: any;

  constructor(private readonly datePipe: DatePipe) {}

  onChange() {
    this.selectedDate = this.datePipe.transform(
      this.selectedDate,
      "YYYY-MM-dd"
    );
    this.selectDate.emit(this.selectedDate);
  }

  clearField() {
    this.selectedDate = null;
    this.selectDate.emit(this.selectedDate);
  }
}
