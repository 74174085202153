import { Component, OnInit, Input } from "@angular/core";
import { ChallengeWorkbenchService } from "src/app/shared/services/challenge-workbench.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-right-panel-statement-details",
  templateUrl: "./right-panel-statement-details.component.html",
  styleUrls: ["./right-panel-statement-details.component.scss"],
})
export class RightPanelStatementDetailsComponent implements OnInit {
  csDetails: any;
  statements: any;
  panelOpenState = true;
  @Input() role: string;
  @Input() position = "";
  constructor(
    private readonly cw: ChallengeWorkbenchService,
    private readonly spinner: NgxSpinnerService,
    private readonly toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    this.cw.data$.subscribe((newData) => {
      this.csDetails = newData;
    });
  }

  downloadFile(file: any) {
    this.spinner.show();
    this.cw.downloadAttachmentFile(file.id).subscribe(
      (res: any) => {
        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(res);
        downloadLink.setAttribute("download", file.file_name);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        this.toastrService.success("File downloaded successfully");
        this.spinner.hide();
      },
      (error: any) => {
        this.spinner.hide();
        throw error;
      }
    );
  }
  getName(name: any) {
    if (name) {
      return name
        .split(" ")
        .slice(0, 2)
        .map((n: any) => n[0])
        .join("");
    } else {
      return name;
    }
  }
}
