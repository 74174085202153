import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DataService {
  isRouteNavigate = false;
  private readonly removeKeyResult = new BehaviorSubject<any>({});
  removeKeyResultItem = this.removeKeyResult.asObservable();

  private readonly removeCustomKeyResult = new BehaviorSubject<any>({});
  removeCustomKeyResultItem = this.removeCustomKeyResult.asObservable();

  private readonly removeToeRecommended = new BehaviorSubject<any>({});
  removeToeRecommendedItem = this.removeToeRecommended.asObservable();

  private readonly removeToeCustom = new BehaviorSubject<any>({});
  removeToeCustomItem = this.removeToeCustom.asObservable();

  public FormArray: any[] = [];

  private readonly stepLabClick = new BehaviorSubject(0);
  stepLab = this.stepLabClick.asObservable();

  scopeData: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  stepperData: BehaviorSubject<any> = new BehaviorSubject<any>([]);

  constructor() {}

  removeKeyMeasurmentMethod(data: any) {
    this.removeKeyResult.next(data);
  }

  removeCustomKeyMeasurmentMethod(data: any) {
    this.removeCustomKeyResult.next(data);
  }

  patchToeCategory(data: any) {
    this.removeToeRecommended.next(data);
  }

  patchCustomToeCategory(data: any) {
    this.removeToeCustom.next(data);
  }

  stepLabNext(data: any) {
    this.stepLabClick.next(data);
  }

  getScopeData() {
    return this.scopeData;
  }

  getStepperData() {
    return this.stepperData;
  }
}
