import {
  Component,
  EventEmitter,
  ViewEncapsulation,
  OnInit,
  Output,
  Renderer2,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { MsalService } from "@azure/msal-angular";
import { Constants } from "../../constant/constant";
import { ModalComponent } from "../modal/modal.component";
import textConfiguration from "src/assets/static-text-configuration.json";
import { NotificationsService } from "../../services/notifications.service";
import { DatePipe } from "@angular/common";
import { FormBuilder } from "@angular/forms";
import { environment } from "src/environments/environment";
import { MessageService } from "../../services/message.service";
import { LoggingService } from "src/app/logging.service";
import { AuthService } from "../../services/auth.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  providers: [DatePipe],
  encapsulation: ViewEncapsulation.Emulated,
})
export class HeaderComponent implements OnInit {
  user: any;
  @Output() refereshParentData = new EventEmitter<string>();
  enableSuitablility = false;
  enableDelivery = false;
  enableEngagement = false;
  enableInsights = false;
  enableProposals = false;
  customStyle = {
    backgroundColor: "#92c353",
    borderRadius: "50%",
    color: "#ffffff",
    cursor: "pointer",
    fontWeight: "bolder",
  };
  showNotifications = false;
  showProfilePopUp = false;
  showProfilePopUpMain = false;
  isChecked: boolean;
  showLoginPopUp = false;
  menuText: any = (textConfiguration as any).menu;
  homeText: any = (textConfiguration as any).home;
  commonText: any = (textConfiguration as any).common;
  headerText: any = (textConfiguration as any).header;
  actionTrackerText: any = (textConfiguration as any).action_tracker;
  filterText: any = (textConfiguration as any).filter;
  buttonsText: any = (textConfiguration as any).buttons;
  notficationsList: any[] = [];
  authenticated = false;
  pageID: any;
  projectPaths = [
    "/overview",
    "/resource",
    "/scorecard",
    "/change-request",
    "/okrlog",
    "/risklog",
    "/training",
    "/release",
    "/toe",
    "/credentials",
  ];
  opportunityPaths = [
    "/setup",
    "/scope",
    "/evaluation",
    "/terms",
    "/more-info",
    "/preview",
    "/proposal-evaluation",
    "/summary",
    "/award-deal",
  ];

  ideapitchUrl: any;
  iframeEmbedded = false;

  constructor(
    private readonly msalAuthService: MsalService,
    private readonly dialog: MatDialog,
    private readonly notificationService: NotificationsService,
    private readonly renderer: Renderer2,
    private readonly router: Router,
    public fb: FormBuilder,
    private readonly activatedRoute: ActivatedRoute,
    private readonly messageService: MessageService,
    private readonly loggingService: LoggingService,
    private readonly authService: AuthService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
    this.activatedRoute.params.subscribe((res: any) => {
      if (res?.id) {
        this.pageID = atob(res.id);
      }
    });
  }

  ngOnInit() {
    this.authService.tagsEnabled.subscribe((res: any) => {
      this.isChecked = res;
    });
    this.messageService.getIframeData().subscribe((res: any) => {
      this.iframeEmbedded = typeof res != "object";
    });
    if (localStorage.getItem("token")) {
      this.authenticated = true;
      this.user = JSON.parse(localStorage.getItem("permission") ?? "{}");
      if (!this.user?.is_vendor) {
        this.getAllUnReadNotificaitons();
      }
    }

    this.renderer.listen("window", "click", () => {
      this.showNotifications = false;
    });

    this.ideapitchUrl = environment.ideapitchURL;
  }

  signIn() {
    const currentState = this.router.getCurrentNavigation()?.extras.state;
    const newState = { ...currentState };
    if (!newState) {
      this.router.navigateByUrl("/auth/login");
    } else {
      this.router.navigateByUrl("/auth/login", { state: newState });
    }
  }

  openAiro() {
    window.open("https://airo.kairhos.com");
  }

  signUp() {
    const currentState = this.router.getCurrentNavigation()?.extras.state;
    const newState = { ...currentState };
    if (!newState) {
      this.router.navigateByUrl("/auth/signup");
    } else {
      this.router.navigateByUrl("/auth/signup", { state: newState });
    }
  }

  logOut() {
    this.showProfilePopUp = false;
    this.showProfilePopUpMain = false;
    //It will solve respective issue when we click logout btn it's opening popup as mutiple instance it's kinda problem.
    this.dialog.closeAll();
    const dialog = this.dialog.open(ModalComponent, { hasBackdrop: true });
    dialog.componentInstance.type = "Logout";
    dialog.afterClosed().subscribe((data) => {
      if (data) {
        if (localStorage.getItem("sso-login") === "true") {
          this.msalAuthService.logoutRedirect();
        }
        localStorage.clear();
        this.authService.setLoginStatus(false);
        window.location.reload();
      }
    });
  }

  clickOpportunity() {
    this.router.navigate(["program"]);
  }

  clickDelivery() {
    this.router.navigate(["delivery/projects"]);
  }

  clickProposals() {
    this.router.navigate(["proposals"]);
  }

  clickEngagement() {
    this.router.navigate(["deal-structure"]);
  }

  clickIdeaPage() {
    this.router.navigate(["innovation/idea-page"]);
  }

  navigateTo(link: string) {
    this.router.navigate([link]);
  }
  menuOpened(type: any) {
    if (type === "suitability") {
      this.enableSuitablility = true;
    } else if (type === "delivery") {
      this.enableDelivery = true;
    } else if (type === "engagement") {
      this.enableEngagement = true;
    } else if (type === "opportunity") {
      this.enableProposals = true;
    } else if (type === "insights") {
      this.enableInsights = true;
    } else {
      this.loggingService.warn("Unexpected value");
    }
  }
  menuClosed(type: any) {
    if (type === "suitability") {
      this.enableSuitablility = false;
    } else if (type === "delivery") {
      this.enableDelivery = false;
    } else if (type === "engagement") {
      this.enableEngagement = false;
    } else if (type === "opportunity") {
      this.enableProposals = false;
    } else if (type === "insights") {
      this.enableInsights = false;
    } else {
      this.loggingService.warn("Unexpected value");
    }
  }

  navigateToHome() {
    this.router.navigate(["auth/landing"]);
  }

  navigateToSP() {
    window.open(Constants.SHAREPOINT_URL, "_blank");
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  openBlank(link: any) {
    window.open(link, "_blank");
  }

  getAllUnReadNotificaitons() {
    this.notificationService
      .getAllNotifications("unread")
      .subscribe((getUnreadList: any) => {
        this.notficationsList = getUnreadList.notifications;
        this.notficationsList = this.notficationsList.map((mapData: any) => {
          mapData["routepath"] = this.routePage(
            mapData?.subject?.toLowerCase(),
            mapData?.details?.id
          );
          return mapData;
        });
      });
  }

  routePage(Page: any, routeId: any) {
    let routepath: any;
    switch (Page) {
      case "proposal":
        routepath = `/getproposal/${btoa(routeId)}/proposal-overview`;
        break;
      case "opportunity":
        routepath = `/rfx/${btoa(routeId)}/opportunity/preview`;
        break;
      case "delivery":
        routepath = `/balancedscorecard/dashboard?project=` + btoa(routeId);
        break;
      case "bvt":
        routepath = "/BVT/list";
        break;
      case "okr":
        routepath = "/okrconvergence";
        break;
      case "workforcedashboard":
        routepath = `/workforce/view/` + btoa(routeId);
        break;
      default:
        routepath = "";
        break;
    }
    return routepath;
  }

  markAsReadNotification(id: number) {
    const okStatus = 200;
    this.notificationService
      .getAllReadNotifications(id, "")
      .subscribe((getReadStatus: any) => {
        if (getReadStatus?.status === okStatus) {
          this.notficationsList = getReadStatus?.body?.notifications;
        }
      });
  }

  toggleTag(event: any) {
    this.isChecked = event.checked;
    this.authService.setTagsEnabled(this.isChecked);
  }
}
