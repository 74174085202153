<div class="nested-accordion">
  <div
    *ngFor="let item of items; let indx = index"
    class="nested-accordion-loop mb-2"
  >
    <div
      class="accordion-item"
      *ngIf="
        item.level !== 'goal' &&
        item.level !== 'term' &&
        item.level !== 'risk' &&
        ((context?.componentName?.name === 'PreviewComponent' &&
          item.selected) ||
          !context?.componentName)
      "
    >
      <div
        class="accordion-header"
        (click)="toggleAccordion(item) && $event.stopPropagation()"
        [ngStyle]="{ backgroundColor: item.expanded ? getColor() : '' }"
      >
        <div
          class="title-hol"
          [ngStyle]="{
            flex: fromPage == 'toe' ? 'auto' : '',
            'align-items': fromPage == 'toe' ? 'baseline' : ''
          }"
        >
          <input
            *ngIf="fromPage === 'toe' && item.id"
            type="checkbox"
            [checked]="item.selected"
            [disabled]="disabled || !hasTerms(item)"
            (change)="item.selected = !item.selected; selectGoals($event, item)"
            (click)="$event.stopPropagation()"
          />
          <span class="log-number ml-1">{{ indx + 1 }}</span>
          <div class="row col-12" *ngIf="fromPage === 'toe' && !item.id">
            <mat-form-field appearance="outline" class="col-12">
              <mat-label>Sub Category Name</mat-label>
              <input
                type="text"
                matInput
                fullWidth
                [(ngModel)]="subCategoryName"
              />
            </mat-form-field>
            <div class="row">
              <div
                class="col-md-4 ml-auto d-flex align-items-center justify-content-end"
                style="margin-left: 400px"
              >
                <button mat-stroked-button color="primary" class="mx-1">
                  Cancel
                </button>
                <button
                  mat-flat-button
                  mat-button
                  class="bg-primary text-white mx-1"
                  color="primary"
                  (click)="updateSubCategory($event, item)"
                >
                  Ok
                </button>
              </div>
            </div>
          </div>

          <div class="title" *ngIf="fromPage === 'toe' && item.id">
            {{ item.name }}
          </div>
          <div class="title" *ngIf="fromPage !== 'toe'">{{ item.name }}</div>
          <div
            *ngIf="
              fromPage === 'toe' &&
              (item.level === 'category' || item.level === 'subcategory') &&
              !user?.is_vendor
            "
          >
            <mat-icon
              aria-hidden="false"
              aria-label="material-icons-filled"
              (click)="add($event, item)"
              *ngIf="item.id"
              class="cursor-pointer fs-5 px-3"
              [ngClass]="{ 'disabled-icon': iframeEmbedded }"
            >
              add
            </mat-icon>
          </div>
        </div>

        <div class="formParentArrow p-2 d-flex align-items-center">
          <mat-icon
            class="material-icons-outlined arrow-icon"
            *ngIf="!item.expanded"
          >
            keyboard_arrow_down
          </mat-icon>
          <mat-icon
            class="material-icons-outlined arrow-icon"
            *ngIf="item.expanded"
          >
            keyboard_arrow_up
          </mat-icon>
        </div>
      </div>
      <div class="accordion-content" [hidden]="!item.expanded">
        <app-loading *ngIf="loading"></app-loading>
        <app-selection-tree-listing
          [tree]="tree"
          *ngIf="item.children"
          [disabled]="disabled"
          [(items)]="item.children"
          [context]="context"
          [fromPage]="fromPage"
          [(frequencyList)]="frequencyList"
          [(userList)]="userList"
          [captureContext]="captureContext"
        ></app-selection-tree-listing>
      </div>
    </div>
    <div
      *ngIf="
        (item.level === 'goal' ||
          item.level === 'term' ||
          item.level === 'risk') &&
        ((context?.componentName?.name === 'PreviewComponent' &&
          item.selected) ||
          !context?.componentName)
      "
      class="hov"
    >
      <div
        [ngStyle]="{ backgroundColor: getColor() }"
        class="last-level"
        [ngStyle]="{ display: fromPage === 'toe' ? 'block' : 'inherit' }"
      >
        <div
          style="display: flex; align-items: flex-start"
          (click)="
            item.selected = disabled ? item.selected : !item.selected;
            selectGoals($event, item)
          "
        >
          <input
            *ngIf="item.id"
            #checkboxes
            style="margin-right: 5px; margin-top: 3px"
            type="checkbox"
            [checked]="item.selected"
            [disabled]="disabled"
            (change)="item.selected = !item.selected; selectGoals($event, item)"
          />
          <div class="leveltext" *ngIf="fromPage !== 'toe'">
            {{ item?.goal_name || item?.name }}
          </div>
          <div class="leveltext" *ngIf="fromPage === 'toe' && item.id">
            {{ item?.goal_name || item?.name }}
          </div>
          <div *ngIf="fromPage === 'toe' && !item.id">
            <mat-form-field appearance="outline" class="w-100 pr-2 pl-2">
              <mat-label>Term Name</mat-label>
              <input type="text" matInput fullWidth [(ngModel)]="termName" />
            </mat-form-field>

            <mat-form-field appearance="outline" class="w-50 pr-2 pl-2">
              <mat-label>Function</mat-label>
              <mat-select
                placeholder="Function"
                [(ngModel)]="termFunction"
                [ngModelOptions]="{ standalone: true }"
              >
                <mat-option *ngFor="let fun of functionList" [value]="fun.id"
                  >{{ fun?.display_name }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" class="w-50 pr-2 pl-2">
              <mat-label>Fulfilled By</mat-label>
              <mat-select
                [(ngModel)]="termFulfilledBy"
                [ngModelOptions]="{ standalone: true }"
              >
                <mat-option value="Enterprise">Enterprise</mat-option>
                <mat-option value="Vendor">Vendor</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" class="w-50 pr-2 pl-2">
              <mat-label>Evidence</mat-label>
              <mat-select
                placeholder="Function"
                [(ngModel)]="termEvidence"
                [ngModelOptions]="{ standalone: true }"
              >
                <mat-option *ngFor="let evd of evidenceList" [value]="evd.id"
                  >{{ evd?.display_name }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" class="w-50 pr-2 pl-2">
              <mat-label>Domain</mat-label>
              <input
                type="text"
                matInput
                fullWidth
                status="basic"
                placeholder="Domain"
                [(ngModel)]="termDomain"
                [ngModelOptions]="{ standalone: true }"
              />
            </mat-form-field>

            <mat-form-field appearance="outline" class="w-50 pr-2 pl-2">
              <mat-label>Definition of done</mat-label>
              <input
                type="text"
                matInput
                fullWidth
                placeholder="Definition of done"
                [(ngModel)]="termDOD"
                [ngModelOptions]="{ standalone: true }"
              />
            </mat-form-field>
            <div class="row mt-2">
              <div
                class="col-md-4 ml-auto d-flex align-items-center justify-content-end"
              >
                <button mat-stroked-button color="primary" class="mx-1">
                  Cancel
                </button>
                <button
                  mat-flat-button
                  mat-button
                  class="bg-primary text-white mx-1"
                  color="primary"
                  (click)="updateTerm(item)"
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
          <mat-icon
            *ngIf="item?.is_recomended"
            matTooltip="Recommended"
            matTooltipClass="example-tooltip"
            style="margin-top: -8px; color: rgb(255, 184, 5)"
          >
            star
          </mat-icon>
        </div>
        <div *ngIf="fromPage === 'toe'" class="comment-input-sec">
          <div style="font-size: 14px; margin-top: 5px">
            <div class="row">
              <div class="col-md-6">
                <div class="row" *ngIf="item?.function">
                  <div class="col-md-4"><b>Function :</b></div>
                  <div class="col-md-8">{{ item?.function?.display_name }}</div>
                </div>
                <div class="row" *ngIf="item?.fulfilment_by">
                  <div class="col-md-4"><b>Fulfilled By :</b></div>
                  <div class="col-md-8">{{ item?.fulfilment_by }}</div>
                </div>
                <div class="row" *ngIf="item?.evidence">
                  <div class="col-md-4"><b>Evidence :</b></div>
                  <div class="col-md-6">{{ item?.evidence?.display_name }}</div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row" *ngIf="item?.domain">
                  <div class="col-md-4"><b>Domain :</b></div>
                  <div class="col-md-8">{{ item?.domain }}</div>
                </div>
                <div class="row" *ngIf="item?.defination_of_done">
                  <div class="col-md-4"><b>Definition of done :</b></div>
                  <div class="col-md-8">{{ item?.defination_of_done }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <!-- <label>Context</label> -->
        <div
          *ngIf="
            fromPage == 'toe' && item.id && item.selected && captureContext
          "
        >
          <div class="row terms-fields">
            <div class="row">
              <div class="col-md-12">
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label>Context</mat-label>
                  <input
                    matInput
                    [value]="item?.context ? item.context : ''"
                    (click)="$event.stopPropagation()"
                    (keyup)="emitCommentValue($event, item, 'comments')"
                    placeholder="Context"
                  />
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label>Frequency</mat-label>
                  <mat-select
                    (click)="$event.stopPropagation()"
                    [(ngModel)]="item.frequency"
                    (selectionChange)="
                      emitCommentValue($event, item, 'frequency')
                    "
                  >
                    <mat-option
                      *ngFor="let fun of frequencyList"
                      [value]="fun.id"
                      >{{ fun?.display_name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label>Due Date</mat-label>
                  <input
                    matInput
                    [matDatepicker]="picker"
                    readonly
                    class="fw-500"
                    [value]="item.due_date"
                    (dateChange)="emitCommentValue($event, item, 'due_date')"
                    (click)="$event.stopPropagation(); picker.open()"
                  />
                  <mat-datepicker-toggle matSuffix [for]="picker">
                  </mat-datepicker-toggle>
                  <mat-datepicker #picker> </mat-datepicker>
                </mat-form-field>
              </div>
            </div>
          </div>
          <!-- Project metrics and evidence -->
          <div class="d-flex justify-content-end">
            <button
              mat-button
              mat-flat-button
              class="bg-primary text-whtie mr-4"
              (click)="onClickMetrics(item, MetricsPopUp)"
              [disabled]="iframeEmbedded"
              color="primary"
            >
              Metrics
            </button>
            <button
              mat-button
              mat-flat-button
              class="bg-primary text-whtie"
              (click)="onClickEvidence(item, EvidencePopUp)"
              color="primary"
              [disabled]="iframeEmbedded"
            >
              Evidence
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Metrics Update -->
<ng-template #MetricsPopUp>
  <div class="modal-dialog m-0" style="max-width: 100%">
    <div class="modal-content" style="border: none">
      <app-modal-header-common [title]="'Metrics Update'">
      </app-modal-header-common>
      <div class="modal-body p-0">
        <div class="saw">
          <div class="d-flex mb-2" style="align-items: center">
            <span class="sjec obj">Category: </span>
            <p class="mb-0">{{ termDetails?.category }}</p>
          </div>
          <div class="d-flex mb-2" style="align-items: center">
            <span class="sjec krq">Sub Category: </span>
            <p class="mb-0">{{ termDetails?.subcategory }}</p>
          </div>
          <div class="d-flex mb-2" style="align-items: center">
            <span class="sjec gol">Term: </span>
            <p class="mb-0">{{ termDetails?.name }}</p>
          </div>
          <div class="d-flex mb-2" style="align-items: center">
            <span class="sjec gol" style="background: #0f7b68">Metrics: </span>
            <mat-form-field style="font-size: 12px">
              <mat-label></mat-label>
              <mat-select
                [(ngModel)]="selectedMetric"
                (selectionChange)="selectedMatricEvent($event)"
              >
                <mat-option>Select Metric</mat-option>
                <mat-option
                  *ngFor="let mos of termDetails?.instancemetrics"
                  value="{{ mos.id }}"
                  >{{ mos?.instancemetrics__name }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
          <div
            class="d-flex mb-2"
            style="align-items: center"
            *ngIf="selectedMetricObj"
          >
            <span class="sjec gol" style="background: #0f7b68">Year: </span>
            <mat-form-field style="font-size: 12px">
              <mat-label></mat-label>
              <mat-select
                [(ngModel)]="selectedPeriod"
                (selectionChange)="selectedMatricYear()"
              >
                <mat-option>Select Period</mat-option>
                <mat-option value="2024">2024</mat-option>
                <mat-option value="2023">2023</mat-option>
                <mat-option value="2022">2022</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div
            *ngIf="selectedMetricObj && selectedPeriod"
            class="custom-table table-responsive p-4 pt-0"
            style="height: auto"
          >
            <div class="d-flex justify-content-between">
              <span>Target Value : {{ selectedMetricObj?.target_value }} </span>
              <button
                type="submit"
                class="btn btn-primary ml-2 mb-2"
                (click)="matricTarVal()"
              >
                Submit
              </button>
            </div>
            <table class="table table-bordered text-center">
              <thead style="position: sticky; top: 0; z-index: 1">
                <tr>
                  <th class="fixed-header text-center" scope="col">Month</th>
                  <th class="fixed-header text-center" scope="col">Value</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let m of monthList; let i = index">
                  <td>{{ m.month }}</td>
                  <td style="padding: 5px">
                    <div class="d-flex justify-content-center" *ngIf="m.edit">
                      <input
                        type="text"
                        [(ngModel)]="m.value"
                        class="form-control"
                        placeholder="Enter Number"
                        style="font-size: 12px; width: 100px"
                      />
                    </div>
                    <div class="d-flex justify-content-between" *ngIf="!m.edit">
                      <div></div>
                      <span>{{ m?.value || 0 }}</span>
                      <i class="fa fa-edit" (click)="editColum(i)"></i>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="modal-footer border-0 text-right">
        <button (click)="closeDialog()" mat-flat-button color="secondary">
          Close
        </button>
      </div>
    </div>
  </div>
</ng-template>
<!-- Metrics Update End -->
<!-- Evidence Update -->
<ng-template #EvidencePopUp>
  <div class="modal-dialog m-0" style="max-width: 100%">
    <div class="modal-content" style="border: none">
      <app-modal-header-common [title]="'Evidence Update'">
      </app-modal-header-common>
      <div class="modal-body p-0">
        <div class="saw">
          <div class="d-flex mb-2" style="align-items: center">
            <span class="sjec obj">Evidence: </span>
            <p class="mb-0">{{ termDetails?.evidence?.display_name }}</p>
          </div>
          <div class="d-flex mb-2" style="align-items: center">
            <span class="sjec gol" style="background: #0f7b68"
              >Compliance:
            </span>
            <mat-form-field style="font-size: 12px">
              <mat-label></mat-label>
              <mat-select [(ngModel)]="selectedCompliance">
                <mat-option>Select Compliance</mat-option>
                <mat-option value="Met">Met</mat-option>
                <mat-option value="NotMet">Not Met</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="selectedCompliance == 'Met'">
            <div class="d-flex">
              <mat-form-field class="w-25">
                <mat-label>Date</mat-label>
                <input
                  matInput
                  [(ngModel)]="selectedDate"
                  [matDatepicker]="picker"
                  readonly
                  class="fw-500"
                  (click)="picker.open()"
                />
                <mat-datepicker-toggle matSuffix [for]="picker">
                </mat-datepicker-toggle>
                <mat-datepicker #picker> </mat-datepicker>
              </mat-form-field>
              <mat-form-field class="w-25 ml-4">
                <mat-label>Owner</mat-label>
                <mat-select [(ngModel)]="selectedOwner">
                  <mat-option *ngFor="let user of userList" [value]="user.id"
                    >{{ user?.first_name }} {{ user?.last_name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
              <mat-form-field class="ml-4">
                <mat-label>Comment</mat-label>
                <input
                  matInput
                  [(ngModel)]="selectedComment"
                  placeholder="Comment"
                />
              </mat-form-field>
            </div>
            <div class="d-flex justify-content-between">
              <div>
                <mat-label style="font-size: 14px">Upload Document *</mat-label
                ><br />
                <button mat-icon-button color="success">
                  <input
                    hidden
                    #fileInput
                    type="file"
                    id="file"
                    (change)="onFileSelected($event)"
                  />
                  <mat-icon (click)="fileInput.click()">cloud_upload</mat-icon>
                </button>
                <span>{{ documentFilename }}</span>
              </div>
            </div>
            <div class="float-right">
              <button
                type="submit"
                class="btn btn-primary ml-2 mb-2"
                (click)="saveEvidence()"
                [disabled]="iframeEmbedded"
              >
                Submit
              </button>
            </div>
          </div>
          <div class="custom-table table-responsive pt-4" style="height: auto">
            <h6>Summary</h6>
            <app-loading *ngIf="loadingEvidence"></app-loading>
            <table
              class="table table-bordered text-center"
              [hidden]="loadingEvidence"
            >
              <thead style="position: sticky; top: 0; z-index: 1">
                <tr>
                  <th class="fixed-header text-center" scope="col">Date</th>
                  <th class="fixed-header text-center" scope="col">Owner</th>
                  <th class="fixed-header text-center" scope="col">Comment</th>
                  <th class="fixed-header text-center" scope="col">Evidence</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let det of termEvidenceList">
                  <td>{{ det?.date }}</td>
                  <td>
                    {{ det?.owner?.first_name }} {{ det?.owner?.last_name }}
                  </td>
                  <td>{{ det?.comment }}</td>
                  <td>
                    <a href="{{ det.url }}">{{ det?.file_name }}</a>
                  </td>
                </tr>
                <tr *ngIf="termEvidenceList?.length == 0">
                  <td colspan="4">No historical data available</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="modal-footer border-0 text-right">
        <button (click)="closeDialog()" mat-flat-button color="secondary">
          Close
        </button>
      </div>
    </div>
  </div>
</ng-template>
<!--Evidence Update end-->
