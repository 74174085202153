import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "dateAgo",
  pure: true,
})
export class DateAgoPipe implements PipeTransform {
  intervals: any;

  transform(value: any, _args?: any): any {
    const justNowInterval = 29;
    if (value) {
      const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
      if (seconds < justNowInterval) {
        return "Just now";
      }
      this.intervals = {
        year: 31536000,
        month: 2592000,
        week: 604800,
        day: 86400,
        hour: 3600,
        minute: 60,
        second: 1,
      };
      for (const [interval, val] of Object.entries(this.intervals)) {
        const numericValue = val as number;
        const counter = Math.floor(seconds / numericValue);
        if (counter > 0) {
          let pluralSuffix = "";
          if (counter !== 1) {
            pluralSuffix = "s";
          }
          return `${counter} ${interval}${pluralSuffix} ago`;
        }
      }
    }
    return value;
  }
}
