@Pipe({
  name: "formGroupNameScope",
})
export class FormGroupNameScopePipe {
  transform(item: any, stepper: any, arr: any) {
    let formGroupNameIndex = 1000000;
    try {
      arr?.controls?.forEach((res: any, index: any) => {
        if (
          res?.controls?.uuid_name?.value === item?.scope_uuid &&
          res?.controls?.category?.value === stepper?.display_name
        ) {
          formGroupNameIndex = index;
          throw BreakException;
        }
      });
    } catch (e) {
      if (e !== BreakException) {
        throw e;
      }
    }
    return formGroupNameIndex;
  }
}

import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  Pipe,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxPermissionsService } from "ngx-permissions";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { BreadcrumbService } from "src/app/shared/services/breadcrumb.service";
import { DataService } from "src/app/shared/services/data.service";
import { ProgramService } from "src/app/shared/services/program.service";
import textConfiguration from "src/assets/static-text-configuration.json";
import { MessageService } from "../../services/message.service";
import _ from "lodash";
import { LoggingService } from "src/app/logging.service";
import { AuthService } from "../../services/auth.service";
import { ProjectService } from "../../services/project.service";
import { ModalComponent } from "../modal/modal.component";
const BreakException = {};
@Component({
  selector: "app-shared-scope",
  templateUrl: "./scope.component.html",
  styleUrls: ["./scope.component.scss"],
  encapsulation: ViewEncapsulation.Emulated,
  providers: [FormGroupNameScopePipe],
})
export class SharedScopeComponent implements OnInit {
  opportunityText: any = (textConfiguration as any).opportunity;
  buttonsText: any = (textConfiguration as any).buttons;
  modalPopUpText: any = (textConfiguration as any).modal_popup;
  rfxText: any = (textConfiguration as any).rfx;
  @Input() entity: any;
  title: string;
  info: string;
  warningText = "Unexpected value";
  @ViewChild("stepper") stepper: any;
  stepStates: any = [];
  opportunityId: any;
  initalLoad = false;
  side = false;
  side1 = false;
  searchText = "";
  search = false;
  showAddField: boolean;
  scopeItems: any;
  allscopeItems: any;
  allcustomScopeItems: any;
  newScopeItems: any;
  textContents = {
    searchText: "Parameter searched is not found in the list",
  };
  sideHelp = true;
  selectedItems: any[] = [];
  updateItems: any;
  tips = true;
  scopeList: any[] = [];
  parameter: any;
  stepperCategory: any[] = [];
  cloneStepperCategory: any[] = [];
  cloneCustomCategory: any[] = [];
  sideParam = false;
  newParameterItems: any;
  selectedParameterItems: any[] = [];
  parameterForm: any;
  stepperIndex = 0;
  parameteClick: any;
  addedParameterItems: any;
  sideParameter: any;
  submitted = false;
  newArr: any;
  parameterIndex: any;
  addColumnIndex = 0;
  showNext = false;
  showUpload = false;
  showAddCustom = false;
  showCustomOnly = false;
  showAddCustomParameter = false;
  CusParameterName: any;
  cusScopeDescription: any;
  cusScopeTitle: any;
  customScopeParameter: any;
  newCustomParameterItems: any;
  categoryName: any;
  customCategory: any;
  showCustomScope = false;
  customScopeItems: any;
  sideScopeParameter: any;
  newCustomScopeItems: any;
  newCustomCategory: any;
  showCustomButton = true;
  selectedCategoryItems: any[] = [];
  customTab = false;
  selectedScope: any[] = [];
  scopeIndex: any;
  totalFiles: any[] = [];
  matTab = true;
  opportunityInfo: any;
  public tabIndex1 = 0;
  itemCategory: any;
  name: any;
  scopeArray: any[] = [];
  filterPayload: any[] = [];
  patchResponse: any;
  newScope: any;
  newStepperCategory: any[] = [];
  index = 0;
  @ViewChild("tabGroup") tabGroup: any;
  scopeArrayToFilter: any[] = [];
  categoryArrayToFilter: any[] = [];
  patchResponseItem: any;
  inc = 0;
  maxChars = 100;
  parameterArrayToFilter: any[] = [];
  scopeData: any;
  disableFields = false;
  stepperCategoryInitial: any[] = [];
  selectedCategoryItemsInitial: any[] = [];
  isRedirectFrom = false;
  projectID: any = "";
  hasChange = false;
  opportunityData: any;
  projectData: any;
  tagDetails: any;
  scopeCreateForm: any;
  scopeDescForm: any;
  scopeCustomCreateForm: any;
  breadcrumb: any;
  user: any;
  iframeEmbedded = false;
  showSimilarProjects = false;
  similarProjects: any = [];
  scopeDescWordCount = 0;
  showTags = false;
  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly ps: ProgramService,
    private readonly fb: FormBuilder,
    public dialog: MatDialog,
    private readonly toastrService: ToastrService,
    private readonly spinner: NgxSpinnerService,
    private readonly permissionsService: NgxPermissionsService,
    private readonly ref: ChangeDetectorRef,
    private readonly dataService: DataService,
    private readonly breadCrumService: BreadcrumbService,
    private readonly messageService: MessageService,
    private readonly loggingService: LoggingService,
    private readonly authService: AuthService,
    private readonly projectService: ProjectService
  ) {
    this.dataService.getScopeData().subscribe(() => {});
  }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem("permission") ?? "{}");
    this.authService.tagsEnabled.subscribe((res: any) => {
      this.showTags = res;
    });
    const perm: any = JSON.parse(localStorage.getItem("permission") ?? "{}");
    this.permissionsService.loadPermissions(perm.permissions);
    const timeOutTime = 1100;
    setTimeout(() => {
      this.showNext = true;
    }, timeOutTime);

    this.breadCrumService.breadcrumbs$.subscribe((bc: any) => {
      this.breadcrumb = bc;
    });

    this.createForm();
    this.messageService.getIframeData().subscribe((res: any) => {
      this.iframeEmbedded = typeof res != "object";
    });
    if (this.entity === "opportunity") {
      this.route.parent?.parent?.params.subscribe((res: any) => {
        this.opportunityId = atob(res.id);
        this.getOpportunityById();
      });

      const oppInfo = localStorage.getItem("opportunity-info");
      this.opportunityInfo = {};
      if (typeof oppInfo === "string") {
        this.opportunityInfo = JSON.parse(oppInfo);
      }
      this.title = this.opportunityText.scope;
      const infoParts = [
        this.opportunityInfo?.opportunity_name,
        this.opportunityInfo?.opportunity_number,
        this.opportunityInfo?.opportunity_type,
        this.opportunityInfo?.portfolio,
        this.opportunityInfo?.subportfolio,
        this.opportunityInfo?.program,
        this.opportunityInfo?.owner_name,
      ];
      this.info = infoParts.join(" | ");
    } else if (this.entity === "project") {
      this.route.parent?.params.subscribe((res: any) => {
        this.projectID = atob(res.id);
        this.getProjectById();
      });
    } else {
      this.loggingService.warn(this.warningText);
    }
  }

  createForm() {
    this.parameterForm = this.fb.group({
      value: this.fb.array([]),
    });
    this.scopeDescForm = new FormGroup({
      scope_desc: new FormControl("", [Validators.required]),
    });
    this.scopeCreateForm = this.fb.array([]);
    this.scopeCustomCreateForm = this.fb.array([]);
  }

  get parameterValueFormArray() {
    return this.parameterForm.get("value") as FormArray;
  }

  parameterValueFormArrayControls(i: any): FormGroup {
    return this.parameterValueFormArray.controls[i] as FormGroup;
  }

  createNewFormGroup() {
    return this.fb.group({});
  }

  handleScopeInput(event: Event) {
    const inputValue = (event.target as HTMLTextAreaElement).value;
    this.searchSimilarProject(inputValue);
  }

  searchSimilarProject(inputValue: string) {
    if (this.entity === "project" && !this.iframeEmbedded) {
      const inputWordCount = inputValue.split(" ").length;
      const WORD_COUNT_LIMIT = 5;
      if (
        inputWordCount > WORD_COUNT_LIMIT &&
        inputWordCount !== this.scopeDescWordCount
      ) {
        this.scopeDescWordCount = inputValue.split(" ").length;
        this.projectService
          .getSimilarProjectWithSope(inputValue)
          .subscribe((res: any) => {
            this.similarProjects = res.filter((item: any) => {
              return item.id !== parseInt(this.projectID, 10);
            });
            this.showSimilarProjects = this.similarProjects.length > 0;
          });
      }
    }
  }

  openProjects(res: any) {
    if (res.has_access === false) {
      this.toastrService.error("You don't have access to this project");
      return;
    }
    const oldItem: any = localStorage.getItem("program");
    const newitem = {
      project_id: res.id,
      program_name: res.program.name,
      program_id: res.program.id,
      subportfolio_name: res.portfolio.subportfolio.name,
      subportfolio_id: res.portfolio.subportfolio.id,
      portfolio_name: res.portfolio.name,
      portfolio_id: res.portfolio.id,
    };
    localStorage.setItem("program", JSON.stringify(newitem));

    this.dialog.closeAll();
    const dialog = this.dialog.open(ModalComponent, {
      hasBackdrop: true,
      disableClose: false,
      width: "80vw",
      data: {
        opportunity: res.id,
      },
    });
    dialog.componentInstance.type = { name: "projectpop" };
    dialog.afterClosed().subscribe((data: any) => {
      localStorage.setItem("program", oldItem);
      if (data) {
        window.location.href = data.replace("embedded=true", "");
      }
    });
  }

  hideSimilarProjects() {
    const HIDE_PROJECT_TIME = 200;
    setTimeout(() => {
      this.showSimilarProjects = false;
      this.similarProjects = [];
      this.scopeDescWordCount = 0;
    }, HIDE_PROJECT_TIME);
  }

  async getScopeList() {
    this.spinner.show();
    await this.getOpportunityScope();
    this.ps.getOpportunityFormData("opportunity_scopes").subscribe(
      (res: any) => {
        this.spinner.hide();

        this.scopeList = res.records;
        this.modifiedScopeData(this.scopeList);
      },
      (error) => {
        this.spinner.hide();
        throw error;
      }
    );
  }

  async getProjectScopeList() {
    this.spinner.show();
    this.ps.getOpportunityFormData("opportunity_scopes").subscribe(
      async (res: any) => {
        this.spinner.hide();

        this.scopeList = res.records;
        await this.getProjectScope();
        this.modifiedScopeData(this.scopeList);
      },
      (error) => {
        this.spinner.hide();
        throw error;
      }
    );
  }

  modifiedScopeData(scopeList?: any) {
    scopeList.map((i: any) => {
      if (i.field_name === "scope") {
        this.allscopeItems = i.field_values;
      }
      if (i.field_name === "scope_parameter") {
        this.parameter = i.field_values;
      }
      if (i.field_name === "scope_category") {
        this.scopeCreateForm.reset();
        i.field_values
          .filter((j: any) => !j.is_custom)
          .forEach((element: any) => {
            element.category = [];
            this.scopeCreateForm.push(this.fb.control(element));
          });

        this.scopeCustomCreateForm.reset();
        i.field_values
          .filter((j: any) => j.is_custom)
          .forEach((element: any) => {
            element.category = [];
            this.scopeCustomCreateForm.push(this.fb.control(element));
          });

        if (this.entity === "opportunity") {
          this.patchapiData();
        } else if (this.entity === "project") {
          this.patchapiProjectData();
        } else {
          this.loggingService.warn(this.warningText);
        }
        this.ref.detectChanges();
      }
    });
  }

  patchapiData() {
    if (this.opportunityData && this.patchResponseItem) {
      this.opportunityData.opportunity_scope = this.patchResponseItem;
      this.patchResponse = this.opportunityData.opportunity_scope;

      this.ps.setStatusValue(this.opportunityData?.opportunity_scope?.status);
      this.constructPatchData(this.opportunityData);
    }
  }

  patchapiProjectData() {
    if (this.projectData && this.patchResponseItem) {
      this.projectData.project_scope = this.patchResponseItem;
      this.patchResponse = this.projectData.project_scope;

      this.ps.setStatusValue(this.projectData?.project_scope?.status);
      this.constructProjectPatchData(this.projectData);
    }
  }

  selectedStepper(e: any) {
    this.stepper.steps.forEach((step: any, index: number) => {
      if (index === e.selectedIndex) {
        this.stepStates[index] = "edit";
      } else if (!step.completed && index !== e.selectedIndex) {
        this.stepStates[index] = "number";
      } else if (step.completed && index !== e.selectedIndex) {
        this.stepStates[index] = "done";
      } else {
        this.loggingService.warn(this.warningText);
      }
    });
    this.stepperIndex = e.selectedIndex;
    this.showNext = false;
    this.sideParam = false;
    if (!this.disableFields) {
      this.side = true;
    }
    const timeoutTime = 550;
    setTimeout(() => {
      this.showNext = true;
    }, timeoutTime);
  }

  skipStepper() {
    const tabCount = 2;
    this.tabIndex1 = (this.tabIndex1 + 1) % tabCount;
    this.updateScope();
    this.ps.getSingleOpportunityById(this.opportunityId).subscribe((res) => {
      this.ps.setStatusValue(res?.status);
      this.router.navigate([
        `rfx/${btoa(this.opportunityId)}/opportunity/terms`,
      ]);
    });
  }

  goToSetup() {
    this.ps.setValue(0);
    this.ps.getSingleOpportunityById(this.opportunityId).subscribe((res) => {
      this.ps.setStatusValue(res?.status);
      this.router.navigate([
        `rfx/${btoa(this.opportunityId)}/opportunity/setup`,
      ]);
    });
  }

  nextScope(stepper: any) {
    this.scopeDescForm.markAllAsTouched();
    if (this.scopeDescForm.invalid) {
      return;
    }
    if (!this.disableFields) {
      this.side = true;
    }
    stepper.next();
  }

  uploadBar() {
    this.showUpload = true;
    this.showCustomOnly = false;
    this.sideHelp = false;
    this.side = false;
    this.sideParam = false;
  }
  clearCategoryField() {
    this.categoryName = "";
  }

  clearCustomParam() {
    this.CusParameterName = "";
  }

  showCustom() {
    this.newCustomCategory = JSON.stringify(this.customCategory);
    this.showCustomOnly = true;
    this.sideHelp = false;
    this.side = false;
    this.showAddCustom = false;
    this.showCustomButton = false;
    this.showUpload = false;
    this.sideParam = false;
  }

  closeCategory() {
    this.dialog.closeAll();
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  goToList() {
    this.router.navigate(["program"]);
  }

  getOpportunityById() {
    this.ps.getOppStatus(this.opportunityId).subscribe(
      (res: any) => {
        this.opportunityData = res;
        this.getScopeList();
        const breadCrumbFirst: any = [this.breadcrumb[0]];
        const breadCrumbLast: any = [
          this.breadcrumb[this.breadcrumb.length - 1],
        ];
        const breadCrumb = [
          {
            label: this.opportunityInfo.portfolio,
            url: "/program",
            queryParams: { portfolio: this.opportunityInfo.portfolio_id },
          },
          {
            label: this.opportunityInfo.subportfolio,
            url: "/program",
            queryParams: { subportfolio: this.opportunityInfo.subportfolio_id },
          },
          {
            label: this.opportunityInfo.program,
            url: "/program",
            queryParams: { program: this.opportunityInfo.program_id },
          },
        ];
        breadCrumbFirst.push(...breadCrumb, ...breadCrumbLast);

        this.breadCrumService.setBreadcrumb(breadCrumbFirst);
      },
      (error) => {
        throw error;
      }
    );
  }

  getProjectById() {
    this.ps.getProject(this.projectID).subscribe(
      (res: any) => {
        this.projectData = res;
        this.getProjectScopeList();
      },
      (error) => {
        throw error;
      }
    );
  }

  processCategory(category: any): void {
    category?.scope?.forEach((element: any) => {
      this.addScopeToFilter(element);
      this.addParametersToFilter(element.parameter);
    });
  }

  private addScopeToFilter(element: any): void {
    this.scopeArrayToFilter.push(element.uuid);
  }

  private addParametersToFilter(parameters: any[]): void {
    parameters.forEach((p: any) => {
      this.parameterArrayToFilter.push(p.id);
    });
    this.parameterArrayToFilter = _.uniqWith(
      [...this.parameterArrayToFilter],
      _.isEqual
    );
  }

  getOpportunityScope() {
    return new Promise((resolve, reject) => {
      this.ps.getOpportunityScopeData(this.opportunityId).subscribe(
        (res: any) => {
          this.tagDetails = {
            id: res.id,
            tag_ids: res.tag_ids,
          };
          this.patchResponseItem = res.opportunity_scope;

          if (
            res.status.slug === "opportunity_evaluating" ||
            res.status.slug === "opportunity_sow_signed" ||
            res.status.slug === "opportunity_cancelled"
          ) {
            this.disableFields = true;
            this.side = false;
            this.parameterForm.disable();
            this.parameterForm.get("value").disable();
            this.parameterForm
              .get("value")
              .controls.forEach((_control: any, index: number) => {
                this.parameterForm.get("value").controls[index].disable();
              });
          }
          this.scopeDescForm.get("scope_desc").setValue(res?.scope_description);

          this.patchResponseItem?.forEach((el: any) => {
            if (el?.category?.is_custom) {
              this.categoryArrayToFilter.push(el?.category.id);
            }
            this.processCategory(el?.category);
          });
          resolve("sdf");
        },
        (error) => {
          reject(new Error(error));
        }
      );
    });
  }

  getProjectScope() {
    return new Promise((resolve, reject) => {
      this.ps.getProjectScopeData(this.projectID).subscribe(
        (res: any) => {
          this.tagDetails = {
            id: res.id,
            tag_ids: res.tag_ids,
          };
          this.patchResponseItem = res.scopes;
          this.scopeDescForm.get("scope_desc").setValue(res?.scope_description);

          this.patchResponseItem?.forEach((el: any) => {
            if (el?.category?.is_custom) {
              this.categoryArrayToFilter.push(el?.category.id);
            }
            this.processCategory(el?.category);
          });
          resolve("sdf");
        },
        (error) => {
          reject(new Error(error));
        }
      );
    });
  }

  constructPatchData(res: any) {
    const recommened = res.opportunity_scope.filter(
      (e: any) => e.category?.is_custom === false
    );
    recommened.forEach((element: any) => {
      const index = this.scopeCreateForm.value.findIndex(
        (e: any) => e.id === element.category.id
      );
      this.scopeCreateForm.controls[index].value.category = element.category;
    });
  }

  constructProjectPatchData(res: any) {
    const recommened = res.project_scope.filter(
      (e: any) => e.category?.is_custom === false
    );
    recommened.forEach((element: any) => {
      const index = this.scopeCreateForm.value.findIndex(
        (e: any) => e.id === element.category.id
      );
      this.scopeCreateForm.controls[index].value.category = element.category;
    });
  }

  getScope(index: any, scopeIndx: any) {
    if (this.allscopeItems) {
      let filteringArray: Array<any> = [];
      for (const element of this.scopeCreateForm.controls) {
        if (element.value.category.scope) {
          filteringArray = filteringArray.concat(element.value.category.scope);
        }
      }
      let filteredArray = this.parameter;
      if (filteringArray.length > 0) {
        filteredArray = this.allscopeItems.filter(function (arrayEl: any) {
          return (
            filteringArray.filter(function (chl: any) {
              return chl.id === arrayEl.id;
            }).length === 0
          );
        });
      }
      if (
        typeof this.scopeCreateForm.controls[index].value.category.scope[
          scopeIndx
        ].id === "string"
      ) {
        filteredArray = filteredArray.filter(
          (f: any) =>
            f.scope_title
              .toLowerCase()
              .indexOf(
                this.scopeCreateForm.controls[index].value.category.scope[
                  scopeIndx
                ].id.toLowerCase()
              ) === 0
        );
      }
      return filteredArray;
    } else {
      return [];
    }
  }

  getScopeTitle(scopeId: any) {
    if (scopeId) {
      return this.allscopeItems.find((item: any) => item.id === scopeId)
        .scope_title;
    }
    return "";
  }

  searchScope(index: any, scopeIndx: any) {
    return this.getScope(index, scopeIndx);
  }

  getScopeDesc(id: any) {
    let desc = "";
    if (this.allscopeItems && id) {
      desc =
        this.allscopeItems[
          this.allscopeItems.findIndex((e: any) => e.id === id)
        ]?.scope_description;
    }
    return desc;
  }

  addScopeItems(index: any) {
    if (
      this.scopeCreateForm.controls[index].value.category.scope === undefined
    ) {
      this.scopeCreateForm.controls[index].value.category = {
        scope: [],
      };
    }
    this.scopeCreateForm.controls[index].value.category.scope.push({
      id: null,
      title: null,
      description: null,
      uuid: null,
      parameter: [],
    });
  }

  removeScope(index: any, i: any) {
    this.scopeCreateForm.controls[index].value.category.scope.splice(i, 1);
  }

  getParamterName(parameterId: any) {
    if (parameterId) {
      return this.parameter.find((item: any) => item.id === parameterId).name;
    }
    return "";
  }

  getParameter(index: any, scopeIndx: any, paramIndx: any) {
    if (this.parameter) {
      let filteringArray = [];
      if (
        this.scopeCreateForm.controls[index].value.category.scope[scopeIndx]
          .parameter
      ) {
        filteringArray =
          this.scopeCreateForm.controls[index].value.category.scope[scopeIndx]
            .parameter;
      }
      let filteredArray = this.parameter;
      if (filteringArray.length > 0) {
        filteredArray = this.parameter.filter(function (arrayEl: any) {
          return (
            filteringArray.filter(function (chl: any) {
              return chl.id === arrayEl.id;
            }).length === 0
          );
        });
      }
      if (
        typeof this.scopeCreateForm.controls[index].value.category.scope[
          scopeIndx
        ].parameter[paramIndx].id === "string"
      ) {
        filteredArray = filteredArray.filter(
          (f: any) =>
            f.name
              .toLowerCase()
              .indexOf(
                this.scopeCreateForm.controls[index].value.category.scope[
                  scopeIndx
                ].parameter[paramIndx].id.toLowerCase()
              ) === 0
        );
      }
      return filteredArray;
    } else {
      return [];
    }
  }

  searchParameter(index: any, scopeIndx: any, paramIndx: any) {
    return this.getParameter(index, scopeIndx, paramIndx);
  }

  addParameterItems(index: any, scopeIndx: any) {
    this.scopeCreateForm.controls[index].value.category.scope[
      scopeIndx
    ].parameter.push({
      id: null,
      name: null,
      scope_parameter_value: null,
    });
  }

  removeParameterItems(index: any, scopeIndx: any, paramIndx: any) {
    this.scopeCreateForm.controls[index].value.category.scope[
      scopeIndx
    ].parameter.splice(paramIndx, 1);
  }

  onselectparameter(event: any, index: any, i: any, j: any) {
    this.scopeCreateForm.controls[index].value.category.scope[i].parameter[j] =
      {
        ...this.parameter[
          this.parameter.findIndex((e: any) => e.id === event.option.value)
        ],
      };
    this.scopeCreateForm.controls[index].value.category.scope[i].parameter[
      j
    ].scope_parameter_value = null;
  }

  onselectscope(event: any, index: any, i: any) {
    this.scopeCreateForm.controls[index].value.category.scope[i].uuid =
      this.allscopeItems[
        this.allscopeItems.findIndex((e: any) => e.id === event.option.value)
      ].scope_uuid;
  }

  nextSection() {
    this.scopeDescForm.markAllAsTouched();
    if (this.scopeDescForm.invalid) {
      return;
    }
    this.skipStepper();
  }

  updateScope() {
    if (this.scopeDescForm.invalid) {
      return;
    }
    this.spinner.show();
    let scopeList: any = [];

    this.scopeCreateForm.value.forEach((element: any) => {
      const scope: any = [];

      if (element?.category?.scope?.length > 0) {
        element.category.scope.forEach((scpe: any) => {
          const parameter: any = [];

          if (scpe.parameter.length > 0) {
            scpe.parameter.forEach((param: any) => {
              parameter.push({
                scope_parameter: param.id,
                scope_parameter_value: param.scope_parameter_value,
              });
            });
          }
          scope.push({
            uuid: scpe.uuid,
            parameter,
          });
        });
      }

      scopeList.push({
        scope_category: element.id,
        scope,
      });
    });

    scopeList = {
      categories: scopeList,
      description: this.scopeDescForm.controls["scope_desc"].value,
    };
    this.ps
      .updateOpportunity(this.opportunityId, { scope_data: scopeList })
      .subscribe(
        () => {
          this.spinner.hide();
          return true;
        },
        (error) => {
          this.spinner.hide();
          throw error;
        }
      );
  }

  updateCustomCategory() {
    if (this.parameterForm.invalid) {
      this.toastrService.error("Please fill all the fields");
    } else {
      this.updateScope();
    }
  }
  onClickEdit(data: any, modal: any) {
    this.scopeData = data;
    this.cusScopeTitle = data.title || data.scope_title;
    this.cusScopeDescription = data.description || data.scope_description;

    this.dialog.open(modal);
  }
  updateScopeData() {
    this.stepperCategory[this.stepperIndex].selectedItems.filter(
      (ele: any) => ele.scope_uuid !== this.scopeData.scope_uuid
    );
  }

  saveSelection() {
    this.spinner.show();
    let scopeList: any = [];
    this.scopeCreateForm.value.forEach((element: any) => {
      const scope: any = [];
      if (element?.category?.scope?.length > 0) {
        element.category.scope.forEach((scpe: any) => {
          const parameter: any = [];

          if (scpe.parameter.length > 0) {
            scpe.parameter.forEach((param: any) => {
              parameter.push({
                scope_parameter: param.id,
                scope_parameter_value: param.scope_parameter_value,
              });
            });
          }
          scope.push({
            uuid: scpe.uuid,
            parameter,
          });
        });
      }
      scopeList.push({
        scope_category: element.id,
        scope,
      });
    });
    scopeList = {
      categories: scopeList,
      description: this.scopeDescForm.controls["scope_desc"].value,
    };
    this.ps
      .updateProjectScope(this.projectID, { scope_data: scopeList })
      .subscribe(
        () => {
          this.spinner.hide();
          this.toastrService.success("Scope added successfully");
          return true;
        },
        (error) => {
          this.spinner.hide();
          throw error;
        }
      );
  }

  clickPrevious() {
    this.router.navigate([`view/${this.projectID}/overview`]);
  }

  getTagObject(id: any) {
    let entityType = "project";
    if (this.entity === "opportunity") {
      entityType = "opportunity";
    }
    return {
      entity_id: id,
      entity_type: entityType,
    };
  }
}
