import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "replaceCharacter",
})
export class ReplaceCharacterPipe implements PipeTransform {
  transform(stringVal: string, _fromChar: string, _tochar: string): string {
    if (stringVal) {
      stringVal = stringVal.replace(/_+/g, " ");
    }
    return stringVal;
  }
}
