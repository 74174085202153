import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-label-input",
  templateUrl: "./label-input.component.html",
  styleUrls: ["./label-input.component.scss"],
})
export class LabelInputComponent {
  @Input() inputString: any;
  @Input() inputType: any;
  @Input() placeholder: string;
  @Input() editable = true;
  @Output() refreshData = new EventEmitter<string>();
  oldData: any;

  editMode = false;

  constructor() {}

  updateData() {
    this.refreshData.emit(this.inputString);
    this.editMode = false;
  }

  cancelEdit() {
    this.inputString = this.oldData;
    this.editMode = false;
  }
}
