import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Calculator } from "src/app/shared/components/scorecard-matrix/scorecard/calculator";
import { ScorecardService } from "src/app/shared/services/scorecard.service";

@Component({
  selector: "app-what-if",
  templateUrl: "./what-if.component.html",
  styleUrls: ["./what-if.component.scss"],
})
export class WhatIfComponent implements OnInit {
  whatIfData: any = null;
  defaultWhatIfItems: any;
  whatIfItems: any;
  updatedValues: any = {};
  sliderValue = 0;
  currentMetricId: any;
  metricsList: any = [];
  projectId: any;

  constructor(
    public dialogRef: MatDialogRef<WhatIfComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly scorecardService: ScorecardService
  ) {}

  ngOnInit(): void {
    this.currentMetricId = this.data?.metricId;
    this.projectId = this.data?.projectId;
    this.getMetricsList();
  }

  getMetricsList() {
    this.scorecardService.getMetricsList().subscribe((resp: any) => {
      this.metricsList = resp;
      if (!this.currentMetricId) {
        this.currentMetricId = resp[0].id;
      }
      this.getWhatIfInfo();
    });
  }

  getWhatIfInfo() {
    const metrictObj = this.metricsList.filter(
      (item: any) => item.id === this.currentMetricId
    );
    if (metrictObj.length >= 1 && metrictObj[0]?.model) {
      this.getModelWhatIfData(metrictObj[0]?.model?.id);
    } else {
      this.getDrivedWhatIfData();
    }
  }

  getModelWhatIfData(modelId: number) {
    let queryParams: any = {};

    if (this.projectId) {
      queryParams["project_id"] = this.projectId;
    }

    this.scorecardService
      .getWhatIfModelData(modelId, queryParams)
      .subscribe((resp: any) => {
        this.whatIfData = resp;
        this.defaultWhatIfItems = resp.default;
        this.whatIfItems = this.whatIfData.dependents;
        this.sliderValue = Math.floor(
          this.whatIfData.default[this.whatIfData.metric.name]
        );
      });
  }

  getDrivedWhatIfData() {
    this.scorecardService
      .getWhatIfData(this.currentMetricId)
      .subscribe((resp: any) => {
        this.whatIfData = resp[0];
        this.defaultWhatIfItems = resp[0].default;
        this.whatIfItems = this.whatIfData.dependents;
        this.sliderValue = Math.floor(
          this.whatIfData.default[this.whatIfData.metric.name]
        );
      });
  }

  increment() {
    this.sliderValue++;
    if (this.sliderValue > 100) {
      this.sliderValue = 100;
    }
    this.onInputChange();
  }

  decrement() {
    this.sliderValue--;
    if (this.sliderValue < 0) {
      this.sliderValue = 0;
    }
    this.onInputChange();
  }

  onInputChange() {
    if (this.whatIfData.model_parameter) {
      // For metrics with Model
      let queryParams: any = {
        metric_value: this.sliderValue,
      };

      if (this.projectId) {
        queryParams["project_id"] = this.projectId;
      }
      this.scorecardService
        .predictMetricModelReverse(this.whatIfData.id, queryParams)
        .subscribe((res: any) => {
          this.updatedValues = res;
        });
    } else {
      // For Drived metrics
      const calc = new Calculator(this.whatIfData);
      calc.calculate();
      calc.changeMetric(this.whatIfData.metric.name, this.sliderValue / 100);
      this.updatedValues = calc.values;
    }
  }

  onLinkedMetricInput(event: any, metricName: any) {
    this.updatedValues["new_" + metricName] = event.target.value;
  }

  predictMetrics() {
    if (this.whatIfData?.model_parameter) {
      let queryParams: any = {};
      Object.keys(this.updatedValues).forEach((item: any) => {
        // removing 'new_' from starting of metric name
        let paramsMetricName = item.slice(4);
        queryParams[paramsMetricName] = this.updatedValues[item];
      });
      if (this.projectId) {
        queryParams["project_id"] = this.projectId;
      }

      this.scorecardService
        .predictMetricModel(this.whatIfData.id, queryParams)
        .subscribe((res: any) => {
          this.sliderValue = res?.value;
        });
    }
  }

  dialogClose() {
    this.dialogRef.close();
  }
}
