import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule, Routes } from "@angular/router";
import { AuthLayoutComponent } from "./auth-layout/auth-layout.component";
import { AuthGuard } from "./shared/guard/auth.guard";

const routes: Routes = [
  {
    path: "",
    redirectTo: "auth",
    pathMatch: "full",
  },
  {
    path: "",
    // component: FullLayoutComponent,
    data: { breadcrumb: null },
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./pages/kepler/kepler.module").then((m) => m.KeplerModule),
      },
      {
        path: "rfx",
        data: { breadcrumb: "Opportunity Workbench" },
        loadChildren: () =>
          import("./pages/rfx/rfx.module").then((m) => m.RfxModule),
      },
      {
        path: "delivery",
        loadChildren: () =>
          import("../app/pages/delivery/delivery.module").then(
            (m) => m.DeliveryModule
          ),
      },
      {
        path: "getproposal",
        data: { breadcrumb: "Opportunities" },
        loadChildren: () =>
          import("../app/pages/get-proposal/get-proposal.module").then(
            (m) => m.GetProposalModule
          ),
      },
      {
        path: "toe",
        data: { breadcrumb: "Deal Structuring" },
        loadChildren: () =>
          import("../app/pages/toe/toe.module").then((m) => m.ToeModule),
      },
      {
        path: "vendor",
        data: { breadcrumb: "Proposal List" },
        loadChildren: () =>
          import("../app/pages/vendor/vendor.module").then(
            (m) => m.VendorModule
          ),
      },
      {
        path: "view",
        data: { breadcrumb: "Project Central" },
        loadChildren: () =>
          import(
            "../app/pages/delivery-functionality/delivery-functionality.module"
          ).then((m) => m.DeliveryFunctionalityModule),
      },
      {
        path: "toeUpload",
        data: { breadcrumb: "Deal Structuring" },
        loadChildren: () =>
          import("../app/pages/toe-upload/toe-upload.module").then(
            (m) => m.ToeUploadModule
          ),
      },
      {
        path: "balancedscorecard",
        data: { breadcrumb: "Kaleidoscope" },
        loadChildren: () =>
          import("../app/pages/balancescore/balancescore.module").then(
            (m) => m.BalancescoreModule
          ),
      },
      {
        path: "actiontracker",
        data: { breadcrumb: "Prism" },
        loadChildren: () =>
          import("../app/pages/action-tracker/action-tracker.module").then(
            (m) => m.ActionTrackerModule
          ),
      },
      {
        path: "incidentracker",
        data: { breadcrumb: "GRC Workbench" },
        loadChildren: () =>
          import("../app/pages/incident-tracker/incident-tracker.module").then(
            (m) => m.IncidentTrackerModule
          ),
      },
      {
        path: "okrconvergence",
        data: { breadcrumb: "Northstar" },
        loadChildren: () =>
          import("../app/pages/okr-convergence/okr-convergence.module").then(
            (m) => m.OkrConvergenceModule
          ),
      },
      {
        path: "riskconvergence",
        data: { breadcrumb: "Kaleidoscope" },
        loadChildren: () =>
          import("../app/pages/risk-convergence/risk-convergence.module").then(
            (m) => m.RiskConvergenceModule
          ),
      },
      {
        path: "workforce",
        data: { breadcrumb: "Kaleidoscope" },
        loadChildren: () =>
          import("./pages/work-force/work-force.module").then(
            (m) => m.WorkFlowModule
          ),
      },
      {
        path: "BVT",
        data: { breadcrumb: "Kaleidoscope" },
        loadChildren: () =>
          import("../app/pages/survey/survey.module").then(
            (m) => m.SurveyModule
          ),
      },
      {
        path: "rate",
        loadChildren: () =>
          import("../app/pages/rate/rate.module").then((m) => m.RateModule),
      },
      {
        path: "toemasterlist",
        data: { breadcrumb: "Prism" },
        loadChildren: () =>
          import("../app/pages/key-terms/key-terms.module").then(
            (m) => m.KeyTermsModule
          ),
      },
      {
        path: "challenge-workbench",
        data: { breadcrumb: "CoSInE" },
        loadChildren: () =>
          import(
            "../app/pages/crowdsource-hackathon/crowdsource-hackathon.module"
          ).then((m) => m.CrowdsourceHackathonModule),
      },
      {
        path: "ideapitch",
        data: { breadcrumb: "CoSInE" },
        loadChildren: () =>
          import("../app/pages/ideapitch/ideapitch.module").then(
            (m) => m.IdeaPitchModule
          ),
      },
      {
        path: "delivery-accelerator",
        data: { breadcrumb: "Confluence" },
        loadChildren: () =>
          import(
            "../app/pages/delivery-accelerator/delivery-accelerator.module"
          ).then((m) => m.DeliveryAcceleratorModule),
      },
      {
        path: "prospect-provider",
        data: { breadcrumb: "Provider" },
        loadChildren: () =>
          import(
            "../app/pages/prospect-provider/prospect-provider.module"
          ).then((m) => m.ProspectProviderModule),
      },
      {
        path: "prospect-provider-workbench",
        data: { breadcrumb: "Provider" },
        loadChildren: () =>
          import(
            "../app/pages/prospect-provider/prospect-provider.module"
          ).then((m) => m.ProspectProviderModule),
      },
      {
        path: "parade",
        data: { breadcrumb: "Confluence" },
        loadChildren: () =>
          import("../app/pages/parade/parade.module").then(
            (m) => m.ParadeModule
          ),
      },
      {
        path: "prospect-provider-workbench",
        loadChildren: () =>
          import(
            "../app/pages/prospect-provider-workbench/prospect-provider-workbench.module"
          ).then((m) => m.ProspectProviderWorkbenchModule),
      },
      {
        path: "vendor-master",
        data: { breadcrumb: "Vendor Master" },
        loadChildren: () =>
          import("../app/pages/vendor-master/vendor-master.module").then(
            (m) => m.VendorMasterModule
          ),
      },
      {
        path: "metrics-master",
        data: { breadcrumb: "Metrics Master" },
        loadChildren: () =>
          import("../app/pages/metrics-master/metrics-master.module").then(
            (m) => m.MetrcisMasterModule
          ),
      },
      {
        path: "application-master",
        data: { breadcrumb: "Application Master" },
        loadChildren: () =>
          import(
            "../app/pages/application-master/application-master.module"
          ).then((m) => m.ApplicationMasterModule),
      },
    ],
    canActivateChild: [AuthGuard],
  },
  {
    path: "auth",
    component: AuthLayoutComponent,
    // canActivate: [GuestGuard],
    //canDeactivate: [GuestGuard],
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./pages/auth/auth.module").then((m) => m.AuthModule),
      },
    ],
  },
  {
    path: "404",
    loadChildren: () =>
      import("../app/pages/pagenotfound/pagenotfound.module").then(
        (m) => m.PagenotfoundModule
      ),
  },
  {
    path: "**",
    redirectTo: "404",
  },
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, { onSameUrlNavigation: "reload" }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
