<mat-form-field
  appearance="outline"
  class="filter-dropdown"
  style="font-size: 12px"
>
  <input
    matInput
    [matDatepicker]="picker1"
    autocomplete="off"
    (click)="picker1.open()"
    [(ngModel)]="selectedDate"
    (ngModelChange)="onChange()"
    placeholder="{{ placeholderText }}"
  />
  <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
  <button
    *ngIf="selectedDate"
    mat-icon-button
    matSuffix
    aria-label="Clear"
    (click)="clearField()"
  >
    <mat-icon>close</mat-icon>
  </button>
  <mat-datepicker #picker1></mat-datepicker>
</mat-form-field>
