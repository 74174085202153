import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "convertDataString",
})
export class ConvertDataStringPipe implements PipeTransform {
  transform(arr: any[], keys: any[], startCount = 1): unknown {
    let sdata = "";
    for (const [i, item] of arr.entries()) {
      if (i >= startCount) {
        if (keys.length > 0) {
          for (const key of keys) {
            sdata += item[key] + " ";
          }
        } else {
          sdata += item + " ";
        }
        if (i !== arr.length - 1) {
          sdata += ",\n ";
        }
      }
    }

    return sdata;
  }
}
