import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  public isVendorLogin = new BehaviorSubject<boolean>(false);
  public loginStatus = new BehaviorSubject<boolean>(false);
  public tagsEnabled = new BehaviorSubject<boolean>(false);

  constructor(private readonly http: HttpClient) {}

  setVendor(value = false) {
    this.isVendorLogin.next(value);
  }

  setTagsEnabled(value = false) {
    this.tagsEnabled.next(value);
  }

  setLoginStatus(value = false) {
    this.loginStatus.next(value);
  }

  login(payload: any) {
    return this.http.post(`api-token-auth/`, payload);
  }
  otplogin(payload: any) {
    return this.http.post(`vendors/otp/verify/`, payload);
  }
  verifyUserToken(token: any) {
    const data = { token };
    return this.http.post(`api-token-verify/`, data);
  }
  loginSSO(payload: any) {
    return this.http.post(`users/check-user-exists/`, payload);
  }

  forgotPassword(username: any) {
    return this.http.post(`v1/users/get-user-profile/`, username);
  }

  signupuser(payload: any) {
    return this.http.post(`users/register/`, payload);
  }
  createotp(payload: any) {
    return this.http.post(`users/create-otp/`, payload);
  }
  verifyotp(payload: any) {
    return this.http.post(`users/verify-otp/`, payload);
  }
  createuser(payload: any) {
    return this.http.post(`users/create-user/`, payload);
  }

  changepassword(data: any, token: any) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `JWT ${token}`);
    return this.http.post("api-password-reset-confirm/", JSON.stringify(data), {
      headers,
    });
  }

  getLinkPasswordResetLink(data: any) {
    return this.http.post(`api-password-reset/`, data);
  }
  verifyToken(token: any) {
    return this.http.post(`api-password-reset-verify/`, token);
  }

  getTokenfromSSO(profile: any) {
    const payload = {
      first_name: profile.givenName,
      last_name: profile.surname,
      email: profile.userPrincipalName,
    };
    return this.http.post(`users/get-token-by-sso/`, payload);
  }

  triggerOtp(data: any) {
    return this.http.post(`vendors/otp/`, data);
  }
}
