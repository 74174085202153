import { Injectable } from "@angular/core";
import { ValidatorFn, AbstractControl } from "@angular/forms";

@Injectable({
  providedIn: "root",
})
export class FormValidatorService {
  constructor() {}
  autocompleteObjectValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value && typeof control.value === "string") {
        return { optionError: { value: control.value } };
      }
      return null;
    };
  }
  autocompleteStringValidator(validOptions: Array<string>): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value && validOptions?.indexOf(control.value) !== -1) {
        return null;
      }
      return { optionError: { value: control.value } };
    };
  }
}
