import { APP_INITIALIZER, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppRoutingModule } from "./app-routing.module";
import { AddFileModalComponent, AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AuthLayoutComponent } from "./auth-layout/auth-layout.component";
import { AuthGuard } from "./shared/guard/auth.guard";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { MyHttpInterceptor } from "./shared/interceptor/interceptor";
import { ToastrModule } from "ngx-toastr";
import { SharedModule } from "./shared/shared.module";
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxPermissionsModule } from "ngx-permissions";
import { MatChipsModule } from "@angular/material/chips";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { MaterialmodulesModule } from "./shared/materialmodules.module";
import { PipeModule } from "./shared/pipes/pipe.module";
import {
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType,
  BrowserCacheLocation,
  LogLevel,
} from "@azure/msal-browser";
import {
  MsalGuard,
  MsalInterceptor,
  MsalBroadcastService,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuardConfiguration,
  MsalRedirectComponent,
} from "@azure/msal-angular";
import { environment } from "src/environments/environment";
import { MatRadioModule } from "@angular/material/radio";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { CookieService } from "ngx-cookie-service";
import { TimeagoModule } from "ngx-timeago";
import { NgPipesModule } from "ngx-pipes";
import { QuillModule } from "ngx-quill";
import { NgxDocViewerModule } from "ngx-doc-viewer";
import { ParadeCreateModalDialogComponent } from "./pages/parade/parade-create-modal/parade-create-modal";
import { ParadeExistingModalDialogComponent } from "./pages/parade/parade-existing-modal/parade-existing-modal";
import { CommonService } from "./shared/services/common.service";

const isIE =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1; // Remove this line to use Angular Universal

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  let env: any = environment.tenant_info;
  let userDomain = window.location.host?.split(".")?.[0];

  let tenant = userDomain;
  if (userDomain?.includes("localhost")) {
    tenant = "alpha";
  }
  return new PublicClientApplication({
    auth: {
      // clientId: '6226576d-37e9-49eb-b201-ec1eeb0029b6', // Prod enviroment. Uncomment to use.
      clientId: env[tenant]?.CLIENT_ID, //environment.PCAClientId, // PPE testing environment
      authority: "https://login.microsoftonline.com/" + env[tenant]?.TENANT_ID, // Prod environment. Uncomment to use.
      // authority: 'https://login.windows-ppe.net/common', // PPE testing environment.
      redirectUri: "/auth/sso",
      postLogoutRedirectUri: "/",
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set("https://graph.microsoft.com/v1.0/me", [
    "user.read",
  ]); // Prod environment. Uncomment to use.
  // protectedResourceMap.set('https://graph.microsoft-ppe.com/v1.0/me', ['user.read']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ["user.read"],
      prompt: "select_account",
    },
    loginFailedRoute: "/",
  };
}

export function initializeApp(commonService: CommonService) {
  return (): Promise<any> => {
    return commonService
      .getDisplayNames()
      .toPromise()
      .then(
        (response: any) => {
          localStorage.setItem("displayLabels", JSON.stringify(response));
        },
        (error) => {
          console.error(error);
        }
      );
  };
}

@NgModule({
  declarations: [
    AppComponent,
    AuthLayoutComponent,
    AddFileModalComponent,
    ParadeCreateModalDialogComponent,
    ParadeExistingModalDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    SharedModule,
    NgxSpinnerModule,
    NgxPermissionsModule.forRoot(),
    MatChipsModule,
    Ng2SearchPipeModule,
    MaterialmodulesModule,
    MatRadioModule,
    MatCheckboxModule,
    MsalModule,
    PipeModule.forRoot(),
    TimeagoModule.forRoot(),
    NgPipesModule,
    QuillModule.forRoot(),
    NgxDocViewerModule,
    /* NgxSkeletonLoaderModule.forRoot() */
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MyHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [CommonService],
      multi: true,
    },
    AuthGuard,
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    CookieService,
    CommonService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {
  // constructor(router: Router) {
  //   let subscription = router.events.subscribe((event) => {
  //     if (event instanceof NavigationStart) {
  //       let browserRefresh = !router.navigated;
  //       console.log(browserRefresh);
  //     }
  //   });
  // }
}
