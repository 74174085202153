import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";

@Component({
  selector: "app-searchable-multiselect",
  templateUrl: "./searchable-multiselect.component.html",
  styleUrls: ["./searchable-multiselect.component.scss"],
})
export class SearchableMultiselectComponent {
  @Input() controlName: any;
  @Input() placeholderText: any;
  @Input() options: any;
  @Input() isDisabled: any;
  @Output() multiSelectValues = new EventEmitter();

  selectedValues: any = [];
  currentSelectedValue: any;
  searchText = "";
  constructor() {}

  filteredList(filterObj: any) {
    if (filterObj) {
      let filteringArray = [];
      if (this.selectedValues) {
        filteringArray = this.selectedValues;
      }
      let filteredArray = filterObj;
      if (filteringArray.length > 0) {
        filteredArray = filterObj.filter(function (arrayEl: any) {
          return (
            filteringArray.filter(function (innerel: any) {
              return innerel.id === arrayEl.id;
            }).length === 0
          );
        });
      }
      if (typeof this.searchText === "string") {
        filteredArray = filteredArray.filter((f: any) => {
          let opt = f.name;
          if (f.display_name) {
            opt = f.display_name;
          }
          if (opt) {
            return (
              opt.toLowerCase().indexOf(this.searchText.toLowerCase()) === 0
            );
          } else {
            return false;
          }
        });
      }
      return filteredArray;
    } else {
      return [];
    }
  }

  clearField() {
    this.selectedValues = [];
    this.multiSelectValues.emit({
      values: this.selectedValues,
      currentValue: null,
    });
  }

  remove(val: string): void {
    const index = this.selectedValues.indexOf(val);
    if (index >= 0) {
      this.selectedValues.splice(index, 1);
      if (!this.selectedValues.length) {
        this.currentSelectedValue = "";
        this.searchText = "";
      }
    }
    this.currentSelectedValue =
      this.selectedValues[this.selectedValues?.length - 1];
    this.multiSelectValues.emit({
      values: this.selectedValues,
      currentValue: this.currentSelectedValue,
    });
  }

  onOptionSelection(event: MatAutocompleteSelectedEvent) {
    this.currentSelectedValue = event.option.value;
    this.selectedValues.push(event.option.value);
    this.multiSelectValues.emit({
      values: this.selectedValues,
      currentValue: this.currentSelectedValue,
    });
  }

  manipulateValue(val: any) {
    this.currentSelectedValue = val;
    this.selectedValues.push(val);
    this.multiSelectValues.emit({
      values: this.selectedValues,
      currentValue: this.currentSelectedValue,
    });
  }
}
